import { forwardRef } from 'react';
import { Group, Text } from '@mantine/core';

import { CategorySelectItemProps } from './CategorySelectItem.types';

export const CategorySelectItem = forwardRef<HTMLDivElement, CategorySelectItemProps>(
  ({ label, level, ...rest }, ref) => (
    <div ref={ref} {...rest}>
      <Group noWrap>
        <Text size='sm' sx={{ paddingLeft: level * 10, fontWeight: !level ? 'bold' : 'normal' }}>
          {label}
        </Text>
      </Group>
    </div>
  ),
);

CategorySelectItem.displayName = 'CategorySelectItem';
