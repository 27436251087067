import { AsyncJobStatusPayload } from '@/modules/catalogTags/types';

export const MODAL_TITLE: Record<AsyncJobStatusPayload['status'], string> = {
  done: 'Теги загружены',
  pending: 'Ожидание загрузки',
  waiting: 'Ожидание загрузки',
  fail: 'Произошла ошибка',
  running: 'В работе',
};

export const MODAL_DESCRIPTION: Record<AsyncJobStatusPayload['status'], string> = {
  done: 'Файл успешно загружен',
  pending: '',
  waiting: '',
  running: '',
  fail: 'При загрузке файла были найдены ошибки, скачайте файл, чтобы увидеть их все',
};
