import React, { useState } from 'react';
import { Box, Stack, Sx, Text } from '@mantine/core';

import { getMainGalleryImage } from '@/utils/image';

import { variants } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.constants';
import { GeneralInformationCardTypes } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.types';
import { DateComponent } from '@/ui/components/GeneralInformationCard/variants/components/DateComponent';
import { productDetailTextStyle } from '@/ui/containers/Reviews/details/constants';
import { presentProductStyles } from '@/ui/containers/Reviews/details/details.constants';

import { ReactComponent as EventNote } from '@/assets/icons/event-note.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
import { useGetSingleProductQuery } from '@/store/slices/reviews/reviewsSlice';

const QuestionVariantCard = ({
  productInfo,
  author,
  editor,
  cardType,
}: GeneralInformationCardTypes) => {
  const [isLoad, setIsLoad] = useState(false);

  return (
    <Stack
      p={24}
      spacing={12}
      sx={{
        width: 352,
        borderRadius: 6,
        height: 'fit-content',
        border: '1px solid #E8ECF0',
        background: '#F6F8F8',
      }}
    >
      <Text weight={700}>Информация о продукте</Text>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 16 }} mt={20}>
        <div style={presentProductStyles.imgWrapper}>
          <img
            onLoad={() => setIsLoad(true)}
            alt='loader'
            style={{ display: 'none' }}
            src={productInfo?.preview}
          />
          {productInfo?.preview && isLoad ? (
            <img style={presentProductStyles.image} alt='example' src={productInfo?.preview} />
          ) : (
            <div style={presentProductStyles.emptyImage}>
              <EventNote />
            </div>
          )}
        </div>
        <div>
          <Text>{productInfo?.title || ' - '}</Text>
          <Text size={14} mt={2} mb={8} color={'#66788A'}>
            ID: {productInfo?.id || ''}
          </Text>
          <a
            href={`${process.env.PREVIEW_URL}/product/${productInfo?.entityCode || ''}`}
            target='_blank'
            style={{ textDecoration: 'none', color: '#322FC6' }}
          >
            Перейти к продукту
          </a>
        </div>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mt={20}>
        <Text>Средняя оценка</Text>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <StarIcon style={{ color: '#F2C94C' }} />
          {productInfo?.rating?.toFixed(1).replace('.0', '') ?? ' - '}
        </div>
      </Box>
      <Text mt={20} weight={700}>
        Основная информация
      </Text>
      <Box>
        <DateComponent text='Дата создания:' date={author.createDate} />
        {editor?.editDate && (
          <DateComponent text={variants[cardType].editDate} date={editor?.editDate} />
        )}

        {author.createDate && (
          <>
            <div style={productDetailTextStyle}>
              <Text color='#66788A' size='sm'>
                {variants[cardType].author}
              </Text>
              <Text sx={{ textAlign: 'end', whiteSpace: 'normal' }}>{author?.name || ' - '}</Text>
            </div>
            <Text sx={presentProductStyles.uuid as Sx}>uuid: {author?.uuid || ' - '}</Text>
          </>
        )}
        {editor?.editDate && (
          <>
            <div style={productDetailTextStyle}>
              <Text color='#66788A' size='sm'>
                Изменено:
              </Text>
              <Text sx={{ textAlign: 'end', whiteSpace: 'normal' }}>{editor.name}</Text>
            </div>
            {editor?.email && <Text sx={presentProductStyles.uuid as Sx}>{editor?.email}</Text>}
          </>
        )}
      </Box>
    </Stack>
  );
};

export const QuestionVariant = (props: GeneralInformationCardTypes) => {
  const response = useGetSingleProductQuery(props?.entityCode || '');

  const productInfo: GeneralInformationCardTypes['productInfo'] = {
    entityCode: props?.entityCode,
    id: response.data?.payload.id,
    preview:
      (process.env.MEDIA_STORAGE_URL as string) +
      getMainGalleryImage(response.data?.payload.productGallery)?.image,
    rating: props?.rating,
    title: response.data?.payload.title,
  };
  return <QuestionVariantCard {...props} productInfo={productInfo} />;
};
