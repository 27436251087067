import { SnippetsMap, SnippetVariant } from '@prosv/core/types/snippets';

import {
  genSnippetSelector,
  setSnippetDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { SnippetData } from '../../store/store.types';

export const useGetSnippetData = <T extends SnippetVariant>(variant: T) => {
  type S = SnippetsMap[T];
  const snippetData = useStoreContentEditor(genSnippetSelector(variant)) as SnippetData<T>;
  const { rowId, cardId } = snippetData || {};
  const setSnippetData = useStoreContentEditor(setSnippetDataSelector);

  const defaultValues = useStoreContentEditor((state): S | undefined => {
    const data = state.savedContentData?.grid[Number(rowId)]?.blocks?.[Number(cardId)];

    if (data?.variant === variant) {
      return data as S;
    }

    return;
  });

  const handleClose = () => {
    setSnippetData(null);
  };

  return {
    snippetData,
    setSnippetData,
    defaultValues,
    handleClose,
  };
};
