import { useEffect } from 'react';
import { Box, Button, Paper, Select } from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePermission } from '@/hooks/usePermissions';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { Loader } from '@/ui/containers/ContentEditor/Loader';

import { useGeneralSettingsState } from './GeneralSettings.state';
import { InputForm } from './InputForm';
import { Slider } from './Slider';
import { Toggler } from './Toggler';

import { useGeneralSettingsHandlers } from '@/pages/GeneralSettings/GeneralSettings.handlers';
import { fetchGeneralSetting } from '@/store/slices/generalSettings/generalSettings';

const GENERAL_SETTINGS_FORM_ID = 'general-settings-form';

const mapComponents = {
  string: InputForm,
  switch: Toggler,
  slider: Slider,
};

export const GeneralSettings = () => {
  const canUpdate = usePermission('SETTING_UPDATE');
  const dispatch = useAppDispatch();

  const state = useGeneralSettingsState();

  const handlers = useGeneralSettingsHandlers(state);

  useEffect(() => {
    dispatch(fetchGeneralSetting());
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Loader loading={state.isLoading} blur />

      <PageHeader
        title='Общие настройки'
        rightButton={
          canUpdate ? (
            <Button sx={{ width: '200px' }} type='submit' form={GENERAL_SETTINGS_FORM_ID}>
              Сохранить
            </Button>
          ) : null
        }
      />

      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          height: '100%',
          overflow: 'hidden',
          gap: '32px',
          position: 'relative',
        }}
      >
        <form
          id={GENERAL_SETTINGS_FORM_ID}
          onSubmit={state.form.onSubmit(handlers.handleSubmitForm)}
          style={{
            display: 'grid',
            gridTemplateColumns: '296px 528px',
            gap: '30px',
            alignItems: 'center',
            maxHeight: '100%',
            overflowY: 'scroll',
          }}
        >
          <Select
            placeholder='Выберите сервис'
            sx={{ width: '296px', gridColumn: 'span 2' }}
            onChange={state.setSelectedServiceFormValue}
            value={state.selectedServiceFormValue}
            data={state.optionServices}
          />

          {state.selectedFormData?.map(({ type, translated, key, description, placeholder }) => {
            const Component = mapComponents[type];

            const props = {
              title: translated,
              description,
              placeholder,
              elementProps: state.form.getInputProps(key),
            };

            return <Component {...props} key={key} />;
          })}
        </form>
      </Paper>
    </Box>
  );
};
