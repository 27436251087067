import { createSelector } from '@reduxjs/toolkit';

import { bannersInitialState } from '@/pages/Banners/BannersListing/BannerListing.constants';
import { IRootReducer } from '@/store';

const bannersFilter = (state: IRootReducer) => state.bannersFilter;

export const isDirtyFilterSelector = createSelector(bannersFilter, (filters) => {
  return JSON.stringify(filters) === JSON.stringify(bannersInitialState);
});

export const bannerFiltersSelector = createSelector(bannersFilter, (filters) => {
  const { limit, place, currentPage, page, site, isActive, sortField, sortDirection } = filters;
  return { limit, place, currentPage, page, site, isActive, sortField, sortDirection };
});
