import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getFormatDateEndDay, getFormatDateStartDay } from '@/utils/getFormatDateForBackend';
import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import { PAGE_ELEMENTS } from '@/constants/common';

import { TOption } from '@/ui/components/MultiSelect';
import {
  ReviewAndQuestionsQueryParams,
  StatusTypes,
} from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

import { IRootReducer } from '@/store';
import { CreateFilterSlice, createTableFilterSlice } from '@/store/slices/banners/bannersSlice';
import {
  PatchQuestionAnswerParams,
  PostQuestionAnswerParams,
  QuestionsResponse,
  QuestionType,
} from '@/store/slices/questions/questions.types';

export interface QuestionsProductsFilter
  extends Pick<
    CreateFilterSlice,
    'currentPage' | 'page' | 'limit' | 'sortDirection' | 'sortField'
  > {
  article?: string;
  id?: string;
  status?: TOption[];
  rating?: TOption[];
  hasReply?: TOption;
  createDate?: [null | Date, null | Date];
}
export const questionsProductsInitialState: QuestionsProductsFilter = {
  currentPage: 1,
  limit: PAGE_ELEMENTS[0].value,
  createDate: [null, null],
  status: [],
  rating: [],
  article: '',
  id: '',
  hasReply: { label: 'Все', value: '' },
};

export const questionsProductsFilterSlice = createTableFilterSlice<QuestionsProductsFilter>({
  name: 'questionsProductsFilter',
  initialState: questionsProductsInitialState,
});
export const questionsProductsFilterActions = questionsProductsFilterSlice.actions;

export const questionsApi = createApi({
  reducerPath: 'api/questions',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    getQuestionsProductsList: build.query<QuestionsResponse, ReviewAndQuestionsQueryParams>({
      query: (params) => {
        const data = {
          sort: {
            field: params.sortField,
            direction: params.sortDirection,
          },
          type: ['product'],
          article: params.article ? [params.article] : undefined,
          questionId: params.id ? [params.id] : undefined,
          status: (params.status || []).map((el) => el.value),
          page: {
            limit: params.limit,
            offset:
              params.currentPage === 1
                ? 0
                : Number(params.limit) * (Number(params.currentPage) - 1),
          },
          dateFrom: params.createDate?.[0] ? getFormatDateStartDay(params.createDate?.[0]) : null,
          dateTo: params.createDate?.[1] ? getFormatDateEndDay(params.createDate?.[1]) : null,
          hasAnswer: params.hasReply?.value ? params.hasReply?.value : undefined,
        };

        return {
          method: 'post',
          url: getUrlWithSearchParams(URLS.questions.getList, {}),
          data,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getSingleQuestion: build.query<{ payload: QuestionType }, string>({
      query: (id) => {
        return {
          method: 'get',
          url: getUrlWithSearchParams(URLS.questions.getItem(id)),
        };
      },
      keepUnusedDataFor: 0,
    }),
    setQuestionStatus: build.query<void, { id: string; status: StatusTypes }>({
      query: ({ status, id }) => ({
        method: 'patch',
        url: getUrlWithSearchParams(URLS.questions.setStatus),
        data: {
          ids: [id],
          status,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    patchQuestionAnswer: build.query<void, PatchQuestionAnswerParams>({
      query: ({ answerId, answer, attachments }) => ({
        method: 'patch',
        url: getUrlWithSearchParams(URLS.questions.patchAnswer(answerId)),
        data: { answer, attachments },
      }),
    }),
    sendQuestionAnswer: build.query<void, PostQuestionAnswerParams>({
      query: (params) => ({
        method: 'post',
        url: getUrlWithSearchParams(URLS.questions.postAnswer),
        data: params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyGetQuestionsProductsListQuery,
  useGetSingleQuestionQuery,
  useLazySetQuestionStatusQuery,
  useLazySendQuestionAnswerQuery,
  useLazyPatchQuestionAnswerQuery,
} = questionsApi;

const questionProductsFilter = (state: IRootReducer) => state.questionsProductsFilter;

export const questionProductsFilterSelector = createSelector(questionProductsFilter, (params) => {
  return params;
});

export const isDirtyQuestionsFilterSelector = createSelector(
  questionProductsFilter,
  ({ status, rating, createDate, hasReply, sortDirection, sortField }) => {
    const initFilterJSON = JSON.stringify({
      status: questionsProductsInitialState.status,
      rating: questionsProductsInitialState.rating,
      createDate: questionsProductsInitialState.createDate,
      hasReply: questionsProductsInitialState.hasReply,
      sortDirection: questionsProductsInitialState.sortDirection,
      sortField: questionsProductsInitialState.sortField,
    });

    const currentFilterJSON = JSON.stringify({
      status,
      rating,
      createDate,
      hasReply,
      sortDirection,
      sortField,
    });
    return initFilterJSON !== currentFilterJSON;
  },
);
