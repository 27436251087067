import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, LoadingOverlay, ScrollArea, Tabs } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { isEqual } from 'lodash';

import { buildDataForUpdate, getFlatDataStructure } from '@/utils/getTransformMenuItems';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePermission } from '@/hooks/usePermissions';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageBody from '@/ui/components/Page/components/PageBody/PageBody';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import Page from '@/ui/components/Page/Page';
import { MenuTabSite } from '@/ui/containers/MenuContainer/components/MenuTabSite/MenuTabSite';
import { MenuContextEnum, menuName } from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { TEditMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';
import { AddOrEditItem } from '@/ui/containers/MenusContainer/components/AddOrEditItem/AddOrEditItem';
import { EAddNewItemConfirmationTexts } from '@/ui/containers/MenusContainer/components/AddOrEditItem/AddOrEditItem.constants';
import { TAddOrEditItem } from '@/ui/containers/MenusContainer/MenuContainer.types';

import {
  fetchGetMenuItemsContextAction,
  fetchUpdateMenuAction,
  selectAddMenuItemsModal,
  selectAnotherContextItems,
  selectDefaultMenuItems,
  selectDeleteMenuItemId,
  selectEditMenuItem,
  selectFetchingGetMenuItems,
  selectFetchingUpdateMenuItem,
  selectMenuContext,
  selectMenuItems,
  selectParentId,
  setAddMenuItemsModalOpened,
  setDeleteMenuItem,
  setDeleteMenuItemId,
  setEditMenuItem,
  setMenuContext,
  setMenuItem,
  setParentId,
  setResetMenuItems,
  setUpdatedMenuItem,
} from '@/store/slices/menu/menuItems';
import { selectCurrentMenuType } from '@/store/slices/menu/menuTypes';

export const MenuContainer: FC = () => {
  const canUpdate = usePermission('MENU_UPDATE');
  const dispatch = useAppDispatch();
  const { height } = useElementSize();
  const { menuCode } = useParams();
  const menuAddItemsModal = useSelector(selectAddMenuItemsModal);
  const deleteMenuItemId = useSelector(selectDeleteMenuItemId);
  const anotherContextItems = useSelector(selectAnotherContextItems);
  const menuItems = useSelector(selectMenuItems);
  const defaultMenuItems = useSelector(selectDefaultMenuItems);
  const editMenuItem = useSelector(selectEditMenuItem);
  const menuContext = useSelector(selectMenuContext);
  const parentId = useSelector(selectParentId);
  const currentTypeMenu = useSelector(selectCurrentMenuType);
  const isFetching = useSelector(selectFetchingGetMenuItems);
  const isUpdating = useSelector(selectFetchingUpdateMenuItem);

  const navigate = useNavigate();

  const breadcrumbs = [
    {
      name: 'Меню',
      url: '/menu',
    },
    {
      name: `${currentTypeMenu}`,
    },
  ];

  const { proceed, reset, state } = useBlocker(!isEqual(defaultMenuItems, menuItems));

  const currentParent = useMemo(() => {
    return menuItems && menuItems.find((item) => item.id === parentId);
  }, [parentId]);

  const onCreateNewMenuItem = (values: Omit<TAddOrEditItem, 'id'>, id: string | null) => {
    dispatch(setMenuItem({ values, parentId: id }));
    dispatch(setParentId(null));
  };

  const onEditMenuItemHandler = (values: TEditMenuItem | Omit<TAddOrEditItem, 'id'>) => {
    dispatch(setUpdatedMenuItem({ updatedMenu: values, menuItems }));
    dispatch(setEditMenuItem({ item: null, parentId: null }));
  };

  const onDeleteHandler = () => {
    dispatch(setDeleteMenuItem(deleteMenuItemId));
    dispatch(setDeleteMenuItemId(null));
  };

  const onSaveHandler = () => {
    if (menuItems && currentTypeMenu) {
      dispatch(
        fetchUpdateMenuAction({
          data: buildDataForUpdate(menuItems, menuContext, anotherContextItems),
          menuType: currentTypeMenu,
        }),
      );
    }
  };

  const onChangeTab = (context: MenuContextEnum) => {
    dispatch(setMenuContext(context));
  };

  const onConfirmLeave = useCallback(() => {
    if (proceed) {
      proceed();
    }
  }, [proceed]);

  const onCancel = useCallback(() => {
    if (reset) {
      reset();
    }
  }, [reset]);

  useEffect(() => {
    if (!currentTypeMenu) {
      navigate('/menu');
    }
    return () => {
      dispatch(setResetMenuItems());
    };
  }, []);

  useEffect(() => {
    if (currentTypeMenu) {
      dispatch(fetchGetMenuItemsContextAction({ menuContext, menuType: currentTypeMenu }));
    }
  }, [menuContext]);

  return (
    <Page>
      <PageHeader
        title={`${currentTypeMenu ? menuName[currentTypeMenu] : 'Меню'}`}
        subTitle={`CODE: ${menuCode}`}
        backLink='/menu'
        breadcrumbs={breadcrumbs}
        rightButton={
          canUpdate ? (
            <Button loading={isUpdating} onClick={onSaveHandler}>
              Сохранить
            </Button>
          ) : null
        }
      />
      <PageBody>
        <Box style={{ position: 'relative' }}>
          <ScrollArea.Autosize offsetScrollbars maxHeight={height - 10}>
            <Tabs
              color='teal'
              defaultValue='first'
              keepMounted={false}
              sx={{ height: '100%', position: 'relative' }}
            >
              <Tabs.List sx={{ borderBottom: 'none' }}>
                <Tabs.Tab
                  value='first'
                  color='primary'
                  onClick={() => onChangeTab(MenuContextEnum.CORP)}
                >
                  Корпоративный сайт
                </Tabs.Tab>
                <Tabs.Tab
                  value='second'
                  color='primary'
                  onClick={() => onChangeTab(MenuContextEnum.USER)}
                >
                  Пользовательский сайт
                </Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value='first' pt='xs' sx={{ height: '90vh', overflow: 'hidden' }}>
                <MenuTabSite items={menuItems} />
              </Tabs.Panel>

              <Tabs.Panel
                value='second'
                pt='xs'
                sx={{ padding: '24px 0 34px 0', height: '90vh', overflow: 'hidden' }}
              >
                <MenuTabSite items={menuItems} />
              </Tabs.Panel>
            </Tabs>
          </ScrollArea.Autosize>
          <LoadingOverlay visible={isFetching} style={{ position: 'absolute', top: '50%' }} />
        </Box>
      </PageBody>

      <AddOrEditItem
        parentId={parentId}
        visibilityParent={currentParent?.isVisible || true}
        context={menuContext}
        isOpened={menuAddItemsModal}
        onChange={onCreateNewMenuItem}
        onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
      />

      {editMenuItem && (
        <AddOrEditItem
          context={menuContext}
          visibilityParent={currentParent?.isVisible || true}
          editItem={getFlatDataStructure(editMenuItem)}
          isOpened={menuAddItemsModal}
          onChange={onEditMenuItemHandler}
          onClose={() => dispatch(setAddMenuItemsModalOpened(false))}
        />
      )}
      {deleteMenuItemId && (
        <ModalConfirm
          title={EAddNewItemConfirmationTexts.TITLE}
          text={EAddNewItemConfirmationTexts.REMOVE_FROM_ALL_SITES}
          confirmHandler={onDeleteHandler}
          opened={!!deleteMenuItemId}
          onClose={() => dispatch(setDeleteMenuItemId(null))}
        />
      )}
      {state === 'blocked' && (
        <ModalConfirm
          title={EAddNewItemConfirmationTexts.OUT_TITLE}
          text={EAddNewItemConfirmationTexts.NOT_SAVE_MENU_SITE}
          confirmHandler={onConfirmLeave}
          opened={state === 'blocked'}
          onClose={onCancel}
        />
      )}
    </Page>
  );
};
