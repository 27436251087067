import { UmkSystemTypeFieldOptions } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemSnippet.types';

export enum UmkSystemSnippetFormFields {
  BLOCK_TYPE = 'blockType',
  TITLE = 'title',
  START_POURING_FROM_FIRST_BLOCK = 'startPouringFromFirstBlock',
  BACKGROUND_COLOR = 'backgroundColor',
  TEXT = 'text',
  IMAGE = 'image',
  BLOCKS = 'blocks',
}

export const umkBlockFieldOptions: UmkSystemTypeFieldOptions[] = [
  { label: 'Один блок в строке', value: 'column' },
  { label: 'Два блока в строке', value: 'row' },
];

export const UMK_COLOR_PICKER_COLORS = ['#DFF1FF', '#DEE2E6', '#FFFFFF'];
