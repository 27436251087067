import { IRedirect } from '@/types/redirects/redirects';

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import {
  LIMIT_QUERY,
  OFFSET_QUERY,
} from '@/ui/containers/RedirectsContainer/RedirectsContainer.constants';

import { ReactComponent as TrashFilled } from '@/assets/icons/trash-filled.svg';
import {
  deleteRedirectAction,
  deleteRedirectIdSelector,
  fetchRedirectListAction,
  redirectsOffsetSelector,
  setDeleteRedirectId,
  setEditRedirectItem,
  setRemoveRedirectItem,
} from '@/store/slices/redirects/redirects';

interface IProps {
  items: IRedirect[];
}

export const RedirectsTable: FC<IProps> = ({ items }) => {
  const dispatch = useAppDispatch();
  const offset = useSelector(redirectsOffsetSelector);

  const theme = useMantineTheme();

  const deleteRedirectId = useSelector(deleteRedirectIdSelector);

  const handleEditRedirect = (editId: string) => {
    dispatch(setEditRedirectItem(editId));
  };

  const handleDeleteRedirect = async (itemId: string) => {
    await dispatch(deleteRedirectAction(itemId));
    await dispatch(fetchRedirectListAction({ [OFFSET_QUERY]: offset, [LIMIT_QUERY]: 10 }));
  };

  return (
    <>
      <Table highlightOnHover verticalSpacing='xs' fontSize='sm'>
        <thead style={{ background: '#E8ECF0' }}>
          <tr>
            <th>Старый адрес URL</th>
            <th>Новый адрес URL</th>
            <th>Код редиректа</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ id, new: newUrl, old, statusCode }) => {
            return (
              <tr key={id}>
                <td>{old}</td>
                <td>{newUrl}</td>
                <td>{statusCode}</td>
                <td style={{ maxWidth: '40px' }}>
                  <Button
                    name='Редактировать'
                    title='Редактировать'
                    variant='subtle'
                    compact
                    style={{ position: 'static' }}
                    onClick={() => handleEditRedirect(id as string)}
                  >
                    <Pencil size={22} color={theme.colors.greyLight[9]} />
                  </Button>
                </td>
                <td style={{ maxWidth: '40px' }}>
                  <Button
                    name='Удалить'
                    title='Удалить'
                    variant='subtle'
                    compact
                    onClick={() => handleDeleteRedirect(id as string)}
                  >
                    <TrashFilled />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <ModalConfirm
        title='Удалить?'
        text='Настройка редиректа будет удалена. Восстановить данные после удаления не получится'
        opened={!!deleteRedirectId}
        onClose={() => dispatch(setDeleteRedirectId(null))}
        confirmHandler={() => dispatch(setRemoveRedirectItem(deleteRedirectId))}
        isReverseButton
      />
    </>
  );
};
