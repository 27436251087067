import { useEffect, useRef, useState } from 'react';
import { Box, Center, Group, Paper, SimpleGrid, Text, Textarea, TextInput } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { v4 as uuid_v4 } from 'uuid';

import { reviewsStatuses } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.consts';
import { QuestionsAndReviewFormProps } from '@/ui/components/ReviewAndQuestionsForm/ReviewAndQuestionsForm.types';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';
import { ReviewUploadPhoto } from '@/ui/containers/Reviews/details/components/ReviewUploadPhoto';
import { reviewTypeValues } from '@/ui/containers/Reviews/details/constants';

import { FilesLoader } from '../FilesLoader';

import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
import { inputStyles } from '@/pages/Banners/BannersListing/BannerListing.constants';
import { useReviewDetailStore } from '@/store/slices/reviews/reviewsSlice';

export const QuestionsAndReviewForm = ({
  status,
  type,
  rating,
  reviewText,
  attachments,
  answerAttachments,
  pageType,
  isLoading,
}: QuestionsAndReviewFormProps) => {
  const sessionID = useRef(uuid_v4());
  const editorRef = useRef<Editor | undefined | null>();
  const [isRenderTextEditor, setIsRenderTextEditor] = useState(false);
  const [answer, setAnswer, answerAttachmentsValue, setAttachments] = useReviewDetailStore(
    (state) => [
      state.params.answer,
      state.setAnswer,
      state.params.attachments,
      state.setAttachments,
    ],
  );

  const isReview = pageType === 'review';

  // useEffect нужен, так как answer приходит с бэка,
  // а editorRef при инициализации должен получить defaultTextValue={answer}
  useEffect(() => {
    if (!isLoading) {
      setIsRenderTextEditor(true);
    }
  }, [isLoading]);

  const title = isReview ? 'отзыва' : 'вопроса';

  return (
    <Paper
      radius={8}
      p={12}
      style={{
        minWidth: 400,
        display: 'flex',
        border: '1px solid #E8ECF0',
        overflow: 'hidden',
        height: 'fit-content',
        position: 'relative',
      }}
    >
      <SimpleGrid
        spacing={24}
        sx={{
          height: 'fit-content',
          width: '100%',
        }}
      >
        <Group spacing={16}>
          <SimpleGrid sx={{ width: 272 }} spacing='xs'>
            <Text weight={500} size='sm'>
              Статус
            </Text>
            <TextInput styles={inputStyles} value={reviewsStatuses[status]} readOnly disabled />
          </SimpleGrid>

          <SimpleGrid sx={{ width: 272 }} spacing='xs'>
            <Text weight={500} size='sm'>
              Тип {title}
            </Text>
            <TextInput
              styles={inputStyles}
              value={reviewTypeValues[type]}
              readOnly
              disabled
              placeholder='Заголовок'
            />
          </SimpleGrid>
          {isReview && (
            <SimpleGrid spacing={10}>
              <Text weight={500} sx={{ position: 'relative', width: 'fit-content' }} size='sm'>
                Оценка
                <div
                  style={{
                    width: 4,
                    position: 'absolute',
                    top: 2,
                    right: -6,
                    height: 4,
                    background: 'red',
                    borderRadius: '50%',
                  }}
                />
              </Text>
              <Group sx={{ height: 40, alignItems: 'flex-start' }} spacing={4}>
                {[1, 2, 3, 4, 5].map((value) => (
                  <StarIcon
                    style={{ color: value > (rating || 0) ? '#E8ECF0' : '#F2C94C' }}
                    key={value}
                  />
                ))}
              </Group>
            </SimpleGrid>
          )}
        </Group>

        <SimpleGrid cols={1} spacing='xs'>
          <Text weight={500} size='sm'>
            Текст {title}
          </Text>
          <Textarea minRows={7} readOnly disabled value={reviewText} />
        </SimpleGrid>

        {isReview && (
          <SimpleGrid>
            <Text weight={500} size='sm'>
              Вложения
            </Text>
            <div
              style={{ borderRadius: 8, padding: 12, marginTop: 8, color: '#8DA2B5' }}
              className='border-dashed'
            >
              {attachments?.length === 0 || !attachments ? (
                <Center
                  sx={{
                    height: 100,
                  }}
                >
                  Пользователь не добавлял вложения
                </Center>
              ) : (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                  {attachments.map((params) => (
                    <ReviewUploadPhoto readOnly {...params} />
                  ))}
                </div>
              )}
            </div>
          </SimpleGrid>
        )}

        <SimpleGrid>
          <Text weight={500} size='sm'>
            Текст ответа
          </Text>
          {isRenderTextEditor && (
            <div style={{ display: 'flex', minHeight: '200px', flexDirection: 'column' }}>
              <TextEditor
                defaultTextValue={answer}
                editorRef={editorRef}
                changeFormValue={(value) => setAnswer(value)}
              />
            </div>
          )}

          <Box>
            <Text>Прикрепить файл к тексту ответа</Text>
            <FilesLoader
              acceptImages
              sessionID={sessionID.current}
              savedAttachments={answerAttachments}
              onChange={(ids) => {
                setAttachments(ids, sessionID.current);
              }}
            />
          </Box>
        </SimpleGrid>
      </SimpleGrid>
    </Paper>
  );
};
