import notify from './notify';

export const errorHandler = (error: any, message?: string) => {
  console.error(error);

  if (message)
    notify({
      type: 'error',
      message:
        message === 'Invalid user credentials' ? 'Неверный логин или пароль' : message.trim(),
    });
};
