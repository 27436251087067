import { PermissionInfoItem } from '@prosv/core/src/lib/types/bff/base/permitions';
import { Banner } from '@prosv/core/types';

export enum EPromotionVariant {
  COMPACT = 'compact',
  FULL = 'full',
}

export type TPromotionsResponse<T> = {
  code: string;
  message: string;
  payload: T;
};

export interface IPromotionsListItem {
  uuid: string;
  title: string;
  isActive: string;
  dateFrom: string | null;
  dateTo: string | null;
}

export interface IPromotionDate {
  value: string;
}

export interface IPromotionMeta {
  createDate: IPromotionDate;
  updateDate: IPromotionDate;
  author: {
    id: string;
    fullName: string;
  };
  editor?: {
    id: string;
    fullName: string;
  };
}

export interface IPromotionItem {
  uuid: string;
  code: string;
  isActive: boolean;
  title: string;
  dateActiveStart: { value: string };
  dateActiveEnd: { value: string };
  isMain: boolean;
  erid: string;
  description: string;
  terms: string;
  isShowDelivery: boolean;
  deliveryDescription: string;
  draftId: string;
  type: EPromotionVariant;
  banner: Banner;
  meta: IPromotionMeta;
  permissions: PermissionInfoItem[];
}

export interface IPromotionCreationData {
  code: string;
  isActive: boolean;
  title: string;
  dateActiveStart: string | Date;
  dateActiveEnd: string | Date;
  isMain: boolean;
  erid: string;
  description: string;
  terms: string;
  isShowDelivery: boolean;
  deliveryDescription: string;
  draftId: string;
  type: EPromotionVariant;
  bannerId: string;
}

export interface IPromotionErrResponse {
  code: string;
  error: { [key: string]: string };
  message: string;
}

export type IPromotionUpdateData = Omit<
  IPromotionCreationData,
  'code' | 'dateActiveStart' | 'dateActiveEnd'
>;
