import type { TGEtNewsQuery } from '@/types/news/news';

import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiValue } from 'react-select';
import {
  Box,
  Button,
  Center,
  Container,
  Group,
  LoadingOverlay,
  Pagination,
  Paper,
  Select,
  Text,
  Title,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { debounce } from 'lodash';
import { RotateClockwise } from 'tabler-icons-react';

import { getOffset } from '@/utils/getOffset';
import { getPages } from '@/utils/getPages';

import { formatDate, PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePermission } from '@/hooks/usePermissions';

import { MultiSelect, TOption } from '@/ui/components/MultiSelect';
import NewsTable from '@/ui/containers/NewsContainer/components/NewsTable/NewsTable';

import { useNewsContainerState } from './NewsContainer.state';

import {
  fetchNewsListAction,
  setNewsLimit,
  setNewsOffset,
  setNewsPage,
} from '@/store/slices/news/news';
import { setServiceChanged } from '@/store/slices/service/service';

const NewsContainer: FC = () => {
  const canCreate = usePermission('NEWS_CREATE');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const state = useNewsContainerState();

  const fetchNewsListDebounced = debounce(() => {
    const payload: TGEtNewsQuery = {
      offset: state.newsOffset,
      limit: state.newsLimit,
      sortDirection: state.sortFiled?.direction,
      sortField: state.sortFiled?.field,
      ...state.filter,
    };

    return dispatch(fetchNewsListAction(payload));
  }, 500);

  useEffect(
    () => void dispatch(setServiceChanged(false)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => void fetchNewsListDebounced(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.newsLimit, state.newsOffset, state.filter, state.sortFiled],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '96vh',
        overflowY: 'hidden',
      }}
    >
      <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group position='apart'>
          <Title order={1}>Новости</Title>

          {canCreate && (
            <Button sx={{ width: '200px' }} onClick={() => navigate('/news/new')}>
              Создать
            </Button>
          )}
        </Group>
      </Container>

      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <Group mb={24} position='apart' align='flex-end' spacing='xs' grow>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <Text size='sm' color='grayDefault'>
              Категория
            </Text>
            <MultiSelect
              options={state.categoryOptions}
              label='Категория'
              onChange={(value) => {
                state.setCategorySelected(value as MultiValue<TOption>);
                state.setFilter('category', value as MultiValue<TOption>);
              }}
              value={state.categorySelected}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <Text size='sm' color='grayDefault'>
              Тема
            </Text>
            <MultiSelect
              options={state.themeOptions}
              label='Тема'
              value={state.themeSelected}
              onChange={(value) => {
                state.setThemeSelected(value as MultiValue<TOption>);
                state.setFilter('theme', value as MultiValue<TOption>);
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <Text size='sm' color='grayDefault'>
              Дивизион
            </Text>
            <MultiSelect
              options={state.divisionOptions}
              label='Дивизион'
              value={state.divisionSelected}
              onChange={(value) => {
                state.setDivisionSelected(value as MultiValue<TOption>);
                state.setFilter('division', value as MultiValue<TOption>);
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
            <Text size='sm' color='grayDefault'>
              Компания
            </Text>
            <MultiSelect
              options={state.companyOptions}
              label='Компания'
              value={state.companySelected}
              onChange={(value) => {
                state.setCompanySelected(value as MultiValue<TOption>);
                state.setFilter('company', value as MultiValue<TOption>);
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 226 }}>
            <Text size='sm' color='grayDefault'>
              Период создания
            </Text>
            <DateRangePicker
              locale='ru'
              placeholder='Выберите период'
              inputFormat={formatDate}
              value={state.valueDate}
              onChange={(value) => {
                state.setValueDate(value);
                state.setDateFilter(value);
              }}
              sx={(theme) => ({
                input: {
                  height: 39,
                  borderColor: theme.colors.greyLink[9],
                  '&:hover': { borderColor: theme.colors.primary[9] },
                  '&:active': { borderColor: theme.colors.primary[9] },
                  '::placeholder': {
                    color: theme.colors.greyDefault[9],
                    fontSize: 16,
                  },
                },
                'button[data-in-range]': {
                  color: '#fff !important',
                },
              })}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}>
            <Button
              variant='subtle'
              radius='xs'
              size='sm'
              compact
              sx={({ colors }) => ({
                color: colors.primary[9],
                ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
              })}
              rightIcon={<RotateClockwise size={20} />}
              onClick={state.resetFilters}
            >
              Сбросить
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 24,
              maxWidth: 'fit-content',
            }}
          >
            <Select
              sx={{ width: '60px', input: { paddingRight: '20px' } }}
              size='sm'
              defaultValue={String(state.newsLimit)}
              allowDeselect
              data={PAGE_ELEMENTS}
              onChange={(value) => {
                if (value) {
                  dispatch(setNewsLimit(Number(value)));
                  dispatch(setNewsOffset(getOffset(state.page, Number(value))));
                }
              }}
            />
          </Box>
        </Group>

        {state.newsList && state.newsList?.length > 0 && (
          <>
            <Box sx={{ position: 'relative', overflow: 'scroll' }}>
              {/*<ScrollArea style={{ height: '100%' }}>*/}
              <NewsTable
                elements={state.newsList}
                sortFiled={state.sortFiled}
                setSortField={state.setSortField}
              />
              {/*</ScrollArea>*/}
              <LoadingOverlay visible={state.isLoadingNewsList} />
            </Box>

            <Box
              sx={{
                marginTop: 'auto',
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                minHeight: '38px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Text size='md' color='greyDefault'>
                  Всего:
                </Text>
                &nbsp;
                <Text size='md'>{state.newsTotal}</Text>
              </Box>
              {state.newsTotal && getPages(state.newsTotal, state.newsLimit) > 1 && (
                <Pagination
                  sx={{ right: '0', position: 'absolute' }}
                  position={'right'}
                  total={getPages(state.newsTotal, state.newsLimit)}
                  onChange={(currentPage) => {
                    dispatch(setNewsOffset(getOffset(currentPage, state.newsLimit)));
                    dispatch(setNewsPage(currentPage));
                  }}
                />
              )}
            </Box>
          </>
        )}

        {!state.newsList?.length && (
          <Box sx={{ position: 'relative', height: '100%' }}>
            <LoadingOverlay visible={state.isLoadingNewsList} />

            {!state.isLoadingNewsList && (
              <Center style={{ height: '100%' }}>
                <Text align={'center'}>У вас нет новостных шаблонов</Text>
              </Center>
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default NewsContainer;
