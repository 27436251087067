import { PeopleSnippet, Person, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import { EPeopleSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/PeopleSnippetModal/PeopleSnippetModal.constants';

export const getPeopleSnippetInitialValues = (
  defaultValues?: PeopleSnippet,
): SnippetValues<PeopleSnippet> => {
  const getDefaultPerson = (person?: Person) => {
    return {
      [EPeopleSnippetFormFields.FULL_NAME]: person?.fullName || '',
      [EPeopleSnippetFormFields.PHONE]: person?.phone || '',
      [EPeopleSnippetFormFields.POSITION]: person?.position || '',
      [EPeopleSnippetFormFields.EMAIL]: person?.email || '',
      [EPeopleSnippetFormFields.BRIEF_INFORMATION]: person?.briefInformation || '',
      [EPeopleSnippetFormFields.FILE]: person?.file || {
        id: '',
        name: '',
        path: '',
      },
    };
  };
  const people = defaultValues?.people?.map(getDefaultPerson);
  const defaultPerson = getDefaultPerson();
  return {
    id: defaultValues?.id || uuid_v4(),
    people: people || [defaultPerson],
    title: defaultValues?.title || '',
  };
};

export const replaceFiles = async (values: SnippetValues<PeopleSnippet>, sessionId: string) => {
  const filesIds = values.people?.map(({ file }) => file?.id);

  if (filesIds && filesIds.length) {
    await replaceTempFile({
      query: {
        sessionId: sessionId,
        ids: [...filesIds] as string[],
      },
    });
  }
};
