import { Gallery, GallerySnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import { EGallerySnippetFormFields } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.constants';

export const getGallerySnippetInitialValues = (
  defaultValues?: GallerySnippet,
): SnippetValues<GallerySnippet> => {
  const getDefaultSlide = (slide?: Gallery) => {
    return {
      [EGallerySnippetFormFields.TITLE]: slide?.title || '',
      [EGallerySnippetFormFields.DESCRIPTION]: slide?.description || '',
      [EGallerySnippetFormFields.IMAGE]: slide?.image || {
        id: '',
        name: '',
        path: '',
      },
    };
  };
  const slides = defaultValues?.slides?.map(getDefaultSlide);
  const defaultSlide = getDefaultSlide();
  return {
    id: defaultValues?.id || uuid_v4(),
    slides: slides || [defaultSlide],
    loopSlide: defaultValues?.loopSlide || true,
  };
};

export const replaceFiles = async (values: SnippetValues<GallerySnippet>, sessionId: string) => {
  const filesIds = values.slides?.map(({ image }) => image?.id);

  if (filesIds && filesIds.length) {
    await replaceTempFile({
      query: {
        sessionId: sessionId,
        ids: [...filesIds] as string[],
      },
    });
  }
};
