import {
  IRequestGlobalSettings,
  IResponseGlobalSettings,
} from '@/types/globalSettings/globalSettings';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../instance';

export const getGlobalSettings = async () => {
  try {
    const { data } = await api.get<IResponseGlobalSettings>(
      URLS.globalSettings.getGlobalSettings(),
      {},
    );
    return data;
  } catch (err) {
    return notify({ message: 'Ошибка получения глобальных настроек', type: 'error' });
  }
};

export const putGlobalSettings = async (params: IRequestGlobalSettings) =>
  api.patch<IResponseGlobalSettings>(URLS.generalSettings.patchGeneralSettings(), { ...params });
