import { IBanner, TNullable } from '@/types/banner/banner';

import { useMemo } from 'react';

import { getImageData } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.helpers';
import { IImageField } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.types';

export const useImageFiles = (arr: IImageField[], fields: string[], banner: TNullable<IBanner>) => {
  return useMemo(() => {
    return arr.map((item, index) => {
      const field = fields[index] as keyof typeof banner;
      return {
        ...item,
        image: banner && getImageData(banner[field]),
      };
    });
  }, [banner]);
};
