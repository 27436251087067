import { EPromotionVariant, IPromotionItem } from '@/types/promotions';

import { FormErrors } from '@mantine/form/lib/types';

import {
  DEFAULT_DELIVERY_DESCRIPTION,
  EActivityFieldValues,
  EMainFieldValues,
  EPromotionFormFields,
  EVisibilityFieldValues,
  PromotionFormFields,
} from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.constants';
import { IPromotionFormFields } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.types';

const getPromotionStatus = (isActive?: boolean) => {
  return isActive ? EActivityFieldValues.ACTIVE : EActivityFieldValues.NOT_ACTIVE;
};
const getPromotionDeliveryStatus = (isShowDelivery?: boolean) => {
  return isShowDelivery ? EVisibilityFieldValues.SHOW : EVisibilityFieldValues.NOT_SHOW;
};
const getPromotionIsMainStatus = (isMain?: boolean) => {
  return isMain ? EMainFieldValues.MAIN : EMainFieldValues.NOT_MAIN;
};

const getDate = (date?: Date | string | null) => (!!date ? new Date(date) : '');

export const getDefaultValues = (promotion: IPromotionItem | null): IPromotionFormFields => {
  return {
    [EPromotionFormFields.CODE]: promotion?.code || '',
    [EPromotionFormFields.TITLE]: promotion?.title || '',
    [EPromotionFormFields.IS_ACTIVE]: getPromotionStatus(promotion?.isActive),
    [EPromotionFormFields.IS_MAIN]: getPromotionIsMainStatus(promotion?.isMain),
    [EPromotionFormFields.ERID]: promotion?.erid || '',
    [EPromotionFormFields.DATE_ACTIVE_START]: getDate(promotion?.dateActiveStart?.value) || '',
    [EPromotionFormFields.DATE_ACTIVE_END]: getDate(promotion?.dateActiveEnd?.value) || '',
    [EPromotionFormFields.DESCRIPTION]: promotion?.description || '',
    [EPromotionFormFields.TERMS]: promotion?.terms || '',
    [EPromotionFormFields.IS_SHOW_DELIVERY]: getPromotionDeliveryStatus(promotion?.isShowDelivery),
    [EPromotionFormFields.DELIVERY_DESCRIPTION]:
      promotion?.deliveryDescription || DEFAULT_DELIVERY_DESCRIPTION,
    [EPromotionFormFields.TYPE]: promotion?.type || EPromotionVariant.FULL,
    [EPromotionFormFields.DRAFT_ID]: promotion?.draftId || '',
    [EPromotionFormFields.BANNER_ID]: promotion?.banner?.uuid || '',
  };
};

export const generateInvalidFieldErrors = (errors: FormErrors): string[] => {
  const errorsMessage: string[] = [];
  for (const key in errors) {
    errorsMessage.push(
      `${PromotionFormFields[key as keyof typeof PromotionFormFields]} : ${errors[key]}`,
    );
  }
  return errorsMessage;
};
