import { FC } from 'react';
import { Burger, createStyles, Group, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';

const useStyles = createStyles({
  root: {
    position: 'relative',
    width: '100%',
    fill: '#bfcedb',
    transition: 'all 0.25s ease-in-out',

    '&:hover': {
      color: '#006fd6',
    },
  },
});

interface ILayoutNavbarBurger {
  opened: boolean;
  onClick?: () => void;
}

const LayoutNavbarBurger: FC<ILayoutNavbarBurger> = ({ opened, onClick }) => {
  const title = opened ? 'Свернуть' : 'Развернуть';
  const { hovered, ref } = useHover();
  const { classes } = useStyles();
  const theme = useMantineTheme();

  return (
    <div ref={ref}>
      <Group spacing={10} noWrap>
        <Burger
          color={hovered ? theme.colors.primary[7] : theme.colors.greyLight[9]}
          opened={opened}
          title={title}
          size='sm'
          onClick={onClick}
          sx={{ padding: opened ? 0 : 2 }}
        />
        <UnstyledButton
          title={title}
          className={classes.root}
          py={12}
          onClick={onClick}
          sx={({ colors }) => ({
            ':hover': {
              '*': { color: colors.primary[7] },
            },
          })}
        >
          {opened && (
            <Text
              lineClamp={1}
              style={{ lineHeight: 1.125, paddingBottom: '2px' }}
              color='greyDark'
            >
              {title}
            </Text>
          )}
        </UnstyledButton>
      </Group>
    </div>
  );
};

export default LayoutNavbarBurger;
