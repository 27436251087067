import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import { IGetMaterialResponse } from '@/ui/containers/MaterialsContainer/Materials.types';

import api from '../../instance';

export const useGetMaterial = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getMaterial: async (uuid: string) => {
      setIsLoading(true);
      try {
        const request = await api.get<IGetMaterialResponse>(URLS.materials.getMaterial(uuid));

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения материала', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
