export enum ProjectDetailsValuesEnum {
  NAME = 'name',
  IS_ACTIVE = 'isActive',
  TYPE = 'type',
  EVENT_IDS = 'eventIds',
  PROJECT_ID = 'projectId',
  UUID = 'uuid',
}

export const FORM_ID = 'projectFormId';
