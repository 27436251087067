import React from 'react';
import { Box, Text, useMantineTheme } from '@mantine/core';

import { TGridCardProps } from './GridCard.types';
import { GridContent } from './GridContent';

export const GridCard: React.FC<TGridCardProps> = (props) => {
  const { colors } = useMantineTheme();

  const handleClick = () => {
    if (!props.disabled) {
      props.setSelectedNumbersBlock(props.size);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderColor:
          props.selectedNumbersBlock === props.size ? colors.primary[9] : colors.greyLine[9],
        borderRadius: 4,
        padding: 24,
        gap: 16,
        cursor: props.disabled ? 'initial' : 'pointer',
        opacity: props.disabled ? 0.2 : 1,
      }}
      onClick={handleClick}
    >
      <GridContent size={props.size} />
      <Text size='sm' align='left' weight={600} color='#25262B'>
        Сетка в {props.size} колонк{`${props.size === 1 ? 'у' : 'и'}`}
      </Text>
    </Box>
  );
};
