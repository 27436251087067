import { PaperProps, Sx } from '@mantine/core';

import { PAGE_ELEMENTS } from '@/constants/common';

import { CreateFilterSlice } from '@/store/slices/support/supportDetailSlice';

export const supportsInitialState: CreateFilterSlice = {
  currentPage: 1,
  limit: PAGE_ELEMENTS[0].value,
  category: [],
  question: '',
};

export const sx: Sx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  minWidth: 216,
  maxWidth: 216,
};

export const inputStyles = {
  input: {
    height: 40,
    borderColor: '#E8ECF0',
    '::-webkit-input-placeholder': { color: '#8493a4', options: 0, fontSize: 16 },
  },
};

export const paperProps: PaperProps = {
  radius: 8,
  p: 24,
  pb: 12,
  shadow: 'xs',
  style: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
};

export const mainBoxProps: Sx = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '96vh',
  overflowY: 'hidden',
};

export const itemsPerPageBox: Sx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 24,
  maxWidth: 'fit-content',
};

export const questionOptions = [{ label: 'Вопрос', value: 'question' }];

export const work = [
  { label: 'Да', value: 'true' },
  { label: 'Нет', value: 'false' },
];
