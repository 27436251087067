import { useEffect } from 'react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { projectsSlice, selectProjectsOptions } from '@/store/slices/projects/projectsSlice';

export const useProjectsOptions = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(projectsSlice.endpoints.getProjectList.initiate({}));
  }, [dispatch]);

  return useAppSelector(selectProjectsOptions);
};
