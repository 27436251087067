import { SnippetsMap, SnippetValues, SnippetVariant } from '@prosv/core/types/snippets';

import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

export const handleAddSnippetData = async <T extends SnippetVariant>(
  contentBlockVariant: T,
  values: SnippetValues<SnippetsMap[T]>,
  handleClose: () => void,
  snippetData: { variant: T; rowId: number; cardId: number } | null,
) => {
  type S = SnippetsMap[T];

  useStoreContentEditor.setState((store) => {
    if (!snippetData) return;

    const { variant, rowId, cardId } = snippetData;
    const foundRowItem = store.savedContentData?.grid[rowId]?.blocks?.[cardId] as S;

    if (foundRowItem) {
      foundRowItem.variant = variant;

      if (foundRowItem.variant === contentBlockVariant) {
        const keys = Object.keys(values) as (keyof SnippetValues<S>)[];
        const rowItem = foundRowItem;

        keys.forEach((key) => {
          rowItem[key] = values[key];
        });
      }
    }

    store.selectContentBlockData = null;
  });

  await useStoreContentEditor.getState().updateContentData();

  handleClose();
};

export const getImageData = ({ id, path, name }: { id?: string; name?: string; path?: string }) => {
  if (id && path && name) {
    return [
      {
        preview: process.env.MEDIA_STORAGE_URL + path || '',
        name: name || '',
        id: id || '',
      },
    ];
  }
  return [];
};
