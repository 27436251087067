import React, { FC, useEffect } from 'react';
import { redirect } from 'react-router-dom';
import { Box, Paper, Tabs } from '@mantine/core';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';

import * as sx from './UmkDetail.styles';

import { ActionButtons } from '@/pages/UmkDetail/ActionButtons';
import { ContentEditorWrapper } from '@/pages/UmkDetail/ContentEditorWrapper/ContentEditorWrapper';
import { UMK_FORM_ID } from '@/pages/UmkDetail/UmkDetail.constants';
import { getBreadcrumbs } from '@/pages/UmkDetail/UmkDetail.helpers';
import { useUmkDetailState } from '@/pages/UmkDetail/UmkDetail.state';
import { UmkTabType } from '@/pages/UmkDetail/UmkDetail.types';
import { UmkDetailGeneralInfo } from '@/pages/UmkDetail/UmkDetailGeneralInfo';

const UmkDetail: FC = () => {
  const state = useUmkDetailState();

  useEffect(() => {
    if (state.isNewUmk) {
      state.handleSetDrafts();
    } else {
      state.fetchUmk().catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.id) {
    redirect('/umk');
    return null;
  }

  const breadcrumbs = getBreadcrumbs(state.isNewUmk, state.id);

  return (
    <Box sx={sx.root}>
      <PageHeader
        breadcrumbs={breadcrumbs}
        title={state.title}
        rightButton={
          <ActionButtons
            isNewUmk={state.isNewUmk}
            onDelete={() => state.setOpenModal(true)}
            previewUrl={state.previewUrl}
            permissions={state.permissions}
            formId={UMK_FORM_ID}
            isShowPreviewBtn
          />
        }
      />
      <Paper radius={8} p={24} shadow='xs' sx={sx.contentWrapper}>
        <Tabs
          color='teal'
          defaultValue='general'
          sx={{ height: '100%', position: 'relative' }}
          onTabChange={(tabName: UmkTabType) => state.setActiveTab(tabName)}
        >
          <Tabs.List sx={{ borderBottom: 'none' }}>
            <Tabs.Tab value='general' color='primary'>
              Общее
            </Tabs.Tab>

            <Tabs.Tab value='firstBlockEditor' color='primary' disabled={state.isLoading}>
              {state.firstTabLabel}
            </Tabs.Tab>
            {!state.isDirections && (
              <Tabs.Tab value='secondBlockEditor' color='primary' disabled={state.isLoading}>
                Редактор страницы (Блок 2)
              </Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value='general' pt='xs' sx={sx.tabPanel}>
            {state.draftIds && (
              <UmkDetailGeneralInfo
                draftIds={state.draftIds}
                id={state.id}
                isNewUmk={state.isNewUmk}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value='firstBlockEditor' pt='xs' sx={sx.tabPanel}>
            <Box>
              {state.firstDraftId && state.activeTab === 'firstBlockEditor' && (
                <ContentEditorWrapper draftId={state.firstDraftId} />
              )}
            </Box>
          </Tabs.Panel>

          {!state.isDirections && (
            <Tabs.Panel value='secondBlockEditor' pt='xs' sx={sx.tabPanel}>
              {state.secondDraftId && state.activeTab === 'secondBlockEditor' && (
                <ContentEditorWrapper draftId={state.secondDraftId} />
              )}
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
      {state.openModal && (
        <ModalConfirm
          title='Удалить?'
          text='Дополнительная информация по УМК будет удалена'
          opened={state.openModal}
          onClose={() => state.setOpenModal(false)}
          confirmHandler={state.handleDeleteUmk}
          subtitleProps={{ size: 'sm' }}
        />
      )}
    </Box>
  );
};

export default UmkDetail;
