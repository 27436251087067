import { Paper } from '@mantine/core';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { GlobalSettingTable } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable';
import { tableList } from '@/ui/containers/GlobalSettingsContainer/GlobalSettingsContainer.constants';

export const GlobalSettingsContainer = () => {
  return (
    <>
      <PageHeader title='Глобальные настройки' />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          height: '100%',
          overflow: 'hidden',
          gap: '32px',
          position: 'relative',
        }}
      >
        <GlobalSettingTable elements={tableList} />
      </Paper>
    </>
  );
};
