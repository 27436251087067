import * as Yup from 'yup';
import { string } from 'yup';

import { validationTexts } from './validationTexts';

export const STRING_REGEXP = /^\S*$|^([a-zA-Zа-яА-Я0-9\s,._!?;:"'`-]+)$/gm;
export const CODE_URL_REGEXP = /^(?![\d!"#$%&\'()*+,./:;<=>?@[\\]^_`{|}~«»]+$)[a-zA-Z0-9-]*$/;
export const REDIRECT_URL_REGEXP = /^[A-Za-z0-9-._~:/?#\[\]@!$&'()*+,;=%]*$/;
const STRING_MENU_REGEXP =
  /^([a-zA-Zа-яА-Я0-9,._!?;:"'`\S-]+)([a-zA-Zа-яА-Я0-9\s\S,._!?;:"'`-]+)$/gm;
const STRING_NOT_ONLY_WHITESPACE_REGEXP =
  /^([a-zA-Zа-яА-Я0-9,._!?;:"'`-]+)([a-zA-Zа-яА-Я0-9\s,._!?;:"'`-]+)$/gm;
export const CODE_REGEXP = /^\s*$|^[a-z0-9]+(?:-[a-z0-9]+)*$/gm;
export const STRING_PHONE_REGEXP = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/gm;
export const STRING_EMAIL_REGEXP =
  /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@[A-Z0-9.-]+\.[A-Z]{2,})$/i;
export const STRING_LINK_REGEXP =
  /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/gm;
const EXTENDED_CODE_REGEXP = /^([a-z0-9-//])*$/gm;
const EXCLUDE_SYMBOLS = /^[a-z0-9]+([a-z0-9-//])*[a-z0-9]$/gm;
export const EXCLUDE_PARAGRAPH_TAGS = /(<([^>]+)>)/gi;

export const VALIDATION_STRING = Yup.string()
  .matches(STRING_REGEXP, validationTexts.STRING)
  .matches(STRING_NOT_ONLY_WHITESPACE_REGEXP, validationTexts.STRING_NOT_ONLY_WHITESPACE_REGEXP)
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

const VALIDATION_MENU_STRING = Yup.string()
  .matches(STRING_MENU_REGEXP, validationTexts.STRING)
  .matches(STRING_MENU_REGEXP, validationTexts.STRING_NOT_ONLY_WHITESPACE_REGEXP)
  .min(3, validationTexts.MIN_LENGTH(3))
  .max(50, validationTexts.MAX_LENGTH(50));

const VALIDATION_CODE = Yup.string()
  .matches(CODE_REGEXP, validationTexts.CODE)
  .max(50, validationTexts.MAX_LENGTH(50));

export const VALIDATION_URL = Yup.string()
  .matches(EXCLUDE_SYMBOLS, validationTexts.NOT_SLASH_FIRST)
  .matches(EXTENDED_CODE_REGEXP, validationTexts.URL);

export const VALIDATION_LINK = Yup.string().matches(STRING_LINK_REGEXP, validationTexts.URL);

export const VALIDATION_PHONE = Yup.string().matches(
  STRING_PHONE_REGEXP,
  validationTexts.ONLY_NUMBER,
);

// Forms
export const NEW_CONFIGURATION_FORM = Yup.object().shape({
  name: VALIDATION_STRING,
  code: VALIDATION_CODE,
});

export const NEW_OPTION_CONFIGURATION_FORM = Yup.object().shape({
  name: VALIDATION_STRING,
  code: VALIDATION_CODE,
});

export const NEW_OPTION_MODEL_FORM = Yup.object().shape({
  name: VALIDATION_STRING,
  code: VALIDATION_CODE,
  url: VALIDATION_URL,
});

export const NEW_ITEM_MENU_FORM = Yup.object().shape({
  name: VALIDATION_MENU_STRING,
  url: VALIDATION_URL,
});

export const ADD_FOLDER_FORM = Yup.object().shape({
  name: VALIDATION_STRING,
});

export const NEW_MENU_FORM = Yup.object().shape({
  name: VALIDATION_MENU_STRING,
  code: VALIDATION_CODE,
});

export const PHONE_NUMBER = Yup.object().shape({
  value: VALIDATION_PHONE,
});

export const FAQ_DETAIL_FORM = Yup.object().shape({
  categoryId: string().required(validationTexts.REQUIRED),
  question: string().required(validationTexts.REQUIRED),
  positionOnMain: Yup.mixed().when('isDisplayOnMain', {
    is: 'true',
    then: Yup.mixed().test('isDisplayOnMain', validationTexts.REQUIRED, (value) => !!value),
  }),
  positionOnHeader: Yup.mixed().when('isDisplayOnHeader', {
    is: 'true',
    then: Yup.mixed().test('isDisplayOnHeader', validationTexts.REQUIRED, (value) => !!value),
  }),
});
