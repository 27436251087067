import { PaperProps, Sx } from '@mantine/core';

import { PAGE_ELEMENTS } from '@/constants/common';

import { CreateFilterSlice } from '@/store/slices/banners/bannersSlice';

export const bannersInitialState: CreateFilterSlice = {
  currentPage: 1,
  limit: PAGE_ELEMENTS[0].value,
  page: [],
  place: [],
  site: [],
  query: '',
};

export const sx: Sx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  minWidth: 216,
  maxWidth: 216,
};

export const inputStyles = {
  input: {
    height: 40,
    borderColor: '#E8ECF0',
    '::-webkit-input-placeholder': { color: '#8493a4', options: 0, fontSize: 16 },
  },
};

export const paperProps: PaperProps = {
  radius: 8,
  style: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    minHeight: '100%',
  },
};

export const mainBoxProps: Sx = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '96vh',
  overflowY: 'hidden',
};

export const itemsPerPageBox: Sx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 24,
  maxWidth: 'fit-content',
};

export const pageOptions = [
  { label: 'Главная', value: 'main' },
  { label: 'Меню "Платформа Просвещение"', value: 'platform' },
  { label: 'Каталог. Главная', value: 'catalog' },
  { label: 'Меню', value: 'menu' },
  { label: 'Спецпроекты', value: 'special' },
  { label: 'Статьи', value: 'article' },
  { label: 'Каталог. Категория', value: 'selection' },
  { label: 'ЛК.Моя лента', value: 'cabinet' },
  { label: 'ЛК.Цифровые продукты', value: 'digitalProduct' },
  { label: 'Акции', value: 'promotion' },
  { label: 'Родителям', value: 'parents' },
  { label: 'Аудиоприложения', value: 'audio' },
  { label: 'КТС', value: 'kts' },
  { label: 'УМК', value: 'umk' },
  { label: 'Ученикам', value: 'student' },
];
export const locationOptions = [
  { label: 'Баннер горизонтальный верхний', value: 'horizontalTop' },
  { label: 'Баннер горизонтальный нижний', value: 'horizontalBottom' },
  { label: 'Ролевой баннер.Родителям', value: 'roleParents' },
  { label: 'Ролевой баннер.Педагогам', value: 'roleEducators' },
  { label: 'Ролевой баннер.Ученикам', value: 'roleStudents' },
  { label: 'Ролевой баннер.Директорам', value: 'roleDirectors' },
  { label: 'Ролевой баннер.Бизнес', value: 'roleBusiness' },
  { label: 'Главный баннер', value: 'main' },
  { label: '1/2 Мини-баннер 1', value: 'mini121' },
  { label: '2/2 Мини-баннер 1', value: 'mini221' },
  { label: '1/2 Мини-баннер 2', value: 'mini122' },
  { label: '2/2 Мини-баннер 2', value: 'mini222' },
  { label: 'Предложения', value: 'offer' },
  { label: 'Акции', value: 'action' },
  { label: 'Баннер для продвижения', value: 'promotion' },
  { label: 'Сториз', value: 'stories' },
  { label: 'Баннер каталога', value: 'catalog' },
  { label: 'Баннер-шапка', value: 'header' },
];

export const siteOptions = [
  { label: 'prosv.ru', value: 'user' },
  { label: 'corp.prosv.ru', value: 'corp' },
  { label: 'uchitel.club', value: 'uchitel' },
];

export const work = [
  { label: 'Да', value: 'true' },
  { label: 'Нет', value: 'false' },
];
