import { FC, useMemo } from 'react';
import { Box, Text } from '@mantine/core';
import { isImageData } from '@prosv/core/types/bff/base/image.helpers';

import { FileLoader } from '@/ui/components/FileLoader';
import { ISimpleFile } from '@/ui/components/FileLoader/FileLoader.types';

import { AdditionalFieldProps } from '../AdditionalField.types';

export const FileField: FC<AdditionalFieldProps> = ({
  form,
  field: { code, title, isRequired },
  data,
  sessionID,
  disabled,
}) => {
  const fieldProps = form.getInputProps(`fields.${code}`);

  const savedFormFiles = useMemo(() => {
    if (!data) return [];

    const res: ISimpleFile[] = [];

    (Array.isArray(data) ? data : [data]).forEach((item) => {
      if (isImageData(item)) {
        res.push({
          name: item.name,
          preview: process.env.MEDIA_STORAGE_URL + item.config.previewConfig.path,
        });
      }
    });

    return res;
  }, [data]);

  return (
    <Box>
      <Text>
        {title} {isRequired && <span style={{ color: 'red' }}>*</span>}
      </Text>
      <FileLoader
        {...{ sessionID, savedFormFiles, disabled }}
        error={!!fieldProps.error}
        onChange={({ id }) => {
          fieldProps.onChange(id);
        }}
      />
    </Box>
  );
};
