import { TCreateDraft } from '@/types/draft/draft';

export const DATE_TEMPLATE_DAYS = 'YYYY-MM-DDT';
export const DATE_TEMPLATE_HOURS = 'HH:mm:ssZ';

export const DATE_TEMPLATE = 'YYYY-MM-DDTHH:mm:ssZ';

export const USER_ID_MOCK = '07988542-b26a-4a8d-be85-799623ebc808'; // TODO исправить после реализации авторизации

export const createNewDraftData: TCreateDraft = {
  content: '',
  meta: {
    userId: USER_ID_MOCK,
  },
};

export const FILTERED_FIELDS = ['modifiedViewsCount', 'dateActiveFromHours'];

export enum EAddNewsConfirmationTexts {
  TITLE = 'Вы уверены?',
  OUT_TITLE = 'Вы уверены, что хотите выйти?',
  NOT_SAVE_MENU_SITE = 'У вас есть несохраненные данные.',
}
