import { FC } from 'react';
import { Box, Button, Group, Radio, Text, TextInput } from '@mantine/core';

import { getFormatDate } from '@/utils/getCurrentDate';

import { AdditionalField } from './AdditionalField';
import { FIELDS } from './DictionaryItemForm.constants';
import { useDictionaryItemForm } from './DictionaryItemForm.hooks';
import * as sx from './DictionaryItemForm.styles';
import { DictionaryItemFormProps } from './DictionaryItemForm.types';

export const DictionaryItemForm: FC<DictionaryItemFormProps> = ({
  item,
  dictionary,
  onSuccessSubmit,
}) => {
  const { form, onSubmit, isSubmittig, sessionID } = useDictionaryItemForm({
    item,
    dictionary,
    onSuccessSubmit,
  });

  return (
    <Box sx={sx.root}>
      {item && (
        <>
          <Text size='xs' color='dimmed'>
            ID: {item.id}
          </Text>
          <Text pb={8} size='xs' color='dimmed'>
            Дата создания: {getFormatDate(item.createAt.value.toUpperCase())}
          </Text>
        </>
      )}

      <form {...{ onSubmit }}>
        <TextInput
          withAsterisk
          disabled={isSubmittig}
          label='Название'
          placeholder='Введите название'
          {...form.getInputProps(FIELDS.name)}
        />
        <TextInput
          withAsterisk
          disabled={isSubmittig}
          label='Код'
          placeholder='Введите код'
          {...form.getInputProps(FIELDS.code)}
        />
        <TextInput
          disabled={isSubmittig}
          label='Комментарий'
          placeholder='Введите комментарий'
          {...form.getInputProps(FIELDS.comment)}
        />

        {dictionary.isCustom &&
          dictionary.additionalFields.map((field) => (
            <AdditionalField
              key={field.code}
              disabled={isSubmittig}
              data={item?.fields[field.code]}
              {...{ form, field, sessionID }}
            />
          ))}

        <Radio.Group label='Активность' {...form.getInputProps(FIELDS.isActive)}>
          <Radio value='true' label='Да' disabled={isSubmittig} />
          <Radio value='false' label='Нет' disabled={isSubmittig} />
        </Radio.Group>

        <Group position='right' sx={sx.actions}>
          <Button type='submit' disabled={isSubmittig}>
            {item ? 'Сохранить' : 'Создать'}
          </Button>
        </Group>
      </form>
    </Box>
  );
};
