import { EmptySnippet } from '@prosv/core/types/snippets';

import { EMPTY_ROW_ID } from '@/ui/containers/ContentEditor/Rows/Rows.constnats';
import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

import { useStateSelectGridModal } from './SelectGridModal.state';

export const useHandlersSelectGridModal = (
  state: ReturnType<typeof useStateSelectGridModal>,
  draftId?: string,
) => {
  const handleClose = () => {
    state.setSelectGridData(null);
  };

  const handleClickEditBtn = async () => {
    if (typeof state.selectGridData?.rowId !== 'number') return;

    const rowId = state.selectGridData.rowId;

    useStoreContentEditor.setState((store) => {
      const savedContentData = store?.savedContentData;
      if (savedContentData?.grid) {
        const { gapColumnSize, gapRowSize, backgroundColor } = state;
        const newRow = { gapColumnSize, gapRowSize, backgroundColor };

        savedContentData.grid = savedContentData.grid.map((item, index) => {
          if (index === rowId) {
            return { ...newRow, blocks: item.blocks };
          }
          return item;
        });
      }
    });

    await useStoreContentEditor.getState().updateContentData(draftId);

    handleClose();
  };
  const handleClickDoneBtn = async () => {
    if (typeof state.selectGridData?.rowId !== 'number') return;

    const rowId = state.selectGridData.rowId;
    const isEmptyRow = rowId === EMPTY_ROW_ID;

    useStoreContentEditor.setState((store) => {
      const grid = store?.savedContentData?.grid;
      if (grid) {
        const emptyContentBlock: EmptySnippet = { id: '', variant: 'empty', versionModel: 1 };
        const blocks = new Array(state.selectedNumbersBlock).fill(1).map(() => emptyContentBlock);
        const { gapColumnSize, gapRowSize, backgroundColor } = state;
        const newRow = { gapColumnSize, gapRowSize, blocks, backgroundColor };

        if (isEmptyRow) {
          grid.push(newRow);
        } else {
          const offsetDownSize = 1;
          grid.splice(rowId + offsetDownSize, 0, newRow);
        }
      }
    });

    await useStoreContentEditor.getState().updateContentData(draftId);

    handleClose();
  };

  return { handleClose, handleClickDoneBtn, handleClickEditBtn };
};
