import * as Yup from 'yup';

import { EHtmlSnippetFields } from '@/ui/containers/ContentEditor/snippets/HtmlSnippet/HtmlSnippet.constants';

const ERR_REQUIRED_TEXT = 'Это поле обязательно для заполнения';

export const htmlBlockSchema = Yup.object({
  [EHtmlSnippetFields.IS_FULL_WIDTH]: Yup.boolean().required(ERR_REQUIRED_TEXT),
  [EHtmlSnippetFields.CONTENT]: Yup.string(),
});
