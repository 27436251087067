import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import { REMOVE_QUESTION_MODAL_TEXT } from '@/ui/containers/Questions/details/QuestionsDetails/QuestionsDetail.constants';

interface PublicationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PublicationModal = ({ isOpen, onClose, onConfirm }: PublicationModalProps) => {
  return (
    <ModalConfirm
      opened={isOpen}
      title={REMOVE_QUESTION_MODAL_TEXT.title}
      text={REMOVE_QUESTION_MODAL_TEXT.text}
      confirmHandler={onConfirm}
      onClose={onClose}
    />
  );
};
