import React, { FC, useEffect } from 'react';

import { TContentData } from '@/ui/containers/ContentEditor/ContentEditor.types';
import { SelectSnippetModalWrapper } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';

import { Rows } from './Rows';
import { useStoreContentEditor } from './store';

type Props = {
  template?: TContentData;
  isForceChangeTemplate?: boolean;
  draftId?: string;
};

export const ContentEditorUI: FC<Props> = ({
  draftId,
  template,
  isForceChangeTemplate = false,
}) => {
  const fetchSavedContentData = useStoreContentEditor((state) => state.fetchSavedContentData);
  const draftIdFromState = useStoreContentEditor((state) => state.draftId);

  useEffect(() => {
    if (draftId || draftIdFromState) {
      void fetchSavedContentData(draftId, template, isForceChangeTemplate);
    }
  }, [draftId, draftIdFromState, fetchSavedContentData, isForceChangeTemplate, template]);

  return (
    <>
      <Rows draftId={draftId} />

      <SelectSnippetModalWrapper draftId={draftId} />
    </>
  );
};

export const ContentEditor = React.memo(ContentEditorUI);
