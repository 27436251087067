import { IBanner, TNullable } from '@/types/banner/banner';

import { useMemo, useRef, useState } from 'react';
import { v4 as uuid_v4 } from 'uuid';

import {
  useCreateBanner,
  useDeleteBanner,
  useGetBanner,
  useUpdateBannerActivity,
} from '@/api/banners/hooks';
import { useGetNumberOfClicks } from '@/api/banners/hooks/useGetNumberOfClicks';
import { useGetNumberOfViews } from '@/api/banners/hooks/useGetNumberOfViews';
import { useUpdateBanner } from '@/api/banners/hooks/useUpdateBanner';

import { getItemPermissions } from '@/utils/permissions';

import { usePermission } from '@/hooks/usePermissions';

import { IBannerCreationState } from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.types';
import { IBannerFormFields } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.types';
import {
  setSelectorDefaultFormData,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

export const useBannerCreationState = (id: string = ''): IBannerCreationState => {
  const canCreate = usePermission('BANNER_CREATE');
  const defaultFormData = useStoreContentEditor((state) =>
    state.getDefaultFormData<IBannerFormFields>(),
  );

  const sessionID = useRef(uuid_v4());
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  const [banner, setBanner] = useState<TNullable<IBanner>>(null);
  const [isShouldBlock, setIsShouldBlock] = useState(false);

  const isNewBanner = !Boolean(id);
  const isShowBannerDetail = isNewBanner || (!isNewBanner && banner);
  const title = isNewBanner
    ? 'Новый баннер'
    : `ID: ${banner?.uuid.slice(0, 5).toUpperCase() || ''}`;
  const [trySaveBanner, setTrySaveBanner] = useState<boolean>(false);

  const { isLoading: isCreateBannerLoading, createBanner } = useCreateBanner();
  const { isLoading: isGetBannerLoading, getBanner } = useGetBanner();
  const { isLoading: isUpdateBannerLoading, updateBanner } = useUpdateBanner();
  const { isLoading: isUpdateBannerActivityLoading, updateBannerActivity } =
    useUpdateBannerActivity();
  const { isLoading: isDeleteBannerLoading, deleteBanner } = useDeleteBanner();
  const { getNumberOfClicks } = useGetNumberOfClicks();
  const { getNumberOfViews } = useGetNumberOfViews();

  const isLoading =
    isCreateBannerLoading ||
    isGetBannerLoading ||
    isUpdateBannerLoading ||
    isUpdateBannerActivityLoading ||
    isDeleteBannerLoading;

  const permissions = useMemo(() => {
    const res = getItemPermissions('BANNER_', banner?.permissions);
    return {
      ...res,
      canView: !banner || !!(isNewBanner ? canCreate : res.canGet),
      canSave: !!(isNewBanner ? canCreate : res.canUpdate || res.canUpdateActivity),
      canUpdate: !!(isNewBanner ? canCreate : res.canUpdate),
    };
  }, [isNewBanner, canCreate, banner]);

  return {
    permissions,
    title,
    isNewBanner,
    isShowBannerDetail,
    defaultFormData,
    banner,
    isShouldBlock,
    isLoading,
    sessionID,
    setDefaultFormData,
    trySaveBanner,
    setTrySaveBanner,
    createBanner,
    getBanner,
    deleteBanner,
    updateBanner,
    updateBannerActivity,
    getNumberOfClicks,
    getNumberOfViews,
    setBanner,
    setIsShouldBlock,
  };
};
