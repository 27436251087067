import * as Yup from 'yup';

import { ECompactPromoSnippetFields } from '@/ui/containers/ContentEditor/snippets/CompactPromoSnippet/СompactPromoSnippet.constants';

const ERR_REQUIRED_TEXT = 'Это поле обязательно для заполнения';

const commonStringValidation = Yup.string().required(ERR_REQUIRED_TEXT);

export const compactPromoBlockSchema = Yup.object({
  [ECompactPromoSnippetFields.IMAGE]: Yup.object({
    id: Yup.string().required(ERR_REQUIRED_TEXT),
    name: Yup.string().required(ERR_REQUIRED_TEXT),
    path: Yup.string().required(ERR_REQUIRED_TEXT),
  }),
  [ECompactPromoSnippetFields.IMAGE_ALT]: commonStringValidation,
  [ECompactPromoSnippetFields.IMAGE_TITLE]: commonStringValidation,
  [ECompactPromoSnippetFields.DESCRIPTION]: commonStringValidation.optional(),
});
