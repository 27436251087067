import {FC} from "react";
import {useDocumentTitle} from "@mantine/hooks";

import {TITLE_MENU} from "@/constants/titles";

import MenusContainer from "@/ui/containers/MenusContainer/MenusContainer";

const Menus: FC = () => {
  useDocumentTitle(TITLE_MENU);

  return <MenusContainer />;
}

export default Menus;
