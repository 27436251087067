import { useRef } from 'react';
import { useDebouncedState } from '@mantine/hooks';
import { TextSnippet } from '@prosv/core/types/snippets';
import { Editor } from '@tiptap/react';

import {
  setSnippetDataSelector,
  textSnippetDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

export const useStateTextModal = () => {
  const snippetData = useStoreContentEditor(textSnippetDataSelector);
  const { rowId, cardId } = snippetData || {};

  const setSnippetData = useStoreContentEditor(setSnippetDataSelector);
  const defaultValues = useStoreContentEditor((state): TextSnippet | undefined => {
    const data = state.savedContentData?.grid[Number(rowId)]?.blocks?.[Number(cardId)];
    if (data?.variant === 'text') {
      return data;
    }
    return;
  });

  const editorRef = useRef<Editor | undefined | null>();
  const [colorValue, setColorValue] = useDebouncedState(defaultValues?.color || '', 500);

  return {
    snippetData,
    setSnippetData,
    defaultTextValue: defaultValues?.text,
    editorRef,
    colorValue,
    setColorValue,
  };
};
