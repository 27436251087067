import React from 'react';
import { Box, Container, Group, Paper, Tabs, Title } from '@mantine/core';

import { ProductsTab } from '@/ui/containers/Questions/listing/tabs/ProductsTab/ProductsTab';

export const Questions = () => {
  return (
    <Box style={{ height: '96vh', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
      <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group position='apart'>
          <Title order={1}>Вопросы</Title>
        </Group>
      </Container>

      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <Tabs sx={{ height: '100%' }} color='teal' defaultValue='products'>
          <Tabs.List sx={{ borderBottom: 'none' }}>
            <Tabs.Tab value='products' pb='md' mb='xl' color='primary'>
              Продукты
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel sx={{ height: '100%' }} value='products' pt='xs'>
            <ProductsTab />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Box>
  );
};
