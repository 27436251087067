import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { tryGetToken } from '@/api/auth/auth';

import { saveToken } from '@/utils/token';

import { AUTH_ERR } from '@/constants/auth';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { logout, setIsLoggin } from '@/store/slices/auth/auth';

export const AdfsOauthContainer: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const error = searchParams.get('error');

    if (error) {
      dispatch(logout(null));
      navigate(`/auth?${AUTH_ERR}=true`);
      return;
    }

    if (code && state) {
      dispatch(setIsLoggin(true));
      tryGetToken({ code, state })
        .then((data) => {
          if (data) {
            saveToken(data.payload.accessToken.accessToken);
            window.location.href = data.payload.backUrl;
          }
        })
        .catch(() => navigate(`/auth?${AUTH_ERR}=true`))
        .finally(() => dispatch(setIsLoggin(false)));
    }
  }, []);

  return null;
};
