import React, { useEffect, useMemo } from 'react';
import { Box, Button, Group, Select, Text, TextInput } from '@mantine/core';
import { RotateClockwise } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { MultiSelect, TOption } from '@/ui/components/MultiSelect';

import { inputStyles } from '@/pages/Banners/BannersListing/BannerListing.constants';
import {
  itemsPerPageBox,
  supportsInitialState,
  sx,
  work,
} from '@/pages/Support/SupportsListing.constants';
import {
  CreateFilterSlice,
  isDirtySupportFilter,
  supportsFilterActions,
  supportsFiltersSelector,
  useGetCategoryTreatmentQuery,
} from '@/store/slices/support/supportDetailSlice';

export const SupportsFilter = ({
  getSupportsListing,
}: {
  getSupportsListing: (params: CreateFilterSlice) => void;
}) => {
  const { resetFilters, setFilterWithResetCurrentPage } = supportsFilterActions;
  const { limit, category, question, isActive } = useAppSelector(supportsFiltersSelector);
  const { data } = useGetCategoryTreatmentQuery();

  const categoryOptions = useMemo(
    () => (data?.payload.items || []).map((item) => ({ label: item.name, value: item.code })),
    [data?.payload.items],
  );
  const filter = useAppSelector((state) => state.supportsFilter);
  const isDirty = useAppSelector(isDirtySupportFilter);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getSupportsListing(supportsInitialState);
  }, []);

  const handleSearchDebounced = useDebouncedCallback((params: Partial<CreateFilterSlice>) => {
    getSupportsListing({ ...filter, ...params });
  }, 300);

  const actionWithDebounce = (params: Partial<CreateFilterSlice>) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };

  const handleSerActive = (v: TOption) => {
    const result = v.value === isActive?.value ? undefined : v;
    actionWithDebounce({ isActive: result });
  };

  const onReset = () => {
    dispatch(resetFilters());
    getSupportsListing(supportsInitialState);
  };

  return (
    <Group sx={{ zIndex: 2 }} mb={24} position='left' align='flex-end' spacing='xl' grow>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Активность
        </Text>
        <MultiSelect
          height={40}
          options={work}
          isMulti={false}
          value={[isActive as TOption]}
          onChange={(v) => {
            handleSerActive(v as any);
          }}
          label='Активность'
        />
      </Box>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Вопрос
        </Text>
        <TextInput
          value={question}
          styles={inputStyles}
          onChange={(v) => actionWithDebounce({ question: v.target.value })}
          placeholder='Заголовок'
        />
      </Box>
      <Box sx={{ ...sx, maxWidth: 340 }}>
        <Text size='sm' color='grayDefault'>
          Категория обращения
        </Text>
        <MultiSelect
          value={category}
          height={40}
          options={categoryOptions}
          onChange={(v) => actionWithDebounce({ category: v as TOption[] })}
          label='Категория обращения'
        />
      </Box>

      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        {isDirty && (
          <Box sx={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}>
            <Button
              variant='subtle'
              radius='xs'
              size='sm'
              compact
              sx={({ colors }) => ({
                color: colors.primary[9],
                ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
              })}
              rightIcon={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}
        <Box sx={itemsPerPageBox}>
          <Select
            sx={{ width: '60px', input: { paddingRight: '20px' } }}
            size='sm'
            value={limit || PAGE_ELEMENTS[0].value}
            onChange={(v) => actionWithDebounce({ limit: v as string })}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
