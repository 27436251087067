import { FC } from 'react';
import { SnippetVariant } from '@prosv/core/types/snippets';

import { ReactComponent as BannerIcon } from './icons/banner.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as CompactPromo } from './icons/compactPromo.svg';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import { ReactComponent as GalleryIcon } from './icons/gallery.svg';
import { ReactComponent as HtmlIcon } from './icons/html.svg';
import { ReactComponent as ImageIcon } from './icons/image.svg';
import { ReactComponent as LinkBtnIcon } from './icons/link-btn.svg';
import { ReactComponent as NewsIcon } from './icons/news.svg';
import { ReactComponent as PeopleIcon } from './icons/people.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as ProductIcon } from './icons/product.svg';
import { ReactComponent as ProductsIcon } from './icons/products.svg';
import { ReactComponent as PromoIcon } from './icons/promo.svg';
import { ReactComponent as QuestionIcon } from './icons/question.svg';
import { ReactComponent as QuoteIcon } from './icons/quote.svg';
import { ReactComponent as SystemIcon } from './icons/system.svg';
import { ReactComponent as TextIcon } from './icons/text.svg';
import { ReactComponent as TextWithImageIcon } from './icons/text-with-image.svg';
import { ReactComponent as VideoIcon } from './icons/video.svg';

interface IProps {
  variant: SnippetVariant;
}
const variantToIconMap: Record<SnippetVariant, JSX.Element> = {
  text: <TextIcon />,
  event: <CalendarIcon />,
  download: <DownloadIcon />,
  image: <ImageIcon />,
  'link-btn': <LinkBtnIcon />,
  people: <PeopleIcon />,
  product: <ProductIcon />,
  quote: <QuoteIcon />,
  video: <VideoIcon />,
  gallery: <GalleryIcon />,
  news: <NewsIcon />,
  promo: <PromoIcon />,
  products: <ProductsIcon />,
  banner: <BannerIcon />,
  empty: <PlusIcon />,
  html: <HtmlIcon />,
  catalog: <ProductsIcon />,
  compactPromo: <CompactPromo />,
  imageWithTextAround: <TextWithImageIcon />,
  umkSystem: <SystemIcon />,
  question: <QuestionIcon />,
};

export const ContentTypeIcon: FC<IProps> = ({ variant }) => {
  return variantToIconMap[variant] || <PlusIcon />;
};
