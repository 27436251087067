import type { TSupportSetting } from '@/types';

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { tryGetGeneralSetting } from '@/api/generalSettings/generalSettings';

import { State } from '@/store';

interface GeneralSettingsState {
  status: {
    fetchingServices: boolean;
  };
  services?: TSupportSetting;
}

const initialState: GeneralSettingsState = {
  status: {
    fetchingServices: false,
  },
};

export const fetchGeneralSetting = createAsyncThunk(
  '/generalSetting',
  async (_, { rejectWithValue }) => {
    const result = await tryGetGeneralSetting();

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  },
);

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeneralSetting.pending, (state) => {
        state.status.fetchingServices = true;
      })
      .addCase(fetchGeneralSetting.fulfilled, (state, action) => {
        state.status.fetchingServices = false;
        state.services = action.payload.payload.services;
      })
      .addCase(fetchGeneralSetting.rejected, (state) => {
        state.status.fetchingServices = false;
      });
  },
});

// Selectors
export const supportSettingSelector = createSelector(
  (state: State) => state.generalSettings,
  ({ services }) => services,
);
export const optionsSettingSelector = createSelector(
  (state: State) => state.generalSettings,
  ({ services }) => {
    if (!services) return [];
    return Object.keys(services).map((i) => ({ value: i, label: i }));
  },
);

export const fetchSupportSettingSelector = createSelector(
  (state: State) => state.generalSettings,
  (generalSetting) => generalSetting.status.fetchingServices,
);

// Reducers and actions

export const {} = newsSlice.actions;

export default newsSlice.reducer;
