import { MutableRefObject } from 'react';

import { replaceTempFile } from '@/api';

export const imageHandler = async (imageId: string, sessionID: MutableRefObject<string>) => {
  const data = await replaceTempFile({
    query: {
      sessionId: sessionID.current,
      ids: [imageId],
    },
  });

  return data?.payload.items[0]?.id || imageId;
};
