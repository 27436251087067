import { Sx } from '@mantine/core';

export const margin = 8;

export const root: Sx = { display: 'flex', alignItems: 'center', margin };

export const handle: Sx = {
  cursor: 'pointer',
  width: 24,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const content: Sx = { flexGrow: 1 };
