import { LinkSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { ELinkButtonModal } from '@/ui/containers/ContentEditor/snippets/LinkButtonSnippetModal/LinkButtonModal.constants';

export const getLinkButtonSnippetInitialValues = (
  defaultValues?: LinkSnippet,
): SnippetValues<LinkSnippet> => {
  return {
    [ELinkButtonModal.ID]: defaultValues?.id || uuid_v4(),
    [ELinkButtonModal.LINK]: defaultValues?.link || '',
    [ELinkButtonModal.NAME]: defaultValues?.name || '',
    [ELinkButtonModal.ALIGNMENT]: defaultValues?.alignment || 'center',
  };
};
