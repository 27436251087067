import { FC } from 'react';
import { Box } from '@mantine/core';

import { AudioAppSelect } from '@/ui/components/AudioAppSelect';
import { DndListItem, DndListItemProps } from '@/ui/components/DndListItem';

import { swapFormFields } from '../CubeContainer.helpers';

import { AUDIOS_FIELDS, type } from './AudiosFormBlock.constants';
import { AudiosFormBlockProps } from './AudiosFormBlock.types';

export const AudiosFormBlock: FC<AudiosFormBlockProps> = ({ form, disabled }) => {
  const move: DndListItemProps['move'] = (dragIndex, hoverIndex) =>
    swapFormFields(AUDIOS_FIELDS, dragIndex, hoverIndex, form.setValues);

  return (
    <Box>
      {AUDIOS_FIELDS.map((field, index) => (
        <DndListItem key={field} {...{ type, index, move }}>
          <AudioAppSelect
            clearable
            searchable
            disabled={disabled}
            placeholder={`Аудиоприложение ${index + 1}`}
            {...form.getInputProps(field)}
          />
        </DndListItem>
      ))}
    </Box>
  );
};
