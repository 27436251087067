import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Group, Paper, Tabs, Title } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { UmkListFilters } from 'src/ui/containers/UmkListContainer/components/UmkListFilters';

import { usePermission } from '@/hooks/usePermissions';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { textWhenDataMissing } from '@/ui/containers/UmkListContainer/UmkListContainer.constants';
import {
  useUmkListColumns,
  useUmkListConfig,
} from '@/ui/containers/UmkListContainer/UmkListContainer.hooks';

import { umkListDefaultFilter } from '@/store/slices/umkList/umkList.constants';
import { UmkType } from '@/store/slices/umkList/umkList.types';
import { useLazyGetUmkListingQuery } from '@/store/slices/umkList/umkListSlice';

export const UmkListContainer: FC = () => {
  const canCreate = usePermission('PROMOTION_CREATE');
  const navigate = useNavigate();
  const [umkType, setUmkType] = useLocalStorage({ key: 'umkType' });
  const [getUmkListingRequest, data] = useLazyGetUmkListingQuery();

  const filterProps = useUmkListConfig(getUmkListingRequest);
  const columns = useUmkListColumns(filterProps);

  const onChangeFilter = (type: UmkType) => {
    setUmkType(type);
    filterProps.onChange({ ...umkListDefaultFilter, type });
  };

  useEffect(() => {
    setUmkType('system');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={{ height: '96vh', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
      <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group position='apart'>
          <Title order={1}>Управление УМК</Title>

          {canCreate && (
            <Button sx={{ width: '200px' }} onClick={() => navigate('/umk/new')}>
              Создать
            </Button>
          )}
        </Group>
      </Container>
      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: '100%',
            marginBottom: '30px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Tabs
            color='teal'
            defaultValue='system'
            sx={{ height: '100%', position: 'relative' }}
            value={filterProps.filters.type}
          >
            <Tabs.List sx={{ borderBottom: 'none' }}>
              <Tabs.Tab value='system' color='primary' onClick={() => onChangeFilter('system')}>
                Система
              </Tabs.Tab>
              <Tabs.Tab value='line' color='primary' onClick={() => onChangeFilter('line')}>
                Линия/Серия
              </Tabs.Tab>
              <Tabs.Tab
                value='direction'
                color='primary'
                onClick={() => onChangeFilter('direction')}
              >
                Направление
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </Box>
        <UmkListFilters {...filterProps} />
        <Box
          sx={{
            height: '100%',
            overflow: 'scroll',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CustomTable
            onChangePagination={(v) => filterProps.onChange({ currentPage: v })}
            limit={Number(filterProps.filters.limit)}
            noData={textWhenDataMissing[umkType]}
            total={data.data?.payload?.meta.count || 0}
            currentPage={filterProps.filters.currentPage}
            status={data.isFetching ? 'loading' : 'loaded'}
            data={data.data?.payload?.items}
            columns={columns}
          />
        </Box>
      </Paper>
    </Box>
  );
};
