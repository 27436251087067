import { ItemPermissions } from '@/types/auth/permissions';
import { EPromotionVariant } from '@/types/promotions';

import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Box, Radio, Select, Textarea, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { IconCalendar } from '@tabler/icons';
import { Editor } from '@tiptap/react';
import { useDebouncedCallback } from 'use-debounce';

import { transliteration } from '@/utils/transliteration';

import { EXCLUDE_PARAGRAPH_TAGS } from '@/constants/validationSchemas';

import { CustomLabel } from '@/ui/containers/BannerCreationContainer/components/CustomLabel';
import { LineLengthDisplay } from '@/ui/containers/BannerCreationContainer/components/LineLengthDisplay';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';
import {
  DESCRIPTION_MAX_LENGTH,
  EActivityFieldValues,
  EMainFieldValues,
  EPromotionFormFields,
  ERROR_MESSAGE_STYLE,
  EVisibilityFieldValues,
} from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.constants';
import { useNotifyOnFormError } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.hooks';
import { IPromotionFormFields } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.types';
import { IBannerSelectorItem } from '@/ui/containers/PromotionCreation/PromotionCreation.types';

interface IProps {
  form: UseFormReturnType<IPromotionFormFields>;
  isNewPromotion: boolean;
  setTrySave: (value: boolean) => void;
  trySavePromotion: boolean;
  handleSearch: (search: string) => void;
  banners: IBannerSelectorItem[];
  isForceChangeTemplate: boolean;
  setOpenConfirmChangeTemplate: (value: boolean) => void;
  permissions: ItemPermissions;
}

export const PromotionForm: FC<IProps> = ({
  form,
  isNewPromotion,
  setTrySave,
  trySavePromotion,
  handleSearch,
  banners,
  isForceChangeTemplate,
  setOpenConfirmChangeTemplate,
  permissions: { canUpdate, canUpdateActivity },
}) => {
  const termsRef = useRef<Editor | undefined | null>();
  const deliveryRef = useRef<Editor | undefined | null>();
  const [typeTemplate, setTypeTemplate] = useState<EPromotionVariant>(
    form.values[EPromotionFormFields.TYPE],
  );

  const searchChange = useDebouncedCallback((value: string) => handleSearch(value), 500);
  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    if(isNewPromotion) {
      form.setFieldValue(
        EPromotionFormFields.CODE,
        `${transliteration(event.currentTarget.value, { onlyLower: true })}`,
      );
    }
    return form.getInputProps(EPromotionFormFields.TITLE).onChange(event);
  };

  useNotifyOnFormError({ errors: form.errors, setTrySave, trySavePromotion });

  const isShowDelivery =
    form.values[EPromotionFormFields.IS_SHOW_DELIVERY] === EVisibilityFieldValues.SHOW;

  const handleTextEditor = (field: string, value: string) => {
    form.setFieldValue(field, !value.replace(EXCLUDE_PARAGRAPH_TAGS, '') ? '' : value);
  };

  useEffect(() => {
    if (isForceChangeTemplate) {
      form.setFieldValue(EPromotionFormFields.TYPE, typeTemplate);
    }
  }, [isForceChangeTemplate]);

  const handlePromoType = (value: string) => {
    if (isForceChangeTemplate || isNewPromotion) {
      form.setFieldValue(EPromotionFormFields.TYPE, value as EPromotionVariant);
    } else {
      setOpenConfirmChangeTemplate(true);
      setTypeTemplate(value as EPromotionVariant);
    }
  };

  return (
    <Box
      style={{
        gridTemplateColumns: 'repeat(12, 1fr)',
        display: 'grid',
        gap: '24px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TextInput
        withAsterisk
        label='Название акции'
        sx={{ gridColumn: 'span 8' }}
        placeholder='Введите название акции'
        disabled={!canUpdate}
        {...form.getInputProps(EPromotionFormFields.TITLE)}
        onChange={handleChangeTitle}
      />
      <Radio.Group
        label='Активность'
        withAsterisk
        {...form.getInputProps(EPromotionFormFields.IS_ACTIVE)}
        sx={{ gridColumn: 'span 4', justifyItems: 'end' }}
      >
        <Radio
          value={EActivityFieldValues.ACTIVE}
          label='Да'
          disabled={!(canUpdate || canUpdateActivity)}
        />
        <Radio
          value={EActivityFieldValues.NOT_ACTIVE}
          label='Нет'
          disabled={!(canUpdate || canUpdateActivity)}
        />
      </Radio.Group>
      <TextInput
        label='Символьный код URL'
        withAsterisk
        sx={{ gridColumn: 'span 6' }}
        placeholder='Cсылка на акцию'
        disabled={!canUpdate}
        {...form.getInputProps(EPromotionFormFields.CODE)}
      />
      <DatePicker
        locale='ru'
        icon={<IconCalendar size={16} />}
        placeholder='Начало активности'
        label='Дата начала активности'
        withAsterisk
        sx={{ gridColumn: 'span 3' }}
        maxDate={form.values[EPromotionFormFields.DATE_ACTIVE_END]}
        disabled={!canUpdate}
        {...form.getInputProps(EPromotionFormFields.DATE_ACTIVE_START)}
      />
      <DatePicker
        locale='ru'
        icon={<IconCalendar size={16} />}
        placeholder='Окончание активности'
        label='Дата окончания активности'
        withAsterisk
        sx={{ gridColumn: 'span 3' }}
        disabled={!canUpdate || !form.values[EPromotionFormFields.DATE_ACTIVE_START]}
        minDate={form.values[EPromotionFormFields.DATE_ACTIVE_START]}
        {...form.getInputProps(EPromotionFormFields.DATE_ACTIVE_END)}
      />
      <Box sx={{ gridColumn: 'span 12' }}>
        <TextInput
          label='erid'
          sx={{ width: '420px' }}
          placeholder='Введите erid'
          disabled={!canUpdate}
          {...form.getInputProps(EPromotionFormFields.ERID)}
        />
      </Box>

      <Select
        label='Баннер'
        withAsterisk
        placeholder='Выберите из списка '
        sx={{ gridColumn: 'span 12', width: '420px' }}
        searchable
        onSearchChange={searchChange}
        nothingFound='Ничего не найдено'
        clearable
        data={banners}
        disabled={!canUpdate}
        {...form.getInputProps(EPromotionFormFields.BANNER_ID)}
      />

      <Radio.Group
        label='Сделать главной акцией'
        withAsterisk
        sx={{ gridColumn: 'span 12' }}
        {...form.getInputProps(EPromotionFormFields.IS_MAIN)}
      >
        <Radio value={EMainFieldValues.MAIN} label='Да' disabled={!canUpdate} />
        <Radio value={EMainFieldValues.NOT_MAIN} label='Нет' disabled={!canUpdate} />
      </Radio.Group>

      <Textarea
        label='Описание'
        sx={{ gridColumn: 'span 8' }}
        autosize
        minRows={6}
        {...form.getInputProps(EPromotionFormFields.DESCRIPTION)}
        placeholder='Введите основной текст'
        rightSection={
          <LineLengthDisplay
            currentLength={form.getInputProps(EPromotionFormFields.DESCRIPTION).value.length}
            maxLength={DESCRIPTION_MAX_LENGTH}
          />
        }
        disabled={!canUpdate}
      />
      <Box sx={{ gridColumn: 'span 8' }}>
        <CustomLabel title='Условия акции' isRequire numSpanInGrid='8' />
        <TextEditor
          editorRef={termsRef}
          defaultTextValue={form.values[EPromotionFormFields.TERMS]}
          changeFormValue={(value) => handleTextEditor(EPromotionFormFields.TERMS, value)}
          disabled={!canUpdate}
        />
        {form.getInputProps(EPromotionFormFields.TERMS).error && (
          <div style={ERROR_MESSAGE_STYLE}>
            {form.getInputProps(EPromotionFormFields.TERMS).error}
          </div>
        )}
      </Box>
      <Radio.Group
        label='Показывать доставку'
        {...form.getInputProps(EPromotionFormFields.IS_SHOW_DELIVERY)}
        sx={{ gridColumn: 'span 12' }}
      >
        <Radio value={EVisibilityFieldValues.SHOW} label='Да' disabled={!canUpdate} />
        <Radio value={EVisibilityFieldValues.NOT_SHOW} label='Нет' disabled={!canUpdate} />
      </Radio.Group>
      {isShowDelivery && (
        <Box sx={{ gridColumn: 'span 8' }}>
          <CustomLabel title='Доставка' numSpanInGrid='8' />
          <TextEditor
            editorRef={deliveryRef}
            defaultTextValue={form.values[EPromotionFormFields.DELIVERY_DESCRIPTION]}
            changeFormValue={(value) =>
              handleTextEditor(EPromotionFormFields.DELIVERY_DESCRIPTION, value)
            }
            disabled={!canUpdate}
          />
          {form.getInputProps(EPromotionFormFields.DELIVERY_DESCRIPTION).error && (
            <div style={ERROR_MESSAGE_STYLE}>
              {form.getInputProps(EPromotionFormFields.DELIVERY_DESCRIPTION).error}
            </div>
          )}
        </Box>
      )}
      <Radio.Group
        label='Компактная акция'
        sx={{ gridColumn: 'span 12' }}
        value={form.values[EPromotionFormFields.TYPE]}
        onChange={handlePromoType}
      >
        <Radio value={EPromotionVariant.COMPACT} label='Да' disabled={!canUpdate} />
        <Radio value={EPromotionVariant.FULL} label='Нет' disabled={!canUpdate} />
      </Radio.Group>
    </Box>
  );
};
