import { EffectCallback, useEffect } from 'react';

import { useCreateDraft } from '@/api/draft/useCreateDraft';
import { useGetPromotion } from '@/api/promotion/hooks/useGetPromotion';

import {
  setSelectorDefaultFormData,
  setSelectorDraftId,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { IPromotionCreationState } from '@/ui/containers/PromotionCreation/PromotionCreation.types';
import { createPromotionDraftData } from '@/ui/containers/PromotionsContainer';

export const useModePromotion = (state: IPromotionCreationState, id: string = '') => {
  const createDraft = useCreateDraft();
  const { getPromotion } = useGetPromotion();
  const setDraftId = useStoreContentEditor(setSelectorDraftId);
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  let effect: EffectCallback = () => {};
  if (state.isNewPromotion) {
    effect = () => {
      createDraft.createDraft(createPromotionDraftData).then((res) => {
        if (res?.payload.draftId) {
          setDraftId(res?.payload.draftId);
        }
      });
      return () => {
        setDefaultFormData(null);
      };
    };
  }

  if (!state.promotion && id) {
    effect = () => {
      getPromotion(id).then((promotionResponse) => {
        if (promotionResponse) {
          state.setPromotion(promotionResponse.payload);
          setDraftId(promotionResponse.payload.draftId);
          setDraftId(promotionResponse.payload.draftId);
        }
      });
    };
  }

  useEffect(effect, []);
};
