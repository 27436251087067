import { useEffect, useState } from 'react';

interface Props {
  getNumberOfViews: (uuid: string) => Promise<void | number>;
  getNumberOfClicks: (uuid: string) => Promise<void | number>;
  bannerId?: string;
}

export const useViewsAndClicks = ({ getNumberOfViews, getNumberOfClicks, bannerId }: Props) => {
  const [views, setViews] = useState(0);
  const [clicks, setClicks] = useState(0);

  const get = async (id: string) => {
    const resViews = await getNumberOfViews(id);
    const resClicks = await getNumberOfClicks(id);
    setViews(resViews ?? 0);
    setClicks(resClicks ?? 0);
  };
  useEffect(() => {
    if (!bannerId) return;
    get(bannerId);
  }, []);
  return {
    views,
    clicks,
  };
};
