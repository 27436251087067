import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import { IGetMaterialResponse } from '@/ui/containers/MaterialsContainer/Materials.types';

import api from '../../instance';

export const useDeleteMaterial = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    deleteMaterial: async (uuid: string) => {
      setIsLoading(true);
      try {
        return await api
          .delete<IGetMaterialResponse>(URLS.materials.deleteMaterial(uuid))
          .then((res) => {
            notify({ message: 'Материал успешно удален', type: 'success' });
            return res.data;
          });
      } catch (err) {
        return notify({ message: 'Ошибка удаления материала', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
