import { Mark } from '@tiptap/react';

export default Mark.create({
  name: 'tooltipView',

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [
      {
        tag: 'tooltip-view',
      },
    ];
  },

  addAttributes() {
    return {
      content: {
        default: '',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['tooltip-view', HTMLAttributes, 0];
  },
});
