import { QuoteSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { EQuoteSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/QuoteSnippetModal/QuoteSnippetModal.constants';

export const getQuoteSnippetInitialValues = (
  defaultValues?: QuoteSnippet,
): SnippetValues<QuoteSnippet> => {
  return {
    [EQuoteSnippetFormFields.ID]: defaultValues?.id || uuid_v4(),
    [EQuoteSnippetFormFields.TEXT]: defaultValues?.text || '',
    [EQuoteSnippetFormFields.AUTHOR]: defaultValues?.author || '',
    [EQuoteSnippetFormFields.ABOUT]: defaultValues?.about || '',
    [EQuoteSnippetFormFields.ALT]: defaultValues?.alt || '',
    [EQuoteSnippetFormFields.TITLE]: defaultValues?.imageTitle || '',
    [EQuoteSnippetFormFields.PHOTO]: defaultValues?.photo || {
      id: '',
      name: '',
      path: '',
    },
  };
};
