import { CubeMenuResponse, UpdateCubeMenuRequest } from '@prosv/core/types/bff/cube/admin';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

export const cubeSlice = createApi({
  reducerPath: 'api/cube',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Cube'],
  endpoints: (build) => ({
    getCube: build.query<CubeMenuResponse['payload'], void>({
      query: () => ({ method: 'get', url: URLS.cube }),
      transformResponse: ({ payload }: CubeMenuResponse) => payload,
      providesTags: [{ type: 'Cube', id: 'LIST' }],
    }),

    updateCube: build.mutation<CubeMenuResponse['payload'], UpdateCubeMenuRequest>({
      query: (data) => ({ method: 'put', url: URLS.cube, data }),
      transformResponse: ({ payload }: CubeMenuResponse) => payload,
      invalidatesTags: ['Cube'],
    }),
  }),
});

export const { useGetCubeQuery, useUpdateCubeMutation } = cubeSlice;
