import { AUDIOS_FIELDS } from './AudiosFormBlock/AudiosFormBlock.constants';
import { CATEGORIES_FIELDS } from './CategoriesFormBlock/CategoriesFormBlock.constants';
import { LabelBlockProps } from './LabelBlock/LabelBlock.types';
import { PERSONAL_FIELDS } from './PersonalFormBlock/PersonalFormBlock.constants';
import { PROJECTS_FIELDS } from './ProjectsFormBlock/ProjectsFormBlock.constants';
import { SERVICES_FIELDS } from './ServicesFormBlock/ServicesFormBlock.constants';

export const FIELDS_BLOCKS = [
  CATEGORIES_FIELDS,
  SERVICES_FIELDS,
  PERSONAL_FIELDS,
  AUDIOS_FIELDS,
  PROJECTS_FIELDS,
] as const;

export const LABELS_DATA: Record<
  'categories' | 'services' | 'personal' | 'webinars' | 'audios' | 'projects',
  LabelBlockProps
> = {
  categories: {
    image: 'categories.png',
    label: 'Категории каталога',
    description: (
      <>
        Выберите до <strong>6 категорий</strong> каталога
      </>
    ),
  },
  services: {
    image: 'services.png',
    label: 'Цифровые сервисы',
    description: (
      <>
        Выберите до <strong>4 цифровых сервисов</strong> и добавьте целевые аудитории
      </>
    ),
  },
  personal: {
    image: 'personal.png',
    label: 'Разделы Личного кабинета',
    description: (
      <>
        Выберите <strong>до 4 разделов</strong> Личного кабинета, добавьте иконки и описание
      </>
    ),
  },
  webinars: {
    label: 'Вебинары',
    description: 'Вебинары настраиваются автоматически с Учитель.Клаб',
  },
  audios: {
    image: 'audios.png',
    label: 'Аудиоприложения',
    description: (
      <>
        Выберите до <strong>4 аудиоприложений</strong> для отображения в Платформе
      </>
    ),
  },
  projects: {
    image: 'projects.png',
    label: 'Проекты',
    description: (
      <>
        Выберите до <strong>5 проектов</strong> для отображения в слайдере Платформы
      </>
    ),
  },
};
