import React, { FC } from 'react';
import { nanoid } from 'nanoid';

import { cellPosition } from '@/ui/components/CustomTable/CustomTable.constants';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';

interface IProps {
  columns: ITableColumns[];
  onRenderClick?: (data: any) => void;
  row: Record<string, any>;
}

export const TableRow: FC<IProps> = ({ columns, onRenderClick, row }) => {
  return (
    <tr>
      {columns.map((column, index) => {
        const position = cellPosition[(column?.position || 'left') as keyof typeof cellPosition];
        return (
          <td style={column.style} key={index + column.field + nanoid()}>
            <div style={{ width: 'fit-content', ...position }}>
              {column.render
                ? column.render(
                    onRenderClick ? () => (onRenderClick ? onRenderClick(row) : null) : row,
                  )
                : row[column.field] || ' - '}
            </div>
          </td>
        );
      })}
    </tr>
  );
};
