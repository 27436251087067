import { Permission } from '@/types/auth';

import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCookie } from '@/utils/cookie';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePermission, usePermissions } from '@/hooks/usePermissions';

import { getIsLogging, logout } from '@/store/slices/auth/auth';

export interface RequireAuthProps extends PropsWithChildren {
  permission?: Permission;
}

export const RequireAuth: FC<RequireAuthProps> = ({ children, permission }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const parsedToken = getCookie('token') as string;
  const isLogging = useSelector(getIsLogging);
  const isPermitted = usePermission(permission);
  const { error: permissionsError } = usePermissions();

  useEffect(() => {
    if (
      permissionsError &&
      typeof permissionsError === 'object' &&
      'status' in permissionsError &&
      permissionsError.status === 403
    ) {
      dispatch(logout(null));
    }
  }, [permissionsError]);

  useEffect(() => {
    if (parsedToken) {
      setLoading(false);
    } else if (!isLogging) {
      dispatch(logout(null));
    }
  }, [isLogging, parsedToken]);

  if (!parsedToken || isLoading || isPermitted === null) {
    return null;
  }

  return isPermitted ? <>{children}</> : <div>Недостаточно прав.</div>;
};
