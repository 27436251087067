import { TFullProductCharacteristics } from '@/types/products/products';

import { ProductItem } from '@prosv/core/types';

import { tryGetProduct } from '@/api/catalog/catalog';

export const getSelectedProduct = async (suggestedData: ProductItem[], value: string) => {
  const product = suggestedData.find((pr) => pr.id.split('_')[0] === value);
  if (!product) return;
  const fullProduct = await tryGetProduct(product.code);
  const year = fullProduct.payload.fullCharacteristics.find(
    (ch: TFullProductCharacteristics) => ch.code === 'god-titula',
  )?.values[0].value;
  return { ...product, publicationYear: year };
};
