import { PAGE_ELEMENTS } from '@/constants/common';

import { TOption } from '@/ui/components/MultiSelect';
import {
  ReviewAndQuestionsQueryParams,
  StatusTypes,
} from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

export const replayOptions: TOption[] = [
  { label: 'Да', value: 'true' },
  { label: 'Нет', value: 'false' },
  { label: 'Все', value: '' },
];

export const reviewsProductRating: TOption[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const reviewsStatuses: Record<StatusTypes, string> = {
  new: 'Новый',
  hidden: 'Снят с публикации',
  published: 'Опубликован',
  removal: 'На удалении',
  delete: 'Удален пользователем',
};

export const reviewStatusOptions: TOption[] = [
  { label: 'Новые', value: 'new' },
  { label: 'Опубликованные', value: 'published' },
  { label: 'Снят с публикации', value: 'hidden' },
  { label: 'На удалении', value: 'removal' },
  { label: 'Удален пользователем', value: 'delete' },
];

export const statusesColor: Record<StatusTypes, string> = {
  new: '#322FC6',
  hidden: '#F24236',
  published: '#2FC635',
  removal: '#F24236',
  delete: '#F24236',
};

export const reviewAndQuestionInitialFilter: ReviewAndQuestionsQueryParams = {
  currentPage: 1,
  limit: PAGE_ELEMENTS[0].value,
  rating: [],
  createDate: [null, null],
  status: [],
  article: '',
  id: '',
  hasReply: { label: 'Все', value: '' },
};
