import { IDisplayOnSitesSelectOptions } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.types';

export enum ENewsFormFields {
  URL_CODE = 'urlCode',
  IS_ACTIVE = 'isActive',
  TITLE = 'title',
  DATA_ACTIVE_FROM_DAYS = 'dateActiveFrom',
  DATA_ACTIVE_FROM_HOURS = 'dateActiveFromHours',
  DATA_ACTIVE_TO = 'dateActiveTo',
  DISPLAY_ON_SITES = 'displayOnSites',
  TARGET_PAGES = 'targetPages',
  IS_MAIN_NEWS = 'isMainNews',
  IMAGE_ALT = 'alt',
  IMAGE_TITLE = 'title',
  IMAGE_ID = 'id',
  DRAFT_ID = 'draftId',
  SHORT_DESCRIPTION = 'shortDescription',
  CATEGORY = 'category',
  THEME = 'theme',
  DIVISION = 'division',
  COMPANY = 'company',
  TAGS = 'tags',
  EDU_LEVEL = 'educationLevel',
  SUBJECT = 'subject',
  SERIES_LINE_UMK = 'seriesLineUmk',
  DIRECTION = 'educationDirection',
  DIGITAL_SERVICE = 'digitalService',
  READING_TIME = 'readingTime',
  IS_DISPLAY_VIEWS = 'isDisplayViews',
  MODIFIED_VIEWS_COUNT = 'modifiedViewsCount',
}

export enum EActivityFieldValues {
  ACTIVE = 'active',
  NOT_ACTIVE = 'notActive',
}

export const NEWS_CREATION_FORM_ID = 'news-creation-form';

export const selectDisplayOnSitesOptions: IDisplayOnSitesSelectOptions[] = [
  { value: 'corp', label: 'КС' },
  { value: 'user', label: 'ПС' },
  { value: 'all', label: 'КС + ПС' },
];

export const selectDisplayOnPageOptions = [
  { value: 'all', label: 'Основной листинг' },
  { value: 'kts', label: 'Оптовым покупателям' },
];

export const ACTIVITY_FIELD_VALUES = {
  [EActivityFieldValues.ACTIVE]: true,
  [EActivityFieldValues.NOT_ACTIVE]: false,
};

export const NewsFormFields = {
  urlCode: 'Символьный код URL',
  isActive: 'Активность',
  title: 'title',
  dateActiveFrom: 'Дата начала активности',
  dateActiveFromHours: 'Время начала активности',
  dateActiveTo: 'Дата окончания активности',
  displayOnSites: 'Показывать на сайтах',
  isMainNews: 'Сделать главной новостью',
  alt: 'alt',
  imageId: '',
  draftId: '',
  shortDescription: 'Краткое описание',
  category: 'Категория',
  theme: 'Тема',
  division: 'Дивизион',
  company: 'Компания',
  tags: 'Теги',
  eduLevel: 'Уровень образования',
  subject: 'Предмет',
  seriesLineUmk: 'Серия/Линия УМК',
  direction: 'Направление',
  digitalServices: 'Цифровой сервис',
  readingTime: 'Время чтения (в минутах)',
  isDisplayViews: '',
  modifiedViewsCount: '',
  targetPages: 'Показывать на страницах',
};
