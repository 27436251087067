import { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Center,
  Group,
  Input,
  LoadingOverlay,
  Pagination,
  Paper,
  Text,
} from '@mantine/core';

import { getOffset } from '@/utils/getOffset';
import { getPages } from '@/utils/getPages';

import { OFFSET } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { Loader } from '@/ui/containers/ContentEditor/Loader';
import { MetaTable } from '@/ui/containers/MetaTagsContainer/components/MetaTable/MetaTable';

import { ReactComponent as SearchIcon } from '@/assets/icons/search-filter.svg';
import {
  fetchMetaTagListAction,
  loadingMetaTagListSelector,
  metaTagListSelector,
  metaTagsLimitSelector,
  metaTagsOffsetSelector,
  metaTagsSearchMessageSelector,
  metaTagsSiteNameFilterSelector,
  metaTagsTotalSelector,
  setMetaTagsOffset,
  setUrl,
} from '@/store/slices/metaTagsAnyPage/metaTagsAnyPage';

export const MetaTagsContainer = () => {
  const dispatch = useAppDispatch();

  const metaTagsList = useSelector(metaTagListSelector);
  const metaTagsTotal = useSelector(metaTagsTotalSelector);
  const metaTagsLimit = useSelector(metaTagsLimitSelector);
  const metaTagsOffset = useSelector(metaTagsOffsetSelector);
  const isFetchingMetaTagsList = useSelector(loadingMetaTagListSelector);
  const filterMessage = useSelector(metaTagsSearchMessageSelector);
  const searchUrl = useSelector(metaTagsSiteNameFilterSelector);

  const onApplyFilters = () => {
    dispatch(
      fetchMetaTagListAction({
        page: OFFSET,
        perPage: metaTagsLimit,
        url: searchUrl,
      }),
    );
  };

  const onChangeUrlHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setUrl(event.target.value));
  };

  useEffect(() => {
    dispatch(fetchMetaTagListAction({ page: metaTagsOffset, perPage: metaTagsLimit }));
  }, [metaTagsOffset]);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Loader loading={false} blur />

      <PageHeader title='Мета-теги для любой страницы' />

      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          overflow: 'hidden',
          gap: '32px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 40px)',
          maxHeight: '100%',
        }}
      >
        <Group mb={24} position='apart' align='flex-end' noWrap>
          <Group spacing='xs' grow sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <Text size='sm' color='grayDefault'>
                Фильтр по URL
              </Text>
              <Input placeholder='Введите URL' onChange={onChangeUrlHandler} />
            </Box>
          </Group>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 38,
              maxWidth: 'fit-content',
            }}
          >
            <Button radius='xs' size='sm' onClick={onApplyFilters}>
              Применить фильтр
            </Button>
          </Box>
        </Group>

        {metaTagsList && metaTagsList?.length > 0 && (
          <>
            <Box sx={{ position: 'relative', overflow: 'scroll' }}>
              <MetaTable items={metaTagsList} />
              <LoadingOverlay visible={isFetchingMetaTagsList} />
            </Box>
          </>
        )}

        {!metaTagsList?.length && (
          <Box sx={{ position: 'relative', height: '100%' }}>
            <LoadingOverlay visible={false} />

            {filterMessage && (
              <Center style={{ height: '100%' }}>
                {filterMessage ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SearchIcon />
                    <Text align={'center'} sx={{ maxWidth: 380, textAlign: 'center' }}>
                      {filterMessage}
                    </Text>
                  </Box>
                ) : (
                  <Text align={'center'}>У вас нет шаблонов</Text>
                )}
              </Center>
            )}
          </Box>
        )}
        <Box
          sx={{
            marginTop: 'auto',
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            minHeight: '38px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Text size='md' color='greyDefault'>
              Всего:
            </Text>
            &nbsp;
            <Text size='md'>{metaTagsTotal}</Text>
          </Box>
          {metaTagsTotal && getPages(metaTagsTotal, metaTagsLimit) > 1 && (
            <Pagination
              sx={{ right: '0', position: 'absolute' }}
              position={'right'}
              total={getPages(metaTagsTotal, metaTagsLimit)}
              onChange={(page) => dispatch(setMetaTagsOffset(getOffset(page, metaTagsLimit)))}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};
