import { FC } from 'react';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import { EAddPromotionConfirmationTexts } from '@/ui/containers/PromotionsContainer';

type Props = {
  isBlockedRoute: boolean;
  isDelWithChanges: boolean;
  isDelete: boolean;
  onConfirmLeave: () => void;
  onCancel: () => void;
  handleDelete: () => void;
  setIsShowModal: (value: boolean) => void;
};

export const ActionsModal: FC<Props> = ({
  isBlockedRoute,
  isDelWithChanges,
  isDelete,
  onConfirmLeave,
  onCancel,
  handleDelete,
  setIsShowModal,
}) => {
  switch (true) {
    case isBlockedRoute:
      return (
        <ModalConfirm
          title={EAddPromotionConfirmationTexts.OUT_TITLE}
          text={EAddPromotionConfirmationTexts.NOT_SAVE_MENU_SITE}
          confirmHandler={onConfirmLeave}
          opened={isBlockedRoute}
          onClose={onCancel}
        />
      );
    case isDelWithChanges:
      return (
        <ModalConfirm
          title={EAddPromotionConfirmationTexts.TITLE_DELETE_ACTION}
          text={EAddPromotionConfirmationTexts.NOT_SAVE_MENU_SITE}
          confirmHandler={handleDelete}
          opened={isDelWithChanges}
          onClose={() => {
            setIsShowModal(false);
            onConfirmLeave();
          }}
        />
      );
    case isDelete:
      return (
        <ModalConfirm
          title={EAddPromotionConfirmationTexts.TITLE_DELETE_ACTION}
          text={EAddPromotionConfirmationTexts.DELETE_DESCRIPTION_ACTION}
          confirmHandler={handleDelete}
          opened={isDelete}
          onClose={() => setIsShowModal(false)}
        />
      );
    default:
      return <></>;
  }
};
