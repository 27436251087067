export enum EImageWithTextAroundFormFields {
  ID = 'id',
  TEXT = 'text',
  FILE = 'file',
  MAX_HEIGHT = 'maxHeight',
  MAX_WIDTH = 'maxWidth',
  IMAGE_ALT = 'imageAlt',
  IMAGE_TITLE = 'imageTitle',
  ALIGNMENT = 'alignment',
  POSITIONS_IMAGE = 'imagePosition',
}

export const IMAGE_POSITION_ELEMENTS = [
  { value: 'left', label: 'изображение слева, текст выравнивается по изображению' },
  { value: 'leftWithTextAround', label: 'изображение слева, текст обтекает изображение' },
  { value: 'right', label: 'изображение справа, текст выравнивается по изображению' },
  { value: 'rightWithTextAround', label: 'изображение справа, текст обтекает изображение' },
];
