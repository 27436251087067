import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { authApi } from './slices/auth';

import { audioappsSlice } from '@/store/slices/audioapps';
import authReducer from '@/store/slices/auth/auth';
import { bannersApi, bannersFilterSlice } from '@/store/slices/banners/bannersSlice';
import CategoryListReducer from '@/store/slices/catalog/catalog';
import { categoriesSlice } from '@/store/slices/categories';
import configurationReducer from '@/store/slices/configuration/configuration';
import configurationOptionReducer from '@/store/slices/configuration/configurationOption';
import configurationOptionsReducer from '@/store/slices/configuration/configurationOptions';
import configurationsReducer from '@/store/slices/configuration/configurations';
import { cubeSlice } from '@/store/slices/cube';
import { dictionaryApiSlice } from '@/store/slices/dictionary';
import featuresReducer from '@/store/slices/features/features';
import generalSettingsReducer from '@/store/slices/generalSettings/generalSettings';
import mailsTemplatesReducer from '@/store/slices/mailsTemplates/mailsTemplates';
import { materials } from '@/store/slices/materials/materials';
import mediaReducer from '@/store/slices/media/media';
import mediaFilesReducer from '@/store/slices/media/mediaFiles';
import mediaFoldersReducer from '@/store/slices/media/mediaFolders';
import menuReducer from '@/store/slices/menu/menu';
import menuItems from '@/store/slices/menu/menuItems';
import menusReducer from '@/store/slices/menu/menus';
import menuTypesReducer from '@/store/slices/menu/menuTypes';
import metaTagsAnyPageReducer from '@/store/slices/metaTagsAnyPage/metaTagsAnyPage';
import modelReducer from '@/store/slices/models/model';
import modelsReducer from '@/store/slices/models/models';
import newsReducer from '@/store/slices/news/news';
import { projectsFilterSlice, projectsSlice } from '@/store/slices/projects/projectsSlice';
import { promotionsApi, promotionsFilterSlice } from '@/store/slices/promotions/promotionsSlice';
import {
  questionsApi,
  questionsProductsFilterSlice,
} from '@/store/slices/questions/questionsSlice';
import redirectsReducer from '@/store/slices/redirects/redirects';
import { reviewsApi, reviewsProductsFilterSlice } from '@/store/slices/reviews/reviewsSlice';
import { selectionsPlacesFiltersSlice, selectionsSlice } from '@/store/slices/selections';
import serviceReducer from '@/store/slices/service/service';
import { supportDetailSlice, supportsFilterSlice } from '@/store/slices/support/supportDetailSlice';
import templatesReducer from '@/store/slices/templates/templates';
import { umkSlice } from '@/store/slices/umk/umkSlice';
import { umkListApi, umkListFilterSlice } from '@/store/slices/umkList/umkListSlice';
import { catalogTagsSlice } from '@/modules/catalogTags/store/catalogTagsSlice';

const rootReducer = combineReducers({
  configurations: configurationsReducer,
  configuration: configurationReducer,
  configurationOptions: configurationOptionsReducer,
  configurationOption: configurationOptionReducer,
  categories: CategoryListReducer,
  mailsTemplates: mailsTemplatesReducer,
  news: newsReducer,
  media: mediaReducer,
  mediaFiles: mediaFilesReducer,
  mediaFolders: mediaFoldersReducer,
  models: modelsReducer,
  model: modelReducer,
  service: serviceReducer,
  menu: menuReducer,
  menus: menusReducer,
  menuItems: menuItems,
  menuTypes: menuTypesReducer,
  auth: authReducer,
  features: featuresReducer,
  generalSettings: generalSettingsReducer,
  [dictionaryApiSlice.reducerPath]: dictionaryApiSlice.reducer,
  redirects: redirectsReducer,
  templates: templatesReducer,
  metaTags: metaTagsAnyPageReducer,
  materials,
  bannersFilter: bannersFilterSlice.reducer,
  questionsProductsFilter: questionsProductsFilterSlice.reducer,
  reviewsProductsFilter: reviewsProductsFilterSlice.reducer,
  promotionsFilter: promotionsFilterSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [bannersApi.reducerPath]: bannersApi.reducer,
  [questionsApi.reducerPath]: questionsApi.reducer,
  [reviewsApi.reducerPath]: reviewsApi.reducer,
  [promotionsApi.reducerPath]: promotionsApi.reducer,
  supportsFilter: supportsFilterSlice.reducer,
  [supportDetailSlice.reducerPath]: supportDetailSlice.reducer,
  projectFilters: projectsFilterSlice.reducer,
  [projectsSlice.reducerPath]: projectsSlice.reducer,
  [selectionsSlice.reducerPath]: selectionsSlice.reducer,
  selectionsPlacesFilters: selectionsPlacesFiltersSlice.reducer,
  [umkListApi.reducerPath]: umkListApi.reducer,
  umkListFilter: umkListFilterSlice.reducer,
  [categoriesSlice.reducerPath]: categoriesSlice.reducer,
  [audioappsSlice.reducerPath]: audioappsSlice.reducer,
  [cubeSlice.reducerPath]: cubeSlice.reducer,
  [umkSlice.reducerPath]: umkSlice.reducer,
  [catalogTagsSlice.reducerPath]: catalogTagsSlice.reducer,
});

// export default rootReducer;
export type IRootReducer = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authApi.middleware)
      .concat(bannersApi.middleware)
      .concat(questionsApi.middleware)
      .concat(supportDetailSlice.middleware)
      .concat(reviewsApi.middleware)
      .concat(promotionsApi.middleware)
      .concat(projectsSlice.middleware)
      .concat(dictionaryApiSlice.middleware)
      .concat(selectionsSlice.middleware)
      .concat(umkListApi.middleware)
      .concat(categoriesSlice.middleware)
      .concat(audioappsSlice.middleware)
      .concat(cubeSlice.middleware)
      .concat(umkSlice.middleware)
      .concat(cubeSlice.middleware)
      .concat(catalogTagsSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type State = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
