import { CSSProperties } from 'react';

import { IGetStylesParams, TAccept } from './FileLoader.types';

export const getStyles = ({ accept, reject, hasFiles }: IGetStylesParams): CSSProperties => {
  const getCurrentColor = () => {
    if (accept) return '#706FDD';

    if (reject) return 'red';

    return '#BFCEDB';
  };

  return {
    flex: 1,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: hasFiles ? 'flex-start' : 'center',
    padding: hasFiles ? '17px 18px 16px' : '30px 60px',
    textAlign: hasFiles ? 'initial' : 'center',
    border: '2px dashed',
    borderColor: getCurrentColor(),
    borderRadius: 8,
    backgroundColor: '#fff',
    color: getCurrentColor(),
    transition: 'border-color .3s ease-in-out, color .3s ease-in-out',
  };
};

export const textStyle: CSSProperties = {
  margin: 0,
  marginBottom: 13,
  fontSize: '16px',
  lineHeight: '18px',
};

export const acceptedTypes: Record<TAccept, Record<string, string[]>> = {
  docs: {
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'application/vnd.ms-powerpoint': [],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': [],
  },
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': [],
    'image/gif': [],
    'image/webp': [],
  },
  catalogTagsFiles: {
    'text/csv': [],
  },
};
