import { FC, MouseEvent } from 'react';
import { ActionIcon, Group } from '@mantine/core';
import { Copy, Pencil, Plus, Trash } from 'tabler-icons-react';

import notify from '@/utils/notify';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { TTransformMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';

import {
  setAddMenuItemsModalOpened,
  setDeleteMenuItemId,
  setEditMenuItem,
  setParentId,
} from '@/store/slices/menu/menuItems';

interface IDropdownButtonItems {
  item: TTransformMenuItem;
  parentId: string;
  url: string;
  isDisable: boolean;
}

const DropdownButtonItems: FC<IDropdownButtonItems> = ({ item, parentId, url, isDisable }) => {
  const dispatch = useAppDispatch();

  const addHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setParentId(parentId));
    dispatch(setAddMenuItemsModalOpened(true));
  };

  const copyHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(url);
      notify({ message: `Текст "${url}" скопирован в буфер обмена`, type: 'success' });
    } catch (error) {
      notify({ message: `Ошибка при копировании текста "${url}"`, type: 'error' });
    }
  };

  const editHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setEditMenuItem({ item, parentId: item.parentId }));
    dispatch(setAddMenuItemsModalOpened(true));
  };

  const delHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setDeleteMenuItemId(item.id));
  };

  return (
    <Group position={'right'}>
      <ActionIcon name='Добавить' title='Добавить' onClick={addHandler} disabled={isDisable}>
        <Plus size={20} color='gray' />
      </ActionIcon>

      <ActionIcon name='Скопировать' title='Скопировать' onClick={copyHandler}>
        <Copy size={20} color='gray' />
      </ActionIcon>

      <ActionIcon name='Изменить' title='Изменить' onClick={editHandler}>
        <Pencil size={20} color='gray' />
      </ActionIcon>

      <ActionIcon name='Удалить' title='Удалить' onClick={delHandler}>
        <Trash size={20} color='gray' />
      </ActionIcon>
    </Group>
  );
};

export default DropdownButtonItems;
