import { ServicesField } from './ServicesFormBlock.types';

export const type = 'cube-service';

export const SERVICES_FIELDS: ServicesField[] = [
  'service1',
  'service2',
  'service3',
  'service4',
] as const;
