import { useMemo } from 'react';

import { DISABLE_COLOR_VALUE } from '@/ui/components/MultiSelect/MultiSelect.constants';

export const useMultiSelectCustomStyles = ({
  maxWidth,
  height,
}: {
  maxWidth?: number | string;
  height?: number;
}) => {
  const customStyles = useMemo(
    () => ({
      valueContainer: (provided: any) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        paddingRight: 0,
        '&:hover': {
          borderColor: '#322FC6',
        },
      }),
      input: (provided: any) => ({
        ...provided,
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? '#322FC6' : '#E8ECF0',
        boxShadow: 'transparent',
        maxWidth: maxWidth,
        caretColor: 'transparent',
        cursor: 'pointer',
        height,
        color: state.isDisabled ? DISABLE_COLOR_VALUE : 'inherit',
        '&:hover': {
          borderColor: '#322FC6',
        },
      }),
      placeholder: (provided: any) => {
        return {
          ...provided,
          margin: 0,
          color: '#8DA2B5',
        };
      },
      dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? '#322FC6' : '#BFCEDB',
        '&:hover': {
          color: '#322FC6',
        },
      }),
    }),
    [maxWidth],
  );

  return customStyles;
};
