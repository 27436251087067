import React from 'react';
import { ActionIcon, Box, Popover } from '@mantine/core';
import { DotsVertical } from 'tabler-icons-react';

import { useOutsideClick } from '@/hooks/useOutsideClick';

import { useHandlersMoveRowButton } from './MoveRowButton.handlers';
import { useStateMoveRowButton } from './MoveRowButton.state';
import { DropDownItem } from './MoveRowButton.styles';
import { TMoveRowButtonProps } from './MoveRowButton.types';

export const MoveRowButton: React.FC<TMoveRowButtonProps> = ({ rowId }) => {
  const target = React.useRef<HTMLDivElement | null>(null);

  const state = useStateMoveRowButton({ rowId });
  const handlers = useHandlersMoveRowButton({ rowId });

  useOutsideClick(target, () => state.setOpened(false));

  return (
    <Popover withArrow shadow='md' position='right-start' opened={state.opened}>
      <Popover.Target>
        <ActionIcon
          size={18}
          color='grayDefault'
          variant='transparent'
          onClick={() => state.setOpened(true)}
        >
          <DotsVertical />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown p={4}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', width: 224, gap: 4 }}
          onClick={() => state.setOpened(false)}
          ref={target}
        >
          <DropDownItem onClick={handlers.handleRemoveRow}>Удалить</DropDownItem>
          <DropDownItem onClick={handlers.handleEditRow}>Редактировать</DropDownItem>
          {!state.isFirstRow && (
            <DropDownItem onClick={handlers.handleMoveUpRow}>Переместить вверх</DropDownItem>
          )}
          {!state.isLastRow && (
            <DropDownItem onClick={handlers.handleMoveDownRow}>Переместить вниз</DropDownItem>
          )}{' '}
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
