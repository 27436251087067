import { FC } from 'react';
import { Box } from '@mantine/core';

import { DictionarySelect } from '@/ui/components/DictionarySelect';
import { DndListItem, DndListItemProps } from '@/ui/components/DndListItem';

import { swapFormFields } from '../CubeContainer.helpers';

import { SERVICES_FIELDS, type } from './ServicesFormBlock.constants';
import { ServicesFormBlockProps } from './ServicesFormBlock.types';

export const ServicesFormBlock: FC<ServicesFormBlockProps> = ({ form, disabled }) => {
  const move: DndListItemProps['move'] = (dragIndex, hoverIndex) =>
    swapFormFields(SERVICES_FIELDS, dragIndex, hoverIndex, form.setValues);

  return (
    <Box>
      {SERVICES_FIELDS.map((field, index) => (
        <DndListItem key={field} {...{ type, index, move }}>
          <DictionarySelect
            clearable
            searchable
            disabled={disabled}
            dictionary='digitalservice'
            placeholder={`Цифровой сервис ${index + 1}`}
            {...form.getInputProps(field)}
          />
        </DndListItem>
      ))}
    </Box>
  );
};
