import { Sx } from '@mantine/core';

import { BREAKPOINTS } from '@/constants/mantine/breakpoints';

export const root: Sx = {
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  form: {
    display: 'flex',
    gap: '72px',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      flexDirection: 'column',
      gap: 12,
    },
  },
};

export const actionButton: Sx = {
  width: '200px',
};

export const actionWrapper: Sx = {
  display: 'flex',
  gap: '10px',
};

export const formWrapper: Sx = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
};
