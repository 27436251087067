import { IRedirect } from '@/types/redirects/redirects';

import { EMenuContainerEditRedirectConstants } from './EditRedirectModal.constants';

export const getRedirectInitialValues = (defaultValues?: IRedirect) => {
  return {
    [EMenuContainerEditRedirectConstants.OLD_URL]: defaultValues?.old || '',
    [EMenuContainerEditRedirectConstants.NEW_URL]: defaultValues?.new || '',
    [EMenuContainerEditRedirectConstants.CODE]: defaultValues?.statusCode || '',
  };
};
