import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Group, Paper, Title } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';

import { ProjectsFilter } from '@/pages/Projects/ProjectsList/components/Filter/ProjectsFilter';
import {
  useProjectsColumns,
  useProjectsConfig,
} from '@/pages/Projects/ProjectsList/ProjectsList.hook';
import { useLazyGetProjectListQuery } from '@/store/slices/projects/projectsSlice';

export const ProjectsList = () => {
  const canCreate = usePermission('PROJECT_CREATE');
  const navigate = useNavigate();

  const [getProjectsListRequest, projectListResponse] = useLazyGetProjectListQuery();
  const filterProps = useProjectsConfig(getProjectsListRequest);
  const columns = useProjectsColumns(filterProps.onChangeSort);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '96vh',
        overflowY: 'hidden',
      }}
    >
      <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group position='apart'>
          <Title order={1}>Проекты</Title>
          {canCreate && (
            <Button sx={{ width: '200px' }} onClick={() => navigate('/projects/new')}>
              Создать
            </Button>
          )}
        </Group>
      </Container>

      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <ProjectsFilter {...filterProps} />
        <CustomTable
          onChangePagination={(v) => {
            filterProps.onChange({ currentPage: v });
          }}
          limit={Number(filterProps.filters.limit)}
          noData='К сожалению, список вопросов пуст.'
          total={projectListResponse.data?.payload.meta.total || 0}
          currentPage={filterProps.filters.currentPage}
          status={projectListResponse.isFetching ? 'loading' : 'loaded'}
          data={projectListResponse.data?.payload.items || []}
          columns={columns}
        />
      </Paper>
    </Box>
  );
};
