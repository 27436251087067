import { IBannerCreationData, IGetBannerResponse } from '@/types/banner/bannerDetail';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

interface IParams {
  uuid: string;
  bannerData: IBannerCreationData;
}

export const useUpdateBanner = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    updateBanner: async ({ uuid, bannerData }: IParams) => {
      setIsLoading(true);
      try {
        const request = await api.put<IGetBannerResponse>(URLS.banners.putBanner(uuid), {
          ...bannerData,
        });
        notify({ message: 'Баннер успешно обновлен', type: 'success' });
        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка обновления баннера', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
