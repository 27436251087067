import * as Yup from 'yup';

import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

import { EUmkFormFields } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.constants';

export const umkDetailFormSchema = Yup.object({
  [EUmkFormFields.PAGE_TYPE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EUmkFormFields.UMK_NAME]: Yup.string().required('Выберите УМК из списка'),
  [EUmkFormFields.BANNER]: Yup.array().notRequired(),
  [EUmkFormFields.ACTIVE]: Yup.string().notRequired(),
});
