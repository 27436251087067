import { GeneralInformationCardTypes } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.types';
import { ProjectVariant } from '@/ui/components/GeneralInformationCard/variants/ProjectVariant';
import { QuestionVariant } from '@/ui/components/GeneralInformationCard/variants/QuestionVariant';
import { SupportVariant } from '@/ui/components/GeneralInformationCard/variants/SupportVariant';

export const GeneralInformationCard = (props: GeneralInformationCardTypes) => {
  switch (props.cardType) {
    case 'support':
      return <SupportVariant {...props} />;
    case 'question':
      return <QuestionVariant {...props} />;
    case 'review':
      return <QuestionVariant {...props} />;
    case 'project':
      return <ProjectVariant {...props} />;
    default:
      return <></>;
  }
};
