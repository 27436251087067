import { IPromotionItem, IPromotionUpdateData, TPromotionsResponse } from '@/types/promotions';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

interface IParams {
  uuid: string;
  promotionData: IPromotionUpdateData;
}

export const useUpdatePromotion = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    updatePromotion: async ({ uuid, promotionData }: IParams) => {
      setIsLoading(true);
      try {
        const request = await api.patch<TPromotionsResponse<IPromotionItem>>(
          URLS.promotions.putPromotion(uuid),
          { ...promotionData },
        );
        notify({ message: 'Акция успешно обновлена', type: 'success' });
        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка обновления акции', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
