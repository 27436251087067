import React from 'react';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { Box, CloseButton, Group, useMantineTheme } from '@mantine/core';
import { nanoid } from 'nanoid';

import { useMultiSelectCustomStyles } from '@/ui/components/MultiSelect/MultiSelect.styles';
import { TMultiSelectProps, TOption } from '@/ui/components/MultiSelect/MultiSelect.types';
import { OptionComponent } from '@/ui/components/MultiSelect/OptionComponent';
import { ValueContainer } from '@/ui/components/TagSelect/TabSelect.ValueContainer';

export const TagSelect: React.FC<TMultiSelectProps> = (props) => {
  const [values, setValues] = React.useState<MultiValue<TOption>>([]);

  const customStyles = useMultiSelectCustomStyles({ maxWidth: props.maxWidth });
  const theme = useMantineTheme();
  const handleChange = (selected: MultiValue<TOption>, actionMeta: ActionMeta<TOption>) => {
    setValues(selected);
    props.onChange?.(selected, actionMeta);
  };

  const handleRemove = (v: string) => {
    props.onChange?.(
      (props?.value as MultiValue<TOption>)?.filter((el) => el.value !== v),
      {} as ActionMeta<TOption>,
    );
  };

  return (
    <Box>
      {props.label}
      <Select
        value={props.value || values}
        onChange={handleChange}
        options={props.options}
        isMulti
        menuPlacement={props.menuPlacement || 'auto'}
        isDisabled={props.isDisabled || false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        styles={customStyles}
        placeholder={props.placeholder}
        maxMenuHeight={props.maxDropdownHeight || 200}
        isClearable={false}
        components={{
          ValueContainer,
          Option: OptionComponent,
          IndicatorSeparator: () => null,
        }}
      />
      {((props?.value || []) as MultiValue<TOption>).length > 0 && (
        <Group mt={16}>
          {(props?.value as MultiValue<TOption>)?.map((e) => (
            <Box
              key={nanoid()}
              sx={{
                display: 'flex',
                borderRadius: 4,
                gap: 8,
                alignItems: 'center',
                background: theme.colors.greyBG,
                padding: '4px 12px',
              }}
            >
              {e.label}
              <CloseButton
                onClick={() => handleRemove(e.value)}
                variant='transparent'
                size={22}
                iconSize={14}
                tabIndex={-1}
              />
            </Box>
          ))}
        </Group>
      )}
    </Box>
  );
};
