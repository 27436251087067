export enum ResourceType {
  FILE = 'file',
  FOLDER = 'folder',
}

export interface IFolder {
  name: string;
  parentFolderId: string | null;
  id?: string;
  depthLevel?: number;
  size?: string;
}

export interface IFolderFetch {
  values: {
    name: string;
    parentFolderId: string | null;
    id?: string;
    depthLevel?: number;
    size?: string;
  }
}

export interface IFolderTree extends IFolder {
  id: string;
  childrens?: IFolderTree[];
}

export interface IFolderType {
  resourceType: ResourceType.FOLDER;
  folderFields: IFolder;
}

export interface IFile {
  id: string;
  name: string;
  size: string;
  updatedAt: string;
  url: string;
  ext: string;
  contentType: string;
  folderId?: string;
  alt?: string;
  title?: string;
}

export interface IFileType {
  resourceType: ResourceType.FILE;
  fileFields: IFile;
}
