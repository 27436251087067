export enum EImageModalFormFields {
  ID = 'id',
  FILE = 'file',
  MAX_HEIGHT = 'maxHeight',
  MAX_WIDTH = 'maxWidth',
  IMAGE_SOURCE = 'imageSource',
  IMAGE_CAPTION = 'imageCaption',
  ALIGNMENT = 'alignment',
  IS_FIXED_HEIGHT = 'isFixedHeight',
}

export const alignmentOptions = [
  { value: 'left', label: 'по левому краю' },
  { value: 'right', label: 'по правому краю' },
  { value: 'center', label: 'по центру' },
];
