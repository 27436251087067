import { FC, memo } from 'react';
import { Grid } from '@mantine/core';

import {
  Card,
  TCardVariants,
} from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectContentBlockModal/Card';
import { useDisabledCallback } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectContentBlockModal/NewsCards/NewsCards.hooks';
import {
  selectContentBlockDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { TNewsCardProps } from './NewsCards.types';

const CARD_VARIANTS: TCardVariants[] = [
  'text',
  'event',
  'link-btn',
  'download',
  'image',
  'people',
  'video',
  'product',
  'quote',
  'gallery',
  'news',
  'products',
  'promo',
  'banner',
  'html',
  'catalog',
  'compactPromo',
  'imageWithTextAround',
  'question',
  'umkSystem',
];

const NewsCardsUI: FC<TNewsCardProps> = () => {
  const selectContentBlockData = useStoreContentEditor(selectContentBlockDataSelector);
  const getIsDisabled = useDisabledCallback();

  return (
    <Grid
      m={0}
      sx={{
        display: 'grid',
        gap: 12,
        gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr));',
      }}
    >
      {CARD_VARIANTS.map((variant) => {
        return (
          <Grid.Col span={3} p={0} key={variant}>
            <Card
              variant={variant}
              disabled={getIsDisabled(variant)}
              rowId={selectContentBlockData?.rowId ?? null}
              cardId={selectContentBlockData?.cardId ?? null}
            />
          </Grid.Col>
        );
      })}
    </Grid>
  );
};

export const NewsCards = memo(NewsCardsUI);
