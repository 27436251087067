import { itemsPerPageOptions } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';

import { UmkListFilterSlice } from '@/store/slices/umkList/umkList.types';

export const umkListDefaultFilter: UmkListFilterSlice = {
  type: 'system',
  currentPage: 1,
  limit: itemsPerPageOptions[0].value,
  sortField: '',
  title: '',
  createdAt: [null, null],
  updatedAt: [null, null],
  isActive: undefined,
};
