import { TMenu } from '@/types';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { tryGetMenuTypes } from '@/api/menu/menuTypes';

import { MenuTypeEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';

interface IMenuTypesState {
  status: {
    fetchingGetMenu: boolean;
  };
  menus: TMenu[] | null;
  currentTypeMenu: null | MenuTypeEnum;
}

const initialState: IMenuTypesState = {
  status: {
    fetchingGetMenu: false,
  },
  menus: null,
  currentTypeMenu: null,
};

export const fetchGetMenuTypesAction = createAsyncThunk(
  '/getMenuTypes',
  async (_, { rejectWithValue }) => {
    try {
      // @ts-ignore
      const { payload } = await tryGetMenuTypes();
      return payload;
    } catch (e) {
      return rejectWithValue('Ошибка');
    }
  },
);

export const menuTypesSlice = createSlice({
  name: 'menuTypes',
  initialState,
  reducers: {
    setTypeMenu: (state, action) => {
      state.currentTypeMenu = action.payload.type;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetMenuTypesAction.pending, (state) => {
        state.status.fetchingGetMenu = true;
      })
      .addCase(fetchGetMenuTypesAction.fulfilled, (state, action) => {
        state.status.fetchingGetMenu = false;
        state.menus = action.payload.items;
      })
      .addCase(fetchGetMenuTypesAction.rejected, (state) => {
        state.status.fetchingGetMenu = false;
      });
  },
});

// Selectors
type TSelectorState = { menuTypes: IMenuTypesState };

// statuses
export const selectFetchingGetMenuTypes = (state: TSelectorState) =>
  state.menuTypes.status.fetchingGetMenu;
export const selectMenuTypes = (state: TSelectorState) => state.menuTypes.menus;
export const selectCurrentMenuType = (state: TSelectorState) => state.menuTypes.currentTypeMenu;
// reducers and actions

export const { setTypeMenu } = menuTypesSlice.actions;

export default menuTypesSlice.reducer;
