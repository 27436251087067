import {
  CreateUmkItemRequest,
  CreateUmkItemResponse,
} from '@prosv/core/types/bff/umk/admin/create';
import { UmkPageResponse } from '@prosv/core/types/bff/umk/admin/get';
import {
  SearchUmkItemResponse,
  SearchUmkRequest,
} from '@prosv/core/types/bff/umk/admin/searchByType';
import {
  UpdateUmkItemRequest,
  UpdateUmkItemResponse,
} from '@prosv/core/types/bff/umk/admin/update';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

export const umkSlice = createApi({
  reducerPath: 'api/umk',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['UMK'],
  endpoints: (build) => ({
    getUmk: build.query<UmkPageResponse['payload'], string | undefined>({
      query: (id) => {
        if (!id) {
          throw new Error('ID is required');
        }
        return {
          method: 'get',
          url: URLS.umk.getUmk(id),
        };
      },
      transformResponse: ({ payload }: UmkPageResponse) => payload,
      providesTags: [{ type: 'UMK' }],
    }),

    createUmkPage: build.mutation<CreateUmkItemResponse['payload'], CreateUmkItemRequest>({
      query: (data) => ({ method: 'post', url: URLS.umk.createUmk, data }),
      transformResponse: ({ payload }: CreateUmkItemResponse) => payload,
      invalidatesTags: ['UMK'],
    }),

    updateUmkPage: build.mutation<
      UpdateUmkItemResponse['payload'],
      { data: UpdateUmkItemRequest; id: string }
    >({
      query: ({ data, id }) => ({ method: 'patch', url: URLS.umk.updateUmk(id), data }),
      transformResponse: ({ payload }: UpdateUmkItemResponse) => payload,
      invalidatesTags: ['UMK'],
    }),

    deleteUmkPage: build.mutation<void, string>({
      query: (id) => ({ method: 'delete', url: URLS.umk.deleteUmk(id) }),
    }),

    searchUmk: build.mutation<SearchUmkItemResponse['payload'], SearchUmkRequest>({
      query: (data) => ({ method: 'post', url: URLS.umk.searchUmk, data }),
      transformResponse: ({ payload }: SearchUmkItemResponse) => payload,
    }),
  }),
});
