import * as Yup from 'yup';

import { EImageModalFormFields } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal/ImageSnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const imageSnippetSchema = Yup.object({
  [EImageModalFormFields.FILE]: Yup.object({
    id: Yup.string().required(ERR_REQUIRED_TEXT),
  }),

  [EImageModalFormFields.MAX_WIDTH]: Yup.string().nullable(),
  [EImageModalFormFields.IMAGE_SOURCE]: Yup.string().nullable(),
  [EImageModalFormFields.IMAGE_CAPTION]: Yup.string().nullable(),
  [EImageModalFormFields.ALIGNMENT]: Yup.string().nullable(),
  [EImageModalFormFields.IS_FIXED_HEIGHT]: Yup.boolean(),
});
