import { FC } from 'react';
import { Box } from '@mantine/core';

import { CategorySelect } from '@/ui/components/CategorySelect';
import { DndListItem, DndListItemProps } from '@/ui/components/DndListItem';

import { swapFormFields } from '../CubeContainer.helpers';

import { CATEGORIES_FIELDS, type } from './CategoriesFormBlock.constants';
import { CategoriesFormBlockProps } from './CategoriesFormBlock.types';

export const CategoriesFormBlock: FC<CategoriesFormBlockProps> = ({ form, disabled }) => {
  const move: DndListItemProps['move'] = (dragIndex, hoverIndex) =>
    swapFormFields(CATEGORIES_FIELDS, dragIndex, hoverIndex, form.setValues);

  return (
    <Box>
      {CATEGORIES_FIELDS.map((field, index) => (
        <DndListItem key={field} {...{ type, index, move }}>
          <CategorySelect
            clearable
            searchable
            disabled={disabled}
            placeholder={`Категория каталога ${index + 1}`}
            {...form.getInputProps(field)}
          />
        </DndListItem>
      ))}
    </Box>
  );
};
