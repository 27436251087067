import { GetPermission } from '@/types/auth/permissions';
import { TServices } from '@/types/services/services';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryGetServices } from '@/api/service/service';

import notify from '@/utils/notify';

interface IServiceState {
  services?: TServices | null;
  currentService: string | null;
  isLoading: boolean;
  hasServiceChanged: boolean;
}

const initialState: IServiceState = {
  services: null,
  currentService: 'configurations',
  hasServiceChanged: false,
  isLoading: false,
};

export const fetchServiceAction = createAsyncThunk(
  '/service',
  async (getPermission: GetPermission) => {
    const result = await tryGetServices(getPermission);

    if (result) {
      return result;
    } else {
      return null;
    }
  },
);

export const serviceSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setCurrentService: (state, action: PayloadAction<string>) => {
      state.currentService = action.payload;
    },
    setServiceChanged: (state, action: PayloadAction<boolean>) => {
      state.hasServiceChanged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchServiceAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.services = action.payload;
      })
      .addCase(fetchServiceAction.rejected, () => {
        notify({ message: 'Приложение сейчас недоступно. Попробуйте позже', type: 'error' });
      });
  },
});

// Selectors

type TSelectorState = { service: IServiceState };
export const selectCurrentService = (state: TSelectorState) => state.service.currentService;
export const selectServices = (state: TSelectorState) => state.service.services;
export const selectServiceChanged = (state: TSelectorState) => state.service.hasServiceChanged;

// Reducers and actions

export const { setCurrentService, setServiceChanged } = serviceSlice.actions;

export default serviceSlice.reducer;
