import { IDraftResponse, TUpdateDraft } from '@/types/draft/draft';

import { URLS } from '@/api/urls';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

import { TContentData } from '@/ui/containers/ContentEditor/ContentEditor.types';

import api from '../instance';

export const apiGetContentDataEditor = async (id: string) => {
  return api.get<IDraftResponse>(URLS.draft.getDraft(id));
};

export const apiUpdateDraft = async ({ data, id }: { data: TUpdateDraft; id: string }) => {
  return api.put<IDraftResponse>(URLS.draft.putDraft(id), { ...data });
};

export const tryGetContentDataEditor = (id: string) =>
  apiGetContentDataEditor(id)
    .then((res) => res.data)
    .catch(axiosErrorHandler);

export const apiUpdateContentDataEditor = async ({
  id,
  contentData,
}: {
  id: string;
  contentData: TContentData;
}) => {
  const HARDCODE_USER_ID = 'e0204537-2110-4d42-a30a-0f915c4f6375'; // TODO исправить после реализации авторизации
  const dataStringify = JSON.stringify(contentData);

  const data: TUpdateDraft = {
    content: dataStringify,
    meta: {
      userId: HARDCODE_USER_ID,
    },
  };

  const request = await apiUpdateDraft({ id, data });

  return request.data;
};
