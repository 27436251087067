import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import { IGetMaterialResponse } from '@/ui/containers/MaterialsContainer/Materials.types';
import { IUpdateMaterialProps } from '@/ui/containers/MaterialsCreationContainer/MaterialState.types';

import api from '../../instance';

export const useUpdateMaterials = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    updateMaterial: async ({ uuid, materialData }: IUpdateMaterialProps) => {
      setIsLoading(true);
      try {
        const request = await api.patch<IGetMaterialResponse>(URLS.materials.putMaterial(uuid), {
          ...materialData,
        });
        notify({ message: 'Материал успешно обновлен', type: 'success' });
        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка обновления материала', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
