import { TGEtNewsQuery, TNewsItem } from '@/types/news/news';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryDelMailsTemplate, tryGetNewsList, tryMultiDelMailsTemplate } from '@/api/news/news';

import notify from '@/utils/notify';

import { LIMIT, OFFSET } from '@/constants/common';

interface INewsState {
  status: {
    fetchingNewsList: boolean;
    fetchingAddNews: boolean;
  };
  modals: {
    delNewsItemModal: boolean;
    delSelectedNewsModal: boolean;
  };
  items: TNewsItem[] | null;
  total: number | null;
  limit: number;
  offset: number;
  page: number;
}

const initialState: INewsState = {
  status: {
    fetchingNewsList: true,
    fetchingAddNews: false,
  },
  modals: {
    delNewsItemModal: false,
    delSelectedNewsModal: false,
  },
  items: null,
  total: null,
  limit: LIMIT,
  offset: OFFSET,
  page: 0,
};

export const fetchNewsListAction = createAsyncThunk(
  '/newsList',
  async (params: TGEtNewsQuery, { rejectWithValue }) => {
    const result = await tryGetNewsList(params);

    if (result) {
      return result.payload;
    } else {
      return rejectWithValue(null);
    }
  },
);

export const fetchDelMailsTemplateAction = createAsyncThunk(
  '/delMailsTemplate',
  async (id: string, { rejectWithValue }) => {
    const result = await tryDelMailsTemplate(id);

    if (result === 204) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  },
);

export const fetchMultiDelMailsTemplateAction = createAsyncThunk(
  '/multiDelMailsTemplate',
  async (id: string[], { rejectWithValue }) => {
    const result = await tryMultiDelMailsTemplate(id);

    if (result === 204) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  },
);

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setOpenDelNewsItemModal: (state, action: PayloadAction<boolean>) => {
      state.modals.delNewsItemModal = action.payload;
    },
    setOpenDelSelectedNewsModal: (state, action: PayloadAction<boolean>) => {
      state.modals.delSelectedNewsModal = action.payload;
    },
    setNewsLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setNewsOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setNewsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsListAction.pending, (state) => {
        state.status.fetchingNewsList = true;
      })
      .addCase(fetchNewsListAction.fulfilled, (state, action) => {
        state.status.fetchingNewsList = false;
        state.items = action.payload.items;
        state.total = action.payload.meta.total;
      })
      .addCase(fetchNewsListAction.rejected, (state) => {
        state.status.fetchingNewsList = false;
      });
    builder
      .addCase(fetchDelMailsTemplateAction.pending, (state) => {
        state.status.fetchingNewsList = true;
      })
      .addCase(fetchDelMailsTemplateAction.fulfilled, (state) => {
        state.status.fetchingNewsList = false;
        state.modals.delNewsItemModal = false;

        notify({ message: 'Новостной шаблон удален', type: 'success' });
      })
      .addCase(fetchDelMailsTemplateAction.rejected, (state) => {
        state.status.fetchingNewsList = false;
        state.modals.delNewsItemModal = false;
      });
    builder
      .addCase(fetchMultiDelMailsTemplateAction.pending, (state) => {
        state.status.fetchingNewsList = true;
      })
      .addCase(fetchMultiDelMailsTemplateAction.fulfilled, (state) => {
        state.status.fetchingNewsList = false;
        state.modals.delSelectedNewsModal = false;

        notify({ message: 'Новостные шаблоны удалены', type: 'success' });
      })
      .addCase(fetchMultiDelMailsTemplateAction.rejected, (state) => {
        state.status.fetchingNewsList = false;
        state.modals.delSelectedNewsModal = false;
      });
  },
});

// Selectors

type TNewsSelector = { news: INewsState };

// modals
export const selectDelMailsTemplateModal = (state: TNewsSelector) =>
  state.news.modals.delNewsItemModal;

export const selectDelMailsTemplatesModal = (state: TNewsSelector) =>
  state.news.modals.delNewsItemModal;
// statuses
export const isLoadingNewsListSelector = (state: TNewsSelector) =>
  state.news.status.fetchingNewsList;
export const newsListSelector = (state: TNewsSelector) => state.news.items;
export const newsTotalSelector = (state: TNewsSelector) => state.news.total;
export const newsListPageSelector = (state: TNewsSelector) => state.news.page;
export const newsLimitSelector = (state: TNewsSelector) => state.news.limit;
export const newsOffsetSelector = (state: TNewsSelector) => state.news.offset;

// Reducers and actions

export const {
  setNewsLimit,
  setNewsOffset,
  setNewsPage,
  setOpenDelSelectedNewsModal,
  setOpenDelNewsItemModal,
} = newsSlice.actions;

export default newsSlice.reducer;
