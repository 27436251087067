import { TMenuTypesResponse } from '@/types';

import { tryAction } from '@/api/tryAction';
import { URLS } from '@/api/urls';

import api from '../instance';

export const apiGetMenuTypes = () => api.get<TMenuTypesResponse>(URLS.menus.getMenuTypes);

/*TRY ...CATCH */
export const tryGetMenuTypes = () => tryAction<TMenuTypesResponse>(apiGetMenuTypes());
