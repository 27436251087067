import { FC } from 'react';
import { Button, Group, Modal, ModalProps, Text, Title } from '@mantine/core';
import { TextProps } from '@mantine/core/lib/Text/Text';

interface IModalConfirm extends ModalProps {
  title: string;
  text: string;
  confirmHandler: () => void;
  isReverseButton?: boolean;
  disabled?: boolean;
  subtitleProps?: TextProps;
}

const ModalConfirm: FC<IModalConfirm> = ({
  title,
  text,
  confirmHandler,
  isReverseButton,
  disabled,
  subtitleProps = {},
  ...props
}) => {
  return (
    <Modal
      centered
      size={'xs'}
      styles={{
        header: {
          marginBottom: 0,
        },
      }}
      {...props}
      title=''
    >
      <Title align='center' order={3} mb={20}>
        {title}
      </Title>
      <Text size='xs' align='center' mb={30} {...subtitleProps}>
        {text}
      </Text>
      <Group position='apart' grow>
        {isReverseButton ? (
          <>
            <Button
              variant='outline'
              color='red'
              onClick={confirmHandler}
              sx={{ flexGrow: 1 }}
              {...{ disabled }}
            >
              Да
            </Button>
            <Button onClick={props.onClose} sx={{ flexGrow: 1 }} {...{ disabled }}>
              Нет
            </Button>
          </>
        ) : (
          <>
            <Button onClick={confirmHandler} sx={{ flexGrow: 1 }} {...{ disabled }}>
              Да
            </Button>
            <Button
              variant='outline'
              color='red'
              onClick={props.onClose}
              sx={{ flexGrow: 1 }}
              {...{ disabled }}
            >
              Нет
            </Button>
          </>
        )}
      </Group>
    </Modal>
  );
};

export default ModalConfirm;
