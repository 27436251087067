import { CSSProperties } from 'react';

import { ReviewType } from '@/ui/containers/Reviews/types';

export const breadcrumbs = [
  {
    name: 'Отзывы',
    url: '/reviews',
  },
  {
    name: 'ID: 35432124',
  },
];

export const reviewTypeValues: Record<ReviewType['type'], string> = {
  event: 'На мероприятие',
  product: 'На продукт',
};

export const productDetailTextStyle: CSSProperties = {
  marginTop: 12,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  flexShrink: 0,
  gap: 20,
  whiteSpace: 'nowrap',
};
