import { FC } from 'react';
import { Box } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { GeneralInformationCard } from '@/ui/components/GeneralInformationCard/GeneralInformationCard';

import { getUmkLink } from '@/pages/UmkDetail/UmkDetail.helpers';
import { useGetUmkQuery } from '@/pages/UmkDetail/UmkDetail.hooks';
import { UmkDetailForm } from '@/pages/UmkDetail/UmkDetailForm';
import { UmkType } from '@/store/slices/umkList/umkList.types';

interface Props {
  id: string;
  isNewUmk: boolean;
  draftIds: string[];
}

export const UmkDetailGeneralInfo: FC<Props> = ({ id, isNewUmk, draftIds }) => {
  const { data } = useGetUmkQuery(id, { skip: !id || isNewUmk });

  const [umkType] = useLocalStorage<UmkType>({ key: 'umkType' });
  const umkLink = `${process.env.PREVIEW_URL}` + getUmkLink(umkType, data?.umk.code || '');

  return (
    <Box sx={{ width: '100%', display: 'flex', gap: '32px' }}>
      {umkType && (
        <UmkDetailForm draftIds={draftIds} isNewUmk={isNewUmk} umkType={umkType} umkInfo={data} />
      )}
      {!isNewUmk && (
        <GeneralInformationCard
          author={{
            name: data?.meta.author.fullName,
            createDate: data?.meta.createdAt,
          }}
          editor={{ editDate: data?.meta.updatedAt, name: data?.meta.editor?.fullName }}
          generalId={data?.id}
          cardType='support'
          pageLink={umkLink}
        />
      )}
    </Box>
  );
};
