import { useDocumentTitle } from '@mantine/hooks';

import { TITLE_META_TAGS } from '@/constants/titles';

import { MetaTagsContainer } from '@/ui/containers/MetaTagsContainer/MetaTagsContainer';

const MetaTagsAnyPage = () => {
  useDocumentTitle(TITLE_META_TAGS);

  return <MetaTagsContainer />;
};

export default MetaTagsAnyPage;
