export enum EProductSnippetModalFormFields {
  ID = 'id',
  TITLE = 'title',
  LINK = 'link',
  DESCRIPTION = 'description',
  IMAGE = 'image',
  AUTHOR = 'author',
  ALT = 'alt',
  IMAGE_TITLE = 'imageTitle',
  IMAGE_ALT = 'imageAlt',
}
