import { IFileType, IFolder, IFolderTree, IFolderType, TQueryParamsNew } from '@/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryGetFolders, tryGetMedia } from '@/api';

interface IMediaState {
  status: {
    fetchingGetMedia: boolean;
    fetchingGetFolders: boolean;
  };
  media: (IFolderType & IFileType)[] | null;
  mediaTotal: number;
  folders: IFolder[] | null;
  moveInFolder: IFolderTree | null | string;
}

const initialState: IMediaState = {
  status: {
    fetchingGetMedia: false,
    fetchingGetFolders: false,
  },
  media: null,
  mediaTotal: 0,
  folders: null,
  moveInFolder: null,
};

export const fetchGetMedia = createAsyncThunk(
  'getMedia',
  async (params: TQueryParamsNew, { rejectWithValue }) => {
    const result = await tryGetMedia(params);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const fetchGetMediaMore = createAsyncThunk(
  'getMediaMore',
  async (params: TQueryParamsNew, { rejectWithValue }) => {
    const result = await tryGetMedia(params);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const fetchGetFolders = createAsyncThunk('getFolders', async (smth, { rejectWithValue }) => {
  const result = await tryGetFolders();

  if (result) {
    return result;
  } else {
    return rejectWithValue(null);
  }
});

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setMoveInFolder: (state, action: PayloadAction<IFolderTree | string | null>) => {
      state.moveInFolder = action.payload;
    },
    setMedia: (state, action: PayloadAction<(IFolderType & IFileType)[] | null>) => {
      state.media = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetMedia.pending, (state) => {
        state.status.fetchingGetMedia = true;
      })
      .addCase(fetchGetMedia.fulfilled, (state, action) => {
        state.status.fetchingGetMedia = false;
        state.media = action.payload.items;
        state.mediaTotal = action.payload.total;
      })
      .addCase(fetchGetMedia.rejected, (state) => {
        state.status.fetchingGetMedia = false;
        state.media = null;
      });
    builder
      .addCase(fetchGetMediaMore.pending, (state) => {
        state.status.fetchingGetMedia = true;
      })
      .addCase(fetchGetMediaMore.fulfilled, (state, action) => {
        state.status.fetchingGetMedia = false;
        state.mediaTotal = action.payload.total;

        if (state.media) {
          state.media = [...state.media, ...action.payload.items];
        } else {
          state.media = action.payload.items;
        }
      })
      .addCase(fetchGetMediaMore.rejected, (state) => {
        state.status.fetchingGetMedia = false;

        if (state.media) {
          state.media = [...state.media];
          state.mediaTotal = state.media.length;
        } else {
          state.media = null;
          state.mediaTotal = 0;
        }
      });
    builder
      .addCase(fetchGetFolders.pending, (state) => {
        state.status.fetchingGetFolders = true;
      })
      .addCase(fetchGetFolders.fulfilled, (state, action) => {
        state.status.fetchingGetFolders = false;
        state.folders = action.payload;
      })
      .addCase(fetchGetFolders.rejected, (state) => {
        state.status.fetchingGetFolders = false;
        state.folders = null;
      });
  },
});

// Selectors
type TSelectorState = { media: IMediaState };

// statuses
export const selectFetchingGetMedia = (state: TSelectorState) =>
  state.media.status.fetchingGetMedia;
export const selectFetchingGetFolders = (state: TSelectorState) =>
  state.media.status.fetchingGetFolders;

export const selectMedia = (state: TSelectorState) => state.media.media;
export const selectMediaTotal = (state: TSelectorState) => state.media.mediaTotal;
export const selectFolders = (state: TSelectorState) => state.media.folders;
export const selectMoveInFolder = (state: TSelectorState) => state.media.moveInFolder;

// reducers and actions
export const { setMoveInFolder, setMedia } = mediaSlice.actions;

export default mediaSlice.reducer;
