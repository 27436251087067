import { ImageWithTextAround, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import {
  EImageWithTextAroundFormFields,
  IMAGE_POSITION_ELEMENTS,
} from '@/ui/containers/ContentEditor/snippets/ImageWithTextAroundSnippet/ImageWithTextAroundSnippet.constants';

export const getInitialValues = (
  defaultValues?: ImageWithTextAround,
): SnippetValues<ImageWithTextAround> => {
  return {
    [EImageWithTextAroundFormFields.ID]: defaultValues?.id || uuid_v4(),
    [EImageWithTextAroundFormFields.TEXT]: defaultValues?.text || '',

    [EImageWithTextAroundFormFields.FILE]: defaultValues?.file || {
      id: '',
      name: '',
      path: '',
    },
    [EImageWithTextAroundFormFields.IMAGE_ALT]: defaultValues?.imageAlt || '',
    [EImageWithTextAroundFormFields.IMAGE_TITLE]: defaultValues?.imageTitle || '',
    [EImageWithTextAroundFormFields.POSITIONS_IMAGE]:
      defaultValues?.imagePosition || IMAGE_POSITION_ELEMENTS[0].value,
  };
};
