import { IGetAudioAttributesResponse } from '@/types/banner/bannerDetail';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import api from '../../instance';

export const useGetAudioAttributes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IGetAudioAttributesResponse | null>(null);

  return {
    data,
    isLoading,
    getAudioAttributes: async (types: string[]) => {
      setIsLoading(true);
      try {
        const request = await api.get<IGetAudioAttributesResponse>(
          getUrlWithSearchParams(
            URLS.banners.getAudioAttribute,
            { types },
            { arrayFormat: 'index' },
          ),
        );
        setData(request.data);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    },
  };
};
