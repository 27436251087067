import * as Yup from 'yup';

import { validationTexts } from '@/constants/validationTexts';

import { EEventSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/EventSnippetModal/EventSnippetModal.constants';
import { EProductSnippetModalFormFields } from '@/ui/containers/ContentEditor/snippets/ProductSnippetModal/ProductSnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const productSnippetSchema = Yup.object({
  [EProductSnippetModalFormFields.LINK]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EProductSnippetModalFormFields.TITLE]: Yup.string()
    .required(ERR_REQUIRED_TEXT)
    .max(150, validationTexts.MAX_LENGTH(150)),
  [EProductSnippetModalFormFields.DESCRIPTION]: Yup.string()
    .required(ERR_REQUIRED_TEXT)
    .max(1000, validationTexts.MAX_LENGTH(1000)),
  [EProductSnippetModalFormFields.IMAGE]: Yup.object({
    [EEventSnippetFormFields.IMAGE_ID]: Yup.string().required(ERR_REQUIRED_TEXT),
  }),
  [EProductSnippetModalFormFields.AUTHOR]: Yup.string()
    .required(ERR_REQUIRED_TEXT)
    .max(150, validationTexts.MAX_LENGTH(150)),
  [EProductSnippetModalFormFields.ALT]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EProductSnippetModalFormFields.IMAGE_TITLE]: Yup.string().required(ERR_REQUIRED_TEXT),
});
