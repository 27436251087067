import React from 'react';
import { Text } from '@mantine/core';
import dayjs from 'dayjs';

import { formatDateWithHours } from '@/constants/common';

interface Props { date?: string; text: string }
export const DateComponent = ({ date, text }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 4,
      }}
    >
      <Text color='#66788A' size='sm'>
        {text}
      </Text>
      <Text sx={{ whiteSpace: 'nowrap' }}>
        {date ? dayjs(date).format(formatDateWithHours) : ' - '}
      </Text>
    </div>
  );
};
