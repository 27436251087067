import { FC } from 'react';
import { Box, Checkbox, Group, Text, Textarea, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { MultiSelect } from '@/ui/components/MultiSelect';
import {
  EMetaTagFormFields,
  selectRobotOptions,
} from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.constants';
import { IMetaTagFormFields } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.types';

interface IProps {
  form: UseFormReturnType<IMetaTagFormFields>;
}

export const MetaTagForm: FC<IProps> = ({ form }) => {
  const { onChange: onChangeRobots, value: valueRobots } = form.getInputProps(
    EMetaTagFormFields.ROBOTS,
  );

  const { onChange: onChangeUrl, value: valueUrl } = form.getInputProps(EMetaTagFormFields.SITE);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'center',
        width: '100%',
        paddingBottom: '22px',
        fontWeight: 500,
        fontSize: 14,
      }}
    >
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>Site name</Text>
        <Box>
          <Group sx={{ gap: 8 }}>
            <TextInput
              sx={{ gridColumn: 'span 8', width: 526 }}
              disabled
              onChange={onChangeUrl}
              value={valueUrl}
            />
          </Group>
          <Checkbox
            label='Не выводить на карте сайта'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.SITE_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.SITE_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>Тип страницы</Text>
        <TextInput
          sx={{ gridColumn: 'span 8', width: 526 }}
          disabled
          {...form.getInputProps(EMetaTagFormFields.TYPE_PAGE)}
        />
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>page_h1</Text>
        <Box>
          <TextInput
            sx={{ gridColumn: 'span 8', width: 526 }}
            disabled={form.getInputProps(EMetaTagFormFields.PAGE_H1_CHECKBOX).value}
            placeholder='Введите page_h1'
            {...form.getInputProps(EMetaTagFormFields.PAGE_H1)}
          />{' '}
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.PAGE_H1_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.PAGE_H1_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>title</Text>
        <Box>
          <TextInput
            sx={{ gridColumn: 'span 8', width: 526 }}
            placeholder='Введите title'
            disabled={form.getInputProps(EMetaTagFormFields.TITLE_CHECKBOX).value}
            {...form.getInputProps(EMetaTagFormFields.TITLE)}
          />{' '}
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.TITLE_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.TITLE_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>description</Text>
        <Box>
          <TextInput
            sx={{ gridColumn: 'span 8', width: 526 }}
            disabled={form.getInputProps(EMetaTagFormFields.DESCRIPTION_CHECKBOX).value}
            placeholder='Введите description'
            {...form.getInputProps(EMetaTagFormFields.DESCRIPTION)}
          />{' '}
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.DESCRIPTION_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.DESCRIPTION_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>keywords</Text>
        <Box>
          <TextInput
            sx={{ gridColumn: 'span 8', width: 526 }}
            disabled={form.getInputProps(EMetaTagFormFields.KEYWORDS_CHECKBOX).value}
            placeholder='Введите keywords'
            {...form.getInputProps(EMetaTagFormFields.KEYWORDS)}
          />
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.KEYWORDS_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.KEYWORDS_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text size='sm' sx={{ fontWeight: 500 }}>
          robots
        </Text>
        <Box>
          <Box
            sx={{
              width: '526px',
            }}
          >
            <MultiSelect
              label='robots'
              isDisabled={form.getInputProps(EMetaTagFormFields.ROBOTS_CHECKBOX).value}
              options={selectRobotOptions}
              value={valueRobots}
              onChange={onChangeRobots}
            />
          </Box>
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.ROBOTS_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.ROBOTS_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>author</Text>
        <Box>
          <TextInput
            sx={{ gridColumn: 'span 8', width: 526 }}
            placeholder='Введите author'
            disabled={form.getInputProps(EMetaTagFormFields.AUTHOR_CHECKBOX).value}
            {...form.getInputProps(EMetaTagFormFields.AUTHOR)}
          />
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.AUTHOR_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.AUTHOR_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>copyright</Text>
        <Box>
          <TextInput
            sx={{ gridColumn: 'span 8', width: 526 }}
            placeholder='Введите copyright'
            disabled={form.getInputProps(EMetaTagFormFields.COPYRIGHT_CHECKBOX).value}
            {...form.getInputProps(EMetaTagFormFields.COPYRIGHT)}
          />
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.COPYRIGHT_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.COPYRIGHT_CHECKBOX)}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>content</Text>
        <Box>
          <Box>
            <Textarea
              placeholder='Введите content'
              disabled={form.getInputProps(EMetaTagFormFields.CONTENT_CHECKBOX).value}
              sx={{ gridColumn: 'span 12', textarea: { minHeight: '114px' }, width: 526 }}
              {...form.getInputProps(EMetaTagFormFields.CONTENT)}
            />
            <Checkbox
              label='Использовать шаблон'
              sx={{ paddingTop: '5px' }}
              checked={form.values[EMetaTagFormFields.CONTENT_CHECKBOX]}
              {...form.getInputProps(EMetaTagFormFields.CONTENT_CHECKBOX)}
            />
          </Box>
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>Канонический URL</Text>
        <Box>
          <TextInput
            sx={{ gridColumn: 'span 6', width: 526 }}
            placeholder='Введите канонический URL'
            disabled={form.getInputProps(EMetaTagFormFields.CANONICAL_URL_CHECKBOX).value}
            {...form.getInputProps(EMetaTagFormFields.CANONICAL_URL)}
          />
          <Checkbox
            label='Использовать шаблон'
            sx={{ paddingTop: '5px' }}
            checked={form.values[EMetaTagFormFields.CANONICAL_URL_CHECKBOX]}
            {...form.getInputProps(EMetaTagFormFields.CANONICAL_URL_CHECKBOX)}
          />
        </Box>
      </Group>
    </Box>
  );
};
