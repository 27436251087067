import { TemplateItem } from '@/types/templates/templates';

import { FC, FormEvent, useEffect } from 'react';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { LegendInfo } from '@/ui/containers/TemplateCreationContainer/LegendInfo/LegendInfo';
import { TemplateForm } from '@/ui/containers/TemplateCreationContainer/TemplateForm';
import { TEMPLATE_CREATION_FORM_ID } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.constants';
import { getDefaultValues } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.helpers';
import { templateSchema } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.schema';
import { ITemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

interface IProps {
  handleSubmit: (values: ITemplateFormFields, event: FormEvent<HTMLFormElement>) => void;
  template: TemplateItem | null;
  setIsShouldBlock: (isShouldBlock: boolean) => void;
}

export const TemplateDetailInfo: FC<IProps> = ({ template, setIsShouldBlock, handleSubmit }) => {
  const form = useForm({
    initialValues: getDefaultValues(template),
    validate: yupResolver(templateSchema),
  });

  useEffect(() => {
    if (form.isDirty()) {
      setIsShouldBlock(true);
      return;
    }
  }, [form]);

  return (
    <Box
      sx={{
        height: 'fit-content',
        display: 'flex',
        gap: '24px',
        paddingBottom: '22px',
        width: '100%',
      }}
    >
      <form
        id={TEMPLATE_CREATION_FORM_ID}
        style={{
          borderRadius: '6px',
          padding: '16px 12px',
          overflow: 'scroll',
          width: '100%',
        }}
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <TemplateForm form={form} />
      </form>
      <Box>
        <LegendInfo />
      </Box>
    </Box>
  );
};
