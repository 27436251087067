import * as Yup from 'yup';

import { validationTexts } from '@/constants/validationTexts';

import {
  EVideoSnippetFormFields,
  VIDEO_URL_REGEX,
} from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoSnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const videoSnippetSchema = Yup.object({
  [EVideoSnippetFormFields.VIDEOS]: Yup.array(
    Yup.object({
      [EVideoSnippetFormFields.VIDEO_URl]: Yup.string()
        .required()
        .matches(
          VIDEO_URL_REGEX,
          validationTexts.ONLY_ALLOWED_URL('YouTube', 'RuTube', 'VK', 'Дзен'),
        ),
      [EVideoSnippetFormFields.DESCRIPTION]: Yup.string().max(200, validationTexts.MAX_LENGTH(150)),
      [EVideoSnippetFormFields.TITLE]: Yup.string().max(60, validationTexts.MAX_LENGTH(60)),
      [EVideoSnippetFormFields.SLIDE]: Yup.object({
        [EVideoSnippetFormFields.SLIDE_ID]: Yup.string().required(ERR_REQUIRED_TEXT),
      }),
    }),
  ).max(30),
});
