import { useCallback, useRef } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import { SnippetValues } from '@prosv/core/types/snippets';
import { CompactPromoSnippet as CompactPromoSnippetType } from '@prosv/core/types/snippets/compactPromo';
import { Editor } from '@tiptap/react';
import { v4 as uuid_v4 } from 'uuid';

import notify from '@/utils/notify';

import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import {
  getImageData,
  handleAddSnippetData,
} from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { ECompactPromoSnippetFields } from '@/ui/containers/ContentEditor/snippets/CompactPromoSnippet/СompactPromoSnippet.constants';
import {
  getCompactPromoBlockDefaultValues,
  getImageReplacedImage,
} from '@/ui/containers/ContentEditor/snippets/CompactPromoSnippet/СompactPromoSnippet.helpers';
import { compactPromoBlockSchema } from '@/ui/containers/ContentEditor/snippets/CompactPromoSnippet/СompactPromoSnippet.schema';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';

export const useCompactPromoSnippet = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('compactPromo');
  const sessionID = useRef(uuid_v4());
  const editorRef = useRef<Editor | undefined | null>();

  const form = useForm({
    initialValues: getCompactPromoBlockDefaultValues(defaultValues),
    validate: yupResolver(compactPromoBlockSchema),
  });

  const handleSubmit = useCallback(
    async (values: SnippetValues<CompactPromoSnippetType>) => {
      try {
        const { image } = values;
        const replacedImage = await getImageReplacedImage(image, sessionID.current);
        handleAddSnippetData(
          'compactPromo',
          {
            ...values,
            description: editorRef.current?.getHTML() || '',
            image: replacedImage ?? image,
          },
          handleClose,
          snippetData,
        );
      } catch (e) {
        notify({ message: 'Ошибка сохранинея' });
      }
    },
    [editorRef.current, snippetData, handleClose],
  );

  const handleChangeImage = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.setFieldValue(ECompactPromoSnippetFields.IMAGE, { id, path, name });
  };

  const handleRemoveImage = () => {
    form.setFieldValue(ECompactPromoSnippetFields.IMAGE, { id: '', path: '', name: '' });
  };

  const imgData = getImageData({
    id: form.values[ECompactPromoSnippetFields.IMAGE].id,
    name: form.values[ECompactPromoSnippetFields.IMAGE].name,
    path: form.values[ECompactPromoSnippetFields.IMAGE].path.replace(
      process.env.MEDIA_STORAGE_URL as string,
      '',
    ),
  });

  return {
    handleSubmit,
    handleChangeImage,
    handleRemoveImage,
    handleClose,
    form,
    imgData,
    snippetData,
    defaultValues,
    sessionID,
    editorRef,
  };
};
