import { STATUS_TOGGLE } from '@/ui/containers/FeatureToggles/components/FeatureEditorModal/FeatureEditorModal.constants';

export const getPayloadUpdateFeature = (
  status: string,
  code: string,
  percentage: number,
  group: string,
) => {
  if (status === STATUS_TOGGLE.active) {
    return {
      code,
      isActive: true,
      coveragePercentage: 0,
      groups: [],
    };
  }
  if (status === STATUS_TOGGLE['partial-active']) {
    return {
      code,
      isActive: false,
      coveragePercentage: percentage,
      groups: [group],
    };
  }
  return {
    code,
    isActive: false,
    coveragePercentage: 0,
    groups: [],
  };
};
