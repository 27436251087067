import * as Yup from 'yup';

import { REDIRECT_URL_REGEXP } from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';

import { ETemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.constants';

export const templateSchema = Yup.object({
  //[ETemplateFormFields.NAME]: Yup.string(),
  [ETemplateFormFields.SITE]: Yup.object().shape({
    field: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  }),
  [ETemplateFormFields.PAGE_TYPE]: Yup.object().shape({
    field: Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  }),
  [ETemplateFormFields.PAGE_H1]: Yup.string().max(150, 'Максимальное количество символов 150'),
  [ETemplateFormFields.TITLE]: Yup.string().max(100, 'Максимальное количество символов 100'),
  [ETemplateFormFields.DESCRIPTION]: Yup.string().max(150, 'Максимальное количество символов 150'),
  [ETemplateFormFields.KEYWORDS]: Yup.string().max(150, 'Максимальное количество символов 150'),
  [ETemplateFormFields.ROBOTS]: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  ),
  [ETemplateFormFields.AUTHOR]: Yup.string(),
  [ETemplateFormFields.COPYRIGHT]: Yup.string(),
  [ETemplateFormFields.CONTENT]: Yup.string(),
  [ETemplateFormFields.CANONICAL]: Yup.string().matches(REDIRECT_URL_REGEXP, validationTexts.URL),
});
