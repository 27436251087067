import * as Yup from 'yup';

import { validationTexts } from '@/constants/validationTexts';

import { EGallerySnippetFormFields } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const gallerySnippetSchema = Yup.object({
  [EGallerySnippetFormFields.SLIDES]: Yup.array(
    Yup.object({
      [EGallerySnippetFormFields.DESCRIPTION]: Yup.string()
        .max(250, validationTexts.MAX_LENGTH(250))
        .required(ERR_REQUIRED_TEXT),
      [EGallerySnippetFormFields.TITLE]: Yup.string()
        .max(100, validationTexts.MAX_LENGTH(100))
        .required(ERR_REQUIRED_TEXT),
      [EGallerySnippetFormFields.IMAGE]: Yup.object({
        [EGallerySnippetFormFields.SLIDE_ID]: Yup.string().required(ERR_REQUIRED_TEXT),
      }),
    }),
  ),
});
