import { AudioAppListResponse } from '@prosv/core/types/bff/audioapp/admin';
import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { AudioAppOption } from './audioappsSlice.types';

export const audioappsSlice = createApi({
  reducerPath: 'api/audioapps',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['AudioApps'],
  endpoints: (build) => ({
    getAudioApps: build.query<AudioAppListResponse['payload']['items'], void>({
      query: () => ({ method: 'get', url: URLS.audioApps.list }),
      transformResponse: ({ payload }: AudioAppListResponse) => payload.items,
      providesTags: [{ type: 'AudioApps', id: 'LIST' }],
    }),
  }),
});

export const { useGetAudioAppsQuery } = audioappsSlice;

export const selectAudioApps = audioappsSlice.endpoints.getAudioApps.select();

export const selectAudioAppsOptions = createSelector(selectAudioApps, ({ data }) =>
  [...(data ?? [])]
    .sort(({ sort: a }, { sort: b }) => a - b)
    .map<AudioAppOption>(({ pimId, name }) => ({ value: pimId, label: name })),
);
