import { IRequestGlobalSettings, TSettings } from '@/types/globalSettings/globalSettings';

import { EGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.constants';
import { IGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.types';
import {
  ISetting,
  TSettingType,
} from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable/GlobalSettingsTable.types';

export const getSettingsByType = (settings: TSettings): ISetting[] => {
  return Object.keys(settings).map((key) => ({ name: key, value: settings[key].value }));
};

export const prepareData = (
  siteType: TSettingType | null,
  values: IGlobalSettingFormFields,
): IRequestGlobalSettings => {
  const HARDCODE_USER_ID = 'e0204537-2110-4d42-a30a-0f915c4f6375';
  return {
    services: [
      {
        serviceName: 'seo-setting',
        fields: [
          { name: `${siteType}SiteTitle`, value: values[EGlobalSettingFormFields.TITLE] },
          { name: `${siteType}SiteRobots`, value: values[EGlobalSettingFormFields.ROBOTS] },
          { name: `${siteType}SiteSynonyms`, value: values[EGlobalSettingFormFields.SYNONYMS] },
        ],
      },
    ],
    meta: {
      userId: HARDCODE_USER_ID,
    },
  };
};
