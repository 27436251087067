export const ERROR_MESSAGE = 'Фильтрация пока не доступна. Повторите попытку позднее';
export const NOT_FOUND_MESSAGE =
  'К сожалению, по вашему запросу ничего не найдено. Пожалуйста, измените параметры, чтобы найти интересующую вас информацию';
export const ACCEPTED_FILE_TYPES = ['.csv', '.xlsx'];
export const FILE_UPLOAD_SUCCEED_MESSAGE = 'Файл успешно загружен';
export const REDIRECTS_UPLOAD_SUCCEED_MESSAGE = 'Редиректы успешно загружены:';
export const REDIRECTS_UPLOAD_UNSUCCEED_MESSAGE = 'Не удалось загрузить:';

export const FILE_UPLOAD_ERROR_MESSAGE = 'Ошибка при загрузке файла';
export const FILE_TYPE_ERROR_MESSAGE =
  'Неверный тип файла. Пожалуйста, выберите файл CSV или XLSX.';

export const LIMIT_QUERY = 'pagination[limit]';
export const OFFSET_QUERY = 'pagination[offset]';
