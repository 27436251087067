export const mockOptions = [
  {
    value: '301',
    label: '301',
  },
  {
    value: '302',
    label: '302',
  },
  {
    value: '303',
    label: '303',
  },
  {
    value: '307',
    label: '307',
  },
  {
    value: '308',
    label: '308',
  },
];
