import { FC } from 'react';
import { SnippetVariant } from '@prosv/core/types/snippets';
import { CompactPromoSnippet } from 'src/ui/containers/ContentEditor/snippets/CompactPromoSnippet';
import { ImageWithTextAroundSnippet } from 'src/ui/containers/ContentEditor/snippets/ImageWithTextAroundSnippet';

import { SelectContentBlockModal } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectContentBlockModal';
import { BannerSnippet } from '@/ui/containers/ContentEditor/snippets/BannerSnippet';
import { CatalogSnippet } from '@/ui/containers/ContentEditor/snippets/CatalogSnippet/CatalogSnippet';
import { DownloadsSnippetModal } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal';
import { EventSnippetModal } from '@/ui/containers/ContentEditor/snippets/EventSnippetModal';
import { GallerySnippetModal } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal';
import { HtmlSnippet } from '@/ui/containers/ContentEditor/snippets/HtmlSnippet';
import { ImageSnippetModal } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal';
import { LinkButtonSnippetModal } from '@/ui/containers/ContentEditor/snippets/LinkButtonSnippetModal';
import { NewsSnippetModal } from '@/ui/containers/ContentEditor/snippets/NewsSnippetModal';
import { PeopleSnippetModal } from '@/ui/containers/ContentEditor/snippets/PeopleSnippetModal';
import { ProductSnippetModal } from '@/ui/containers/ContentEditor/snippets/ProductSnippetModal';
import { ProductsSnippetModal } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal';
import { PromoSnippet } from '@/ui/containers/ContentEditor/snippets/PromoSnippet';
import { QuestionSnippet } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet';
import { QuoteSnippetModal } from '@/ui/containers/ContentEditor/snippets/QuoteSnippetModal';
import { TextSnippetModal } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal';
import { UmkSystemSnippet } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet';
import { VideoSnippetModal } from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal';

import { SelectGridModal } from '../SelectGridModal';
import { selectedSnippetDataSelector, useStoreContentEditor } from '../store';

const SNIPPET_CONFIG: Record<SnippetVariant, FC> = {
  text: TextSnippetModal,
  image: ImageSnippetModal,
  video: VideoSnippetModal,
  product: ProductSnippetModal,
  people: PeopleSnippetModal,
  news: NewsSnippetModal,
  event: EventSnippetModal,
  quote: QuoteSnippetModal,
  'link-btn': LinkButtonSnippetModal,
  download: DownloadsSnippetModal,
  gallery: GallerySnippetModal,
  products: ProductsSnippetModal,
  empty: PromoSnippet,
  promo: PromoSnippet,
  banner: BannerSnippet,
  html: HtmlSnippet,
  catalog: CatalogSnippet,
  compactPromo: CompactPromoSnippet,
  imageWithTextAround: ImageWithTextAroundSnippet,
  question: QuestionSnippet,
  umkSystem: UmkSystemSnippet,
};
interface Props {
  draftId?: string;
}

export const SelectSnippetModalWrapper: FC<Props> = ({ draftId }) => {
  const selectedSnippetData = useStoreContentEditor(selectedSnippetDataSelector);

  const selectGridData = useStoreContentEditor((state) => state.selectGridData!!);
  const isShowSnippet = selectedSnippetData?.variant && selectedSnippetData?.variant !== 'empty';
  const Snippet = isShowSnippet ? SNIPPET_CONFIG[selectedSnippetData?.variant] : null;

  return (
    <>
      <SelectContentBlockModal />

      {selectGridData && <SelectGridModal draftId={draftId} />}

      {Snippet && <Snippet />}
    </>
  );
};
