import { Category } from '@prosv/core/types/bff/catalog/admin/category';
import { TreeItem } from '@prosv/core/utils/tree';

import { CategorySelectOption } from './categories.types';

export const createCategoriesOptions = (tree: TreeItem<Category>[]): CategorySelectOption[] => {
  const res: CategorySelectOption[] = [];

  const traverse = (treeItems: TreeItem<Category>[], level = 0) =>
    treeItems.forEach(({ id, title, items }) => {
      res.push({ value: id, label: title, level });
      traverse(items, level + 1);
    });

  traverse(tree);

  return res;
};
