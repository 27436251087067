import { CategoryField } from './CategoriesFormBlock.types';

export const type = 'cube-category';

export const CATEGORIES_FIELDS: CategoryField[] = [
  'category1',
  'category2',
  'category3',
  'category4',
  'category5',
  'category6',
] as const;
