import * as Yup from 'yup';

import { ECatalogSnippetFields } from '@/ui/containers/ContentEditor/snippets/CatalogSnippet/CatalogSnippet.constans';

const ERR_REQUIRED_TEXT = 'Это поле обязательно для заполнения';

const commonStringValidation = Yup.array().of(Yup.string()).min(1, ERR_REQUIRED_TEXT);

export const catalogBlockSchema = Yup.object({
  [ECatalogSnippetFields.SELECTION_CODE]: commonStringValidation,
});
