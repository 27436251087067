import { TMailsTemplate } from '@/types';

import { tryPatchGeneralSetting } from '@/api/generalSettings/generalSettings';

import { useGeneralSettingsState } from '@/pages/GeneralSettings/GeneralSettings.state';

const HARDCODE_USER_ID = 'e0204537-2110-4d42-a30a-0f915c4f6375'; // TODO исправить после реализации авторизации

export const useGeneralSettingsHandlers = (state: ReturnType<typeof useGeneralSettingsState>) => {
  const handleSubmitForm = async (values: Partial<TMailsTemplate>) => {
    state.setIsSubmittingForm(true);

    const fields = Object.entries(values).map(([key, value]) => ({ name: key, value }));

    await tryPatchGeneralSetting({
      services: [
        {
          serviceName: state.selectedServiceFormValue || '',
          fields,
        },
      ],
      meta: {
        userId: HARDCODE_USER_ID,
      },
    });
    state.setIsSubmittingForm(false);
  };

  return { handleSubmitForm };
};
