import { ItemPermissions } from '@/types/auth/permissions';
import { IMaterials } from '@/types/materials/materials';

import { FC, FormEvent, useEffect } from 'react';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { isEqual } from 'lodash';

import { materialFormSchema } from '@/ui/containers/MaterialDetailInfo/MaterialDetailInfo.schema';
import { MaterialsForm } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import { ARTICLE_CREATION_FORM_ID } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { getDefaultValues } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.helpers';
import { MaterialsGeneralInfo } from '@/ui/containers/MaterialsCreationContainer/MaterialsGemeralInfo';

interface IProps {
  permissions: ItemPermissions;
  handleSubmit: (values: IMaterialsFormFields, event: FormEvent<HTMLFormElement>) => void;
  isNewMaterial: boolean;
  material: IMaterials | null;
  sessionID: string;
  setIsShouldBlock: (isShouldBlock: boolean) => void;
  defaultFormData: IMaterialsFormFields | null;
}

export const MaterialDetailInfo: FC<IProps> = ({
  material,
  isNewMaterial,
  handleSubmit,
  setIsShouldBlock,
  sessionID,
  defaultFormData,
  permissions,
}) => {
  const form = useForm({
    initialValues: getDefaultValues(material),
    validate: yupResolver(materialFormSchema),
  });

  useEffect(() => {
    if (defaultFormData && !isEqual(form.values, defaultFormData)) {
      setIsShouldBlock(true);
    }
  }, [form]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        gap: '24px',
      }}
    >
      <form
        id={ARTICLE_CREATION_FORM_ID}
        style={{
          border: '1px solid #EDF1F3',
          borderRadius: '6px',
          padding: '16px 12px',
          overflow: 'scroll',
          width: '100%',
          position: 'relative',
        }}
        onSubmit={form.onSubmit(handleSubmit)}
      >
        <MaterialsForm
          permissions={permissions}
          isNewMaterial={isNewMaterial}
          form={form}
          material={material}
          sessionID={sessionID}
        />
      </form>
      <Box>
        <MaterialsGeneralInfo {...{ materials: material, form, isNewMaterial, permissions }} />
      </Box>
    </Box>
  );
};
