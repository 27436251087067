import { ISelectOptions } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

export enum ETemplateFormFields {
  ID = 'id',
  NAME = 'name',
  SITE = 'site',
  PAGE_TYPE = 'pageType',
  PAGE_H1 = 'pageH1',
  TITLE = 'title',
  DESCRIPTION = 'description',
  KEYWORDS = 'keywords',
  ROBOTS = 'robots',
  AUTHOR = 'author',
  COPYRIGHT = 'copyright',
  CONTENT = 'content',
  CANONICAL = 'canonical',
}

export const selectRobotOptions: ISelectOptions[] = [
  { value: 'all', label: 'all' },
  { value: 'index', label: 'index' },
  { value: 'noindex', label: 'noindex' },
  { value: 'follow', label: 'follow' },
  { value: 'nofollow', label: 'nofollow' },
  { value: 'noarchive', label: 'noarchive' },
  { value: 'archive', label: 'archive' },
  { value: 'noyaca', label: 'noyaca' },
  { value: 'none', label: 'none' },
];

export const selectSiteOptions: ISelectOptions[] = [
  {
    value: 'prosv.ru',
    label: 'prosv.ru',
  },
  {
    value: 'corp.prosv.ru',
    label: 'corp.prosv.ru',
  },
];

export const selectPageTypeOptions: ISelectOptions[] = [
  {
    value: 'value1',
    label: 'Материал',
  },
  {
    value: 'value2',
    label: 'Новость',
  },
  {
    value: 'value3',
    label: 'Лендинг',
  },
  {
    value: 'value4',
    label: 'Конференция',
  },
  {
    value: 'value5',
    label: 'Мероприятие',
  },
];

export const TEMPLATE_CREATION_FORM_ID = 'template-creation-form';
