import { FC } from 'react';
import { Box, Container, Group, Paper, Title } from '@mantine/core';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';

import { SelectionPlaceEditorModal } from './SelectionPlaceEditorModal';
import { useTableData } from './SelectionsPlacesContainer.hooks';
import { SelectionsPlacesFilters } from './SelectionsPlacesFilters';

export const SelectionsPlacesContainer: FC = () => {
  const { filters, items, total, isFetching, editItem, setEditItem, ...tbl } = useTableData();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '96vh',
          overflowY: 'hidden',
        }}
      >
        <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
          <Group position='apart'>
            <Title order={1}>Подборки</Title>
          </Group>
        </Container>
        <Paper
          radius={8}
          p={24}
          pb={12}
          shadow='xs'
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >
          <SelectionsPlacesFilters />
          <CustomTable
            onChangePagination={tbl.setCurrentPage}
            limit={filters.limit ?? 10}
            noData='К сожалению, список позиций подборок пуст.'
            total={total}
            currentPage={tbl.currentPage}
            status={isFetching ? 'loading' : 'loaded'}
            data={items}
            columns={tbl.columns}
          />
        </Paper>
      </Box>

      {!!editItem && (
        <SelectionPlaceEditorModal item={editItem} onClose={() => setEditItem(null)} />
      )}
    </>
  );
};
