import React from 'react';
import { Box, Grid, Input, InputBase, Radio, Select, Stack } from '@mantine/core';

import { GeneralInformationCard } from '@/ui/components/GeneralInformationCard/GeneralInformationCard';
import { SupportDetailFormField } from '@/ui/containers/SupportContainer/detail/components/tabs/general/GeneralTab.constants';
import { useGeneralTabForm } from '@/ui/containers/SupportContainer/detail/components/tabs/general/GeneralTab.hooks';

import { SupportDetailResponse } from '@/store/slices/support/supportDetailsTypes';

export const GeneralForm = ({
  data,
  categoryOptions,
  type,
}: {
  data?: SupportDetailResponse;
  type: 'create' | 'edit';
  categoryOptions: { label: string; value: string | number }[];
}) => {
  const {
    productInfo,
    handleChangeQuestion,
    form,
    isViewMainPageConfig,
    isDisplayOnHeader,
    onSubmit,
  } = useGeneralTabForm(data, type);
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 32 }}>
      <form id='faqDetailForm' onSubmit={form.onSubmit(onSubmit)}>
        <Stack sx={{ border: '1px solid #E8ECF0', borderRadius: 6, padding: 12 }}>
          <Select
            placeholder='Выберите категорию обращения'
            required
            label='Категория обращения'
            styles={{ input: { marginTop: 8 } }}
            {...form.getInputProps(SupportDetailFormField.CATEGORY_ID)}
            data={categoryOptions}
          />
          <Grid>
            <Grid.Col span={6}>
              <Input.Wrapper required label='Вопрос'>
                <InputBase
                  mt={8}
                  placeholder='Введите вопрос'
                  {...form.getInputProps(SupportDetailFormField.QUESTION)}
                  onChange={handleChangeQuestion}
                />
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={6}>
              <Input.Wrapper required label='Cимвольный код URL'>
                <InputBase
                  {...form.getInputProps(SupportDetailFormField.URL_CODE)}
                  disabled
                  mt={8}
                  placeholder='Ссылка на вопрос'
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Radio.Group
            {...form.getInputProps(SupportDetailFormField.IS_ACTIVE)}
            required
            label='Активность'
          >
            <Radio value='true' label='Да' />
            <Radio value='' label='Нет' />
          </Radio.Group>
          <Input.Wrapper sx={{ maxWidth: 590 }} label='Сортировка'>
            <InputBase
              {...form.getInputProps(SupportDetailFormField.SORT)}
              mt={8}
              placeholder='Введите значение'
              type='number'
            />
          </Input.Wrapper>

          <Radio.Group
            {...form.getInputProps(SupportDetailFormField.IS_DISPLAY_ON_MAIN)}
            label='Отображать на главной'
          >
            <Radio sx={{ cursor: 'pointer' }} value='true' label='Да' />
            <Radio sx={{ cursor: 'pointer' }} value='' label='Нет' />
          </Radio.Group>
          {isViewMainPageConfig && (
            <Input.Wrapper sx={{ maxWidth: 590 }} label='Позиция на главной' required>
              <InputBase
                {...form.getInputProps(SupportDetailFormField.POSITION_ON_MAIN)}
                mt={8}
                placeholder='Введите значение'
                type='number'
              />
            </Input.Wrapper>
          )}
          <Radio.Group
            {...form.getInputProps(SupportDetailFormField.IS_DISPLAY_ON_HEADER)}
            required
            label='Отображать в шапке'
          >
            <Radio value='true' label='Да' />
            <Radio value='' label='Нет' />
          </Radio.Group>
          {isDisplayOnHeader && (
            <Input.Wrapper sx={{ maxWidth: 590 }} label='Позиция в шапке' required>
              <InputBase
                {...form.getInputProps(SupportDetailFormField.POSITION_ON_HEADER)}
                mt={8}
                placeholder='Введите значение'
                type='number'
              />
            </Input.Wrapper>
          )}
        </Stack>
      </form>

      {productInfo && (
        <div style={{ width: 352 }}>
          <GeneralInformationCard {...productInfo} />
        </div>
      )}
    </Box>
  );
};
