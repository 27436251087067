import { ItemPermissions } from '@/types/auth/permissions';
import { IMaterials } from '@/types/materials/materials';

import { FC, useState } from 'react';
import { ActionIcon, Box, Checkbox, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Pencil } from 'tabler-icons-react';

import { formatDate } from '@/utils/formatDate';

import { EditViewPopup } from '@/ui/containers/MaterialDetailInfo/EditViewPopup';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import { EMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';

interface IProps {
  isNewMaterial: boolean;
  form: UseFormReturnType<IMaterialsFormFields>;
  materials: IMaterials | null;
  permissions: ItemPermissions;
}

export const MaterialsGeneralInfo: FC<IProps> = ({
  isNewMaterial,
  form,
  materials,
  permissions,
}) => {
  const [showModalWindow, setShowModalWindow] = useState(false);
  const numberOfViews = isNewMaterial ? 0 : materials?.viewsCount;

  return (
    <Box
      sx={{
        borderRadius: '6px',
        backgroundColor: '#F6F8F8',
        border: '1px solid #E8ECF0',
        padding: '16px 24px',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        rowGap: '14px',
        columnGap: '4px',
        width: '350px',
      }}
    >
      {!isNewMaterial && materials?.meta && (
        <>
          <Text color='greyDark' sx={{ gridColumn: 'span 2' }}>
            ID: {materials?.id}
          </Text>

          <Text color='black' sx={{ gridColumn: 'span 2', fontWeight: 600, paddingTop: '14px' }}>
            Основная информация
          </Text>
          <Box
            sx={{
              paddingTop: '6px',
              gridColumn: 'span 2',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Text color='greyDark' sx={{ fontSize: 14 }}>
              Дата создания:
            </Text>
            <Text sx={{ fontSize: 16 }}>{formatDate(materials?.meta?.createDate.value, true)}</Text>
          </Box>

          {materials?.meta?.editor && (
            <Box
              sx={{
                paddingTop: '6px',
                gridColumn: 'span 2',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Text color='greyDark' sx={{ fontSize: 14, width: 'min-content' }}>
                Последнее редактирование:
              </Text>
              <Text sx={{ fontSize: 16 }}>
                {formatDate(materials?.meta?.updateDate.value, true)}
              </Text>
            </Box>
          )}
          <Box
            sx={{
              paddingTop: '6px',
              gridColumn: 'span 2',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Text color='greyDark' sx={{ fontSize: 14 }}>
              Автор:
            </Text>
            <Text sx={{ textAlign: 'right', maxWidth: 152 }}>
              {materials?.meta?.author.fullName}
            </Text>
          </Box>
          {materials.meta.editor && (
            <Box
              sx={{
                paddingTop: '6px',
                gridColumn: 'span 2',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Text color='greyDark' sx={{ fontSize: 14 }}>
                Изменено:
              </Text>
              <Text sx={{ textAlign: 'right', maxWidth: 152 }}>
                {materials.meta.editor.fullName}
              </Text>
            </Box>
          )}
        </>
      )}

      <Text color='black' sx={{ fontWeight: 600 }}>
        Просмотры
      </Text>
      <Text sx={{ textAlign: 'right' }}>{numberOfViews}</Text>

      <Text color='greyDark' sx={{ paddingTop: '6px', fontSize: 14 }}>
        Отредактированные:
      </Text>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          gap: '4px',
          cursor: 'pointer',
        }}
      >
        {form.values[EMaterialsFormFields.MODIFIED_VIEWS_COUNT]}

        {permissions.canUpdateViewsCount && (
          <ActionIcon>
            <Pencil width={18} onClick={() => setShowModalWindow(true)} />
          </ActionIcon>
        )}
      </Box>

      <Checkbox
        label='Показать просмотры'
        checked={form.values[EMaterialsFormFields.IS_DISPLAY_VIEWS]}
        sx={{ gridColumn: 'span 2' }}
        {...form.getInputProps(EMaterialsFormFields.IS_DISPLAY_VIEWS)}
      />

      <EditViewPopup
        materialUuid={materials?.uuid}
        isNewMaterial={isNewMaterial}
        form={form}
        isOpen={showModalWindow}
        onClose={() => setShowModalWindow(false)}
      />
    </Box>
  );
};
