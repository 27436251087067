import { PromoSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { EPromoSnippetFields } from '@/ui/containers/ContentEditor/snippets/PromoSnippet/PromoSnippet.constants';

export const getPromoSnippetDefaultValues = (
  defaultValues?: PromoSnippet,
): SnippetValues<PromoSnippet> => {
  return {
    [EPromoSnippetFields.ID]: defaultValues?.id || uuid_v4(),
    [EPromoSnippetFields.TITLE]: defaultValues?.title || '',
    [EPromoSnippetFields.DESCRIPTION]: defaultValues?.description || '',
    [EPromoSnippetFields.PROMO_TITLE]: defaultValues?.promoTitle || '',
    [EPromoSnippetFields.PROMO_CODE]: defaultValues?.promoCode || '',
  };
};
