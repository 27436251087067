export const activeStatus = [
  { label: 'Да', value: 'true' },
  { label: 'Нет', value: 'false' },
];

export const option = {
  true: { label: 'Да', value: 'true' },
  false: { label: 'Нет', value: 'false' },
};

export const inputStyles = {
  input: {
    height: 40,
    borderColor: '#E8ECF0',
    '::-webkit-input-placeholder': { color: '#8493a4', options: 0, fontSize: 16 },
  },
};
