import { useDocumentTitle } from '@mantine/hooks';

import { TITLE_TEMPLATES } from '@/constants/titles';

import { TemplatesContainer } from '@/ui/containers/TemplatesContainer';

const Templates = () => {
  useDocumentTitle(TITLE_TEMPLATES);

  return <TemplatesContainer />;
};

export default Templates;
