import { OldProductsSnippet, ProductItem } from '@prosv/core/types';
import { SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

export const getProductsSnippetInitialValues = (
  defaultValues?: OldProductsSnippet,
): SnippetValues<OldProductsSnippet> => {
  return {
    id: uuid_v4(),
    products: defaultValues?.products || [],
    type: defaultValues?.type || 'custom',
    title: defaultValues?.title || 'Рекомендуем',
    category: defaultValues?.category || '',
    filters: defaultValues?.filters || [],
    limit: defaultValues?.limit || 10,
    isShowAllProducts: defaultValues?.isShowAllProducts || false,
  };
};

export const validateProductItems = (items: (ProductItem | string)[] = []) => {
  const stringProductValues: string[] = [];
  const nonStringProductValues: ProductItem[] = [];

  items.forEach((item) => {
    if (typeof item === 'string') {
      stringProductValues.push(item);
    } else {
      nonStringProductValues.push(item);
    }
  });

  return { stringProductValues, nonStringProductValues };
};

export const getProductsDefaultPagination = () => {
  return {
    pagination: {
      limit: 10,
      offset: 0,
    },
  };
};
