import { FileStatus } from '@/ui/containers/RedirectsContainer/RedirectsContainer.types';

export enum TypeEnum {
  STRING = 'string',
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  FILE = 'file',
  IMAGE = 'image',
  DATE = 'date',
  DATETIME = 'datetime',
  KEYVALUES = 'string',
  INTEGER = 'integer',
}

export enum ViewTypeEnum {
  STRING = 'string',
  TEXT = 'text',
  HTML = 'html',
  INT = 'int',
  NUMERIC = 'numeric',
  FLOAT = 'float',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  DATETIME = 'datetime',
  TIME = 'time',
  MEDIA = 'media',
  MODEL = 'model',
  MODELSCOLLECTION = 'modelsCollection',
  KEYVALUES = 'keyValues',
  SLICEOFSTRINGS = 'sliceOfStrings',
  SLICEOFUUID = 'sliceOfUuid',
  EMAIL = 'email',
}

export enum FileSizeEnum {
  KB = 'kb',
  MB = 'mb',
  GB = 'gb',
  TB = 'tb',
}

export type TQueryParams = {
  offset: number;
  limit: number;
  sort?: string;
  order?: string;
  query?: string;
  filter?: Record<string, unknown>;
  parentFolderId?: string;
  page?: number;
};

export type TQueryParamsDictionary = {
  name: string;
  params: {
    offset: number;
    limit: number;
    isActive?: boolean;
    query?: string;
    field?: string;
    direction?: 'asc' | 'desc';
  };
};

export type TQueryParamsNew = {
  params?: {
    offset: number;
    limit: number;
    sort?: string;
    order?: string;
    query?: string;
    filter?: Record<string, unknown>;
    parentFolderId?: string;
    page?: number;
  };
};

export type TElementsRes<T> = {
  total: number;
  items: T[];
};

export type TBreadcrumbsExtItem = {
  name: string;
  url?: string;
};

export type TTableConfig = {
  code: string;
  name: string;
};

export type TQueryParamsRedirects = {
  'pagination[offset]'?: number;
  'pagination[limit]'?: number;
  filter?: {
    old?: string | null;
    statusCode?: string[] | null;
  };
};

export type TQueryUpdateRedirect = {
  id: string;
  old: string;
  new: string;
  statusCode: number;
};

export type TResponseUpdateRedirect = {
  code: string;
  payload: {
    id: string;
    old: string;
    new: string;
    statusCode: number;
    type: string;
  };
};

export interface IQuerySendFileId {
  fileId: string;
}

export type TResponse<T> = {
  code: string;
  payload: T;
  message: string;
};

export interface IUploadFileRedirectsResponse {
  extension: string;
  id: string;
  config: Config;
  name: string;
  srcPath: string;
  meta: Meta;
}

export interface File {
  id: string;
  srcPath: string;
  config: Config;
  name: string;
  extension: string;
  meta: Meta;
}

export interface Config {
  supportedMime: string[];
  type: string;
  previewConfig: PreviewConfig;
}

export interface PreviewConfig {
  type: string;
  path: string;
  sizes: any[];
}

export interface Meta {
  userId: string;
  sourceService: string;
}

export interface TmpConfig {
  type: string;
  expirationInterval: string;
}

export interface FileIdResponse {
  id: string;
  fileId: string;
  reportFileId: string;
  progress: number;
  status: FileStatus;
  createGoodCount: number;
  failCount: number;
  updateGoodCount: number;
}
