import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneProps } from '@mantine/dropzone';
import { ImageData } from '@prosv/core/types/bff/base';
import { nanoid } from 'nanoid';

import { uploadTempFile } from '@/api';

import notify from '@/utils/notify';

import { FilesLoaderProps, FileWithState } from './FilesLoader.types';

interface UseFilesLoaderProps
  extends Required<Pick<FilesLoaderProps, 'sessionID' | 'maxFiles'>>,
    Pick<FilesLoaderProps, 'acceptImages' | 'savedAttachments'> {}

export const useFilesLoader = ({
  sessionID,
  acceptImages,
  savedAttachments,
  maxFiles,
}: UseFilesLoaderProps) => {
  const [savedFiles, setSavedFiles] = useState<ImageData[]>(savedAttachments ?? []);
  const [files, setFiles] = useState<FileWithState[]>([]);
  const freeSlots = Math.max(0, maxFiles - savedFiles.length - files.length);

  useEffect(() => {
    setSavedFiles(savedAttachments ?? []);
  }, [savedAttachments]);

  const setFile = useCallback(
    (file: FileWithState, state: Partial<FileWithState> = {}) =>
      setFiles((currentFiles) =>
        currentFiles.map((item) => (item.id === file.id ? Object.assign(file, state) : item)),
      ),
    [],
  );

  const removeFile = useCallback(
    (id: string) => setFiles((currentFiles) => currentFiles.filter((item) => item.id !== id)),
    [],
  );

  const removeSavedFile = useCallback(
    (id: string) => setSavedFiles((currentFiles) => currentFiles.filter((item) => item.id !== id)),
    [],
  );

  const uploadFile = useCallback(
    async (file: FileWithState) => {
      if (!file.id) return;

      setFile(file, { uploading: true });

      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);
      formData.append('configType', acceptImages ? 'defaultImage' : 'default');
      formData.append('tmpConfigType', 'default');
      formData.append('sessionId', sessionID ?? '');

      try {
        const res = await uploadTempFile(formData);
        if (!res) throw new Error('Не получилось залить файл');

        setFile(file, {
          api: {
            id: res.id,
            path: process.env.MEDIA_STORAGE_URL + res.config?.previewConfig?.path,
          },
          uploading: false,
        });
      } catch (e) {
        removeFile(file.id);
      }
    },
    [acceptImages, removeFile, sessionID, setFile],
  );

  useEffect(() => {
    files.filter(({ api, uploading }) => !api && !uploading).forEach(uploadFile);
  }, [files, uploadFile]);

  const onDrop = useCallback<DropzoneProps['onDrop']>(
    (acceptedFiles) => {
      setFiles((currentFiles) => {
        const newFiles: FileWithState[] = acceptedFiles
          .filter((f) => !currentFiles.find(({ name, path }) => name === f.name && path === f.path))
          .map((file) => Object.assign(file, { id: nanoid() }));

        if (freeSlots < newFiles.length) {
          notify({
            message: `Добавлено максимальное количество файлов: ${maxFiles}`,
            type: 'error',
          });
        }

        return [...currentFiles, ...newFiles].slice(0, freeSlots);
      });
    },
    [freeSlots, maxFiles],
  );

  const isUploading = useMemo(() => files.some(({ uploading }) => uploading), [files]);

  return { freeSlots, isUploading, files, savedFiles, onDrop, removeFile, removeSavedFile };
};
