import { FC } from 'react';
import { DoorExit, UserCircle } from 'tabler-icons-react';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import LayoutNavbarButton from '@/ui/Layout/components/LayoutNavbar/components/LayoutNavbarButton/LayoutNavbarButton';
import LayoutNavbarRadioSelect from '@/ui/Layout/components/LayoutNavbar/components/LayoutNavbarRadioSelect/LayoutNavbarRadioSelect';

import { logout } from '@/store/slices/auth/auth';

interface ILayoutNavbarFooter {
  opened?: boolean;
}

const LayoutNavbarFooter: FC<ILayoutNavbarFooter> = ({ opened }) => {
  const dispatch = useAppDispatch();

  const handleLogOut = () => {
    dispatch(logout(null));
  };

  return (
    <>
      <LayoutNavbarRadioSelect opened={opened} />
      <LayoutNavbarButton
        opened={opened}
        to='/profile'
        label='Профиль'
        icon={<UserCircle size={24} />}
      />
      <LayoutNavbarButton
        onClick={handleLogOut}
        opened={opened}
        label='Выйти'
        icon={<DoorExit size={24} />}
      />
    </>
  );
};

export default LayoutNavbarFooter;
