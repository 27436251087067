import * as Yup from 'yup';

import { EPromoSnippetFields } from '@/ui/containers/ContentEditor/snippets/PromoSnippet/PromoSnippet.constants';

const ERR_REQUIRED_TEXT = 'Это поле обязательно для заполнения';
const validationTexts = {
  MAX_LENGTH: (maxLength: number) => `Максимальная длина ${maxLength} символов`,
};

const commonStringValidation = Yup.string().required(ERR_REQUIRED_TEXT);

const maxLengthValidation = (maxLength: number) =>
  commonStringValidation.max(maxLength, validationTexts.MAX_LENGTH(maxLength));

export const promoSnippetSchema = Yup.object({
  [EPromoSnippetFields.TITLE]: maxLengthValidation(150),
  [EPromoSnippetFields.DESCRIPTION]: maxLengthValidation(1000).optional(),
  [EPromoSnippetFields.PROMO_TITLE]: commonStringValidation.optional(),
  [EPromoSnippetFields.PROMO_CODE]: commonStringValidation.optional(),
});
