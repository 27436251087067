import { FC } from 'react';
import { Box, Button, Container, Group, Paper, Title } from '@mantine/core';

import { AudiosFormBlock } from './AudiosFormBlock';
import { CategoriesFormBlock } from './CategoriesFormBlock';
import { LABELS_DATA } from './CubeContainer.constants';
import { useCubeForm } from './CubeContainer.hooks';
import * as sx from './CubeContainer.styles';
import { LabelBlock } from './LabelBlock';
import { PersonalFormBlock } from './PersonalFormBlock';
import { ProjectsFormBlock } from './ProjectsFormBlock';
import { ServicesFormBlock } from './ServicesFormBlock';
import { WebinarsFormBlock } from './WebinarsFormBlock';

export const CubeContainer: FC = () => {
  const { form, onSubmit } = useCubeForm();

  return (
    <Box sx={sx.root}>
      <form onSubmit={onSubmit}>
        <Container fluid sx={sx.head}>
          <Group position='apart'>
            <Title order={1}>Настройка меню Платформы</Title>

            <Button type='submit' sx={sx.submitButton} disabled={!form.isValid()}>
              Сохранить
            </Button>
          </Group>
        </Container>

        <Paper shadow='xs' sx={sx.paper}>
          <LabelBlock {...LABELS_DATA.categories} />
          <CategoriesFormBlock {...{ form }} />

          <LabelBlock {...LABELS_DATA.services} />
          <ServicesFormBlock {...{ form }} />

          <LabelBlock {...LABELS_DATA.personal} />
          <PersonalFormBlock {...{ form }} />

          <LabelBlock {...LABELS_DATA.webinars} />
          <WebinarsFormBlock />

          <LabelBlock {...LABELS_DATA.audios} />
          <AudiosFormBlock {...{ form }} />

          <LabelBlock {...LABELS_DATA.projects} />
          <ProjectsFormBlock {...{ form }} />
        </Paper>
      </form>
    </Box>
  );
};
