import { FC } from 'react';
import { Center, Group, UnstyledButton, useMantineTheme } from '@mantine/core';
import { ChevronDown, ChevronUp, Selector } from 'tabler-icons-react';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';

import { useStyles } from './style';

interface ITableHeadSortable {
  name: string;
  reversed?: boolean;
  sorted?: boolean | null;
  onSort(sortFieldName?: string): void;
}

const TableHeadSortable: FC<ITableHeadSortable> = ({ name, reversed, sorted, onSort }) => {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  const theme = useMantineTheme();

  return (
    <th className={classes.th}>
      <UnstyledButton onClick={() => onSort()} className={classes.control}>
        <Group position='left' spacing='xs' sx={{ display: 'flex', flexWrap: 'nowrap', gap: 6 }}>
          <Center className={classes.icon}>
            <Icon size={20} color={theme.colors.greyDefault[9]} />
          </Center>
          <TableColumnTitle>{name}</TableColumnTitle>
        </Group>
      </UnstyledButton>
    </th>
  );
};

export default TableHeadSortable;
