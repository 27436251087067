import { TBreadcrumbsExtItem } from '@/types';

import { FC } from 'react';
import { BoxProps, Breadcrumbs } from '@mantine/core';
import { nanoid } from 'nanoid';
import { ChevronRight } from 'tabler-icons-react';

import BreadcrumbsExtItem from './components/BreadcrumbsExtItem/BreadcrumbsExtItem';

interface IBreadcrumbsExt extends BoxProps {
  items: TBreadcrumbsExtItem[];
}

const BreadcrumbsExt: FC<IBreadcrumbsExt> = ({ items, ...props }) => {
  return (
    <Breadcrumbs separator={<ChevronRight size={12} />} mt={20} mb={15} {...props}>
      {items.map((item) => (
        <BreadcrumbsExtItem item={item} key={nanoid()} />
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsExt;
