import { GeneralInformationCardTypes } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.types';

export const variants: Record<
  GeneralInformationCardTypes['cardType'],
  { editDate: string; author: string }
> = {
  project: {
    editDate: 'Последнее редактирование:',
    author: 'Создатель:',
  },
  question: {
    editDate: 'Последнее редактирование:',
    author: 'Автор вопроса:',
  },
  support: {
    editDate: 'Дата изменения:',
    author: 'Автор:',
  },
  review: {
    editDate: 'Последнее редактирование:',
    author: 'Автор отзыва:',
  },
};
