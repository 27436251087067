import * as Yup from 'yup';

import { CODE_URL_REGEXP } from '@/constants/validationSchemas';

import { EMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';
import { ENewsFormFields } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

export const newsFormSchema = Yup.object({
  [ENewsFormFields.URL_CODE]: Yup.string()
    .matches(CODE_URL_REGEXP, 'Символьный код не должен содержать спец символы')
    .required(ERR_REQUIRED_TEXT),
  [ENewsFormFields.IS_ACTIVE]: Yup.string(),
  [ENewsFormFields.TITLE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [ENewsFormFields.DATA_ACTIVE_FROM_DAYS]: Yup.string().required(ERR_REQUIRED_TEXT),
  [ENewsFormFields.DATA_ACTIVE_FROM_HOURS]: Yup.string().required(ERR_REQUIRED_TEXT),
  [ENewsFormFields.DATA_ACTIVE_TO]: Yup.string().nullable(),
  [ENewsFormFields.DISPLAY_ON_SITES]: Yup.string().nullable(),
  [EMaterialsFormFields.TARGET_PAGES]: Yup.array().min(1, ERR_REQUIRED_TEXT),
  [ENewsFormFields.IS_MAIN_NEWS]: Yup.boolean(),

  image: Yup.object({
    [ENewsFormFields.IMAGE_ID]: Yup.string().nullable(),
    [ENewsFormFields.IMAGE_ALT]: Yup.string().nullable(),
    [ENewsFormFields.IMAGE_TITLE]: Yup.string().nullable(),
  }),

  [ENewsFormFields.DRAFT_ID]: Yup.string(),
  [ENewsFormFields.SHORT_DESCRIPTION]: Yup.string()
    .max(149, 'Максимальное количество символов 149')
    .nullable(),
  [ENewsFormFields.CATEGORY]: Yup.string(),
  [ENewsFormFields.THEME]: Yup.string(),
  [ENewsFormFields.DIVISION]: Yup.string(),
  [ENewsFormFields.COMPANY]: Yup.string(),
  [ENewsFormFields.DIRECTION]: Yup.array(),
  [ENewsFormFields.DIGITAL_SERVICE]: Yup.array(),
  [ENewsFormFields.SUBJECT]: Yup.array(),
  [ENewsFormFields.SERIES_LINE_UMK]: Yup.array(),
  [ENewsFormFields.EDU_LEVEL]: Yup.array(),
  [ENewsFormFields.TAGS]: Yup.array(),
  [ENewsFormFields.READING_TIME]: Yup.number().moreThan(0, 'Значение должно быть больше 0'),
  [ENewsFormFields.IS_DISPLAY_VIEWS]: Yup.string(),
  [ENewsFormFields.MODIFIED_VIEWS_COUNT]: Yup.number(),
});
