import { IGetBannerResponse } from '@/types/banner/bannerDetail';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useGetBanner = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getBanner: async (uuid: string) => {
      setIsLoading(true);
      try {
        const request = await api.get<IGetBannerResponse>(URLS.banners.getBanner(uuid));

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения баннера', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
