import { TAuthParams, TUserSession } from '@/types/auth/auth';

import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { tryPostAuth } from '@/api/auth/auth';

import { setCookie } from '@/utils/cookie';
import { saveToken } from '@/utils/token';

import { State } from '@/store';

interface IAuthState {
  isLogging: boolean;
  session: TUserSession | null;
  fetchingGetConfiguration: boolean;
}

const initialState: IAuthState = {
  isLogging: false,
  session: null,
  fetchingGetConfiguration: false,
};

export const setIsLoggin = createAction<boolean>('setIsLogging');

export const fetchAuth = createAsyncThunk(
  '/auth',
  async (data: TAuthParams, { rejectWithValue }) => {
    const result = await tryPostAuth(data);
    if (result) saveToken(result);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  },
);

export const logout = createAsyncThunk('/logout', async (data: null) => {
  setCookie('token', '');
  return data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserToken: (state, action) => {
      state.session = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setIsLoggin, (state, { payload }) => ({ ...state, isLogging: payload }))
      .addCase(fetchAuth.pending, (state) => {
        state.isLogging = true;
        state.fetchingGetConfiguration = true;
      })
      .addCase(fetchAuth.fulfilled, (state, action) => {
        state.isLogging = false;
        state.fetchingGetConfiguration = false;
        state.session = action.payload;
      })
      .addCase(fetchAuth.rejected, (state) => {
        state.isLogging = false;
        state.fetchingGetConfiguration = false;
        state.session = null;
      });
    builder.addCase(logout.fulfilled, (state) => {
      state.session = null;
      window.location.assign(`/auth?redirectTo=${window.location.href}`);
    });
  },
});

// Selectors

export const tokenSelector = (state: State) => state?.auth.session;
export const fetchingGetConfiguration = (state: State) => state?.auth.fetchingGetConfiguration;
export const getIsLogging = (state: State) => state?.auth.isLogging;

// reducers and actions

export const { setUserToken } = authSlice.actions;

export default authSlice.reducer;
