import { SnippetVariant } from '@prosv/core/types/snippets';

import { TViewActions, TViewState } from './store.types';

// data selectors

export const genSnippetSelector = (variant: SnippetVariant) => (state: TViewState) => {
  return state.snippetData?.variant === variant ? state.snippetData : null;
};

export const getLoadingSelector = (state: TViewState) => state.loadingContentData;

export const genSelectedSnippetSelector = () => (state: TViewState) =>
  state.snippetData ? state.snippetData : null;

export const textSnippetDataSelector = genSnippetSelector('text');

export const selectedSnippetDataSelector = genSelectedSnippetSelector();

export const selectContentBlockDataSelector = (actions: TViewState) =>
  actions.selectContentBlockData;

export const selectContentVariantsSelector = (state: TViewState) => {
  const result: SnippetVariant[] = [];
  const grids = state.savedContentData?.grid;
  grids?.forEach((grid) =>
    grid.blocks.forEach((block) => {
      result.push(block.variant);
    }),
  );
  return result;
};

export const disableSnippetSelector = (state: TViewState) => {
  const variants = selectContentVariantsSelector(state);
  return variants.includes('catalog');
};

export const savedContentDataSelector = (actions: TViewState) => actions.savedContentData;

// setter selectors
export const setSnippetDataSelector = (actions: TViewActions) => actions.setSnippetData;
export const setSelectGridDataSelector = (actions: TViewActions) => actions.setSelectGridData;
export const setSelectorDraftId = (actions: TViewActions) => actions.setDraftId;
export const setSelectorDefaultFormData = (actions: TViewActions) => actions.setDefaultFormData;
export const setSelectContentBlockDataSelector = (actions: TViewActions) =>
  actions.setSelectContentBlockData;
export const getTemplateSelectValue = (actions: TViewActions) => actions.getTemplateSelectValue();
export const setTemplateSelectValue = (actions: TViewActions) => actions.setTemplateSelectValue;

// ** несколько блоков в одном конструкторе */
export const savedFromAllDataSelector = (actions: TViewState, draftId: string) =>
  actions.savedContentDataList[draftId];

export const setDraftIdToMap = (actions: TViewActions) => actions.setDraftIdToMap;
