import { Sx } from '@mantine/core';

export const sx: Sx = {
  minWidth: 272,
  maxWidth: 272,
};

export const textWhenDataMissing: Record<string, string> = {
  system: 'К сожалению, список страниц Систем УМК пуст',
  line: 'К сожалению, список страниц Линий/Серий УМК пуст',
  direction: 'К сожалению, список страниц УМК 2 уровня по направлению пуст',
};
