import {
  setSelectGridDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

type TUseHandlersMoveRowButtonProps = {
  rowId: number;
};

export const useHandlersMoveRowButton = ({ rowId }: TUseHandlersMoveRowButtonProps) => {
  const setVisibleSelectGrid = useStoreContentEditor(setSelectGridDataSelector);
  const handleRemoveRow = async () => {
    useStoreContentEditor.setState((state) => {
      const grid = state?.savedContentData?.grid;
      if (grid) grid.splice(rowId, 1);
    });
    await useStoreContentEditor.getState().updateContentData();
  };
  const handleEditRow = async () => {
    setVisibleSelectGrid({ rowId, isEdit: true });
  };
  const handleMoveUpRow = async () => {
    useStoreContentEditor.setState((state) => {
      const grid = state?.savedContentData?.grid;
      if (grid) {
        const [item] = grid.splice(rowId, 1);
        grid.splice(rowId - 1, 0, item);
      }
    });
    await useStoreContentEditor.getState().updateContentData();
  };
  const handleMoveDownRow = async () => {
    useStoreContentEditor.setState((state) => {
      const grid = state?.savedContentData?.grid;
      if (grid) {
        const [item] = grid.splice(rowId, 1);
        grid.splice(rowId + 1, 0, item);
      }
    });
    await useStoreContentEditor.getState().updateContentData();
  };

  return { handleRemoveRow, handleMoveUpRow, handleMoveDownRow, handleEditRow };
};
