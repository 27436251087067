import React from 'react';

import {
  reviewsStatuses,
  statusesColor,
} from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.consts';
import { StatusTypes } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

export const SupportStatusCell = ({ status }: { status: StatusTypes }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <div
        style={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          background: statusesColor[status],
        }}
      />
      {reviewsStatuses[status]}
    </div>
  );
};
