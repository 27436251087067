import { FC } from 'react';
import { Box, Group, Text } from '@mantine/core';

import { CustomLabel } from '@/ui/containers/BannerCreationContainer/components/CustomLabel';

const LABEL_STYLE = {
  fontWeight: 400,
  fontSize: '16px',
  color: '#66788A',
};

const BLOCK_STYLE = {
  padding: '8px',
  color: '#66788A',
  backgroundColor: '#E8ECF0',
  borderRadius: '7px',
  fontSize: '14px',
};

export const Constructor: FC = () => {
  return (
    <Box
      sx={{
        gridColumn: 'span 12',
        backgroundColor: '#F6F8F8',
        width: '100%',
        padding: '24px',
        display: 'flex',
        borderRadius: '6px',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <CustomLabel title='Дата' styles={{ ...LABEL_STYLE, marginBottom: '8px' }} />
        <Group sx={{ gap: '8px', marginBottom: '25px' }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Text sx={BLOCK_STYLE} key={index}>
              Лого
            </Text>
          ))}
        </Group>
        <CustomLabel title='Заголовок' isRequire styles={LABEL_STYLE} />
        <Group sx={{ marginBottom: '24px' }}>
          <CustomLabel title='Подзаголовок' isRequire styles={LABEL_STYLE} />
          <Text sx={BLOCK_STYLE}>поле для промокода</Text>
        </Group>
        <CustomLabel title='Текст' isRequire styles={LABEL_STYLE} />

        <Text sx={{ ...BLOCK_STYLE, marginTop: '49px', padding: '8px 30px', width: 'fit-content' }}>
          Кнопка{' '}
        </Text>
      </Box>
      <Box
        sx={{
          minWidth: '50%',
          minHeight: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#E8ECF0',
          borderRadius: '7px',
        }}
      >
        <CustomLabel title='Изображение' isRequire styles={LABEL_STYLE} />
      </Box>
    </Box>
  );
};
