import { useDocumentTitle } from '@mantine/hooks';

import { TITLE_PROMOTIONS } from '@/constants/titles';

import { PromotionsContainer } from '@/ui/containers/PromotionsContainer';

const Promotions = () => {
  useDocumentTitle(TITLE_PROMOTIONS);
  return <PromotionsContainer />;
};

export default Promotions;
