import { ProductSnippet, SnippetValues } from '@prosv/core/types/snippets';

import { EProductSnippetModalFormFields } from '@/ui/containers/ContentEditor/snippets/ProductSnippetModal/ProductSnippetModal.constants';

export const getProductSnippetDefaultValues = (
  defaultValues?: ProductSnippet,
): SnippetValues<ProductSnippet> => {
  return {
    [EProductSnippetModalFormFields.ID]: defaultValues?.id || '',
    [EProductSnippetModalFormFields.LINK]: defaultValues?.link || '',
    [EProductSnippetModalFormFields.TITLE]: defaultValues?.title || '',
    [EProductSnippetModalFormFields.DESCRIPTION]: defaultValues?.description || '',
    [EProductSnippetModalFormFields.IMAGE]: defaultValues?.image || {
      id: '',
      name: '',
      path: '',
    },
    [EProductSnippetModalFormFields.AUTHOR]: defaultValues?.author || '',
    [EProductSnippetModalFormFields.ALT]: defaultValues?.alt || '',
    [EProductSnippetModalFormFields.IMAGE_TITLE]: defaultValues?.imageTitle || '',
    [EProductSnippetModalFormFields.IMAGE_ALT]: defaultValues?.imageAlt || '',
  };
};
