import { MenuItemTypeEnum } from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { EMenuContainerAddNewItemConstants } from '@/ui/containers/MenusContainer/components/AddOrEditItem/AddOrEditItem.constants';
import { TAddOrEditItem } from '@/ui/containers/MenusContainer/MenuContainer.types';

export const getNewItemInitialValues = (defaultValues?: TAddOrEditItem) => {
  return {
    [EMenuContainerAddNewItemConstants.MENU_NAME]: defaultValues?.name || '',
    [EMenuContainerAddNewItemConstants.TEXT_BOLD]: defaultValues?.isBold || false,
    [EMenuContainerAddNewItemConstants.ITEM_TYPE]:
      defaultValues?.itemType || MenuItemTypeEnum.INTERNAL,
    [EMenuContainerAddNewItemConstants.URL]: defaultValues?.url || '',
    [EMenuContainerAddNewItemConstants.IS_VISIBLE]: defaultValues?.isVisible || false,
    [EMenuContainerAddNewItemConstants.IS_NO_FOLLOW]: defaultValues?.isNofollow || false,
    [EMenuContainerAddNewItemConstants.IS_BURGER_BOTTOM]: defaultValues?.isBurgerBottom || false,
  };
};
