import { itemsPerPageOptions } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';

import { FormValues, ProjectItem, ProjectSliceTypes } from './projectsSlice.types';

import { ProjectDetailsValuesEnum } from '@/pages/Projects/ProjectDetails/ProjectDetails.consts';

export const projectDefaultFilter: ProjectSliceTypes = {
  currentPage: 1,
  limit: itemsPerPageOptions[0].value,
  isActive: null,
  projectType: null,
};

export const projectTypeOptions = [
  { label: 'Лендинг', value: 'landing' },
  { label: 'Основан на мероприятиях', value: 'eventBased' },
];
export const projectTypes: Record<ProjectItem['type'], string> = {
  eventBased: 'Основан на мероприятиях',
  landing: 'Лендинг',
};

export const landingOptions = [
  { label: 'Урок безопасности', value: 'd5fd1d33-9cf1-4da6-ae7a-9a37e734675d' },
  { label: 'Химический диктант', value: '07b29a17-c63f-4bae-b859-a6f719a67b86' },
  { label: 'Год учителя', value: '58ba9bba-4051-4b90-98a2-02d444545d48' },
];

export const initialValues: FormValues = {
  [ProjectDetailsValuesEnum.NAME]: '',
  [ProjectDetailsValuesEnum.PROJECT_ID]: [],
  [ProjectDetailsValuesEnum.IS_ACTIVE]: 'true',
  [ProjectDetailsValuesEnum.EVENT_IDS]: [],
  [ProjectDetailsValuesEnum.TYPE]: undefined,
  [ProjectDetailsValuesEnum.UUID]: '',
};
