import {
  ProductGalleryImageItem,
  ProductGalleryItem,
} from '@prosv/core/types/bff/catalog/product/gallery';

export const getMainGalleryImage = (
  gallery: ProductGalleryItem[] = [],
): ProductGalleryImageItem | undefined => {
  let res: ProductGalleryImageItem | undefined;

  for (const item of gallery) {
    if (item.type === 'image') {
      if (item.isMain) {
        res = item;
        break;
      } else if (!res) {
        res = item;
      }
    }
  }

  return res;
};
