import { ItemPermissions } from '@/types/auth/permissions';
import { IMaterials } from '@/types/materials/materials';

import { ChangeEvent, FC } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Input,
  MultiSelect,
  NumberInput,
  Radio,
  Select,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { IconCalendar } from '@tabler/icons';

import { transliteration } from '@/utils/transliteration';

import { FileLoader } from '@/ui/components/FileLoader';
import { TagSelect } from '@/ui/components/TagSelect/TagSelect';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import {
  containsOptions,
  EActivityFieldValues,
  EMaterialsFormFields,
  selectDisplayOnPageOptions,
  selectDisplayOnSitesOptions,
  sortTooltip,
} from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { getImageData } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.helpers';

import { useDictionaryOptions } from '@/store/slices/dictionary';

interface IProps {
  form: UseFormReturnType<IMaterialsFormFields>;
  sessionID: string;
  material: IMaterials | null;
  isNewMaterial: boolean;
  permissions: ItemPermissions;
}

export const MaterialsForm: FC<IProps> = ({
  form,
  isNewMaterial,
  sessionID,
  material,
  permissions: { canUpdate, canUpdateActivity },
}) => {
  const { data: ageSelectOptions } = useDictionaryOptions('cnclassage');
  const { data: controlSelectOptions } = useDictionaryOptions('typeofcontrol');
  const { data: umkSelectOptions } = useDictionaryOptions('attrlineumk');
  const { data: roleSelectOptions } = useDictionaryOptions('readerrole');
  const { data: levelSelectOptions } = useDictionaryOptions('cnedulevelid');
  const { data: subjectSelectOptions } = useDictionaryOptions('cnsubject');
  const { data: themeSelectOptions } = useDictionaryOptions('theme');
  const { data: directionSelectOptions } = useDictionaryOptions('cndirectionid');
  const { data: authorsSelectOptions } = useDictionaryOptions('materialauthor');
  const { data: typeSelectOptions } = useDictionaryOptions('materialtype');
  const { data: digitalServiceOptions } = useDictionaryOptions('digitalservice');
  const { data: tagOptions } = useDictionaryOptions('tag');

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNewMaterial) {
      form.setFieldValue(
        EMaterialsFormFields.URL_CODE,
        `${transliteration(event.currentTarget.value, { onlyLower: true })}`,
      );
    }
    return form.getInputProps(EMaterialsFormFields.TITLE).onChange(event);
  };

  const savedFormFiles = getImageData(material?.image?.imageData);

  return (
    <Box
      style={{
        display: 'grid',
        gap: '24px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TextInput
        withAsterisk
        label='Заголовок'
        sx={{ gridColumn: 'span 8' }}
        placeholder='Введите заголовок новости'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.TITLE)}
        onChange={handleChangeTitle}
      />

      <Radio.Group
        label='Активность'
        withAsterisk
        sx={{ gridColumn: 'span 4', justifyItems: 'end' }}
        {...form.getInputProps(EMaterialsFormFields.IS_ACTIVE)}
      >
        <Radio
          value={EActivityFieldValues.ACTIVE}
          label='Да'
          disabled={!(canUpdate || canUpdateActivity)}
        />
        <Radio
          value={EActivityFieldValues.NOT_ACTIVE}
          label='Нет'
          disabled={!(canUpdate || canUpdateActivity)}
        />
      </Radio.Group>

      <TextInput
        label='Символьный код URL'
        withAsterisk
        sx={{ gridColumn: 'span 8' }}
        placeholder='Cсылка на новость'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.URL_CODE)}
      />

      <DatePicker
        locale='ru'
        icon={<IconCalendar size={16} />}
        placeholder='Дата публикации'
        label='Дата публикации'
        withAsterisk
        sx={{ gridColumn: 'span 4' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DATA_ACTIVE_FROM_DAYS)}
      />

      <MultiSelect
        label={
          <TooltipTextWrapper
            label='Показывать на сайтах'
            text='Используется также для заполнения SEO-тега name у изображения'
            required
          />
        }
        data={selectDisplayOnSitesOptions}
        sx={{ gridColumn: 'span 6' }}
        placeholder='Выберите сайты'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DISPLAY_ON_SITES)}
      />

      <MultiSelect
        withAsterisk
        label='Показывать на страницах'
        data={selectDisplayOnPageOptions}
        sx={{ gridColumn: 'span 6' }}
        placeholder='Выберите страницы'
        disabled={!canUpdate}
        defaultValue={[selectDisplayOnPageOptions[0].value]}
        {...form.getInputProps(EMaterialsFormFields.TARGET_PAGES)}
      />

      <NumberInput
        sx={{ gridColumn: 'span 3' }}
        label={<TooltipTextWrapper label='Вес сортировки' text={sortTooltip} />}
        hideControls
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.SORT)}
      />

      <NumberInput
        label='Время чтения (в минутах)'
        sx={{ gridColumn: 'span 3' }}
        hideControls
        placeholder='Укажите время чтения'
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.READING_TIME)}
      />
      <Box sx={{ gridColumn: 'span 3' }}></Box>

      <Checkbox
        label='Сделать главным материалом'
        sx={{ gridColumn: 'span 12' }}
        checked={form.values[EMaterialsFormFields.IS_MAIN_ARTICLE]}
        onChange={({ target: { checked } }) => {
          form.setFieldValue(EMaterialsFormFields.IS_MAIN_ARTICLE, checked);
        }}
        disabled={!canUpdate}
      />
      {/*TODO: скрыто PROSVNEW-4336[4] */}
      {/*<Checkbox*/}
      {/*  label='Сгенерировать оглавление'*/}
      {/*  sx={{ gridColumn: 'span 6' }}*/}
      {/*  checked={form.values[EMaterialsFormFields.IS_MAIN_ARTICLE]}*/}
      {/*  {...form.getInputProps(EMaterialsFormFields.IS_MAIN_ARTICLE)}*/}
      {/*/>*/}

      <Textarea
        label={
          <TooltipTextWrapper
            label='Краткое описание'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите краткое описание новости'
        sx={{ gridColumn: 'span 6', textarea: { minHeight: '114px' } }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.SHORT_DESCRIPTION)}
      />

      <Input.Wrapper label='Изображение' sx={{ gridColumn: 'span 6' }}>
        <FileLoader
          sessionID={sessionID}
          savedFormFiles={savedFormFiles}
          {...form.getInputProps(`image.${EMaterialsFormFields.IMAGE_ID}`)}
          onRemove={() => form.setFieldValue(`image.${EMaterialsFormFields.IMAGE_ID}`, '')}
          onChange={(params) => {
            form.setFieldValue(`image.${EMaterialsFormFields.IMAGE_ID}`, params.id);
          }}
          disabled={!canUpdate}
        />
      </Input.Wrapper>

      <Select
        withAsterisk
        label='Тип'
        placeholder='Выберите тип'
        data={typeSelectOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.TYPE)}
      />

      <MultiSelect
        label='Роль читателя'
        placeholder='Выберите роль читателя'
        dropdownPosition='flip'
        data={roleSelectOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.READER_ROLE)}
      />

      <MultiSelect
        label='Направление'
        placeholder='Выберите направление'
        dropdownPosition='flip'
        data={directionSelectOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DIRECTION)}
      />

      <MultiSelect
        label='Форма контроля'
        placeholder='Выберите форму контроля'
        dropdownPosition='flip'
        data={controlSelectOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.TYPE_OF_CONTROL)}
      />

      <MultiSelect
        label='Уровень образования'
        placeholder='Выберите уровень образования'
        dropdownPosition='flip'
        data={levelSelectOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.EDUCATION_LEVEL)}
      />

      <MultiSelect
        label='Класс и возраст'
        placeholder='Выберите класс'
        dropdownPosition='flip'
        data={ageSelectOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.AGE)}
      />

      <MultiSelect
        label='Тема'
        placeholder='Выберите тему'
        dropdownPosition='flip'
        data={themeSelectOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.THEME)}
      />
      <MultiSelect
        label='Статья содержит'
        placeholder='Выберите содержание статьи'
        dropdownPosition='flip'
        data={containsOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.CONTAINS)}
      />

      <MultiSelect
        label='Цифровой сервис'
        placeholder='Выберите цифровой сервис'
        dropdownPosition='flip'
        data={digitalServiceOptions}
        sx={{ gridColumn: 'span 6' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.DIGITAL_SERVICE)}
      />

      <MultiSelect
        label='Предмет'
        placeholder='Выберите предмет'
        dropdownPosition='flip'
        data={subjectSelectOptions}
        sx={{ gridColumn: 'span 12' }}
        disabled={!canUpdate}
        {...form.getInputProps(EMaterialsFormFields.SUBJECT)}
      />

      <Box sx={{ gridColumn: 'span 12' }}>
        <TagSelect
          style={{ gridColumn: 'span 12' }}
          options={umkSelectOptions}
          label='Линия УМК'
          placeholder='Выберите линию УМК'
          disabled={!canUpdate}
          {...form.getInputProps(EMaterialsFormFields.LINE_UMK)}
        />
      </Box>

      <Box sx={{ gridColumn: 'span 12' }}>
        <TagSelect
          style={{ gridColumn: 'span 12' }}
          options={authorsSelectOptions}
          label='Авторы'
          placeholder='Выберите автора'
          disabled={!canUpdate}
          {...form.getInputProps(EMaterialsFormFields.AUTHOR)}
        />
      </Box>

      <Box sx={{ gridColumn: 'span 8' }}>
        <TagSelect
          style={{ gridColumn: 'span 8' }}
          options={tagOptions}
          label='Теги'
          placeholder='Выберите теги'
          disabled={!canUpdate}
          {...form.getInputProps(EMaterialsFormFields.TAGS)}
        />
      </Box>

      <Box style={{ gridColumn: 'span 4', position: 'relative', height: '100%' }}>
        <Button
          component={Link}
          to='/dictionary/tag'
          variant='white'
          target='_blank'
          sx={{ top: 26, position: 'absolute', padding: 0 }}
        >
          Добавить новый тег
        </Button>
      </Box>
    </Box>
  );
};
