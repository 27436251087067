import { SnippetValues, UmkSystem, UmkSystemBlock } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { UmkSystemSnippetFormFields } from './UmkSystemSnippet.constants';

export const getUmkSystemSnippetDefaultValues = (
  defaultValues?: UmkSystem,
): SnippetValues<UmkSystem> => {
  const getDefaultUmkSnippetBlock = (umkSystem?: UmkSystemBlock) => {
    return {
      [UmkSystemSnippetFormFields.TEXT]: umkSystem?.text || '',
      [UmkSystemSnippetFormFields.IMAGE]: umkSystem?.image || {
        id: '',
        name: '',
        path: '',
      },
    };
  };

  const umkSnippetsBlock = defaultValues?.blocks?.map(getDefaultUmkSnippetBlock);
  return {
    id: defaultValues?.id || uuid_v4(),
    title: defaultValues?.title || '',
    blockType: defaultValues?.blockType || 'row',
    startPouringFromFirstBlock: defaultValues?.startPouringFromFirstBlock || 'false',
    backgroundColor: defaultValues?.backgroundColor || '',
    blocks: umkSnippetsBlock || [getDefaultUmkSnippetBlock()],
  };
};
