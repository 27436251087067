import { create } from 'zustand';
interface ModalData {
  currentModal?: ModalsVariants;
  stringSource?: string;
}
interface ModalsStore {
  modal: ModalData;
  openModal: (params: ModalData) => void;
  closeModal: () => void;
}

export const useModalsStore = create<ModalsStore>((set) => ({
  modal: {},
  openModal: (modal) => set((state) => ({ ...state, modal: { ...state.modal, ...modal } })),
  closeModal: () => set((state) => ({ ...state, modal: {} })),
}));

export enum ModalsVariants {
  WITHDRAW_FROM_PUBLICATION_REVIEW,
  REVIEW_DETAILS_VIDEO,
  PUBLICATION_QUESTION_MODAL,
}
