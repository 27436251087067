import * as Yup from 'yup';

import { STRING_EMAIL_REGEXP } from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';

import { EPeopleSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/PeopleSnippetModal/PeopleSnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const peopleSnippetSchema = Yup.object({
  [EPeopleSnippetFormFields.TITLE]: Yup.string()
    .required(ERR_REQUIRED_TEXT)
    .max(150, validationTexts.MAX_LENGTH(150)),
  [EPeopleSnippetFormFields.PEOPLE]: Yup.array(
    Yup.object({
      [EPeopleSnippetFormFields.FULL_NAME]: Yup.string().required(ERR_REQUIRED_TEXT),
      [EPeopleSnippetFormFields.PHONE]: Yup.string().max(30, validationTexts.MAX_LENGTH(30)),

      [EPeopleSnippetFormFields.POSITION]: Yup.string().max(150, validationTexts.MAX_LENGTH(150)),
      [EPeopleSnippetFormFields.EMAIL]: Yup.string()
        .test(EPeopleSnippetFormFields.EMAIL, validationTexts.EMAIL, (value) => {
          if (!value || value.trim().length === 0) {
            return true;
          }
          return STRING_EMAIL_REGEXP.test(value);
        })
        .max(150, validationTexts.MAX_LENGTH(150)),
      [EPeopleSnippetFormFields.BRIEF_INFORMATION]: Yup.string().max(
        500,
        validationTexts.MAX_LENGTH(500),
      ),
      [EPeopleSnippetFormFields.ALT]: Yup.string().max(250, validationTexts.MAX_LENGTH(250)),
      [EPeopleSnippetFormFields.IMAGE_TITLE]: Yup.string().max(
        100,
        validationTexts.MAX_LENGTH(100),
      ),
    }),
  ).max(10),
});
