import { Sx } from '@mantine/core';

export const root: Sx = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '96vh',
};

export const contentWrapper: Sx = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
};

export const tabPanel: Sx = {
  padding: '24px 0 34px 0',
  height: 'inherit',
  overflow: 'scroll',
};
