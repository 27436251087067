export const cardData = {
  text: {
    title: 'Текст',
    description: 'Добавить текстовый блок',
  },
  image: {
    title: 'Изображение',
    description: 'Добавить блок с изображением',
  },
  people: {
    title: 'Люди',
    description: 'Добавить блок с людьми',
  },
  quote: {
    title: 'Цитата',
    description: 'Добавить блок с цитатой',
  },
  download: {
    title: 'Скачиваемые материалы',
    description: 'Добавить блок со скачиваемыми материалами',
  },
  'link-btn': {
    title: 'Кнопка со ссылкой',
    description: 'Добавить блок с ссылкой и кнопкой',
  },
  product: {
    title: 'Продукт',
    description: 'Добавить блок с продуктом',
  },
  event: {
    title: 'Мероприятие',
    description: 'Добавить блок с мероприятием',
  },
  gallery: {
    title: 'Галерея',
    description: 'Добавить блок с галереей',
  },
  video: {
    title: 'Видео',
    description: 'Добавить блок с видео',
  },
  news: {
    title: 'Новости',
    description: 'Добавить блок с новостями',
  },
  promo: {
    title: 'Текст+промокод',
    description: 'Добавить блок с промокодом',
  },
  banner: {
    title: 'Баннер',
    description: 'Добавить блок с баннером',
  },
  products: {
    title: 'Продукты',
    description: 'Добавить слайдер продуктов',
  },
  html: {
    title: 'HTML-блок',
    description: 'Добавить блок с кодом HTML',
  },
  compactPromo: {
    title: 'Компактная акция',
    description: 'Добавить блок с компактной акцией',
  },
  catalog: {
    title: 'Листинг товаров',
    description: 'Добавить блок с листингом товаров',
  },
  imageWithTextAround: {
    title: 'Текст с изображением',
    description: 'Добавить текст с изображением',
  },
  question: {
    title: 'Вопросы и ответы',
    description: 'Добавить сниппет с вопросами и ответами',
  },
  umkSystem: {
    title: 'Как устроена система',
    description: 'Добавить сниппет как устроена система УМК',
  },
};
