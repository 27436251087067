import { IPlaceOptions } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.types';

export enum EBannerFormFields {
  IS_ACTIVE = 'isActive',
  TITLE = 'title',
  BANNER_LINK = 'bannerLink',
  SUB_TITLE = 'subTitle',
  DESCRIPTION = 'description',
  PROMOCODE = 'promocode',
  BUTTON_TEXT = 'buttonText',
  BUTTON_LINK = 'buttonLink',
  NUMBER_OF_VIEW_BEFORE_HIDING = 'numberOfViewsBeforeHiding',
  NUMBER_OF_CLICK_BEFORE_HIDING = 'numberOfClicksBeforeHiding',
  SORT = 'sort',
  DATE_ACTIVE_TO_DAYS = 'dateActiveToDays',
  DATE_ACTIVE_TO_HOURS = 'dateActiveToHours',
  DATE_ACTIVE_FROM_DAYS = 'dateActiveFromDays',
  DATE_ACTIVE_FROM_HOURS = 'dateActiveFromHours',
  SITE = 'site',
  PAGE = 'page',
  GRADE = 'grade',
  SUBJECTS = 'subjects',
  PLACE = 'place',
  BACKGROUND = 'background',
  DESKTOP_IMAGE_ID = 'desktopImageId',
  TABLET_IMAGE_ID = 'tabletImageId',
  MOBILE_IMAGE_ID = 'mobileImageId',
  LOGO1_IMAGE_ID = 'logo1ImageId',
  LOGO2_IMAGE_ID = 'logo2ImageId',
  LOGO3_IMAGE_ID = 'logo3ImageId',
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo',
  ERID = 'erid',
  ORGANIZATION_NAME = 'organizationName',
}

export enum EActivityFieldValues {
  ACTIVE = 'true',
  NOT_ACTIVE = 'false',
}

export const dependentOptions: IPlaceOptions = {
  main: [
    { label: 'Главная: Баннер-слайдер горизонтальный верхний', value: 'horizontalTop' },
    { label: 'Главная: Баннер-слайдер горизонтальный нижний', value: 'horizontalBottom' },
    { label: 'Главная: Ролевой баннер.Родителям', value: 'roleParents' },
    { label: 'Главная: Ролевой баннер.Педагогам', value: 'roleEducators' },
    { label: 'Главная: Ролевой баннер.Ученикам', value: 'roleStudents' },
    { label: 'Главная: Ролевой баннер.Директорам', value: 'roleDirectors' },
    { label: 'Главная: Ролевой баннер.Бизнес', value: 'roleBusiness' },
  ],
  platform: [{ label: 'Платформа: Главный баннер', value: 'main' }],
  catalog: [
    { label: 'Каталог: Баннер-слайдер каталога горизонтальный верхний', value: 'horizontalTop' },
    { label: 'Каталог: 1 ряд Мини-баннер текстовый слева', value: 'mini121' },
    { label: 'Каталог: 1 ряд Мини-баннер текстовый справа', value: 'mini221' },
    { label: 'Каталог: 2 ряд Мини-баннер текстовый слева', value: 'mini122' },
    { label: 'Каталог: 2 ряд Мини-баннер текстовый справа', value: 'mini222' },
    {
      label: 'Каталог: Баннер статичный каталога горизонтальный нижний',
      value: 'horizontalBottom',
    },
  ],
  menu: [
    { label: 'Меню: Сториз', value: 'stories' },
    { label: 'Меню: Баннер каталога', value: 'catalog' },
  ],
  special: [{ label: 'Спецпроекты: Главный баннер-слайдер', value: 'main' }],
  article: [{ label: 'Статьи: Главный баннер-слайдер', value: 'main' }],
  selection: [{ label: 'Каталог категория: Главный баннер', value: 'main' }],
  cabinet: [{ label: 'ЛК: Предложения', value: 'offer' }],
  promotion: [{ label: 'Акции: Сниппет', value: 'main' }],
  digitalProduct: [
    { label: 'Цифровые продукты: Акции', value: 'action' },
    {
      label: 'Цифровые продукты: Баннер для продвижения',
      value: 'promotion',
    },
  ],
  parents: [{ label: 'Родителям: Баннер-шапка', value: 'header' }],
  audio: [{ label: 'Главный баннер аудио', value: 'main' }],
  kts: [{ label: 'КТС: Баннер-шапка', value: 'header' }],
  umk: [{ label: 'Баннер УМК', value: 'main' }],
  student: [{ label: 'Баннер Шапка', value: 'header' }],
};

export const BANNER_CREATION_FORM_ID = 'banner-creation-form';
export const TITLE_MAX_LENGTH = 90;
export const SUB_TITLE_MAX_LENGTH = 100;
export const DESCRIPTION_MAX_LENGTH = 250;
export const BUTTON_TEXT_MAX_LENGTH = 17;
export const PROMOCODE_MAX_LENGTH = 15;
export const LINK_MAX_LENGTH = 500;
export const ERID_MAX_LENGTH = 100;
export const ORGANIZATION_MAX_LENGTH = 100;

export const DEFAULT_CLICKS_VALUE = 9999999;
export const DEFAULT_VIEWS_VALUE = 9999999;
export const DEFAULT_MIN_CLICKS_VALUE = 0;
export const DEFAULT_MIN_VIEWS_VALUE = 0;

export const MAX_IMAGE_SIZE = 5242880;

export const LOGO_BREAKPOINTS = [
  { title: 'Логотип 1', field: EBannerFormFields.LOGO1_IMAGE_ID },
  { title: 'Логотип 2', field: EBannerFormFields.LOGO2_IMAGE_ID },
  { title: 'Логотип 3', field: EBannerFormFields.LOGO3_IMAGE_ID },
];

export const BACKGROUND_BREAKPOINTS = [
  { title: 'Десктопная версия - 1920', field: EBannerFormFields.DESKTOP_IMAGE_ID },
  { title: 'Версия для планшетов - 768', field: EBannerFormFields.TABLET_IMAGE_ID },
  { title: 'Версия для мобильных устройств - 375', field: EBannerFormFields.MOBILE_IMAGE_ID },
];

export const bgFields = ['desktopImage', 'tabletImage', 'mobileImage'];
export const logoFields = ['logo1Image', 'logo2Image', 'logo3Image'];

export const disabledOptions = ['corp', 'uchitel'];
export const colorsOptions = [
  {
    color: 'blue',
    label: 'Педагогам',
    value: 'blue',
  },

  {
    color: 'orange',
    label: 'Ученикам',
    value: 'orange',
  },
  {
    color: 'green',
    label: 'Образовательным организациям',
    value: 'green',
  },
  {
    color: 'turquoise',
    label: 'Родителям',
    value: 'turquoise',
  },
  {
    color: 'cyan',
    label: 'Голубой',
    value: 'cyan',
  },
  {
    color: 'lightCyan',
    label: 'Светло-голубой',
    value: 'lightCyan',
  },
  {
    color: 'purple',
    label: 'Фиолетовый',
    value: 'purple',
  },
];
