import { IBanner } from '@/types/banner/banner';

import { BannerSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { EBannerSnippet } from '@/ui/containers/ContentEditor/snippets/BannerSnippet/BannerSnippet.constants';

export const getBannerSnippetInitialValues = (
  defaultValues?: BannerSnippet,
): SnippetValues<BannerSnippet> => {
  return {
    [EBannerSnippet.ID]: defaultValues?.id || uuid_v4(),
    [EBannerSnippet.BANNER_ID]: defaultValues?.bannerId || '',
  };
};

export const getOptions = (banners: IBanner[]) => {
  return banners.map(({ uuid, title }) => ({ value: uuid ?? '', label: title ?? '' }));
};
