import { useNavigate } from 'react-router-dom';
import { Button, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { usePermission } from '@/hooks/usePermissions';

import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';
import { ActiveStatusCell } from '@/ui/containers/MaterialsContainer/Components/ActiveStatusCell';
import { MaterialsModalsEnum } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';

import { setMaterialsModal } from '@/store/slices/materials/materials';
import {
  CreateFilterSlice,
  supportsFilterActions,
  supportsFiltersSelector,
} from '@/store/slices/support/supportDetailSlice';
import { ISupportsResponse } from '@/store/slices/support/supportDetailsTypes';

export const useColumns = (getBannersListing: (params: CreateFilterSlice) => void) => {
  const canGet = usePermission('FAQ_GET');
  const canUpdate = usePermission('FAQ_UPDATE');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filter = useAppSelector(supportsFiltersSelector);
  const theme = useMantineTheme();
  const { setFilter } = supportsFilterActions;

  const handleSetSort = (type: string) => {
    const params = {
      sortField: type,
      sortDirection: setSortDirection(filter.sortDirection),
    };
    dispatch(setFilter(params));
    getBannersListing(params);
  };

  const toggleActive = (uuid: string, isActive: boolean) =>
    dispatch(
      setMaterialsModal({
        currentModal: MaterialsModalsEnum.MATERIALS_ACTIVE_STATUS_MODAL,
        currentId: uuid,
        isActive,
      }),
    );

  const getSort = (field: string) =>
    filter.sortField === field ? filter.sortDirection : undefined;

  const columns: ITableColumns<ISupportsResponse>[] = [
    {
      title: 'ID',
      field: 'id',
      sort: getSort('id'),
      sortAction: () => {
        handleSetSort('id');
      },
    },
    {
      title: 'Активность',
      field: 'isActive',
      sort: getSort('isActive'),
      sortAction: () => {
        handleSetSort('isActive');
      },
      render: (item) => (
        <ActiveStatusCell
          isActive={item.isActive}
          toggleActivity={() => toggleActive(item.uuid, item.isActive)}
        />
      ),
    },
    {
      title: 'Вопрос',
      field: 'question',
      sort: getSort('question'),
      sortAction: () => {
        handleSetSort('question');
      },
    },
    {
      title: 'Категория обращения',
      field: 'category',
      render: ({ category }) => category?.name || ' - ',
    },
    {
      title: <span style={{ whiteSpace: 'nowrap' }}>Сортировка (вес)</span>,
      field: 'sort',
      position: 'center',
      sort: getSort('sort'),
      sortAction: () => {
        handleSetSort('sort');
      },
    },
    {
      title: 'Отображать на главной',
      field: 'isDisplayOnMain',
      position: 'center',
      sort: getSort('isDisplayOnMain'),
      sortAction: () => {
        handleSetSort('isDisplayOnMain');
      },
      render: (data) => <>{data.isDisplayOnMain ? 'Да' : 'Нет'}</>,
    },
    {
      title: 'Позиция на главной',
      field: 'positionOnMain',
      position: 'center',
      sort: getSort('positionOnMain'),
      sortAction: () => {
        handleSetSort('positionOnMain');
      },
    },

    {
      title: '',
      style: { width: 50 },
      field: 'actions',
      render: ({ uuid: id }) =>
        canGet || canUpdate ? (
          <Button
            onClick={() => {
              navigate(`/support/${id}`);
            }}
            name='Редактировать'
            title='Редактировать'
            variant='subtle'
            style={{ position: 'static' }}
            compact
          >
            <Pencil size={22} color={theme.colors.greyLight[9]} />
          </Button>
        ) : null,
    },
  ];
  return columns;
};
