import { INewsErrResponse } from '@/types/news/news';

import { useState } from 'react';
import { AxiosError } from 'axios';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import {
  IGetMaterialResponse,
  IMaterialCreationData,
} from '@/ui/containers/MaterialsContainer/Materials.types';

import api from '../../instance';

enum EErrCode {
  URL_CODE_EXISTS = '0a48a1ad-2f3a-4452-9d35-184e0b46a09a',
}

export const useCreateMaterial = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    createMaterial: async (materialsData: IMaterialCreationData) => {
      setIsLoading(true);
      try {
        const request = await api.post<IGetMaterialResponse>(URLS.materials.createMaterial, {
          ...materialsData,
        });
        notify({ message: 'Материал добавлен', type: 'success' });
        return request.data;
      } catch (err) {
        const { response } = err as AxiosError<INewsErrResponse, {}>;
        if (response?.data.code === EErrCode.URL_CODE_EXISTS) {
          return notify({
            message: 'Материал c таким символьным кодом уже существует',
            type: 'error',
          });
        }
        return notify({ message: 'Ошибка добавления материала', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
