import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useNavigationStore } from '@/store/slices/navigationZustandStore/navigationZustandStore';

export const NavigateWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    useNavigationStore.getState().setNavigate(navigate);
  }, [navigate]);

  return <>{<Outlet />}</>;
};
