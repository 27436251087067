import { useEffect } from 'react';
import { Text } from '@mantine/core';
import { FormErrors } from '@mantine/form/lib/types';
import { isEmpty } from 'lodash';

import notify from '@/utils/notify';

import { generateInvalidFieldErrors } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.helpers';

interface IUseNotifyParams {
  errors: FormErrors;
  trySavePromotion: boolean;
  setTrySave: (value: boolean) => void;
}

export const useNotifyOnFormError = ({
  errors,
  trySavePromotion,
  setTrySave,
}: IUseNotifyParams) => {
  useEffect(() => {
    if (!isEmpty(errors) && trySavePromotion) {
      notify({
        message: '',
        type: 'warning',
        params: {
          title: <Text>Акцию не удалось сохранить:</Text>,
          message: generateInvalidFieldErrors(errors).map((item) => <Text>{item}</Text>),
          closeButtonProps: {
            style: {
              position: 'absolute',
              content: 'close',
              top: '10px',
              right: '10px',
            },
          },
          styles: {
            root: {
              width: 'max-content',
            },
            title: {
              fontWeight: 'bold',
              marginBottom: '10px',
            },
          },
        },
      });
    }
    return () => {
      setTrySave(false);
    };
  }, [errors]);
};
