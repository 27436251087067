import { UseFormReturnType } from '@mantine/form';
import { QuestionSnippet, SnippetValues } from '@prosv/core/types/snippets';

import { QuestionSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet.constants';

interface UseQuestionSnippetModal {
  form: UseFormReturnType<SnippetValues<QuestionSnippet>>;
}

export const useQuestionSnippetModal = ({ form }: UseQuestionSnippetModal) => {
  const handleAddQuestion = () => {
    const newQuestion = {
      [QuestionSnippetFormFields.ANSWER]: '',
      [QuestionSnippetFormFields.QUESTION]: '',
    };

    form.values.questions?.push(newQuestion);

    form.setValues(form.values);
  };

  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = form.values.questions![dragIndex];
    form.values.questions?.splice(dragIndex, 1);
    form.values.questions?.splice(hoverIndex, 0, draggedItem);
    form.setValues(form.values);
  };

  const handleRemoveBlock = (index: number) => {
    form.values.questions?.splice(index, 1);
    form.setValues(form.values);
  };

  return { handleAddQuestion, handleMoveItem, handleRemoveBlock };
};
