import { useCallback } from 'react';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';

import { imageHandler } from '@/utils/imageReplaceHandler';

import {
  formFieldsToCreate,
  formFieldsToUpdate,
} from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.helpers';
import { INewsCreationState } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.types';
import { INewsFormFields } from '@/ui/containers/NewsDetailInfo';
import { ENewsFormFields } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

export const useCreationNewsHandlers = (id: string, newsState: INewsCreationState) => {
  const navigate = useNavigate();
  const { proceed, reset, state } = useBlocker(newsState.isShouldBlock);

  const handleSubmit = async (formValues: INewsFormFields) => {
    newsState.setDefaultFormData(formValues);

    const backendImageId = await imageHandler(
      formValues.image[ENewsFormFields.IMAGE_ID],
      newsState.sessionID,
    );

    const requestData = { ...formValues, image: { ...formValues.image, id: backendImageId } };
    const data = id
      ? formFieldsToUpdate(requestData, newsState.draftId || '')
      : formFieldsToCreate(requestData, newsState.draftId || '');

    const update = () =>
      newsState.permissions.canUpdate
        ? newsState.updateNews({ uuid: id, newsData: data })
        : newsState.updateNewsActivity(id, data.isActive).then(() => {
            newsState.setNews((prev) => prev && { ...prev, isActive: data.isActive });
            return id;
          });

    let responseData = await (id ? update() : newsState.createNews(data));

    if (responseData) {
      let uuid: string;
      if (typeof responseData === 'string') {
        uuid = responseData;
      } else {
        newsState.setNews(responseData.payload);
        uuid = responseData.payload.uuid;
      }

      const newsResponse = await newsState.getNews(uuid);
      if (newsResponse) newsState.setNews(newsResponse.payload);

      newsState.setIsShouldBlock(false);
      setTimeout(() => navigate(`/news/${uuid}`, { replace: true }), 0);
    }
  };

  const handleDelete = async () => {
    const responseData = await newsState.deleteNews(id || '');

    if (responseData) {
      newsState.setNews(null);
      navigate('/news/');
    }
  };

  const onConfirmLeave = useCallback(() => {
    if (proceed) {
      proceed();
    }
  }, [proceed]);

  const onCancel = useCallback(() => {
    if (reset) {
      reset();
    }
  }, [reset]);

  const onChangeForm = useCallback(() => {
    newsState.setIsShouldBlock(true);
  }, []);

  return {
    handleSubmit,
    handleDelete,
    onConfirmLeave,
    onCancel,
    onChangeForm,
    state,
  };
};
