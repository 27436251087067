import { PermissionCode, permissionCodes } from '@/types/auth/permissions';

import { PermissionInfoItem } from '@prosv/core/src/lib/types/bff/base/permitions';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';

export interface AuthPermissionsResponse {
  code: string;
  message: string;
  payload: {
    permissions: PermissionInfoItem<PermissionCode>[];
  };
}

export const authApi = createApi({
  reducerPath: 'api/auth',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getPermissions: build.query<PermissionCode[], void>({
      query: () => ({
        method: 'post',
        url: '/v1/auth/permissions',
        data: { permissions: permissionCodes.map((attribute) => ({ attribute })) },
      }),
      transformResponse: ({ payload: { permissions } }: AuthPermissionsResponse) =>
        permissions.filter(({ isAccessible }) => isAccessible).map(({ attribute }) => attribute),
    }),
  }),
});
