import { EGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.constants';
import { IGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.types';
import { ISetting } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable/GlobalSettingsTable.types';

export const getDefaultValues = (globalSetting: ISetting[] | null): IGlobalSettingFormFields => {
  return {
    [EGlobalSettingFormFields.TITLE]: globalSetting?.[0].value || '',
    [EGlobalSettingFormFields.ROBOTS]: globalSetting?.[1].value || '',
    [EGlobalSettingFormFields.SYNONYMS]: globalSetting?.[2].value || '',
  };
};
