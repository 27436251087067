import { useEffect } from 'react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { categoriesSlice, selectCategoriesOptions } from '@/store/slices/categories';

export const useCategoriesOptions = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(categoriesSlice.endpoints.getCategories.initiate());
  }, [dispatch]);

  return useAppSelector(selectCategoriesOptions);
};
