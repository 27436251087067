import { UseFormReturnType } from '@mantine/form';
import { PeopleSnippet, SnippetValues } from '@prosv/core/types/snippets';

import { EPeopleSnippetFormFields } from './PeopleSnippetModal.constants';

type TUsePeopleSnippetModal = {
  form: UseFormReturnType<SnippetValues<PeopleSnippet>>;
};

export const usePeopleSnippetModal = ({ form }: TUsePeopleSnippetModal) => {
  const handleAddPerson = () => {
    const newPerson = {
      [EPeopleSnippetFormFields.FULL_NAME]: '',
      [EPeopleSnippetFormFields.PHONE]: '',
      [EPeopleSnippetFormFields.POSITION]: '',
      [EPeopleSnippetFormFields.EMAIL]: '',
      [EPeopleSnippetFormFields.BRIEF_INFORMATION]: '',
      [EPeopleSnippetFormFields.FILE]: {
        id: '',
        name: '',
        path: '',
      },
    };

    form.values.people?.push(newPerson);

    form.setValues(form.values);
  };

  return { handleAddPerson };
};
