import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';

import { uploadTempFile } from '@/api';

import { getFileLoaderAcceptType } from '@/ui/components/FileLoader/FileLoader.helpers';
import { ValueComponent } from '@/ui/components/FileLoader/ValueComponent';
import { Loader } from '@/ui/containers/ContentEditor/Loader';

import { IFileLoaderProps, IFileWithPreview, ISimpleFile, ITempFileData } from './FileLoader.types';
import { acceptedTypes, getStyles, textStyle } from './FileLoader.utils';

import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';

const DEFAULT_MAX_SIZE = 15728640; // 15Mb

export interface FileLoaderRef {
  clearFiles: () => void;
  setNewFile: (newFiles: ISimpleFile[]) => void;
}

export const FileLoader = forwardRef<FileLoaderRef, IFileLoaderProps>(
  (
    {
      fileName,
      placeholder,
      sessionID,
      savedFormFiles,
      onChange,
      onRemove,
      accept = 'images',
      maxSize = DEFAULT_MAX_SIZE,
      isFullWidth = false,
      error = false,
      disabled,
    },
    ref,
  ) => {
    const [files, setFiles] = useState<IFileWithPreview[]>([]);
    const [savedFiled, setSavedFiles] = useState<ISimpleFile[]>(savedFormFiles ?? []);
    const [isLoading, setIsLoading] = useState(false);

    const acceptType = getFileLoaderAcceptType(accept);

    const [tempFileData, setTempFileData] = useState<ITempFileData | null>(null);

    const uploadFile = async (formData: FormData, size: number, type: string, name: string) => {
      const fileData = await uploadTempFile(formData);

      if (fileData) {
        setSavedFiles([]);
        onChange?.({
          id: fileData.id,
          path: process.env.MEDIA_STORAGE_URL + fileData.config?.previewConfig?.path,
          size: size.toString(),
          type,
          name,
        });
        setTempFileData({
          id: fileData.id,
          path: process.env.MEDIA_STORAGE_URL + fileData.config?.previewConfig?.path,
        });
      }
    };

    useImperativeHandle(ref, () => ({
      clearFiles() {
        setFiles([]);
        setSavedFiles([]);
      },
      setNewFile(newFiles: ISimpleFile[]) {
        setSavedFiles(newFiles);
      },
    }));

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      disabled,
      accept: acceptedTypes[accept],
      maxSize,
      multiple: false,
      onDrop: async (acceptedFiles: File[]) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        formData.append('name', fileName || acceptedFiles[0].name);
        formData.append('configType', acceptType);
        formData.append('tmpConfigType', 'default');
        formData.append('sessionId', sessionID ?? '');

        try {
          await uploadFile(
            formData,
            acceptedFiles[0].size,
            acceptedFiles[0].type,
            fileName || acceptedFiles[0].name,
          );

          setFiles(
            acceptedFiles.map<IFileWithPreview>((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                id: nanoid(),
              }),
            ),
          );
        } finally {
          setIsLoading(false);
        }
      },
    });

    const handleRemove = (id: string) => {
      setFiles((current) => {
        onRemove?.();
        return current.filter((item) => item.id !== id);
      });
      setSavedFiles((current) => {
        return current.filter((item) => item.id !== id);
      });
    };

    const filesToRender = files.length === 0 ? savedFiled : files;

    const style = useMemo<CSSProperties>(
      () =>
        getStyles({
          accept: isDragAccept,
          reject: isDragReject || error,
          hasFiles: filesToRender.length > 0,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isFocused, isDragAccept, isDragReject, filesToRender, error],
    );

    useEffect(() => {
      return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Loader loading={isLoading} />
        <div {...getRootProps({ style })}>
          <input type='hidden' name='file_type' value={files[0]?.type ?? ''} />
          <input type='hidden' name='file_size' value={files[0]?.size ?? ''} />
          <input type='hidden' name='file_id' value={savedFiled[0]?.id ?? ''} />
          <input type='hidden' name='file_temp_id' value={tempFileData?.id ?? ''} />
          <input {...getInputProps()} />
          {filesToRender.length > 0 ? (
            filesToRender.map((file) => (
              <ValueComponent
                key={file.name}
                isFullWidth={isFullWidth}
                file={file}
                onRemove={handleRemove}
              />
            ))
          ) : (
            <>
              <p style={textStyle}>{placeholder ?? 'Перетащите или добавьте изображения'}</p>
              <PlusIcon />
            </>
          )}
        </div>
      </>
    );
  },
);
