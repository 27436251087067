import { UMKItem } from '@prosv/core/types/bff/umk';
import { UmkDetailInfo } from '@prosv/core/types/bff/umk/admin';

import notify from '@/utils/notify';

import {
  EUmkFormFields,
  searchSelectLabel,
  umkPageTypeOptions,
} from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.constants';
import { UmkDetailFormTypes } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.types';
import { UmkType } from '@/store/slices/umkList/umkList.types';

export const convertActiveFieldValueToRequest = (value: string) => value === 'yes';
export const convertRequestDataToActiveFieldValue = (value?: boolean) => (value ? 'yes' : 'no');

export const convertFormDataToRequest = (
  formData: UmkDetailFormTypes,
  draftIds: string[],
  type: 'update' | 'create' = 'create',
) => {
  const bannerIds = formData.bannerValues;

  return {
    draftIds,
    bannerIds,
    isActive: convertActiveFieldValueToRequest(formData.isActive),
    ...(type === 'create' && { umkId: formData.umkId }),
  };
};

export const getUmkDefaultValue = (umkInfo?: UmkDetailInfo, umkType?: UmkType) => {
  return {
    [EUmkFormFields.UMK_NAME]: umkInfo?.umk.id || '',
    [EUmkFormFields.ACTIVE]: convertRequestDataToActiveFieldValue(umkInfo?.isActive),
    [EUmkFormFields.PAGE_TYPE]: umkPageTypeOptions.filter((option) => option.value === umkType)[0]
      .value,
    [EUmkFormFields.BANNER]: umkInfo?.banners.map((el) => el.uuid) || [],
  };
};

export const getUmkListOptions = (items: UMKItem[]) => {
  if (!!items.length) {
    return items.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  }
  return [];
};

export const getSearchUmkParams = (attributeCodeGroup: UmkType, query?: string) => {
  return {
    attributeCodeGroup,
    query: { query: query ?? 'УМК', isAutocorrectQuery: true },
  };
};

export const getSearchSelectLabel = (umkType: UmkType): string => searchSelectLabel[umkType];

export const notifySaveSuccess = () =>
  notify({ message: 'Контент УМК успешно добавлен', type: 'success' });

export const notifyUpdateSuccess = () =>
  notify({ message: 'Контент УМК успешно обновлён', type: 'success' });

export const notifyError = () =>
  notify({
    message: 'Произошла ошибка, изменения не были сохранены',
    type: 'error',
  });
