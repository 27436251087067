import { CSSProperties } from 'react';

export const presentProductStyles = {
  paper: {
    border: '1px solid #E8ECF0',
    width: 352,
    flexShrink: 0,
    background: '#F6F8F8',
    height: 'fit-content',
  },
  imgWrapper: {
    maxWidth: 77,
    maxHeight: 96,
    width: '100%',
    borderRadius: 4,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E8ECF0',
  },
  uuid: {
    textAlign: 'end',
    // maxWidth: 272,
    marginLeft: 'auto',
    color: '#66788A',
    marginTop: 4,
  },
  emptyImage: {
    background: '#E8ECF0',
    width: 77,
    height: 96,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  } as CSSProperties,
};

export const REMOVE_REVIEW_MODAL_TEXT = {
  title: 'Отклонить отзыв?',
  text: 'Отзыв будет отклонён и не будет отображаться на сайте. В личном кабинете пользователя отзыв останется в статусе “Отклонён”',
};
