import { FC } from 'react';

import { TSettingType } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable/GlobalSettingsTable.types';
import { IGlobalSettingItem } from '@/ui/containers/GlobalSettingsContainer/GlobalSettingsContainer.types';

interface IProps {
  items: IGlobalSettingItem[];
  onClickHandle: (type: TSettingType) => void;
}
export const GlobalSettingsTableBody: FC<IProps> = ({ items, onClickHandle }) => {
  return (
    <tbody>
      {items.map(({ name, type }, index) => {
        return (
          <tr key={index} style={{ cursor: 'pointer' }} onClick={() => onClickHandle(type)}>
            <td>{name}</td>
          </tr>
        );
      })}
    </tbody>
  );
};
