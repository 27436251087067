import { TBreadcrumbsExtItem } from '@/types';

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Center, Group, Loader, Paper, Tabs } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ContentEditor } from '@/ui/containers/ContentEditor';
import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';
import { DeleteModal } from '@/ui/containers/SupportContainer/detail/components/DeleteModal';
import { GeneralTab } from '@/ui/containers/SupportContainer/detail/components/tabs/general/GeneralTab';

import { useLazyDeleteQuestionQuery } from '@/store/slices/support/supportDetailSlice';
import { SupportDetailResponse } from '@/store/slices/support/supportDetailsTypes';

export const DetailPageContainer = ({
  breadcrumbs,
  isLoading,
  data,
  categoryOptions,
  title,
}: {
  breadcrumbs?: TBreadcrumbsExtItem[];
  isLoading: boolean;
  categoryOptions: { value: number | string; label: string }[];
  data?: SupportDetailResponse;
  title?: string;
}) => {
  const canDelete = usePermission('FAQ_DELETE');
  const canCreate = usePermission('FAQ_CREATE');
  const canUpdate = usePermission('FAQ_UPDATE');
  const canSave = title ? canCreate : canUpdate;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteRequest, deleteResponse] = useLazyDeleteQuestionQuery();
  const params = useParams();
  const navigate = useNavigate();
  const setDefaultFormData = useStoreContentEditor((state) => state.setDefaultFormData);
  const backToList = () => navigate('/support');
  const isShowPreview = useStoreContentEditor(
    (state) => (state.savedContentData?.grid?.length || 0) > 0,
  );
  const draftId = useStoreContentEditor((state) => state.draftId);

  const handleDelete = () => {
    deleteRequest({ id: params?.id || '', onSuccess: backToList });
  };

  useEffect(() => {
    return () => {
      setDefaultFormData(null);
    };
  }, []);

  const url = `${process.env.PREVIEW_URL}/help/preview/${draftId}`;

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        backLink='/support'
        breadcrumbs={breadcrumbs}
        title={title ?? (data?.payload.question || ' - ')}
        rightButton={
          <Group spacing={10}>
            {isShowPreview && (
              <Button
                component='a'
                href={url}
                rel='noopener noreferrer'
                target='_blank'
                sx={{ width: '200px' }}
                variant='default'
              >
                Предпросмотр
              </Button>
            )}
            {!title && canDelete && (
              <Button
                onClick={() => setOpenDeleteModal(true)}
                sx={{ width: '200px' }}
                variant='default'
              >
                Удалить
              </Button>
            )}
            {canSave && (
              <Button
                form='faqDetailForm'
                type='submit'
                sx={{ width: '200px', background: '#322FC6' }}
              >
                Сохранить
              </Button>
            )}
          </Group>
        }
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          height: '100%',
          overflow: 'auto',
          position: 'relative',
          gap: 24,
        }}
      >
        {isLoading ? (
          <Center sx={{ height: 300 }}>
            <Loader />
          </Center>
        ) : (
          <Tabs color='teal' defaultValue='general'>
            <Tabs.List sx={{ borderBottom: 'none' }}>
              <Tabs.Tab value='general' pb='md' mb='xl' color='primary'>
                Общее
              </Tabs.Tab>
              <Tabs.Tab value='page_editor' pb='md' mb='xl' color='primary'>
                Редактор страницы
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value='general' pt='xs'>
              <GeneralTab data={data} categoryOptions={categoryOptions} />
            </Tabs.Panel>
            <Tabs.Panel value='page_editor' pt='xs'>
              <ContentEditor />
            </Tabs.Panel>
          </Tabs>
        )}
      </Paper>
      {openDeleteModal && (
        <DeleteModal
          disabled={deleteResponse.isLoading}
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onSuccess={handleDelete}
        />
      )}
    </Box>
  );
};
