import notify from '@/utils/notify';

import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

import { useStateTextModal } from './TextSnippetModal.state';

export const useHandlersTextModal = (state: ReturnType<typeof useStateTextModal>) => {
  const handleClose = () => {
    state.setSnippetData(null);
  };

  const handleClickAddBtn = async () => {
    if (!state.editorRef.current?.getText().length) {
      return notify({ message: 'Введите текст', type: 'error' });
    }
    useStoreContentEditor.setState((store) => {
      if (!state.snippetData) return;

      const { variant, rowId, cardId } = state.snippetData;

      const foundRowItem = store.savedContentData?.grid[rowId]?.blocks?.[cardId];

      if (foundRowItem) {
        foundRowItem.variant = variant;
        if (foundRowItem.variant === 'text') {
          foundRowItem.text = state.editorRef.current?.getHTML();
          foundRowItem.color = state.colorValue;
        }
      }

      store.selectContentBlockData = null;
    });

    await useStoreContentEditor.getState().updateContentData();

    handleClose();
  };

  const handleChangeColor = (value: string) => {
    state.setColorValue(value);
  };

  return {
    handleClose,
    handleClickAddBtn,
    handleChangeColor,
  };
};
