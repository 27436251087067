import { components, ValueContainerProps } from 'react-select';

import type { TOption } from '../MultiSelect.types';

export const ValueContainer = ({ children, ...props }: ValueContainerProps<TOption>) => {
  // @ts-ignore
  const label = props?.selectProps?.label;

  let [values, input] = children as any;

  if (Array.isArray(values)) {
    if (values.length === 1) values = `${values[0]?.props?.data?.label}`;
    else values = `${label}: ${values.length}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};
