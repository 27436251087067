import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Paper, Tabs } from '@mantine/core';

import { useModeNews } from '@/api/news/hooks/useModeNewsContainer';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ContentEditor } from '@/ui/containers/ContentEditor';
import { ActionButtons } from '@/ui/containers/NewsCreationContainer/ActionButtons';
import { useCreationNewsHandlers } from '@/ui/containers/NewsCreationContainer/NewCreationContainer.handlers';
import { EAddNewsConfirmationTexts } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { getBreadcrumbs } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.helpers';
import { useNewsCreationState } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.state';
import { NewsDetailInfo } from '@/ui/containers/NewsDetailInfo';
import { NEWS_CREATION_FORM_ID } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

export const NewsCreationContainer: FC = () => {
  const { id } = useParams();
  const newsState = useNewsCreationState(id);
  const breadcrumbs = getBreadcrumbs(newsState.title);
  const { onCancel, handleDelete, handleSubmit, onConfirmLeave, onChangeForm, state } =
    useCreationNewsHandlers(id || '', newsState);

  useModeNews(newsState, id);
  const onTrySave = () => {
    newsState.setTrySaveNews(true);
  };

  if (!newsState.permissions.canView) {
    return <div>Недостаточно прав.</div>;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        breadcrumbs={breadcrumbs}
        title={newsState.title}
        rightButton={
          <ActionButtons
            permissions={newsState.permissions}
            isNewNews={newsState.isNewNews}
            handleDelete={handleDelete}
            onSave={onTrySave}
            formID={NEWS_CREATION_FORM_ID}
          />
        }
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={newsState.isLoading} />
        <Tabs color='teal' defaultValue='first' sx={{ height: '100%', position: 'relative' }}>
          <Tabs.List sx={{ borderBottom: 'none' }}>
            <Tabs.Tab value='first' color='primary'>
              Общее
            </Tabs.Tab>
            {newsState.permissions.canUpdate && (
              <Tabs.Tab value='second' color='primary'>
                Редактор страницы
              </Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value='first' pt='xs' sx={{ padding: '24px 0 34px 0', height: 'inherit' }}>
            {newsState.isShowNewsDetail && (
              <NewsDetailInfo
                news={newsState.news}
                permissions={newsState.permissions}
                sessionID={newsState.sessionID.current}
                isNewNews={newsState.isNewNews}
                handleSubmit={handleSubmit}
                setIsShouldBlock={onChangeForm}
                defaultFormData={newsState.defaultFormData}
                trySaveNews={newsState.trySaveNews}
                setTrySave={newsState.setTrySaveNews}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel value='second' pt='xs' sx={{ padding: '24px 0 34px 0', height: 'inherit' }}>
            <ContentEditor />
          </Tabs.Panel>
        </Tabs>
        {state === 'blocked' && (
          <ModalConfirm
            title={EAddNewsConfirmationTexts.OUT_TITLE}
            text={EAddNewsConfirmationTexts.NOT_SAVE_MENU_SITE}
            confirmHandler={onConfirmLeave}
            opened={state === 'blocked'}
            onClose={onCancel}
          />
        )}
      </Paper>
    </Box>
  );
};
