import { Sx } from '@mantine/core';

export const btn: Sx = {
  width: '100%',
};

export const root: Sx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '26px',
};

export const btnWrapper: Sx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};
