import { umkSlice } from '@/store/slices/umk/umkSlice';

export const {
  useCreateUmkPageMutation,
  useLazyGetUmkQuery,
  useGetUmkQuery,
  useSearchUmkMutation,
  useDeleteUmkPageMutation,
  useUpdateUmkPageMutation,
} = umkSlice;
