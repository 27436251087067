import * as Yup from 'yup';

import { EGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.constants';

export const globalSettingFormSchema = Yup.object({
  [EGlobalSettingFormFields.TITLE]: Yup.string().required(
    'Полe "Название сайта" обязательно для заполнения',
  ),
  [EGlobalSettingFormFields.ROBOTS]: Yup.string().required(
    'Полe "Robots.txt" обязательно для заполнения',
  ),
  [EGlobalSettingFormFields.SYNONYMS]: Yup.string().required(
    'Полe "Synonyms" обязательно для заполнения',
  ),
});
