import * as Yup from 'yup';

import { EQuoteSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/QuoteSnippetModal/QuoteSnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const quoteSnippetSchema = Yup.object({
  [EQuoteSnippetFormFields.TEXT]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EQuoteSnippetFormFields.AUTHOR]: Yup.string().nullable(),
  [EQuoteSnippetFormFields.ABOUT]: Yup.string().nullable(),
  [EQuoteSnippetFormFields.ALT]: Yup.string().nullable(),
  [EQuoteSnippetFormFields.TITLE]: Yup.string().nullable(),
  [EQuoteSnippetFormFields.PHOTO]: Yup.object({
    id: Yup.string().nullable(),
  }),
});
