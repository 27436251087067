import { useMemo, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { notifySaveError, notifySaveSuccess } from '@/utils/notify';

import {
  createFormValidate,
  getCreateSubmitData,
  getFormValues,
  getUpdateSubmitData,
} from './DictionaryItemForm.helpers';
import { DictionaryItemFormProps } from './DictionaryItemForm.types';

import { useCreateDictionaryItem, useUpdateDictionaryItem } from '@/store/slices/dictionary';

export const useDictionaryItemForm = ({
  dictionary,
  item,
  onSuccessSubmit,
}: DictionaryItemFormProps) => {
  const { code } = dictionary;
  const sessionID = useRef(uuid_v4());

  const [create] = useCreateDictionaryItem();
  const [update] = useUpdateDictionaryItem();
  const [isSubmittig, setSubmitting] = useState(false);

  const initialValues = useMemo(() => getFormValues(dictionary, item), [dictionary, item]);
  const validate = useMemo(() => createFormValidate(dictionary), [dictionary]);
  const form = useForm({ initialValues, validate });

  const onSubmit = form.onSubmit((vals) => {
    setSubmitting(true);

    const promise = item
      ? getUpdateSubmitData(dictionary, vals, sessionID.current, item).then((params) =>
          update({ code, id: item.id, params }).unwrap(),
        )
      : getCreateSubmitData(dictionary, vals, sessionID.current).then((params) =>
          create({ code, params }).unwrap(),
        );

    promise
      .then((res) => {
        notifySaveSuccess();
        onSuccessSubmit?.();
        return res;
      })
      .catch((err) => {
        notifySaveError();
        throw err;
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  return { form, onSubmit, isSubmittig, sessionID: sessionID.current };
};
