import { Sx } from '@mantine/core';

export const sx: Sx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  minWidth: 216,
  maxWidth: 216,
};

export const inputStyles = {
  input: {
    height: 40,
    borderColor: '#E8ECF0',
    '::-webkit-input-placeholder': { color: '#8493a4', options: 0, fontSize: 16 },
  },
};

export const itemsPerPageBox: Sx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 24,
  maxWidth: 'fit-content',
};

export const activityOptions = [
  { label: 'Активно', value: 'true' },
  { label: 'Неактивно', value: 'false' },
];
