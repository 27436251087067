import { FC } from 'react';
import { Box, MultiSelect, Radio, Select } from '@mantine/core';
import { UmkDetailInfo } from '@prosv/core/types/bff/umk/admin';

import * as sx from './UmkDetailForm.styles';

import { UMK_FORM_ID } from '@/pages/UmkDetail/UmkDetail.constants';
import { UmkDetailFormModal } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailConfirmModal';
import {
  EUmkFormFields,
  umkPageTypeOptions,
} from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.constants';
import { getSearchSelectLabel } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.helpers';
import { useUmkDetailFormState } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.state';
import { UmkType } from '@/store/slices/umkList/umkList.types';

export interface UmkDetailFormProps {
  umkType: UmkType;
  umkInfo?: UmkDetailInfo;
  isNewUmk: boolean;
  draftIds: string[];
}

export const UmkDetailForm: FC<UmkDetailFormProps> = ({ draftIds, umkType, umkInfo, isNewUmk }) => {
  const state = useUmkDetailFormState({ draftIds, umkType, umkInfo, isNewUmk });

  return (
    <Box sx={sx.root}>
      <form id={UMK_FORM_ID} onSubmit={state.form.onSubmit(state.handleSubmit)}>
        <Select
          withAsterisk
          disabled
          sx={{ gridColumn: 'span 8' }}
          placeholder='Введите название блока, например, Материалы для скачивания'
          label='Тип страницы'
          data={umkPageTypeOptions}
          {...state.form.getInputProps(EUmkFormFields.PAGE_TYPE)}
        />
        <Radio.Group
          sx={{ gridColumn: 'span 4', justifyItems: 'end' }}
          label='Активность'
          withAsterisk
          {...state.form.getInputProps(EUmkFormFields.ACTIVE)}
        >
          <Radio value='yes' label='Да' />
          <Radio value='no' label='Нет' />
        </Radio.Group>
        <Select
          label={getSearchSelectLabel(state.form.getInputProps(EUmkFormFields.PAGE_TYPE).value)}
          placeholder='Введите название'
          sx={{ gridColumn: 'span 12' }}
          withAsterisk
          disabled={!isNewUmk}
          data={state.umkNameFieldOptions}
          searchable
          onSearchChange={state.handleSearch}
          {...state.form.getInputProps(EUmkFormFields.UMK_NAME)}
          onChange={state.handleChangeUmkFieldName}
        />
        {state.isShowBannerField && (
          <MultiSelect
            disabled={state.isBannerLoading}
            label='Баннеры'
            searchable
            sx={{ gridColumn: 'span 12' }}
            data={state.banners || []}
            placeholder={state.isBannerLoading ? 'Загрузка...' : 'Выберите из списка'}
            {...state.form.getInputProps(EUmkFormFields.BANNER)}
          />
        )}
      </form>
      <UmkDetailFormModal
        handleClose={state.handleClose}
        isOpen={state.open}
        createdUmkLink={state.createdUmkLink}
      />
    </Box>
  );
};
