import { TFeature } from '@/types/features/features';

import React, { FC } from 'react';
import { Table } from '@mantine/core';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';

import { TableBody } from './TableBody';

type TConfigurationsTable = {
  elements: TFeature[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FeatureTogglesTable: FC<TConfigurationsTable> = ({ elements }) => {
  return (
    <Table highlightOnHover verticalSpacing='xs' fontSize='sm'>
      <thead style={{ background: '#E8ECF0' }}>
        <tr>
          <th>
            <TableColumnTitle>Feature</TableColumnTitle>
          </th>
          <th>
            <TableColumnTitle>Активность</TableColumnTitle>
          </th>
          <th>
            <TableColumnTitle>Описание</TableColumnTitle>
          </th>
          <th>
            <TableColumnTitle>Стратегия</TableColumnTitle>
          </th>
          <th style={{ maxWidth: '50px' }}></th>
        </tr>
      </thead>

      <TableBody {...{ items: elements }} />
    </Table>
  );
};

export default FeatureTogglesTable;
