import { StatusTypes } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

export const REMOVE_QUESTION_MODAL_TEXT = {
  title: 'Отклонить вопрос?',
  text: 'Вопрос будет отклонён и не будет отображаться на сайте. В личном кабинете пользователя отзыв останется в статусе “Отклонён”',
};

export const DISAPPROVE_BUTTON_LABELS: Partial<Record<StatusTypes, string>> = {
  new: 'Не публиковать на сайте',
  published: 'Снять с публикации',
  hidden: 'Сохранить и не публиковать',
};

export const APPROVE_BUTTON_LABELS: Partial<Record<StatusTypes, string>> = {
  new: 'Опубликовать на сайте',
  published: 'Сохранить изменения',
  hidden: 'Опубликовать на сайте',
};

export const STATUS_LABELS: Record<StatusTypes, string> = {
  new: 'новый',
  published: 'опубликовано',
  hidden: 'скрыто',
  removal: 'удаление',
  delete: 'удалено',
};
