import { TAccept } from '@/ui/components/FileLoader/FileLoader.types';

export const getFileLoaderAcceptType = (accept: TAccept) => {
  switch (accept) {
    case 'images':
      return 'defaultImage';
    case 'docs':
      return 'default';
    case 'catalogTagsFiles':
      return 'catalogTagsFiles';
  }
};
