import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useLocalStorageRoute = () => {
  const navigate = useNavigate();
  const params = useLocation();

  useEffect(() => {
    const storedRoute = localStorage.getItem('lastRoute');
    if (storedRoute && params.pathname === storedRoute) {
      navigate(storedRoute);
    }
  }, [navigate, params.pathname]);
};
