export type TFeature = {
  code: string;
  coveragePercentage: number;
  description: string;
  groups: string[] | [];
  name: string;
  status: EStatusFeature;
  strategy: string;
};

type TFeatureUpdate = {
  code: string;
  isActive: boolean;
  coveragePercentage: number;
  groups: string[];
};

export type TFeatureUpdateResponse = TFeatureUpdate;

export type TFeatureResponse<T> = {
  code: string;
  payload: {
    items: T;
  };
  message: string;
};

export enum EStatusFeature {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PARTIAL_ACTIVE = 'partial-active',
}

export enum EStatusFeatureToggle {
  'inactive',
  'active',
  'partial-active',
}
