export enum MaterialsModalsEnum {
  MATERIALS_ACTIVE_STATUS_MODAL = 'MATERIALS_ACTIVE_STATUS_MODAL',
}

export const itemsPerPageOptions = [
  { value: '10', label: '10' },
  { value: '16', label: '16' },
  { value: '32', label: '32' },
  { value: '64', label: '64' },
];

export const REQUEST_MATERIALS_DELAY = 1500;
