import { FC } from 'react';
import { MultiSelect } from '@mantine/core';

import { DictionaryMultiSelectProps } from './DictionaryMultiSelect.types';

import { useDictionaryOptions } from '@/store/slices/dictionary';

export const DictionaryMultiSelect: FC<DictionaryMultiSelectProps> = ({
  dictionary,
  idField,
  disabled,
  ...props
}) => {
  const { data } = useDictionaryOptions(dictionary, idField);

  return <MultiSelect data={data} disabled={disabled || !data.length} {...props} />;
};
