import * as Yup from 'yup';

import {
  BUTTON_TEXT_MAX_LENGTH,
  DEFAULT_MIN_CLICKS_VALUE,
  DEFAULT_MIN_VIEWS_VALUE,
  DESCRIPTION_MAX_LENGTH,
  EBannerFormFields,
  ERID_MAX_LENGTH,
  LINK_MAX_LENGTH,
  ORGANIZATION_MAX_LENGTH,
  PROMOCODE_MAX_LENGTH,
  SUB_TITLE_MAX_LENGTH,
  TITLE_MAX_LENGTH,
} from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const bannerFormSchema = Yup.object({
  [EBannerFormFields.IS_ACTIVE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EBannerFormFields.DATE_ACTIVE_FROM_DAYS]: Yup.string().required(ERR_REQUIRED_TEXT).nullable(),
  [EBannerFormFields.DATE_ACTIVE_FROM_HOURS]: Yup.string().required(ERR_REQUIRED_TEXT).nullable(),
  [EBannerFormFields.DATE_ACTIVE_TO_DAYS]: Yup.string().required(ERR_REQUIRED_TEXT).nullable(),
  [EBannerFormFields.DATE_ACTIVE_TO_HOURS]: Yup.string().required(ERR_REQUIRED_TEXT).nullable(),
  [EBannerFormFields.DATE_FROM]: Yup.string().nullable(),
  [EBannerFormFields.DATE_TO]: Yup.string().nullable(),
  [EBannerFormFields.SORT]: Yup.number().nullable(),
  [EBannerFormFields.SITE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EBannerFormFields.PAGE]: Yup.array().of(Yup.string()).min(1, ERR_REQUIRED_TEXT),
  [EBannerFormFields.PLACE]: Yup.array().of(Yup.string()).min(1, ERR_REQUIRED_TEXT),
  [EBannerFormFields.TITLE]: Yup.string().max(
    TITLE_MAX_LENGTH,
    `Максимальное количество символов ${TITLE_MAX_LENGTH}`,
  ),
  [EBannerFormFields.SUB_TITLE]: Yup.string()
    .max(SUB_TITLE_MAX_LENGTH, `Максимальное количество символов ${SUB_TITLE_MAX_LENGTH}`)
    .nullable(),
  [EBannerFormFields.PROMOCODE]: Yup.string()
    .max(PROMOCODE_MAX_LENGTH, `Максимальное количество символов ${PROMOCODE_MAX_LENGTH}`)
    .nullable(),
  [EBannerFormFields.DESCRIPTION]: Yup.string()
    .max(DESCRIPTION_MAX_LENGTH, `Максимальное количество символов ${DESCRIPTION_MAX_LENGTH}`)
    .nullable(),
  [EBannerFormFields.BUTTON_TEXT]: Yup.string()
    .max(BUTTON_TEXT_MAX_LENGTH, `Максимальное количество символов ${BUTTON_TEXT_MAX_LENGTH}`)
    .nullable(),
  [EBannerFormFields.BUTTON_LINK]: Yup.string()
    .max(LINK_MAX_LENGTH, `Максимальное количество символов ${LINK_MAX_LENGTH}`)
    .nullable(),
  [EBannerFormFields.ERID]: Yup.string()
    .max(ERID_MAX_LENGTH, `Максимальное количество символов ${ERID_MAX_LENGTH}`)
    .nullable(),
  [EBannerFormFields.ORGANIZATION_NAME]: Yup.string()
    .max(ORGANIZATION_MAX_LENGTH, `Максимальное количество символов ${ORGANIZATION_MAX_LENGTH}`)
    .nullable(),
  [EBannerFormFields.BANNER_LINK]: Yup.string().max(
    LINK_MAX_LENGTH,
    `Максимальное количество символов ${LINK_MAX_LENGTH}`,
  ),
  [EBannerFormFields.BACKGROUND]: Yup.string().nullable(),
  [EBannerFormFields.NUMBER_OF_VIEW_BEFORE_HIDING]: Yup.number()
    .min(DEFAULT_MIN_VIEWS_VALUE, `Минимальное количество просмотров ${DEFAULT_MIN_VIEWS_VALUE}`)
    .nullable(),
  [EBannerFormFields.NUMBER_OF_CLICK_BEFORE_HIDING]: Yup.number()
    .min(DEFAULT_MIN_CLICKS_VALUE, `Минимальное количество просмотров ${DEFAULT_MIN_CLICKS_VALUE}`)
    .nullable(),
  [EBannerFormFields.DESKTOP_IMAGE_ID]: Yup.string().nullable(),
  [EBannerFormFields.TABLET_IMAGE_ID]: Yup.string().nullable(),
  [EBannerFormFields.MOBILE_IMAGE_ID]: Yup.string().nullable(),
});
