import React, { FC, useEffect, useRef } from 'react';
import { Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { SnippetValues } from '@prosv/core/types/snippets';
import { QuestionSnippet as QuestionSnippetType } from '@prosv/core/types/snippets/question';
import { Editor } from '@tiptap/react';

import { QuestionSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet.constants';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';

interface Props {
  form: UseFormReturnType<SnippetValues<QuestionSnippetType>>;
  index: number;
}

export const QuestionTextEditorField: FC<Props> = ({ form, index }) => {
  const editorRef = useRef<Editor | undefined | null>();
  const blockValue = form.values.questions[index];

  useEffect(() => {
    if (editorRef.current?.getHTML() !== form.values.questions[index].answer) {
      editorRef.current?.commands.setContent(form.values.questions[index].answer);
    }
  }, [form.values.questions, index, blockValue]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gridColumn: 'span 12',
        minHeight: 200,
        position: 'relative',
      }}
    >
      <label style={{ fontSize: 14 }}>Описание</label>
      <TextEditor
        placeholder='Введите описание'
        defaultTextValue={
          form.getInputProps(`questions.${index}.${QuestionSnippetFormFields.ANSWER}`).value
        }
        editorRef={editorRef}
        changeFormValue={(value) => {
          form.setFieldValue(`questions.${index}.${QuestionSnippetFormFields.ANSWER}`, value);
        }}
      />
    </Box>
  );
};
