import { useCallback } from 'react';
import { SnippetVariant } from '@prosv/core/types/snippets';

import { disableSnippetSelector, useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

export const useDisabledCallback = () => {
  const state = useStoreContentEditor(disableSnippetSelector);
  const getDisabledCatalogSnippet = useCallback(
    (variant: SnippetVariant) => {
      if (variant === 'catalog') {
        return state;
      } else {
        return false;
      }
    },
    [state],
  );
  return (variant: SnippetVariant) => getDisabledCatalogSnippet(variant);
};
