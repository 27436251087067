import type { TServicesResponse } from '@/types';
import { TPatchServiceSettingsRequest } from '@/types';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

import api from '../instance';
import { URLS } from '../urls';

export const getGeneralSetting = () =>
  api.get<TServicesResponse>(URLS.generalSettings.getGeneralSettings());

export const patchGeneralSetting = (body: TPatchServiceSettingsRequest) =>
  api.patch<TServicesResponse>(URLS.generalSettings.patchGeneralSettings(), body);

/*TRY ...CATCH */
export const tryGetGeneralSetting = () =>
  getGeneralSetting()
    .then((res) => res.data)
    .catch(axiosErrorHandler);

export const tryPatchGeneralSetting = (body: TPatchServiceSettingsRequest) =>
  patchGeneralSetting(body)
    .then((res) => res.data)
    .catch(axiosErrorHandler);
