import { FC, ReactNode } from 'react';
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import { Group, Text, UnstyledButton, UnstyledButtonProps } from '@mantine/core';

import { setLastRoute } from '@/utils/setLastRoute';

import styles from './LayoutNavbarButton.module.scss';

interface ILayoutNavbarButton extends UnstyledButtonProps {
  label: string;
  to?: string;
  opened?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

const LayoutNavbarButton: FC<ILayoutNavbarButton> = ({
  label,
  to,
  opened = true,
  icon,
  onClick = () => {},
}) => {
  let resolved = useResolvedPath(to ? to : '');
  let match = useMatch({ path: resolved.pathname, end: false });
  const { pathname } = useLocation();

  const onClickHandler = () => {
    if (pathname) {
      setLastRoute(pathname);
    }
    onClick();
  };

  return (
    <>
      {to ? (
        <UnstyledButton
          title={label}
          component={Link}
          to={to}
          className={cn(styles.root, { [styles.active]: match })}
          py={12}
          onClick={onClickHandler}
          sx={({ colors }) => ({
            ':hover': {
              '*': { color: colors.primary[7] },
              ':after': {
                backgroundColor: colors.primary[7],
              },
            },
          })}
        >
          <Group spacing={10} noWrap>
            {icon}
            {opened && (
              <Text
                lineClamp={1}
                sx={(theme) => ({
                  lineHeight: 1.125,
                  paddingBottom: '2px',
                  color: match ? theme.colors.primary[0] : theme.colors.black[0],
                  ':hover': {
                    color: theme.colors.primary[7],
                  },
                })}
              >
                {label}
              </Text>
            )}
          </Group>
        </UnstyledButton>
      ) : (
        <UnstyledButton
          title={label}
          className={cn(styles.root, { [styles.active]: match && to })}
          py={12}
          onClick={onClick}
          sx={({ colors }) => ({
            ':hover': {
              '*': { color: colors.primary[7] },
            },
          })}
        >
          <Group spacing={10} noWrap>
            {icon}
            {opened && (
              <Text lineClamp={1} sx={{ lineHeight: 1.125, paddingBottom: '2px' }}>
                {label}
              </Text>
            )}
          </Group>
        </UnstyledButton>
      )}
    </>
  );
};

export default LayoutNavbarButton;
