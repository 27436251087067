import { TSupportSettingItem } from '@/types';

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from '@mantine/form';

import { TGeneralForm } from '@/pages/GeneralSettings/GeneralSettings.types';
import {
  fetchSupportSettingSelector,
  optionsSettingSelector,
  supportSettingSelector,
} from '@/store/slices/generalSettings/generalSettings';

export const useGeneralSettingsState = () => {
  const isLoadingServices = useSelector(fetchSupportSettingSelector);
  const services = useSelector(supportSettingSelector);
  const optionServices = useSelector(optionsSettingSelector);
  const defaultOptions = optionServices?.[0]?.value;

  const [selectedServiceFormValue, setSelectedServiceFormValue] = React.useState<string | null>(
    null,
  );
  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);

  const isSetDefaultOption = useRef(false);

  useEffect(() => {
    if (
      defaultOptions &&
      defaultOptions !== selectedServiceFormValue &&
      !isSetDefaultOption.current
    ) {
      setSelectedServiceFormValue(defaultOptions);
      isSetDefaultOption.current = true;
    }
  }, [defaultOptions]);

  const form = useForm<Partial<TGeneralForm>>({
    initialValues: {},
  });

  const selectedFormData = React.useMemo(() => {
    if (selectedServiceFormValue && services) {
      const formData = (services[selectedServiceFormValue] as TSupportSettingItem) || null;
      const entriesFormData = Object.entries(formData) as [string, TSupportSettingItem][] | [];
      const formDataList = entriesFormData.map(([key, value]) => ({ key, ...value }));

      const initialValues = formDataList?.reduce((acc, curr) => {
        return { ...acc, [curr.key]: curr.value };
      }, {});
      form.setValues(initialValues || {});

      return formDataList;
    }

    return null;
  }, [selectedServiceFormValue, services]);

  return {
    isLoading: isLoadingServices || isSubmittingForm,
    services,
    selectedServiceFormValue,
    setSelectedServiceFormValue,
    form,
    optionServices,
    selectedFormData,
    isSubmittingForm,
    setIsSubmittingForm,
  };
};
