import React, { useEffect, useState } from 'react';
import { Badge, Text } from '@mantine/core';
import { NewsDetailsPayload } from '@prosv/core/types';

import { useUpdateNewsActivity } from '@/api/news/hooks';
import { useGetNews } from '@/api/news/hooks/useGetNews';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import { Loader } from '@/ui/containers/ContentEditor/Loader';

type TActivityProps = {
  status?: boolean;
  onChangeStatus?: () => void;
  newsId: string;
  canUpdate?: boolean;
};

export const ActiveStatus: React.FC<TActivityProps> = ({
  status = false,
  onChangeStatus,
  newsId,
  canUpdate,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [news, setNews] = useState<NewsDetailsPayload | undefined>();
  const [isActive, setActive] = useState<boolean>(status);
  const { isLoading, getNews } = useGetNews();
  const { isLoading: isUpdateLoading, updateNewsActivity } = useUpdateNewsActivity();

  useEffect(() => {
    if (showConfirm) {
      getNews(newsId).then((newsResponse) => setNews(newsResponse?.payload));
    }
  }, [showConfirm]);

  if (status === undefined) return null;

  const handleChangeStatus = async () => {
    setDisabled(true);
    if (news) {
      updateNewsActivity(newsId, !news.isActive);
      setActive(!news.isActive);
      onChangeStatus?.();
      setShowConfirm(false);
      setDisabled(false);
    }
  };

  const textTitle = isActive ? 'Убрать активность?' : 'Активировать новость?';

  const textDescription = isActive
    ? 'Новость перестанет отображаться на сайте. Её можно будет активировать в списке новостей или на детальной странице'
    : 'Новость станет отображаться на сайте. Её можно будет деактивировать в списке новостей или на детальной странице';

  return (
    <>
      <Loader loading={isLoading || isUpdateLoading} blur />
      <Badge
        color={isActive ? 'green' : 'greyLine'}
        size='lg'
        radius='sm'
        variant='filled'
        p={0}
        sx={{ width: 88, cursor: canUpdate ? 'pointer' : 'default' }}
        onClick={() => {
          if (canUpdate) setShowConfirm(true);
        }}
      >
        <Text
          size='sm'
          color='black'
          align='center'
          sx={{ textTransform: 'none', fontWeight: 400 }}
        >
          {isActive ? 'Активно' : 'Неактивно'}
        </Text>
      </Badge>

      {showConfirm && (
        <ModalConfirm
          title={textTitle}
          text={textDescription}
          opened={showConfirm}
          onClose={() => setShowConfirm(false)}
          confirmHandler={handleChangeStatus}
          disabled={disabled}
        />
      )}
    </>
  );
};
