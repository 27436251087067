import { Question, QuestionSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { QuestionSnippetFormFields } from './QuestionSnippet.constants';

export const getQuestionSnippetDefaultValues = (
  defaultValues?: QuestionSnippet,
): SnippetValues<QuestionSnippet> => {
  const getDefaultQuestion = (question?: Question) => {
    return {
      [QuestionSnippetFormFields.QUESTION]: question?.question || '',
      [QuestionSnippetFormFields.ANSWER]: question?.answer || '',
    };
  };

  const questions = defaultValues?.questions?.map(getDefaultQuestion);
  return {
    id: defaultValues?.id || uuid_v4(),
    title: defaultValues?.title || '',
    questions: questions || [getDefaultQuestion()],
  };
};
