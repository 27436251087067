import React from 'react';
import { useParams } from 'react-router-dom';

import { GeneralForm } from '@/ui/containers/SupportContainer/detail/components/GeneralForm';

import { SupportDetailResponse } from '@/store/slices/support/supportDetailsTypes';

export const GeneralTab = ({
  data,
  categoryOptions,
}: {
  data?: SupportDetailResponse;
  categoryOptions: { value: number | string; label: string }[];
}) => {
  const params = useParams();

  return (
    <GeneralForm
      type={params?.id ? 'edit' : 'create'}
      categoryOptions={categoryOptions}
      data={data}
    />
  );
};
