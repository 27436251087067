import { ImageSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { EImageModalFormFields } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal/ImageSnippetModal.constants';

export const getInitialValues = (defaultValues?: ImageSnippet): SnippetValues<ImageSnippet> => {
  return {
    [EImageModalFormFields.ID]: defaultValues?.id || uuid_v4(),
    [EImageModalFormFields.FILE]: defaultValues?.file || {
      id: '',
      name: '',
      path: '',
    },
    [EImageModalFormFields.MAX_WIDTH]: defaultValues?.maxWidth || '',
    [EImageModalFormFields.IMAGE_SOURCE]: defaultValues?.imageSource || '',
    [EImageModalFormFields.IMAGE_CAPTION]: defaultValues?.imageCaption || '',
    [EImageModalFormFields.ALIGNMENT]: defaultValues?.alignment || 'center',
    [EImageModalFormFields.IS_FIXED_HEIGHT]: defaultValues?.isFixedHeight,
  };
};
