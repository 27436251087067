import { IGlobalSettingItem } from '@/ui/containers/GlobalSettingsContainer/GlobalSettingsContainer.types';

export const tableList: IGlobalSettingItem[] = [
  {
    name: 'Корпоративный сайт',
    type: 'corp',
  },
  {
    name: 'Пользовательский сайт',
    type: 'client',
  },
];
