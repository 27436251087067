import { Children, FC, MouseEvent, ReactElement, useState } from 'react';
import { ActionIcon, Box, Collapse, Group } from '@mantine/core';
import { Plus } from 'tabler-icons-react';

interface IProps {
  children: ReactElement[] | null;
  name: string;
  addItemHandler: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const MainMenu: FC<IProps> = ({ children, name, addItemHandler }) => {
  const isCollapse = !!Children.count(children);

  const [opened, setOpened] = useState<boolean>(false);

  const handleOpen = () => {
    setOpened((prev) => !prev);
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          width: '99%',
          border: '1px solid #E8ECF0',
          borderRadius: 4,
          padding: '10px 12px 12px 12px',
          cursor: 'pointer',
          display: 'flex',
          marginBottom: 4,
          marginTop: 8,
          '.mantine-Group-root': {
            marginLeft: 'auto',
          },
        }}
        className='can'
      >
        {isCollapse && (
          <Box
            sx={{
              marginRight: 12,
              transform: !opened ? 'rotate(180deg)' : '',
            }}
            className='can'
          >
            <svg
              width='12'
              height='8'
              viewBox='0 0 12 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='can'
            >
              <path
                className='can'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.7489 7.17754C11.4142 7.51227 10.8715 7.51227 10.5368 7.17754L6 2.64077L1.46323 7.17754C1.1285 7.51227 0.585787 7.51227 0.251052 7.17754C-0.083684 6.8428 -0.083684 6.30009 0.251052 5.96536L5.39391 0.822501C5.72864 0.487766 6.27136 0.487766 6.60609 0.822501L11.7489 5.96535C12.0837 6.30009 12.0837 6.8428 11.7489 7.17754Z'
                fill='#121212'
              />
            </svg>
          </Box>
        )}

        <span className='can'>
          <b className='can'>{name} &nbsp;</b>
        </span>
        <Group position={'right'}>
          <ActionIcon name='Добавить' title='Добавить' onClick={addItemHandler}>
            <Plus size={20} color='gray' />
          </ActionIcon>
        </Group>
      </Box>
      <Box
        sx={{
          marginLeft: 40,
        }}
      >
        {children && (
          <Collapse in={opened} transitionDuration={100} transitionTimingFunction='linear'>
            {children}
          </Collapse>
        )}
      </Box>
    </>
  );
};
