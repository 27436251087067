import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { QuestionsAndReviewForm } from '@/ui/components/ReviewAndQuestionsForm/ReviewAndQuestionsForm';
import { ReviewResponseType } from '@/ui/containers/Reviews/types';

import { useReviewDetailStore } from '@/store/slices/reviews/reviewsSlice';

export const ReviewForm = ({
  data,
  isLoading,
}: {
  data: ReviewResponseType;
  isLoading: boolean;
}) => {
  const values = {
    status: data?.payload.status,
    type: data?.payload.type,
    rating: data?.payload.rating,
    reviewText: data?.payload?.text,
    attachments: data?.payload?.attachments || [],
  };

  const [, setAnswer] = useReviewDetailStore(
    (state) => [state.params.answer, state.setAnswer],
    shallow,
  );

  useEffect(() => {
    if (data && data?.payload?.reply && data?.payload?.reply?.text) {
      setAnswer(data?.payload?.reply?.text);
    }
  }, [data]);

  useEffect(() => {
    return () => setAnswer('');
  }, []);

  return <QuestionsAndReviewForm {...values} isLoading={isLoading} pageType={'review'} />;
};
