import dayjs from 'dayjs';

export const getFormatDateEndDay = (date?: string | Date | null) => {
  if (!date) {
    return null;
  } else {
    return dayjs(date).format('YYYY-MM-DDT23:59:59Z');
  }
};
export const getFormatDateStartDay = (date?: string | Date | null) => {
  if (!date) {
    return null;
  } else {
    return dayjs(date).format('YYYY-MM-DDT00:00:00Z');
  }
};
