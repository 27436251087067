import { IFile } from '@/types/file';

import React, { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import { Box, Button, Input, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import notify from '@/utils/notify';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';

export const FileUpload = () => {
  const sessionID = useRef(uuid_v4());
  const [file, setFile] = useState<IFile | null>(null);
  const formId = 'upload-form';
  const mediaStorageUrl = process.env.MEDIA_STORAGE_URL as string;

  const [fileName, setFileName] = useState('');

  const form = useForm({
    initialValues: {
      file: {
        id: '',
        path: '',
        name: '',
      },
    },
  });

  const copyHandler = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(mediaStorageUrl + file?.config.previewConfig.path);
      notify({ message: `Ссылка на файл скопирована в буфер обмена`, type: 'success' });
    } catch (error) {
      notify({ message: `Ошибка при копировании ссылки`, type: 'error' });
    }
  };

  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.values.file = {
      id,
      path,
      name,
    };
  };
  const handleRemove = () => {
    form.values.file = {
      id: '',
      path: '',
      name: '',
    };
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  const imgData = getImageData({
    id: form.values.file.id,
    name: form.values.file.name,
    path: form.values.file.path.replace(process.env.MEDIA_STORAGE_URL as string, ''),
  });

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        title='Загрузка изображений'
        rightButton={
          <Button sx={{ width: '200px' }} type='submit' form={formId}>
            Сохранить
          </Button>
        }
      />

      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <form
          id={formId}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
          onSubmit={form.onSubmit((values) => {
            if (values.file.id) {
              replaceTempFile({
                query: {
                  sessionId: sessionID.current,
                  ids: [values.file.id],
                },
              }).then((fileResponse) => {
                setFile(fileResponse?.payload.items[0] || null);
                handleRemove();
                form.reset();
              });
            }
          })}
        >
          <Input placeholder='Название файла' value={fileName} onChange={onInputChange} />
          <FileLoader
            fileName={fileName}
            sessionID={sessionID.current}
            savedFormFiles={imgData}
            placeholder='Перетащите или добавьте файл'
            onChange={handleChange}
            onRemove={handleRemove}
          />
          {file && (
            <>
              <p>Ссылка на файл {mediaStorageUrl + file.config.previewConfig.path}</p>
              <Button sx={{ width: '25%' }} onClick={copyHandler}>
                Скопировать ссылку
              </Button>
            </>
          )}
        </form>
      </Paper>
    </Box>
  );
};
