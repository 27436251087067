import { FC } from 'react';
import { Select } from '@mantine/core';

import { useAudioAppsOptions } from './AudioAppSelect.hooks';
import { AudioAppSelectProps } from './AudioAppSelect.types';

export const AudioAppSelect: FC<AudioAppSelectProps> = ({ disabled, ...props }) => {
  const data = useAudioAppsOptions();

  return <Select data={data} disabled={disabled || !data.length} {...props} />;
};
