import { EffectCallback, useEffect } from 'react';

import { useCreateDraft } from '@/api/draft/useCreateDraft';
import { useGetNews } from '@/api/news/hooks/useGetNews';

import {
  setSelectorDefaultFormData,
  setSelectorDraftId,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { createNewDraftData } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { INewsCreationState } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.types';

export const useModeNews = (state: INewsCreationState, id: string = '') => {
  const createDraft = useCreateDraft();
  const { getNews } = useGetNews();
  const setDraftId = useStoreContentEditor(setSelectorDraftId);
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  let effect: EffectCallback = () => {};
  if (state.isNewNews) {
    effect = () => {
      createDraft.createDraft(createNewDraftData).then((res) => {
        if (res?.payload.draftId) {
          setDraftId(res?.payload.draftId);
        }
      });
      return () => {
        setDefaultFormData(null);
      };
    };
  }

  if (!state.news && id) {
    effect = () => {
      getNews(id).then((newsResponse) => {
        if (newsResponse) {
          state.setNews(newsResponse.payload);
          setDraftId(newsResponse.payload.draftId);
        }
      });
    };
  }

  useEffect(effect, []);
};
