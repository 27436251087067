import React, {useEffect} from 'react';

import {
  setSelectGridDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { SizeGrid } from './GridCard';
import { DEFAULT_OPTIONS } from './SelectGrid.constants';

export const useStateSelectGridModal = () => {
  const setSelectGridData = useStoreContentEditor(setSelectGridDataSelector);
  const selectGridData = useStoreContentEditor((state) => state.selectGridData);
  const grid = useStoreContentEditor((state) => state.savedContentData?.grid);

  const [selectedNumbersBlock, setSelectedNumbersBlock] = React.useState<null | SizeGrid>(null);
  const [gapColumnSize, setGapColumnSize] = React.useState<string>(DEFAULT_OPTIONS.value);
  const [gapRowSize, setGapRowSize] = React.useState<string>(DEFAULT_OPTIONS.value);
  const [backgroundColor, setBackgroundColor] = React.useState<string>('');

  const disabledReadyButton = selectedNumbersBlock === null;

  useEffect(() => {
    if (grid && selectGridData?.isEdit) {
      const editableBlock = grid[selectGridData.rowId]
      setSelectedNumbersBlock(editableBlock.blocks.length as SizeGrid)
      setGapColumnSize(editableBlock.gapColumnSize)
      setGapRowSize(editableBlock.gapRowSize)
      setBackgroundColor(editableBlock.backgroundColor)
    }
  }, [selectGridData?.isEdit])

  return {
    setSelectGridData,
    selectGridData,
    selectedNumbersBlock,
    setSelectedNumbersBlock,
    gapColumnSize,
    setGapColumnSize,
    gapRowSize,
    setGapRowSize,
    disabledReadyButton,
    backgroundColor,
    setBackgroundColor,
  };
};
