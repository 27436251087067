import { LimitType, MenuNameType } from '@/ui/containers/MenuContainer/MenuContainer.types';

export enum MenuItemTypeEnum {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum MenuContextEnum {
  CORP = 'corp',
  USER = 'user',
}

export const MenuOppositeContext = {
  user: 'corp',
  corp: 'user',
};

export const limitNestingLevel: LimitType = {
  'top-menu': 2,
  'bottom-menu': 2,
  'burger-menu': 1,
};

export const limitNestingItems: LimitType = {
  'top-menu': 10,
  'bottom-menu': 7,
  'burger-menu': 7,
  'burger-mobile': 15,
};

export const menuName: MenuNameType = {
  'top-menu': 'Верхнее меню',
  'bottom-menu': 'Нижнее меню',
  'burger-menu': 'Бургер меню',
};

export enum DragItemTypes {
  MENU = 'menu',
}

export enum MenuTypeEnum {
  TOP_MENU = 'top-menu',
  BOTTOM_MENU = 'bottom-menu',
  BURGER_MENU = 'burger-menu',
}

export const DEFAULT_LIMIT_ITEMS = 4;

export const NESTED_DEFAULT_LIMIT = 1;
