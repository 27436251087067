import React from 'react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';

import { useColumns } from '@/pages/Banners/BannersListing/BannerListing.hooks';
import {
  bannersFilterActions,
  CreateFilterSlice,
  IBannerResponse,
} from '@/store/slices/banners/bannersSlice';

export const BannerListingTable = ({
  total,
  data,
  status,
  getBannersListing,
}: {
  total?: number;
  status?: boolean;
  data?: IBannerResponse[];
  getBannersListing: (params: CreateFilterSlice) => void;
}) => {
  const dispatch = useAppDispatch();
  const { setFilter } = bannersFilterActions;
  const columns = useColumns(getBannersListing);
  const filters = useAppSelector((state) => state.bannersFilter);
  const handleChange = (value: Partial<CreateFilterSlice>) => {
    dispatch(setFilter(value));
  };

  const onChangeLimit = (value: number) => {
    dispatch(setFilter({ limit: value.toString() }));
  };

  return (
    <CustomTable
      onChangePagination={(v) => handleChange({ currentPage: v })}
      onChangeLimit={onChangeLimit}
      limit={Number(filters.limit)}
      noData='У вас нет банеров'
      total={total || 0}
      currentPage={filters.currentPage}
      status={status ? 'loading' : 'loaded'}
      data={data || []}
      columns={columns}
    />
  );
};
