import React from 'react';
import Select, { ActionMeta, MultiValue } from 'react-select';

import { useMultiSelectCustomStyles } from './MultiSelect.styles';
import type { TMultiSelectProps, TOption } from './MultiSelect.types';
import { OptionComponent } from './OptionComponent';
import { ValueContainer } from './ValueContainer';

export const MultiSelect: React.FC<TMultiSelectProps> = (props) => {
  const [values, setValues] = React.useState<MultiValue<TOption>>([]);

  const customStyles = useMultiSelectCustomStyles({
    maxWidth: props.maxWidth,
    height: props.height,
  });

  const handleChange = (selected: MultiValue<TOption>, actionMeta: ActionMeta<TOption>) => {
    setValues(selected);
    props.onChange?.(selected, actionMeta);
  };

  return (
    <Select
      value={props.value || values}
      onChange={handleChange as any}
      options={props.options}
      isMulti={props.isMulti ?? true}
      isDisabled={props.isDisabled || false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      styles={customStyles}
      placeholder={props.label}
      maxMenuHeight={props.maxDropdownHeight || 200}
      isClearable={false}
      components={{ ValueContainer, Option: OptionComponent, IndicatorSeparator: () => null }}
      // @ts-ignore
      label={props.label}
    />
  );
};
