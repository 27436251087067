/* 2023-05-10T11:57:00+03:00 => 22.08.2020 */
export const formatDate = (date?: string, withTime = false): string => {
  if (!date) return '';
  const dateInstance = new Date(date);
  let timeOptions: Intl.DateTimeFormatOptions | undefined;
  if (withTime)
    timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
    };
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    ...timeOptions,
  };
  return dateInstance.toLocaleDateString('ru-RU', options);
};
