import { ILoadingStatus } from '@/types';

import React, { FC } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons';
import { nanoid } from 'nanoid';

import { cellPosition } from '@/ui/components/CustomTable/CustomTable.constants';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';
import { IDragCollectProps, IDraggableItem } from '@/ui/components/DnDBlock/DnDBlock.types';

interface IProps {
  index: number;
  columns: ITableColumns[];
  onRenderClick?: (data: any) => void;
  row: Record<string, any>;
  status: ILoadingStatus;
  moveItem: (pos: number, index: number) => void;
  id: string;
}

export const DraggbleTableRow: FC<IProps> = ({
  index,
  columns,
  onRenderClick,
  row,
  id,
  moveItem,
}) => {
  const [, drag] = useDrag<IDraggableItem, IDragCollectProps, IDragCollectProps>({
    type: 'DRAGGABLE_COMPONENT',
    item: { type: 'DRAGGABLE_COMPONENT', id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop<IDraggableItem, void, unknown>({
    accept: 'DRAGGABLE_COMPONENT',
    drop(item) {
      moveItem(item.index, index);
      item.index = index;
    },
  });

  return (
    <tr
      ref={(node) => drag(drop(node))}
      style={{
        position: 'relative',
      }}
    >
      <Box
        component='td'
        style={{ borderBottom: 'none', padding: 0, fontSize: 'unset' }}
        sx={{ position: 'absolute', top: '50%' }}
      >
        <IconGripVertical size='16px' color='#8DA2B5' />
      </Box>
      {columns.map((column) => {
        const position = cellPosition[(column?.position || 'left') as keyof typeof cellPosition];
        return (
          <td style={column.style} key={index + column.field + nanoid()}>
            <div style={{ width: 'fit-content', ...position }}>
              {column.render
                ? column.render(
                    onRenderClick ? () => (onRenderClick ? onRenderClick(row) : null) : row,
                  )
                : row[column.field] || ' - '}
            </div>
          </td>
        );
      })}
    </tr>
  );
};
