import * as Yup from 'yup';

import notify from '@/utils/notify';

import { EProductsSnippetFormFields } from './ProductsSnippetModal.constants';

export const productsSnippetSchema = Yup.object({
  [EProductsSnippetFormFields.PAGINATION_LIMIT]: Yup.number().min(1).max(30),
  [EProductsSnippetFormFields.TITLE]: Yup.string().required('Поле обязательно для заполнения'),
  [EProductsSnippetFormFields.IS_SHOW_ALL_PRODUCTS]: Yup.boolean()
    .required('Поле обязательно для заполнения')
    .default(false),
  [EProductsSnippetFormFields.PRODUCTS]: Yup.array().test((val) => {
    const isInvalid = val?.length === 0;
    if (isInvalid) notify({ message: 'Подборка не должна быть пуста', type: 'error' });
    return !isInvalid;
  }),
});
