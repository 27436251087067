import { FC } from 'react';
import { Select } from '@mantine/core';

import { useProjectsOptions } from './ProjectSelect.hooks';
import { ProjectSelectProps } from './ProjectSelect.types';

export const ProjectSelect: FC<ProjectSelectProps> = ({ disabled, ...props }) => {
  const data = useProjectsOptions();

  return <Select data={data} disabled={disabled || !data.length} {...props} />;
};
