import { GetPermission, Permission } from '@/types/auth/permissions';
import { TServices, TServicesResponse } from '@/types/services/services';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

// export const apiGetServices = () => api.get(URLS.admin.service);

type PluginsPermissions = Record<string, Permission | Permission[]>;

interface ServicePermissions {
  name: string;
  permissions: Permission | Permission;
  plugins: PluginsPermissions;
}

const services: Record<string, ServicePermissions> = {
  configurations: {
    name: 'Сервисы конфигурации',
    permissions: 'CONFIG',
    plugins: {
      'general-settings': 'SETTING_GET_ALL',
      // Исправить скрытие страницы тоглов
      'feature-toggles': 'SETTING_GET_ALL',
      menu: 'MENU_GET_LIST',
      dictionary: 'DICTIONARY',
    },
  },
  content: {
    name: 'Создание контента',
    permissions: 'CONTENT',
    plugins: {
      news: 'NEWS_GET_LIST',
      materials: 'ARTICLE_GET_LIST',
      banners: 'BANNER_GET_LIST',
      promotions: 'PROMOTION_GET_LIST',
    },
  },
  support: {
    name: 'Сервис поддержки',
    permissions: 'SUPPORT',
    plugins: { reviews: 'REVIEW_VIEW', questions: 'QUESTION_GET_LIST' },
  },
  seo: {
    name: 'Сервис SEO',
    permissions: 'SEO',
    plugins: {
      'global-settings': 'SEO',
      redirects: 'LIST_REDIRECTS',
      'templates-meta-tags': 'LIST_SEO_TEMPLATE',
      'meta-tags-any-page': 'LIST_SEO_META',
    },
  },
};

export const apiGetServices = (getPermission: GetPermission) => {
  const data: TServices = {};

  for (const svcKey in services) {
    const { name, permissions, plugins } = services[svcKey];
    if (!getPermission(permissions)) continue;
    data[svcKey] = {
      name,
      plugins: Object.keys(plugins).filter((plugin) => getPermission(plugins[plugin])),
    };
  }

  const res: TServicesResponse = {
    data,
    status: 200,
  };

  return Promise.resolve(res);
};

export const tryGetServices = (getPermission: GetPermission) =>
  apiGetServices(getPermission)
    .then((res) => res.data)
    .catch(axiosErrorHandler);
