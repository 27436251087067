import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { deleteCookie, getCookie, setCookie } from '@/utils/cookie';
import notify from '@/utils/notify';

const api = axios.create({
  baseURL: process.env.PUBLIC_API_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { indices: false }); // param=value1&param=value2
  },
});

const requestMiddleware = async (config: any) => {
  const cookiesToken = getCookie('token') as string;
  const pathName = window.location.pathname;

  if (pathName !== '/auth' && pathName !== '/adfs-oauth') {
    // Если токена нет в cookie сбрасываем пользователя на стр входа
    if (!cookiesToken) {
      setCookie('token', '');
      window.location.assign(`/auth?redirectTo=${window.location.href}`);
    }
  }

  const tokenFromHeaders = { Authorization: `Bearer ${cookiesToken}` };

  return {
    ...config,
    ...{
      headers: {
        ...config.headers,
        ...tokenFromHeaders,
      },
    },
  };
};

api.interceptors.request.use(
  (request) => {
    // Type request middleware here
    return requestMiddleware(request);
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    // Type response middleware here
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      notify({ message: 'Ошибка авторизации', type: 'error' });
      deleteCookie('token');
      setTimeout(() => {
        window.location.assign(`/auth?redirectTo=${window.location.href}`);
      }, 1000);
    }
    return Promise.reject(error);
  },
);

type Methods = 'get' | 'put' | 'post' | 'patch' | 'delete';

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method?: Methods;
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ method, url, data, params }) => {
    try {
      return !!method ? await api[method](url, data, params) : await api.get(url, data);
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export default api;
