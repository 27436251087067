import { FormErrors } from '@mantine/form/lib/types';
import { DictionaryItem, ImageData, NewsDetailsPayload } from '@prosv/core/types';

import {
  EActivityFieldValues,
  ENewsFormFields,
  NewsFormFields,
} from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';
import { INewsFormFields } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.types';

export const getImageData = (imageData?: ImageData) => {
  if (!imageData) return [];
  const { config, name, id } = imageData;
  const preview = process.env.MEDIA_STORAGE_URL + config.previewConfig.path ?? '';
  return [{ preview, name, id }];
};

const getDate = (date?: Date | string | null) => (date ? new Date(date) : '');

const getTagsToFormFields = (tags?: DictionaryItem[] | null) => tags?.map((tag) => tag.id) || [];

const getNewsStatus = (isActive?: boolean) =>
  isActive ? EActivityFieldValues.ACTIVE : EActivityFieldValues.NOT_ACTIVE;

export const getDefaultValues = (news: NewsDetailsPayload | null): INewsFormFields => {
  return {
    [ENewsFormFields.URL_CODE]: news?.urlCode || '',
    [ENewsFormFields.IS_ACTIVE]: news?.isActive
      ? getNewsStatus(news?.isActive)
      : EActivityFieldValues.NOT_ACTIVE,
    [ENewsFormFields.TITLE]: news?.title || '',
    [ENewsFormFields.DATA_ACTIVE_FROM_DAYS]: getDate(news?.dateActiveFrom.value) || '',
    [ENewsFormFields.DATA_ACTIVE_FROM_HOURS]: getDate(news?.dateActiveFrom.value) || '',
    [ENewsFormFields.DATA_ACTIVE_TO]: getDate(news?.dateActiveTo?.value) || '',
    [ENewsFormFields.DISPLAY_ON_SITES]: news?.displayOnSites || 'corp',
    [ENewsFormFields.IS_MAIN_NEWS]: news?.isMainNews || false,
    image: {
      [ENewsFormFields.IMAGE_ID]: news?.image?.imageData.id || '',
      [ENewsFormFields.IMAGE_ALT]: news?.image?.alt || '',
      [ENewsFormFields.IMAGE_TITLE]: news?.image?.title || '',
    },
    [ENewsFormFields.DRAFT_ID]: news?.draftId || '',
    [ENewsFormFields.SHORT_DESCRIPTION]: news?.shortDescription || '',
    [ENewsFormFields.CATEGORY]: news?.category?.id || '',
    [ENewsFormFields.THEME]: news?.theme?.id || '',
    [ENewsFormFields.DIVISION]: news?.division?.id || '',
    [ENewsFormFields.COMPANY]: news?.company?.id || '',
    [ENewsFormFields.TAGS]: getTagsToFormFields(news?.tags) || [],
    [ENewsFormFields.EDU_LEVEL]: getTagsToFormFields(news?.educationLevels) || [],
    [ENewsFormFields.SERIES_LINE_UMK]: getTagsToFormFields(news?.umks) || [],
    [ENewsFormFields.SUBJECT]: getTagsToFormFields(news?.subjects) || [],
    [ENewsFormFields.DIRECTION]: getTagsToFormFields(news?.educationDirections) || [],
    [ENewsFormFields.DIGITAL_SERVICE]: getTagsToFormFields(news?.digitalServices) || [],
    [ENewsFormFields.READING_TIME]: news?.readingTime || 0,
    [ENewsFormFields.IS_DISPLAY_VIEWS]: news?.isDisplayViews || false,
    [ENewsFormFields.MODIFIED_VIEWS_COUNT]: news?.modifiedViewsCount || 0,
    [ENewsFormFields.TARGET_PAGES]: news?.targetPages || ['all'],
  };
};

export const generateInvalidFieldErrors = (errors: FormErrors): string[] => {
  const errorsMessage: string[] = [];
  for (const key in errors) {
    errorsMessage.push(`${NewsFormFields[key as keyof typeof NewsFormFields]} : ${errors[key]}`);
  }
  return errorsMessage;
};
