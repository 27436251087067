import * as Yup from 'yup';

import { EDownloadsSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal/DownloadsSnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const downloadSnippetSchema = Yup.object({
  [EDownloadsSnippetFormFields.TITLE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EDownloadsSnippetFormFields.FILES]: Yup.array(
    Yup.object({
      name: Yup.string().required(ERR_REQUIRED_TEXT),
      file: Yup.object({
        id: Yup.string().required(ERR_REQUIRED_TEXT),
      }),
    }),
  ).max(10),
});
