export const getFormatDate = (value?: string) => {
  let date;
  if (value) {
    date = new Date(value);
  } else {
    date = new Date();
  }
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};
