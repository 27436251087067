import React, { FC } from 'react';
import { Input, InputBase, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Gallery, GallerySnippet, SnippetValues } from '@prosv/core/types/snippets';
import { Trash } from 'tabler-icons-react';

import { S3_STORAGE_LINK } from '@/constants/common';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { EGallerySnippetFormFields } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.constants';

interface IProps {
  index: number;
  form: UseFormReturnType<SnippetValues<GallerySnippet>>;
  slide: Gallery;
  sessionID: string;
}

export const GalleryItemUI: FC<IProps> = ({ sessionID, form, index }) => {
  const handleRemoveVideo = () => {
    form.values.slides?.splice(index, 1);
    form.setValues(form.values);
  };

  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    if (form.values.slides?.[index]) {
      form.values.slides[index] = {
        ...form.values.slides[index],
        image: {
          id: id,
          path,
          name,
        },
      };
    }
  };

  const handleRemove = () => {
    if (form.values.slides?.[index]) {
      form.values.slides[index] = {
        ...form.values.slides[index],
        image: {
          id: '',
          path: '',
          name: '',
        },
      };
    }
  };

  const imgData = getImageData({
    id: form.values.slides?.[index].image?.id,
    name: form.values.slides?.[index].image?.name,
    path: form.values.slides?.[index].image?.path.replace(S3_STORAGE_LINK as string, ''),
  });

  return (
    <div
      key={index}
      style={{
        gridColumn: 'span 12',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '24px',
        padding: '0 16px',
      }}
    >
      <Text
        size='sm'
        color='black'
        align='center'
        sx={{
          textTransform: 'none',
          fontWeight: 500,
          gridColumn: 'span 12',
          textAlign: 'start',
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        Слайд {index + 1}
        {index !== 0 && (
          <Trash size={20} color='gray' onClick={handleRemoveVideo} style={{ cursor: 'pointer' }} />
        )}
      </Text>
      <Input.Wrapper
        label='Изображение'
        sx={{ gridColumn: 'span 6', gridRow: 'span 2' }}
        {...form.getInputProps(`slides.${index}.image.id`)}
      >
        <FileLoader
          sessionID={sessionID}
          onRemove={handleRemove}
          onChange={handleChange}
          savedFormFiles={imgData}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>
      <InputBase
        sx={{ gridColumn: 'span 6' }}
        label={
          <TooltipTextWrapper
            label='Описание'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите alt'
        {...form.getInputProps(`slides.${index}.${EGallerySnippetFormFields.DESCRIPTION}`)}
      />
      <InputBase
        sx={{ gridColumn: 'span 6' }}
        label={
          <TooltipTextWrapper
            label='Название изображения'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Вставьте title'
        {...form.getInputProps(`slides.${index}.${EGallerySnippetFormFields.TITLE}`)}
      />
    </div>
  );
};
