import React from 'react';
import { ActionIcon, Box, Text } from '@mantine/core';
import { Plus } from 'tabler-icons-react';

import {
  setSelectGridDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { TAddRowButtonProps } from './AddRowButton.types';

export const AddRowButton: React.FC<TAddRowButtonProps> = ({ rowId, isShowText = false }) => {
  const setVisibleSelectGrid = useStoreContentEditor(setSelectGridDataSelector);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
      }}
      onClick={() => setVisibleSelectGrid({ rowId })}
    >
      <ActionIcon color='grayDefault' variant='transparent' size={18}>
        <Plus />
      </ActionIcon>
      {isShowText && (
        <Text sx={{ gridRow: 'span 2' }} lineClamp={1} color='grayDefault'>
          Настройте сетку для начала работы с конструктором
        </Text>
      )}
    </Box>
  );
};
