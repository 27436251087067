import React, { FC, ReactNode } from 'react';
import { Text, TextInputProps, Tooltip } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';

interface IProps extends TextInputProps {
  text: string | ReactNode;
  label: string;
  required?: boolean;
}

export const TooltipTextWrapper: FC<IProps> = ({ label, text, required }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
      {label}
      {required && <span style={{ color: '#fa5252' }}>*</span>}
      <Tooltip
        label={text}
        width={400}
        multiline
        position='top'
        sx={{ whiteSpace: 'break-spaces' }}
      >
        <Text sx={{ lineHeight: 0 }}>
          <InfoCircle color='#BFCEDB' size='1rem' />
        </Text>
      </Tooltip>
    </span>
  );
};
