import { useEffect } from 'react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { audioappsSlice, selectAudioAppsOptions } from '@/store/slices/audioapps';

export const useAudioAppsOptions = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(audioappsSlice.endpoints.getAudioApps.initiate());
  }, [dispatch]);

  return useAppSelector(selectAudioAppsOptions);
};
