import { IDisplayOnSitesSelectOptions } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.types';

export enum EMaterialsFormFields {
  URL_CODE = 'urlCode',
  IS_ACTIVE = 'isActive',
  TITLE = 'title',
  DATA_ACTIVE_FROM_DAYS = 'dateActiveFrom',
  DATA_ACTIVE_TO = 'dateActiveTo',
  DISPLAY_ON_SITES = 'displayOnSites',
  TARGET_PAGES = 'targetPages',
  IMAGE_ALT = 'alt',
  IMAGE_TITLE = 'title',
  IMAGE_ID = 'id',
  DRAFT_ID = 'draftId',
  SORT = 'sort',
  SHORT_DESCRIPTION = 'shortDescription',
  TYPE = 'type',
  READER_ROLE = 'readerRole',
  DIRECTION = 'direction',
  TYPE_OF_CONTROL = 'typeOfControl',
  EDUCATION_LEVEL = 'educationLevel',
  AGE = 'age',
  THEME = 'theme',
  SUBJECT = 'subject',
  LINE_UMK = 'lineUmk',
  AUTHOR = 'author',
  MODIFIED_VIEWS_COUNT = 'modifiedViewsCount',
  IS_DISPLAY_VIEWS = 'isDisplayViews',
  DIGITAL_SERVICE = 'digitalService',
  IS_GENERATE_TABLE_OF_CONTENTS = 'isGenerateTableOfContents',
  IS_MAIN_ARTICLE = 'isMainArticle',
  READING_TIME = 'readingTime',
  TAGS = 'tags',
  CONTAINS = 'contains',
}

export const ARTICLE_CREATION_FORM_ID = 'article-creation-form';

export enum EActivityFieldValues {
  ACTIVE = 'active',
  NOT_ACTIVE = 'notActive',
}

export const selectDisplayOnSitesOptions: IDisplayOnSitesSelectOptions[] = [
  { value: 'user', label: 'prosv.ru' },
  { value: 'uchitel', label: 'uchitel.club' },
];

export const selectDisplayOnPageOptions = [
  { value: 'all', label: 'Основной листинг' },
  { value: 'kts', label: 'Оптовым покупателям' },
];

export const containsOptions: { value: string; label: string }[] = [
  { value: 'isContainsVideo', label: 'Видео' },
  { value: 'isContainsDownloadMaterial', label: 'Файлы для скачивания' },
];

export const sortTooltip =
  'Вес сортировки необходим для настройки отображения первых 10 материалов в листинге материалов на публичном сайте. \nПо умолчанию при создании материала это поле заполнено значением 100.\nДля поднятия материала в топ-10 необходимо увеличить вес сортировки.\nНапример, материал с весом 1000 будет на первом месте, с весом 900 на втором месте и так далее.\nУ материалов с весом меньше 100 крайне низкая вероятность попасть в топ-10.';
