import { FC } from 'react';
import { Table } from '@mantine/core';
import { Dictionary } from '@prosv/core/types/bff/dictionary';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';

import TableBody from './TableBody';

interface IProps {
  elements: Dictionary[];
}

const DictionaryTable: FC<IProps> = ({ elements }) => {
  return (
    <Table highlightOnHover verticalSpacing='xs' fontSize='sm'>
      <thead style={{ background: '#E8ECF0' }}>
        <tr>
          <th>
            <TableColumnTitle>Код</TableColumnTitle>
          </th>
          <th>
            <TableColumnTitle>Название</TableColumnTitle>
          </th>
        </tr>
      </thead>

      <TableBody items={elements} />
    </Table>
  );
};

export default DictionaryTable;
