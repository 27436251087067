import React, { useEffect } from 'react';
import { Box, Button, Group, Select, Text, TextInput } from '@mantine/core';
import { RotateClockwise } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { MultiSelect, TOption } from '@/ui/components/MultiSelect';

import {
  inputStyles,
  itemsPerPageBox,
  locationOptions,
  pageOptions,
  siteOptions,
  sx,
  work,
} from '@/pages/Banners/BannersListing/BannerListing.constants';
import {
  bannerFiltersSelector,
  isDirtyFilterSelector,
} from '@/store/slices/banners/bannersSelectors';
import { bannersFilterActions, CreateFilterSlice } from '@/store/slices/banners/bannersSlice';

export const BannerListingFilters = ({
  getBannersListing,
}: {
  getBannersListing: (params: CreateFilterSlice) => void;
}) => {
  const { resetFilters, setFilterWithResetCurrentPage } = bannersFilterActions;
  const { limit, page, place, site, currentPage, isActive, sortDirection, sortField } =
    useAppSelector(bannerFiltersSelector);
  const query = useAppSelector((state) => state.bannersFilter.query);
  const isDirty = useAppSelector(isDirtyFilterSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getBannersListing({
      limit,
      page,
      place,
      site,
      currentPage,
      isActive,
      sortDirection,
      sortField,
    });
  }, [limit, page, place, site, currentPage, isActive, sortDirection, sortField]);

  const handleChange = (value: Partial<CreateFilterSlice>) => {
    dispatch(setFilterWithResetCurrentPage(value));
  };

  const handleGetBannersDebounced = useDebouncedCallback(
    (v: string) =>
      getBannersListing({
        query: v,
        limit,
        page,
        place,
        site,
        currentPage,
        isActive,
        sortDirection,
        sortField,
      }),
    500,
  );

  const handleSetTitle = (v: string) => {
    handleChange({ query: v });
    handleGetBannersDebounced(v);
  };

  const handleSerActive = (v: TOption) => {
    const valueByType = typeof isActive === 'string' ? isActive : isActive?.value;
    const result = v.value === valueByType ? undefined : v;
    handleChange({ isActive: result });
  };

  return (
    <Group mb={24} position='left' align='flex-end' spacing='xl' grow>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Заголовок
        </Text>
        <TextInput
          value={query}
          styles={inputStyles}
          onChange={(v) => handleSetTitle(v.target.value)}
          placeholder='Заголовок'
        />
      </Box>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Страница
        </Text>
        <MultiSelect
          options={pageOptions}
          height={40}
          value={page}
          onChange={(v) => handleChange({ page: v as TOption[] })}
          label='Страница'
        />
      </Box>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Расположение
        </Text>
        <MultiSelect
          value={place}
          height={40}
          options={locationOptions}
          onChange={(v) => handleChange({ place: v as TOption[] })}
          label='Расположение'
        />
      </Box>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Сайт
        </Text>
        <MultiSelect
          height={40}
          options={siteOptions}
          value={site}
          onChange={(v) => handleChange({ site: v as TOption[] })}
          label='Сайт'
        />
      </Box>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Включен
        </Text>
        <MultiSelect
          height={40}
          options={work}
          isMulti={false}
          value={[isActive as TOption]}
          onChange={(v) => {
            handleSerActive(v as any);
          }}
          label='Да/Нет'
        />
      </Box>
      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        {!isDirty && (
          <Box sx={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}>
            <Button
              variant='subtle'
              radius='xs'
              size='sm'
              compact
              sx={({ colors }) => ({
                color: colors.primary[9],
                ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
              })}
              rightIcon={<RotateClockwise size={20} />}
              onClick={() => dispatch(resetFilters())}
            >
              Сбросить
            </Button>
          </Box>
        )}
        <Box sx={itemsPerPageBox}>
          <Select
            sx={{ width: '60px', input: { paddingRight: '20px' } }}
            size='sm'
            value={limit || PAGE_ELEMENTS[0].value}
            onChange={(v) => handleChange({ limit: v as string })}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
