import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';
import notify from '@/utils/notify';

import {
  FormValues,
  ProjectItem,
  ProjectListResponse,
  ProjectOption,
  ProjectSliceTypes,
} from './projectsSlice.types';

import { ProjectDetailsValuesEnum } from '@/pages/Projects/ProjectDetails/ProjectDetails.consts';
import { State } from '@/store';
import { createTableFilterSlice } from '@/store/slices/banners/bannersSlice';
import { useNavigationStore } from '@/store/slices/navigationZustandStore/navigationZustandStore';
import { projectDefaultFilter } from '@/store/slices/projects/projectsSlice.constants';

export const projectsSlice = createApi({
  reducerPath: 'api/projectsSliceApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Projects'],
  endpoints: (build) => ({
    getProjectList: build.query<ProjectListResponse, ProjectSliceTypes>({
      query: ({
        currentPage = 1,
        limit = 100,
        sortField,
        sortDirection,
        projectType,
        isActive,
      }) => {
        const params = {
          offset: currentPage === 1 ? 0 : Number(limit) * (Number(currentPage) - 1),
          limit: limit,
          sortField,
          sortDirection,
          type: projectType,
          isActive,
        };
        return {
          url: getUrlWithSearchParams(URLS.project.list, params),
        };
      },
      providesTags: [{ type: 'Projects', id: 'LIST' }],
    }),
    getSingleProject: build.query<{ payload: ProjectItem }, string>({
      keepUnusedDataFor: 0,
      query: (uuid) => {
        return {
          url: getUrlWithSearchParams(URLS.project.single(uuid)),
        };
      },
      providesTags: (res) => (res ? [{ type: 'Projects', id: res.payload.uuid }] : []),
    }),
    createProject: build.query<unknown, FormValues>({
      keepUnusedDataFor: 0,
      onQueryStarted: async (form, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          notify({
            message: `Спецпроект "${form.name}" успешно создан`,
            type: 'success',
          });
          useNavigationStore.getState().navigate?.('/projects');
        } catch (e) {
          notify({
            message: `Произошла ошибка при создании спецпроекта "${form.name}"`,
            type: 'error',
          });
        }
      },
      query: (params) => {
        const data = {
          uuid: params.uuid,
          isActive: params[ProjectDetailsValuesEnum.IS_ACTIVE] === 'true',
          title: params[ProjectDetailsValuesEnum.NAME],
          type: params[ProjectDetailsValuesEnum.TYPE],
          externalIds: params?.[ProjectDetailsValuesEnum.PROJECT_ID] || [],
          eventIds:
            (params?.[ProjectDetailsValuesEnum.EVENT_IDS] || []).length === 0
              ? undefined
              : params[ProjectDetailsValuesEnum.EVENT_IDS],
        };
        return {
          url: getUrlWithSearchParams(URLS.project.list),
          method: 'post',
          data,
        };
      },
    }),
    editProject: build.query<unknown, FormValues>({
      keepUnusedDataFor: 0,
      onQueryStarted: async (form, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          notify({
            message: `Спецпроект "${form.name}" успешно изменен`,
            type: 'success',
          });
          useNavigationStore.getState().navigate?.('/projects');
        } catch (e) {
          notify({
            message: `Произошла ошибка при сохранении изменений спецпроекта "${form.name}"`,
            type: 'error',
          });
        }
      },
      query: (params) => {
        const data = {
          uuid: params.uuid,
          isActive: params[ProjectDetailsValuesEnum.IS_ACTIVE] === 'true',
          title: params[ProjectDetailsValuesEnum.NAME],
          type: params[ProjectDetailsValuesEnum.TYPE],
          externalIds: params?.[ProjectDetailsValuesEnum.PROJECT_ID] || [],
          eventIds:
            (params?.[ProjectDetailsValuesEnum.EVENT_IDS] || []).length === 0
              ? undefined
              : params[ProjectDetailsValuesEnum.EVENT_IDS],
        };
        return {
          url: getUrlWithSearchParams(URLS.project.single(String(params.uuid))),
          method: 'put',
          data,
        };
      },
    }),
  }),
});

export const {
  useGetProjectListQuery,
  useLazyGetProjectListQuery,
  useGetSingleProjectQuery,
  useLazyCreateProjectQuery,
  useLazyEditProjectQuery,
} = projectsSlice;

export const projectsFilterSlice = createTableFilterSlice<ProjectSliceTypes>({
  name: 'projectFilters',
  initialState: projectDefaultFilter,
});

export const supportsFilterActions = projectsFilterSlice.actions;

export const projectFilterSelector = createSelector(
  (state: State) => state.projectFilters,
  (projectFilters) => projectFilters,
);

export const isProjectDirtySelector = createSelector(
  (state: State) => state.projectFilters,
  (filters) => {
    const { isActive, projectType, sortField, sortDirection } = filters;
    return (
      JSON.stringify({ isActive, projectType, sortField, sortDirection }) !==
      JSON.stringify({
        isActive: projectDefaultFilter.isActive,
        projectType: projectDefaultFilter.projectType,
        sortField: projectDefaultFilter.sortField,
        sortDirection: projectDefaultFilter.sortDirection,
      })
    );
  },
);

export const selectProjects = createSelector(
  projectsSlice.endpoints.getProjectList.select({}),
  ({ data }) => data?.payload.items ?? [],
);

export const selectProjectsOptions = createSelector(selectProjects, (items) =>
  items
    .filter(({ isActive }) => isActive)
    .map<ProjectOption>(({ uuid, title }) => ({ value: uuid, label: title })),
);
