import React, { FC } from 'react';
import { Box, Button, ColorInput, Grid, Group, Modal, Select, Text } from '@mantine/core';

import { COLOR_PICKER_COLORS } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextSnippetModal.constants';

import { GridCard, SizeGrid } from './GridCard';
import { MAX_NUMBER_BLOCKS, OPTIONS } from './SelectGrid.constants';
import { useHandlersSelectGridModal } from './SelectGridModal.handlers';
import { useStateSelectGridModal } from './SelectGridModal.state';
import { TSelectGridModalProps } from './SelectGridModal.types';

const SelectGridModalUI: FC<TSelectGridModalProps> = ({ draftId }) => {
  const state = useStateSelectGridModal();
  const handlers = useHandlersSelectGridModal(state, draftId);

  return (
    <Modal
      opened={!!state.selectGridData}
      onClose={handlers.handleClose}
      title={
        <Text size={24} align='left' weight={500}>
          Настройка сетки
        </Text>
      }
      size={650}
      withCloseButton
      overflow='inside'
    >
      <Box sx={{ position: 'relative' }}>
        <Text size='xs' align='left' weight={400} color='darkest'>
          Выберите количество колонок в сетке на странице с контентом и отступы между ними.
        </Text>

        <Box pt={32} sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Text size='xs' align='left' weight={500} color='black'>
            Выберите вариант сетки
          </Text>

          <Grid m={0} sx={{ display: 'flex', gap: 12 }}>
            {new Array(MAX_NUMBER_BLOCKS)
              .fill(null)
              .map((_item, index) => {
                return (
                  <Grid.Col key={index} span={6} p={0} sx={{ flexBasis: '49%' }}>
                    <GridCard
                      disabled={state?.selectGridData?.isEdit}
                      size={(index + 1) as SizeGrid}
                      selectedNumbersBlock={state.selectedNumbersBlock}
                      setSelectedNumbersBlock={state.setSelectedNumbersBlock}
                    />
                  </Grid.Col>
                );
              })
              .reverse()}
          </Grid>
        </Box>

        <Box pt={32} sx={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
          <Select
            label={
              <Text size='sm' align='left' weight={500} color='black' pb={8}>
                Отступ между колонками
              </Text>
            }
            placeholder='выбрать'
            onChange={(value) => typeof value === 'string' && state.setGapColumnSize(value)}
            nothingFound='No options'
            data={OPTIONS}
            value={state.gapColumnSize}
          />
          <Select
            label={
              <Text size='sm' align='left' weight={500} color='black' pb={8}>
                Отступ между строками
              </Text>
            }
            placeholder='выбрать'
            onChange={(value) => typeof value === 'string' && state.setGapRowSize(value)}
            nothingFound='No options'
            data={OPTIONS}
            value={state.gapRowSize}
          />

          <ColorInput
            placeholder='Выберите цвет'
            label='Цвет заливки фона'
            withPicker={false}
            disallowInput={false}
            onChange={(value) => state.setBackgroundColor(value)}
            value={state.backgroundColor}
            swatches={COLOR_PICKER_COLORS}
          />
        </Box>

        <Group position='left' pt={32}>
          <Button
            onClick={
              state?.selectGridData?.isEdit
                ? handlers.handleClickEditBtn
                : handlers.handleClickDoneBtn
            }
            px={75}
            sx={{ fontSize: 16 }}
            disabled={state.disabledReadyButton}
          >
            Готово
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

export const SelectGridModal = React.memo(SelectGridModalUI);
