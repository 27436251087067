import React, { FC } from 'react';
import { Box } from '@mantine/core';

import { TRow } from '@/ui/containers/ContentEditor/ContentEditor.types';

import { ContentTypeCard } from '../../ContentTypeCard';

import { AddRowButton } from './AddRowButton/AddRowButton';
import { MoveRowButton } from './MoveRowButton';

interface IRowConfigurationProps {
  rowData?: TRow;
  rowId: number;
}

export const RowConfiguration: FC<IRowConfigurationProps> = (props) => {
  const { rowData, rowId } = props;

  const hasBlocks = !!rowData?.blocks?.length;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'flex-start',
        gap: '8px',
        paddingBottom: !rowData ? 40 : 0,
      }}
    >
      <AddRowButton rowId={rowId} rowData={rowData} isShowText={!hasBlocks} />

      {hasBlocks && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gridRow: 'span 2',
            gap: 24,
          }}
        >
          {rowData.blocks?.map(({ variant }, cardId) => {
            return (
              <ContentTypeCard
                type='grid'
                key={cardId + String(variant)}
                variant={variant}
                cardId={cardId}
                rowId={rowId}
              />
            );
          })}
        </Box>
      )}

      {rowData && <MoveRowButton rowId={rowId} />}
    </Box>
  );
};
