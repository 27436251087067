import { DownloadFile, DownloadSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import notify from '@/utils/notify';

import { EDownloadsSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal/DownloadsSnippetModal.constants';

export const getDownloadsSnippetInitialValues = (
  defaultValues?: DownloadSnippet,
): SnippetValues<DownloadSnippet> => {
  const getDefaultFile = (file?: DownloadFile) => {
    return {
      [EDownloadsSnippetFormFields.NAME]: file?.name || '',
      [EDownloadsSnippetFormFields.FILE]: file?.file || {
        id: '',
        fileType: '',
        fileSize: '',
        filePath: '',
        fileName: '',
      },
    };
  };

  const files = defaultValues?.files?.map(getDefaultFile);
  const defaultFile = getDefaultFile();

  return {
    id: defaultValues?.id || uuid_v4(),
    title: defaultValues?.title || '',
    files: files || [defaultFile],
  };
};

export const replaceFiles = async (values: SnippetValues<DownloadSnippet>, sessionId: string) => {
  if (values.files?.length === 0) {
    return notify({ message: 'Введите текст', type: 'error' });
  }
  const filesIds = values.files?.map(({ file }) => file?.id);

  if (filesIds && filesIds.length) {
    await replaceTempFile({
      query: {
        sessionId: sessionId,
        ids: [...filesIds] as string[],
      },
    });
  }
};
