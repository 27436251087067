export enum EPromotionFormFields {
  CODE = 'code',
  TITLE = 'title',
  IS_ACTIVE = 'isActive',
  IS_MAIN = 'isMain',
  ERID = 'erid',
  DATE_ACTIVE_START = 'dateActiveStart',
  DATE_ACTIVE_END = 'dateActiveEnd',
  DESCRIPTION = 'description',
  TERMS = 'terms',
  IS_SHOW_DELIVERY = 'isShowDelivery',
  DELIVERY_DESCRIPTION = 'deliveryDescription',
  TYPE = 'type',
  DRAFT_ID = 'draftId',
  BANNER_ID = 'bannerId',
}

export const DEFAULT_DELIVERY_DESCRIPTION = `<p>Ознакомьтесь с <a target="_blank" rel="noopener noreferrer nofollow" href='${process.env.PREVIEW_URL}/help/priobretenie-produkcii-v-internet-magazine/#sposoby-dostavki'>актуальными условиями</a> и способами доставки.</p>`;

export const PROMOTION_CREATION_FORM_ID = 'promotion-creation-form';

export const DATE_TEMPLATE = 'YYYY-MM-DDTHH:mm:ssZ';

export const DESCRIPTION_MAX_LENGTH = 160;

export enum EActivityFieldValues {
  ACTIVE = 'active',
  NOT_ACTIVE = 'notActive',
}

export enum EVisibilityFieldValues {
  SHOW = 'show',
  NOT_SHOW = 'notShow',
}

export enum EMainFieldValues {
  MAIN = 'main',
  NOT_MAIN = 'notMain',
}

export const VISIBILITY_DELIVERY_FIELD_VALUES = {
  [EVisibilityFieldValues.SHOW]: true,
  [EVisibilityFieldValues.NOT_SHOW]: false,
};

export const MAIN_PROMOTION_FIELD_VALUES = {
  [EMainFieldValues.MAIN]: true,
  [EMainFieldValues.NOT_MAIN]: false,
};

export const ACTIVITY_FIELD_VALUES = {
  [EActivityFieldValues.ACTIVE]: true,
  [EActivityFieldValues.NOT_ACTIVE]: false,
};

export const PromotionFormFields = {
  code: 'Символьный код URL',
  isActive: 'Активность',
  title: 'Название акции',
  dateActiveStart: 'Дата начала активности',
  dateActiveEnd: 'Дата окончания активности',
  isMainNews: 'Сделать главной акцией',
  draftId: '',
  erid: 'erid',
  description: 'Описание',
  terms: 'Условия',
  isShowDelivery: 'Показать доставку',
  deliveryDescription: 'Описание доставки',
  type: 'Тип акции',
  bannerId: 'Баннер',
};

export const ERROR_MESSAGE_STYLE = {
  color: '#fa5252',
  fontSize: '12px',
  lineHeight: 1.2,
  marginTop: '5px',
};
