import { useParams } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';

import { ProjectDetails } from '@/pages/Projects/ProjectDetails/ProjectDetails';
import { ProjectDetailsValuesEnum } from '@/pages/Projects/ProjectDetails/ProjectDetails.consts';
import { useGetSingleProjectQuery } from '@/store/slices/projects/projectsSlice';
import { FormValues } from '@/store/slices/projects/projectsSlice.types';

export const ProjectDetailsEdit = () => {
  const uuid = useParams()?.id || '';

  const { data, isFetching } = useGetSingleProjectQuery(uuid);
  const initialValues: FormValues = {
    [ProjectDetailsValuesEnum.NAME]: data?.payload?.title || '',
    [ProjectDetailsValuesEnum.PROJECT_ID]: data?.payload?.externalIds || [],
    [ProjectDetailsValuesEnum.IS_ACTIVE]: data?.payload.isActive ? 'true' : 'false',
    [ProjectDetailsValuesEnum.EVENT_IDS]: data?.payload.eventIds?.map(String) || [],
    [ProjectDetailsValuesEnum.TYPE]: data?.payload?.type || undefined,
    [ProjectDetailsValuesEnum.UUID]: data?.payload?.uuid,
  };

  return (
    <>
      {isFetching ? (
        <LoadingOverlay visible={true} />
      ) : (
        <ProjectDetails initialValues={initialValues} title={String(initialValues.name)} />
      )}
    </>
  );
};
