import {
  IPromotionCreationData,
  IPromotionErrResponse,
  IPromotionItem,
  TPromotionsResponse,
} from '@/types/promotions';

import { useState } from 'react';
import { AxiosError } from 'axios';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

enum EErrCode {
  URL_CODE_EXISTS = 'e9ea999e-e79c-49ea-b5b5-395386908999',
}

export const useCreatePromotion = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    createPromotion: async (promotionData: IPromotionCreationData) => {
      setIsLoading(true);
      try {
        const request = await api.post<TPromotionsResponse<IPromotionItem>>(
          URLS.promotions.createPromotion,
          { ...promotionData },
        );
        notify({ message: 'Акция добавлена', type: 'success' });
        return request.data;
      } catch (err) {
        const { response } = err as AxiosError<IPromotionErrResponse, {}>;
        if (response?.data.code === EErrCode.URL_CODE_EXISTS) {
          return notify({
            message: 'Акция c таким символьным кодом уже существует',
            type: 'error',
          });
        }
        return notify({ message: 'Ошибка добавления акции', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
