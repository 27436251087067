import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container, Group, LoadingOverlay, Paper, Title } from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import MenuContainerTable from '@/ui/containers/MenusContainer/MenuContainerTable';
import { MenusContainerNoMenus } from '@/ui/containers/MenusContainer/MenusContainerNoMenus';

import {
  fetchGetMenuTypesAction,
  selectFetchingGetMenuTypes,
  selectMenuTypes,
} from '@/store/slices/menu/menuTypes';

const MenusContainer: FC = () => {
  const dispatch = useAppDispatch();
  const menus = useSelector(selectMenuTypes);
  const isFetching = useSelector(selectFetchingGetMenuTypes);

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleNewItemPopup = () => {
    setIsOpened(!isOpened);
  };

  useEffect(() => {
    if (!menus) {
      dispatch(fetchGetMenuTypesAction());
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '96vh',
          overflowY: 'hidden',
        }}
      >
        <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
          <Group position='apart'>
            <Title order={1}>Меню</Title>
          </Group>
        </Container>

        <Paper
          radius={8}
          p={24}
          pb={12}
          shadow='xs'
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >
          {!isFetching && menus && menus?.length > 0 && (
            <Box sx={{ position: 'relative', overflow: 'scroll' }}>
              <MenuContainerTable items={menus} />
            </Box>
          )}

          {isFetching && <LoadingOverlay visible={isFetching} />}

          {!menus && !isFetching && <MenusContainerNoMenus addNewItem={handleNewItemPopup} />}
        </Paper>
      </Box>
    </>
  );
};

export default MenusContainer;
