import {TElementsRes, TModel, TQueryParamsNew} from '@/types';

import api from '../instance';
import { tryAction } from '../tryAction';
import { URLS } from '../urls';

export const apiGetModels = (params: TQueryParamsNew) =>
  api.get<TElementsRes<TModel>>(URLS.models.getModels, { params: params.params });

/*TRY ...CATCH */
export const tryGetModels = (params: TQueryParamsNew) =>
  tryAction<TElementsRes<TModel>>(apiGetModels(params));
