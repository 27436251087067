import { FC } from 'react';
import { Box, Checkbox } from '@mantine/core';

export const WebinarsFormBlock: FC = () => {
  return (
    <Box ml={32}>
      <Checkbox checked disabled label='Получать автоматически с Учитель.Клаб' />
    </Box>
  );
};
