import * as Yup from 'yup';

import { validationTexts } from '@/constants/validationTexts';

import { EEventSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/EventSnippetModal/EventSnippetModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const eventSnippetSchema = Yup.object({
  [EEventSnippetFormFields.TITLE]: Yup.string()
    .required(ERR_REQUIRED_TEXT)
    .max(150, validationTexts.MAX_LENGTH(150)),
  [EEventSnippetFormFields.LINK]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EEventSnippetFormFields.DESCRIPTION]: Yup.string()
    .required(ERR_REQUIRED_TEXT)
    .max(1000, validationTexts.MAX_LENGTH(1000)),
  [EEventSnippetFormFields.BACKGROUND]: Yup.string(),
  [EEventSnippetFormFields.DATE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EEventSnippetFormFields.TIME]: Yup.string().nullable(),
  [EEventSnippetFormFields.ALT]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EEventSnippetFormFields.IMAGE_TITLE]: Yup.string().required(ERR_REQUIRED_TEXT),

  image: Yup.object({
    [EEventSnippetFormFields.IMAGE_ID]: Yup.string().required(ERR_REQUIRED_TEXT),
  }),
});
