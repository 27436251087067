import { FC, useRef } from 'react';
import { Box, Input, InputBase, Select } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { Editor } from '@tiptap/react';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import {
  getImageData,
  handleAddSnippetData,
} from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { imageSnippetSchema } from '@/ui/containers/ContentEditor/snippets/ImageSnippetModal/ImageSnippetModal.schema';
import {
  EImageWithTextAroundFormFields,
  IMAGE_POSITION_ELEMENTS,
} from '@/ui/containers/ContentEditor/snippets/ImageWithTextAroundSnippet/ImageWithTextAroundSnippet.constants';
import { getInitialValues } from '@/ui/containers/ContentEditor/snippets/ImageWithTextAroundSnippet/ImageWithTextAroundSnippet.helpers';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';

export const ImageWithTextAroundSnippet: FC = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('imageWithTextAround');
  const sessionID = useRef(uuid_v4());

  const editorRef = useRef<Editor | undefined | null>();

  const form = useForm({
    initialValues: getInitialValues(defaultValues),
    validate: yupResolver(imageSnippetSchema),
  });
  const handleChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.values[EImageWithTextAroundFormFields.FILE] = {
      id,
      path,
      name,
    };
  };
  const handleRemove = () => {
    form.values[EImageWithTextAroundFormFields.FILE] = {
      id: '',
      path: '',
      name: '',
    };
  };

  const imgData = getImageData({
    id: form.values[EImageWithTextAroundFormFields.FILE].id,
    name: form.values[EImageWithTextAroundFormFields.FILE].name,
    path: form.values[EImageWithTextAroundFormFields.FILE].path.replace(
      process.env.MEDIA_STORAGE_URL as string,
      '',
    ),
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Текст с изображением'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        if (values[EImageWithTextAroundFormFields.FILE].id) {
          await replaceTempFile({
            query: {
              sessionId: sessionID.current,
              ids: [values[EImageWithTextAroundFormFields.FILE].id],
            },
          });
        }
        return handleAddSnippetData('imageWithTextAround', values, handleClose, snippetData);
      })}
    >
      <Input.Wrapper
        label='Изображение'
        sx={{ gridRow: 'span 2', gridColumn: 'span 6' }}
        withAsterisk
        {...form.getInputProps('file.id')}
      >
        <FileLoader
          savedFormFiles={imgData}
          onChange={handleChange}
          onRemove={handleRemove}
          sessionID={sessionID.current}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>

      <InputBase
        sx={{ gridColumn: 'span 6' }}
        label='Alt'
        placeholder='Введите здесь'
        {...form.getInputProps(EImageWithTextAroundFormFields.IMAGE_ALT)}
      />
      <InputBase
        sx={{ gridColumn: 'span 6' }}
        label='Title'
        placeholder='Введите здесь'
        {...form.getInputProps(EImageWithTextAroundFormFields.IMAGE_TITLE)}
      />

      <Select
        sx={{ gridColumn: 'span 6' }}
        size='sm'
        {...form.getInputProps(EImageWithTextAroundFormFields.POSITIONS_IMAGE)}
        data={IMAGE_POSITION_ELEMENTS}
      />

      <Box
        sx={{
          gridColumn: 'span 12',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: 24,
          minHeight: 325,
          position: 'relative',
          marginTop: '50px',
        }}
      >
        <TextEditor
          defaultTextValue={defaultValues?.text}
          editorRef={editorRef}
          changeFormValue={(value) =>
            form.setFieldValue(EImageWithTextAroundFormFields.TEXT, value)
          }
        />
      </Box>
    </SnippetModal>
  );
};
