export const genColors = (color: string) => {
  return new Array(10).fill(color) as [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
  ];
};
