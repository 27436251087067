import React from 'react';
import { Badge, Text } from '@mantine/core';

export const ActiveStatusCell = ({
  isActive,
  toggleActivity,
  canUpdate,
}: {
  isActive: boolean;
  toggleActivity: () => void;
  canUpdate?: boolean;
}) => {
  return (
    <Badge
      color={isActive ? 'green' : 'greyLine'}
      size='lg'
      radius='sm'
      variant='filled'
      p={0}
      sx={{ width: 88, cursor: canUpdate ? 'pointer' : 'default' }}
      onClick={canUpdate ? toggleActivity : undefined}
    >
      <Text size='sm' color='black' align='center' sx={{ textTransform: 'none', fontWeight: 400 }}>
        {isActive ? 'Активно' : 'Не активно'}
      </Text>
    </Badge>
  );
};
