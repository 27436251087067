import { FC, PropsWithChildren } from 'react';
import { Text } from '@mantine/core';

export const TableColumnTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text color='#3E4D5B' size='sm' weight={600}>
      {children}
    </Text>
  );
};
