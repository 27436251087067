import {
  ItemPermissions,
  ItemPermissionsPrefix,
  PermissionGroup,
  permissionGroups,
} from '@/types/auth/permissions';

import { PermissionInfoItem } from '@prosv/core/src/lib/types/bff/base/permitions';

export const isPermissionGroup = (item: string): item is PermissionGroup =>
  item in permissionGroups;

export const getItemPermissions = <T extends string>(
  prefix: ItemPermissionsPrefix,
  items: PermissionInfoItem<T>[] = [],
): ItemPermissions =>
  items.reduce<ItemPermissions>((res, { attribute, isAccessible }) => {
    if (!isAccessible) return res;

    if (attribute === `${prefix}GET`) {
      res.canGet = true;
    } else if (attribute === `${prefix}UPDATE`) {
      res.canUpdate = true;
    } else if (attribute === `${prefix}DELETE`) {
      res.canDelete = true;
    } else if ([`${prefix}UPDATE_ACTIVITY`, `${prefix}UPDATE_TOGGLE_ACTIVE`].includes(attribute)) {
      res.canUpdateActivity = true;
    } else if (
      [`${prefix}UPDATE_VIEWS_COUNT`, `${prefix}UPDATE_MODIFIED_VIEW_COUNT`].includes(attribute)
    ) {
      res.canUpdateViewsCount = true;
    }

    return res;
  }, {});
