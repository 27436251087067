import { Button } from '@mantine/core';

import { FORM_ID } from '@/pages/Projects/ProjectDetails/ProjectDetails.consts';

export const ActionButtons = () => {
  return (
    <Button type='submit' form={FORM_ID} sx={{ width: '200px' }}>
      Сохранить
    </Button>
  );
};
