import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

interface IParams {
  uuid: string;
  isActive: boolean;
}

export const useToggleActiveMaterial = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    toggleActive: async ({ uuid, isActive }: IParams) => {
      setIsLoading(true);
      try {
        const request = await api.patch<{ isActive: boolean }>(
          URLS.materials.updateMaterialActive(uuid),
          { isActive },
        );
        notify({ message: 'Материал успешно обновлен', type: 'success' });
        return request.data;
      } catch (err) {
        notify({ message: 'Ошибка обновления материала', type: 'error' });
        return false;
      } finally {
        setIsLoading(false);
      }
    },
  };
};
