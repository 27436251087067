import { ItemPermissions } from '@/types/auth/permissions';

import { FC } from 'react';
import { Box, Button } from '@mantine/core';

interface IProps {
  isNew: boolean;
  handleDelete: () => void;
  onSave?: () => void;
  formId?: string;
  permissions: ItemPermissions;
}
export const ActionButtons: FC<IProps> = ({ isNew, formId, onSave, handleDelete, permissions }) => {
  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      {!isNew && permissions.canDelete && (
        <>
          <Button sx={{ width: '200px', color: 'red' }} variant='default' onClick={handleDelete}>
            Удалить
          </Button>
        </>
      )}
      {permissions.canSave && (
        <Button type='submit' form={formId} onClick={onSave} sx={{ width: '200px' }}>
          Сохранить
        </Button>
      )}
    </Box>
  );
};
