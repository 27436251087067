import { UmkType } from '@/store/slices/umkList/umkList.types';

export const getBreadcrumbs = (isNewUmk: boolean, id: string) => {
  const currentPageLinkName = isNewUmk ? 'Новая страница' : `ID: ${id}`;
  return [
    {
      name: 'Управление УМК',
      url: '/umk/',
    },
    {
      name: currentPageLinkName,
    },
  ];
};

export const getUmkUrl = (isDirections: boolean, firstDraftId?: string, secondDraftId?: string) => {
  return isDirections
    ? `${process.env.PREVIEW_URL}/umk/preview/?firstId=${firstDraftId}/`
    : `${process.env.PREVIEW_URL}/umk/preview/?firstId=${firstDraftId}&secondId=${secondDraftId}/`;
};

export const getTopAreaLink = (code: string) => {
  switch (code) {
    case 'cifrovye_produkty':
      return '/umk/napravlenie/cifrovye-produkty/';
    case 'korrekcionnaya_pedagogika':
      return '/umk/napravlenie/korrekcionnaya-pedagogika/';
    default:
      return `/umk/napravlenie/${code}`;
  }
};

export const getUmkLink = (umkType: UmkType, code: string) => {
  switch (umkType) {
    case 'line':
      return `/umk/liniya-seriya/${code}/`;
    case 'direction':
      return getTopAreaLink(code);
    case 'system':
      return `/umk/sistema-umk/${code}/`;
  }
};
