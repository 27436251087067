export enum EEventSnippetFormFields {
  ID = 'id',
  TITLE = 'title',
  LINK = 'link',
  DESCRIPTION = 'description',
  IMAGE = 'image',
  BACKGROUND = 'background',
  DATE = 'date',
  TIME = 'time',
  ALT = 'alt',
  IMAGE_TITLE = 'imageTitle',
  IMAGE_PATH = 'path',
  IMAGE_ID = 'id',
}

export const DATE_TEMPLATE = 'YYYY-MM-DDTHH:mm:ssZ';

export const PICKER_BACKGROUND_COLOR = [
  '#0983D8',
  '#545764',
  '#009966',
  '#F67C27',
  '#F6273A',
  '#FFFFFF',
];
