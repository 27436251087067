import React, { memo, useCallback, useState } from 'react';
import { unstable_useBlocker as useBlocker, useNavigate, useParams } from 'react-router-dom';
import { Box, Button } from '@mantine/core';

import notify from '@/utils/notify';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import { REMOVE_REVIEW_MODAL_TEXT } from '@/ui/containers/Reviews/details/details.constants';
import { ReviewResponseType } from '@/ui/containers/Reviews/types';

import { useModalsStore } from '@/store/slices/modalsZustandStore/modalsStore';
import {
  useLazySetReplyReviewQuery,
  useLazySetStatusReviewQuery,
  useReviewDetailStore,
} from '@/store/slices/reviews/reviewsSlice';

const useDetailActions = (data?: ReviewResponseType) => {
  const replyText = useReviewDetailStore((state) => state.params.answer);
  const id = useParams()?.id || '';

  const [request, response] = useLazySetReplyReviewQuery();
  const [setWithdrawReview, withdrawReviewData] = useLazySetStatusReviewQuery();
  const navigate = useNavigate();
  const closeModal = useModalsStore((state) => state.closeModal);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const { proceed, reset } = useBlocker(isOpenModal);

  const onConfirmHide = useCallback(() => {
    proceed?.();
    setIsOpenModal(false);
  }, [proceed]);

  const onCancelHide = useCallback(() => {
    reset?.();
    setIsOpenModal(false);
  }, [reset]);

  const onSuccessPublished = () => {
    closeModal();
    navigate('/reviews');
    notify({
      message: 'Отзыв успешно опубликован',
      type: 'success',
    });
  };

  const onSuccessSave = () => {
    navigate('/reviews');
    notify({
      message: 'Отзыв успешно сохранен',
      type: 'success',
    });
  };

  const onPublishedReview = () =>
    setWithdrawReview({
      id,
      reason: data?.payload.text || '',
      status: 'published',
      onSuccessPublished,
    });

  const action = useCallback(() => {
    if (data?.payload.reply?.text !== replyText) {
      request({
        replyText,
        text: data?.payload.text || '',
        id,
        onSuccessPublished,
        onPublishedReview,
      });
    } else if (data.payload.status !== 'published') {
      onPublishedReview();
    }
  }, [replyText, data, id]);

  const hiddenRequest = () => {
    onConfirmHide();
    if (data?.payload.reply?.text !== replyText) {
      request({
        replyText,
        text: data?.payload.text || '',
        id,
      });
    }

    setWithdrawReview({
      id,
      status: 'hidden',
      onSuccessPublished: () => {
        closeModal();
        navigate('/reviews');
        notify({
          message: `Отзыв успешно скрыт `,
          type: 'success',
        });
      },
    });
  };

  const handleHidden = () => {
    if (data?.payload.status !== 'hidden') {
      setIsOpenModal(true);
    } else {
      request({
        id,
        replyText,
        onPublishedReview: onSuccessSave,
      });
    }
  };

  return {
    action,
    isLoading: response.isLoading || withdrawReviewData.isLoading,
    handleHidden,
    isOpenModal,
    hiddenRequest,
    onCancelHide,
  };
};

const BUTTON_TEXT = {
  new: {
    primary: 'Не публиковать на сайте',
    secondary: 'Опубликовать на сайте',
  },
  published: {
    primary: 'Снять с публикации',
    secondary: 'Сохранить изменения',
  },
  hidden: {
    primary: 'Сохранить и не публиковать',
    secondary: 'Опубликовать на сайте',
  },
  delete: null,
  removal: null,
};

export const ReviewRightButtons = memo(({ data }: { data?: ReviewResponseType }) => {
  const { action, isLoading, handleHidden, isOpenModal, hiddenRequest, onCancelHide } =
    useDetailActions(data);

  const canUpdate = data?.payload?.reviewPermissions?.isCanUpdate;
  const status = data?.payload?.status;
  const btnText = !!status ? BUTTON_TEXT[status] : BUTTON_TEXT.new;
  const redText = status === 'new' ? 'red' : '';

  if (!btnText) return null;

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <Button
        disabled={isLoading || !canUpdate}
        onClick={handleHidden}
        variant='default'
        style={{ color: redText }}
      >
        {btnText.primary}
      </Button>
      <Button disabled={isLoading || !canUpdate} onClick={action} sx={{ background: '#02C39A' }}>
        {btnText.secondary}
      </Button>
      {isOpenModal && (
        <ModalConfirm
          title={REMOVE_REVIEW_MODAL_TEXT.title}
          text={REMOVE_REVIEW_MODAL_TEXT.text}
          opened={isOpenModal}
          onClose={onCancelHide}
          confirmHandler={hiddenRequest}
        />
      )}
    </Box>
  );
});
