import { ProjectField } from './ProjectsFormBlock.types';

export const type = 'cube-project';

export const PROJECTS_FIELDS: ProjectField[] = [
  'project1',
  'project2',
  'project3',
  'project4',
  'project5',
] as const;
