import { FC } from 'react';
import { Button, useMantineTheme } from '@mantine/core';
import { DictionaryFullItem } from '@prosv/core/types/bff/dictionary';
import { Pencil } from 'tabler-icons-react';

import { getFormatDate } from '@/utils/getCurrentDate';

import { usePermission } from '@/hooks/usePermissions';

interface IProps {
  items: DictionaryFullItem[];
  onClick: (item: DictionaryFullItem) => void;
}

const TableBody: FC<IProps> = ({ items, onClick }) => {
  const canUpdate = usePermission('DICTIONARY_UPDATE');
  const theme = useMantineTheme();

  return (
    <tbody>
      {items.map((item) => {
        return (
          <tr key={item.id} style={{ cursor: 'pointer' }}>
            <td>{item.name}</td>
            <td>{item.isActive ? 'Активно' : 'Неактивно'}</td>
            <td>{item.code}</td>
            <td>{getFormatDate(item.createAt.value)}</td>
            <td style={{ display: 'flex', justifyContent: 'center' }}>
              {canUpdate && (
                <Button
                  name='Редактировать'
                  title='Редактировать'
                  variant='subtle'
                  compact
                  onClick={() => onClick(item)}
                  style={{ position: 'static' }}
                >
                  <Pencil size={22} color={theme.colors.greyLight[9]} />
                </Button>
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
