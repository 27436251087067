import React, { ReactNode } from 'react';
import { components, ValueContainerProps } from 'react-select';
import { Text } from '@mantine/core';

import { TOption } from '@/ui/components/MultiSelect';

export const ValueContainer = ({ children, ...props }: ValueContainerProps<TOption>) => {
  let [, input] = children as [ReactNode, ReactNode];
  const placeholder = props?.selectProps?.placeholder;

  return (
    <components.ValueContainer {...props}>
      <div style={{ position: 'relative' }}>
        <Text
          color='grayDefault'
          sx={{
            position: 'absolute',
            left: 4,
            top: 4,
          }}
        >
          {placeholder}
        </Text>
        {input}
      </div>
    </components.ValueContainer>
  );
};
