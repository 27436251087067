import { Sx } from '@mantine/core';

import { BREAKPOINTS } from '@/constants/mantine/breakpoints';

export const root: Sx = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '96vh',
  overflowY: 'hidden',

  form: {
    maxHeight: 'calc(100vh - 32px)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
};

export const head: Sx = { marginLeft: 'initial', marginRight: 'initial', marginBottom: 16 };

export const submitButton: Sx = { width: 200 };

export const formContent: Sx = {
  maxHeight: 'calc(100vh - 32px)',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

export const paperWrapper: Sx = {
  overflow: 'scroll',
};

export const paper: Sx = {
  overflow: 'scroll',
  padding: 24,
  paddingBottom: 12,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: 32,

  [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
    gridTemplateColumns: 'auto',
    gap: 12,
  },

  '> *:nth-of-type(even)': {
    marginBottom: 24,
  },
};
