export enum EVideoSnippetFormFields {
  ID = 'id',
  TITLE = 'title',
  VIDEO_URl = 'videoUrl',
  DESCRIPTION = 'description',
  SLIDE = 'slide',
  VIDEOS = 'videos',
  ONLY_FOR_AUTH = 'onlyForAuth',
  LOOP_SLIDE = 'loopSlide',
  SLIDE_ID = 'id',
}

export const VIDEO_URL_REGEX =
  /^(https:\/\/(www\.)?youtube\.com\/|https:\/\/rutube\.ru\/|https:\/\/vk\.com\/video|https:\/\/dzen\.ru\/)/;

export const YOUTUBE_URL_MAPPER = /https?:\/\/(?:www\.)?youtube\.com\/watch\?v=(\w+)/i;

export const RUTUBE_URL_MAPPER = /https?:\/\/rutube\.ru\/video\/(\w+)\//i;
