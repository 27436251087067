import { SnippetValues, Video, VideoSnippet } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import {
  EVideoSnippetFormFields,
  RUTUBE_URL_MAPPER,
  YOUTUBE_URL_MAPPER,
} from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoSnippetModal.constants';

export const getVideoSnippetInitialValues = (
  defaultValues?: VideoSnippet,
): SnippetValues<VideoSnippet> => {
  const getDefaultVideo = (video?: Video) => {
    return {
      [EVideoSnippetFormFields.TITLE]: video?.title || '',
      [EVideoSnippetFormFields.VIDEO_URl]: video?.videoUrl || '',
      [EVideoSnippetFormFields.DESCRIPTION]: video?.description || '',
      [EVideoSnippetFormFields.SLIDE]: video?.slide || {
        id: '',
        name: '',
        path: '',
      },
    };
  };
  const videos = defaultValues?.videos?.map(getDefaultVideo);
  const defaultVideo = getDefaultVideo();

  return {
    [EVideoSnippetFormFields.ID]: defaultValues?.id || uuid_v4(),
    [EVideoSnippetFormFields.LOOP_SLIDE]: defaultValues?.loopSlide ?? false,
    [EVideoSnippetFormFields.ONLY_FOR_AUTH]: defaultValues?.onlyForAuth ?? false,
    videos: videos || [defaultVideo],
  };
};

export const replaceFiles = async (values: SnippetValues<VideoSnippet>, sessionId: string) => {
  const filesIds = values.videos?.map((video) => video?.slide.id);

  if (filesIds?.length) {
    await replaceTempFile({
      query: {
        sessionId: sessionId,
        ids: [...filesIds] as string[],
      },
    });
  }
};

export const convertVideosUrl = (values: SnippetValues<VideoSnippet>): Video[] => {
  if (!values.videos) return [];

  return values.videos.map((video) => {
    const url = video.videoUrl;
    let convertedUrl: string;

    if (YOUTUBE_URL_MAPPER.test(url)) {
      convertedUrl = url.replace(YOUTUBE_URL_MAPPER, 'https://www.youtube.com/embed/$1');
    } else if (RUTUBE_URL_MAPPER.test(url)) {
      convertedUrl = url.replace(RUTUBE_URL_MAPPER, 'https://rutube.ru/play/embed/$1');
    } else {
      convertedUrl = url;
    }
    return {
      ...video,
      videoUrl: convertedUrl,
    };
  });
};
