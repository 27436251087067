import { useState } from 'react';
import { Box, Button, Group, Text } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { Filter, RotateClockwise } from 'tabler-icons-react';

import { formatDate } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { MultiSelect, TOption } from '@/ui/components/MultiSelect';
import { useGetMaterialsList } from '@/ui/containers/MaterialsContainer/Materials.hooks';

import { useDictionaryOptions } from '@/store/slices/dictionary';
import {
  IInitialState,
  materialsListingFilters,
  resetMaterialsFilter,
  setMaterialsFilter,
} from '@/store/slices/materials/materials';

export const MaterialsFilter = () => {
  const dispatch = useAppDispatch();
  const [moreOptions, setMoreOptions] = useState(false);

  const { data: ageSelectOptions } = useDictionaryOptions('cnclassage', 'code');
  const { data: controlSelectOptions } = useDictionaryOptions('typeofcontrol', 'code');
  const { data: umkSelectOptions } = useDictionaryOptions('attrlineumk', 'code');
  const { data: roleSelectOptions } = useDictionaryOptions('readerrole', 'code');
  const { data: levelSelectOptions } = useDictionaryOptions('cnedulevelid', 'code');
  const { data: subjectSelectOptions } = useDictionaryOptions('cnsubject', 'code');
  const { data: themeSelectOptions } = useDictionaryOptions('theme', 'code');
  const { data: directionSelectOptions } = useDictionaryOptions('cndirectionid', 'code');
  const { data: authorsSelectOptions } = useDictionaryOptions('materialauthor', 'code');
  const { data: typeSelectOptions } = useDictionaryOptions('materialtype', 'code');
  const { data: digitalServicesSelectOptions } = useDictionaryOptions('digitalservice', 'code');

  const {
    type,
    typeOfControl,
    direction,
    author,
    lineUmk,
    theme,
    readerRole,
    age,
    educationLevel,
    subject,
    digitalService,
  } = useAppSelector(materialsListingFilters);

  const [datePickerVal] = useState();

  const getMaterialsList = useGetMaterialsList();
  const onReset = () => {
    dispatch(resetMaterialsFilter());
    getMaterialsList();
  };
  const onChange = (params: Partial<IInitialState['filter']>) => {
    dispatch(setMaterialsFilter(params));
    getMaterialsList();
  };
  return (
    <>
      <Group position='left' align='flex-end' spacing='xs' sx={{ rowGap: 24 }} grow>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 150 }}>
          <Text size='sm' color='grayDefault'>
            Тип материала
          </Text>
          <MultiSelect
            options={typeSelectOptions}
            label='Выберите тип'
            value={type}
            onChange={(value) => onChange({ type: value as TOption[], page: 1 })}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 226 }}>
          <Text size='sm' color='grayDefault'>
            Период создания
          </Text>
          <DateRangePicker
            allowSingleDateInRange
            dropdownPosition='bottom-start'
            value={datePickerVal}
            onChange={(value) =>
              onChange({
                createDateFrom: value[0],
                createDateTo: value[1],
                page: 1,
              })
            }
            locale='ru'
            placeholder='Выберите период'
            inputFormat={formatDate}
            sx={(mantineTheme) => ({
              input: {
                height: 39,
                borderColor: mantineTheme.colors.greyLink[9],
                '&:hover': { borderColor: mantineTheme.colors.primary[9] },
                '&:active': { borderColor: mantineTheme.colors.primary[9] },
                '::placeholder': {
                  color: mantineTheme.colors.greyDefault[9],
                  fontSize: 16,
                },
              },
              'button[data-in-range]': {
                color: '#fff !important',
              },
            })}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 296 }}>
          <Text size='sm' color='grayDefault'>
            Роль читателя
          </Text>
          <MultiSelect
            options={roleSelectOptions}
            label='Выберите роль'
            value={readerRole}
            onChange={(value) => onChange({ readerRole: value as TOption[], page: 1 })}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 296 }}>
          <Text size='sm' color='grayDefault'>
            Класс и возраст
          </Text>
          <MultiSelect
            options={ageSelectOptions}
            label='Выберите класс'
            value={age}
            onChange={(value) => onChange({ age: value as TOption[], page: 1 })}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: 38,
            maxWidth: 'fit-content',
            marginLeft: 'auto',
            gap: 8,
          }}
        >
          <Button
            variant='subtle'
            radius='xs'
            size='sm'
            compact
            sx={({ colors }) => ({
              color: colors.primary[9],
              ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
            })}
            rightIcon={<RotateClockwise size={20} />}
            onClick={onReset}
          >
            Сбросить
          </Button>

          <Button
            onClick={() => setMoreOptions((prev) => !prev)}
            sx={{
              width: '190px',
              maxWidth: '190px',
              height: '42px',
              display: 'flex',
              gap: 100,
              justifyContent: 'center',
            }}
          >
            <Filter fill='white' style={{ marginRight: 4 }} />
            {moreOptions ? 'Скрыть фильтры' : 'Ещё фильтры'}
          </Button>
        </Box>
      </Group>
      {moreOptions && (
        <>
          <Group position='left' align='flex-end' spacing='xs' mt={24} sx={{ rowGap: 24 }} grow>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 220,
                maxWidth: 300,
              }}
            >
              <Text size='sm' color='grayDefault'>
                Направление
              </Text>
              <MultiSelect
                options={directionSelectOptions}
                label='Выберите направление'
                value={direction}
                onChange={(value) => onChange({ direction: value as TOption[], page: 1 })}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 182 }}>
              <Text size='sm' color='grayDefault'>
                Форма контроля
              </Text>
              <MultiSelect
                options={controlSelectOptions}
                label='Форма контроля'
                value={typeOfControl}
                onChange={(value) => onChange({ typeOfControl: value as TOption[], page: 1 })}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, minWidth: 300 }}>
              <Text size='sm' color='grayDefault'>
                Уровень образования
              </Text>
              <MultiSelect
                options={levelSelectOptions}
                label='Уровень образования'
                value={educationLevel}
                onChange={(value) => onChange({ educationLevel: value as TOption[], page: 1 })}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <Text size='sm' color='grayDefault'>
                Предмет
              </Text>
              <MultiSelect
                options={subjectSelectOptions}
                label='Предмет'
                value={subject}
                onChange={(value) => onChange({ subject: value as TOption[], page: 1 })}
              />
            </Box>
          </Group>
          <Group position='left' align='flex-end' spacing='xs' mt={24} sx={{ rowGap: 24 }} grow>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                maxWidth: 216,
              }}
            >
              <Text size='sm' color='grayDefault'>
                Тема
              </Text>
              <MultiSelect
                options={themeSelectOptions}
                label='Введите тему'
                value={theme}
                onChange={(value) => onChange({ theme: value as TOption[], page: 1 })}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 200,
                maxWidth: '100%',
              }}
            >
              <Text size='sm' color='grayDefault'>
                Линия УМК
              </Text>
              <MultiSelect
                options={umkSelectOptions}
                label='Линия УМК'
                value={lineUmk}
                onChange={(value) => onChange({ lineUmk: value as TOption[], page: 1 })}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 200,
                maxWidth: '100%',
              }}
            >
              <Text size='sm' color='grayDefault'>
                Авторы
              </Text>
              <MultiSelect
                options={authorsSelectOptions}
                label='Авторы'
                value={author}
                onChange={(value) => onChange({ author: value as TOption[], page: 1 })}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                minWidth: 200,
                maxWidth: '100%',
              }}
            >
              <Text size='sm' color='grayDefault'>
                Цифровые сервисы
              </Text>
              <MultiSelect
                options={digitalServicesSelectOptions}
                label='Цифровые сервисы'
                value={digitalService}
                onChange={(value) => onChange({ digitalService: value as TOption[], page: 1 })}
              />
            </Box>
          </Group>
        </>
      )}
    </>
  );
};
