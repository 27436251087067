import { Sx } from '@mantine/core';

export const root: Sx = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',
  height: 'fit-content',

  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
};

export const actions: Sx = {
  marginTop: 16,
};
