import type { UseFormReturnType } from '@mantine/form';
import { DownloadFile, DownloadSnippet, SnippetValues } from '@prosv/core/types/snippets';

import { EDownloadsSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal/DownloadsSnippetModal.constants';

type TUseDownloadsSnippetModalHandlers = {
  form: UseFormReturnType<SnippetValues<DownloadSnippet>>;
};

export const useDownloadsSnippetModalHandlers = ({ form }: TUseDownloadsSnippetModalHandlers) => {
  const handleAddFile = () => {
    const newFile: DownloadFile = {
      [EDownloadsSnippetFormFields.NAME]: '',
      [EDownloadsSnippetFormFields.FILE]: {
        id: '',
        fileSize: '',
        filePath: '',
        fileType: '',
        fileName: '',
      },
    };

    form.values.files?.push(newFile);

    form.setValues(form.values);
  };

  return { handleAddFile };
};
