import React, { useMemo } from 'react';
import { Modal, Title } from '@mantine/core';

import { ModalsVariants, useModalsStore } from '@/store/slices/modalsZustandStore/modalsStore';

const ReviewDetailFileBase = () => {
  const { modal, closeModal } = useModalsStore((state) => state);
  const source = modal.stringSource;

  const mediaType = useMemo(() => {
    const substring = source?.substring(source.length - 6)?.toUpperCase();
    if (['MP4', 'M4V', 'WEBM'].some((format) => substring?.includes(format))) {
      return 'video';
    } else {
      return 'photo';
    }
  }, [source]);

  return (
    <Modal
      centered
      size='xl'
      title={
        <Title order={3}>{mediaType === 'video' ? 'Вложенное видео' : 'Вложенное фото'} </Title>
      }
      opened={modal.currentModal === ModalsVariants.REVIEW_DETAILS_VIDEO}
      onClose={closeModal}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {mediaType === 'video' ? (
          <video width='100%' height='100%' controls autoPlay={false}>
            <source src={modal.stringSource} type='video/mp4' />
          </video>
        ) : (
          <img src={modal.stringSource} alt='вложение ' />
        )}
      </div>
    </Modal>
  );
};

export const ReviewDetailFile = () => {
  const currentModal = useModalsStore((state) => state.modal.currentModal);

  return currentModal === ModalsVariants.REVIEW_DETAILS_VIDEO ? <ReviewDetailFileBase /> : null;
};
