export const imageFields = [
  'desktopImageId',
  'tabletImageId',
  'mobileImageId',
  'logo1ImageId',
  'logo2ImageId',
  'logo3ImageId',
] as const;

export const DATE_FILTERED_FIELDS = [
  'dateActiveFromDays',
  'dateActiveFromHours',
  'dateActiveToDays',
  'dateActiveToHours',
  'page',
  'place',
];

export const SELECTED_PAGE_AUDIO = 'audio';
