import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getFormatDateEndDay, getFormatDateStartDay } from '@/utils/getFormatDateForBackend';
import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import { State } from '@/store';
import { createTableFilterSlice } from '@/store/slices/banners/bannersSlice';
import { umkListDefaultFilter } from '@/store/slices/umkList/umkList.constants';
import { UmkListFilterSlice, UmkListResponse } from '@/store/slices/umkList/umkList.types';

export const umkListFilterSlice = createTableFilterSlice<UmkListFilterSlice>({
  name: 'umkListFilter',
  initialState: umkListDefaultFilter,
});

export const umkListFilterActions = umkListFilterSlice.actions;

export const umkListApi = createApi({
  reducerPath: 'api/umkList',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    getUmkListing: build.query<UmkListResponse, UmkListFilterSlice>({
      query: (params) => {
        const { limit, currentPage, title, type, createdAt, updatedAt, ...rest } = params;
        const newParams = {
          offset: currentPage === 1 ? 0 : Number(limit) * (Number(currentPage) - 1),
          limit,
          name: title,
          type,
          isActive: rest.isActive?.value,
          sortField: rest.sortField,
          sortDirection: rest.sortDirection,
          createdAtFrom: getFormatDateStartDay(createdAt?.[0]),
          createdAtTo: getFormatDateEndDay(createdAt?.[1]),
          updatedAtFrom: getFormatDateStartDay(updatedAt?.[0]),
          updatedAtTo: getFormatDateEndDay(updatedAt?.[1]),
        };

        return {
          url: getUrlWithSearchParams(URLS.umk.list, newParams),
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useLazyGetUmkListingQuery } = umkListApi;

const umkListFilter = (state: State) => state.umkListFilter;

export const umkListFiltersSelector = createSelector(umkListFilter, (filters) => filters);

export const isUmkListDirtySelector = createSelector(umkListFilter, (filters) => {
  const currentFilter: UmkListFilterSlice = {
    type: filters.type,
    isActive: filters.isActive,
    sortField: filters.sortField,
    title: filters.title,
    createdAt: filters.createdAt,
    updatedAt: filters.updatedAt,
  };

  const defaultFilter: UmkListFilterSlice = {
    type: umkListDefaultFilter.type,
    isActive: umkListDefaultFilter.isActive,
    sortField: umkListDefaultFilter.sortField,
    title: umkListDefaultFilter.title,
    createdAt: umkListDefaultFilter.createdAt,
    updatedAt: umkListDefaultFilter.updatedAt,
  };

  return JSON.stringify(currentFilter) !== JSON.stringify(defaultFilter);
});
