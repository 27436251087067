import { EventSnippet, SnippetValues } from '@prosv/core/types/snippets';
import dayjs from 'dayjs';
import { v4 as uuid_v4 } from 'uuid';

import { EEventSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/EventSnippetModal/EventSnippetModal.constants';

export const getEventSnippetInitialValues = (
  defaultValues?: EventSnippet,
): SnippetValues<EventSnippet> => {
  return {
    [EEventSnippetFormFields.ID]: defaultValues?.id || uuid_v4(),
    [EEventSnippetFormFields.LINK]: defaultValues?.link || '',
    [EEventSnippetFormFields.BACKGROUND]: defaultValues?.background || '',
    [EEventSnippetFormFields.IMAGE]: defaultValues?.image || {
      id: '',
      path: '',
      name: '',
    },
    [EEventSnippetFormFields.DESCRIPTION]: defaultValues?.description || '',
    [EEventSnippetFormFields.TITLE]: defaultValues?.title || '',
    [EEventSnippetFormFields.IMAGE_TITLE]: defaultValues?.imageTitle || '',
    [EEventSnippetFormFields.ALT]: defaultValues?.alt || '',
    [EEventSnippetFormFields.DATE]: dayjs(defaultValues?.date).isValid()
      ? new Date(defaultValues?.date as string).toString()
      : '',
    [EEventSnippetFormFields.TIME]: dayjs(defaultValues?.time).isValid()
      ? new Date(defaultValues?.time as string).toString()
      : '',
  };
};
