import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
import { ChevronLeft } from 'tabler-icons-react';

interface IPageHeaderBackLink {
  url: string;
}

const PageHeaderBackLink: FC<IPageHeaderBackLink> = ({ url }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Button
      title='Вернуться'
      size='xs'
      p={0}
      variant='subtle'
      component={Link}
      to={url}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ChevronLeft size={24} color={isHovered ? 'white' : 'blue'} />
    </Button>
  );
};

export default PageHeaderBackLink;
