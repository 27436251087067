import {
  ITemplateResponse,
  ITemplateUpdateResponse,
  TQueryParamsTemplates,
  TQueryUpdateTemplate,
} from '@/types/templates/templates';

import { api } from '@/api';
import { URLS } from '@/api/urls';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

const getTemplateList = async (params: TQueryParamsTemplates) => {
  return api.get<ITemplateResponse>(URLS.templates.getTemplates, {
    params,
  });
};

const updateTemplate = async (data: TQueryUpdateTemplate) => {
  const { id, template } = data;
  return api.post<ITemplateUpdateResponse>(URLS.templates.updateTemplate(id), {
    ...template,
  });
};

export const tryGetTemplateList = (params: TQueryParamsTemplates) => {
  return getTemplateList(params)
    .then((res) => res.data.payload)
    .catch(axiosErrorHandler);
};

export const tryUpdateTemplate = (data: TQueryUpdateTemplate) => {
  return updateTemplate(data)
    .then((res) => res.data.payload)
    .catch(axiosErrorHandler);
};
