import { Sx } from '@mantine/core';

export const root: Sx = {
  maxWidth: 266,
};

export const label: Sx = {
  '.required': {
    color: 'red',
  },
};

export const image: Sx = {
  maxWidth: 266,
  paddingTop: 16,
};
