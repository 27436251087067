import {
  FileIdResponse,
  IQuerySendFileId,
  IUploadFileRedirectsResponse,
  TQueryParamsRedirects,
  TQueryUpdateRedirect,
  TResponse,
} from '@/types';

import { api } from '@/api';
import { URLS } from '@/api/urls';

const getRedirectList = (params: TQueryParamsRedirects) =>
  api.get(URLS.redirects.getRedirects, { params });

const updateRedirect = ({ id, ...params }: TQueryUpdateRedirect) =>
  api.put(URLS.redirects.updateRedirect(id), { ...params });

const deleteRedirect = (id: string) => api.delete(URLS.redirects.deleteRedirect(id));

export const uploadRedirectFile = async (formData: FormData) => {
  const { data } = await api.post<TResponse<IUploadFileRedirectsResponse>>(
    URLS.redirects.uploadFile,
    formData,
    {
      headers: {
        'Content-Type': 'seoRedirectFiles',
      },
    },
  );
  return data.payload.id ?? null;
};

export const sendFileId = (params: { fileId: string }) =>
  api.post(URLS.redirects.postRedirectFileId, { ...params });

export const getRedirectFileParseStatus = async (id: string) =>
  api.get<TResponse<FileIdResponse>>(URLS.redirects.getRedirectParseFile(id));
export const tryGetRedirectList = (params: TQueryParamsRedirects) => {
  return getRedirectList(params)
    .then((res) => res.data)
    .catch(() => {
      throw new Error();
    });
};

export const tryUpdateRedirect = (params: TQueryUpdateRedirect) => {
  return updateRedirect(params)
    .then((res) => res.data)
    .catch(() => {
      throw new Error();
    });
};

export const tryDeleteRedirect = (id: string) => {
  return deleteRedirect(id)
    .then((res) => res.data)
    .catch(() => {
      throw new Error();
    });
};

export const trySendFileId = (params: IQuerySendFileId) => {
  return sendFileId(params)
    .then((res) => res.data.payload)
    .catch(() => {
      throw new Error();
    });
};

export const tryGetRedirectStatus = (id: string) => {
  return getRedirectFileParseStatus(id)
    .then((res) => res.data.payload)
    .catch(() => {
      throw new Error();
    });
};
