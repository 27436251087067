import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import { IGetMaterialResponse } from '@/ui/containers/MaterialsContainer/Materials.types';

import api from '../../instance';

interface IParams {
  uuid: string;
  modifiedViewsCount: number;
}

export const useModifiedViews = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    modifiedViews: async ({ uuid, modifiedViewsCount }: IParams) => {
      setIsLoading(true);
      try {
        const request = await api.patch<IGetMaterialResponse>(
          URLS.materials.patchMaterialsViews(uuid),
          {
            modifiedViewsCount,
          },
        );
        notify({ message: 'Количество просмотров обновлено', type: 'success' });

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка обновления количества просмотров', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
