import { IRedirect } from '@/types/redirects/redirects';

import React, { ChangeEvent, FC } from 'react';
import { Box, Button, Group, Modal, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { EMenuContainerEditRedirectConstants } from '@/ui/containers/RedirectsContainer/components/EditRedirectModal/EditRedirectModal.constants';

import { getRedirectInitialValues } from './EditRedirectModal.helpers';
import { NEW_ITEM_FORM } from './EditRedirectModal.schema';

interface IProps {
  isOpen: boolean;
  editItem: IRedirect;
  onChange: (item: IRedirect) => void;
  onClose: () => void;
}

export const EditRedirectModal: FC<IProps> = ({ isOpen, editItem, onChange, onClose }) => {
  const editRedirectForm = useForm({
    initialValues: getRedirectInitialValues(editItem),
    validate: yupResolver(NEW_ITEM_FORM),
  });

  const handleSubmit = (values: Omit<IRedirect, 'id' | 'type'>) => {
    if (editRedirectForm.isValid()) {
      onChange({ ...editItem, ...values });
      editRedirectForm.reset();
    } else {
      editRedirectForm.validate();
    }
    onClose();
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const parsedValue = Number(inputValue);
    editRedirectForm.setFieldValue(EMenuContainerEditRedirectConstants.CODE, parsedValue);
  };

  return (
    <Modal
      opened={isOpen}
      centered
      onClose={onClose}
      styles={{
        header: {
          marginBottom: 0,
        },
      }}
      size={640}
      title={
        <Text pt={32} size={24} align='left' weight={500}>
          Настройка редиректа
        </Text>
      }
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <form
        style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '24px' }}
        onSubmit={editRedirectForm.onSubmit((values) => handleSubmit(values))}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <TextInput
            withAsterisk
            label='Старый URL'
            placeholder='Введите старый URL'
            {...editRedirectForm.getInputProps(EMenuContainerEditRedirectConstants.OLD_URL)}
          />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <TextInput
            withAsterisk
            label='Новый URL'
            placeholder='Введите новый URL'
            {...editRedirectForm.getInputProps(EMenuContainerEditRedirectConstants.NEW_URL)}
          />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <TextInput
            withAsterisk
            type='number'
            label='Код редикрета'
            placeholder='Введите код редиректа'
            onChange={handleNumberChange}
            value={editRedirectForm.values[EMenuContainerEditRedirectConstants.CODE].toString()}
            error={editRedirectForm.errors[EMenuContainerEditRedirectConstants.CODE]}
          />
        </Box>
        <Group position='right' pt={32}>
          <Button type='submit' px={60} sx={{ fontSize: 16 }}>
            Подтвердить
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
