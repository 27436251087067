import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker, useNavigate, useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Paper } from '@mantine/core';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { EAddNewsConfirmationTexts } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { ActionButtons } from '@/ui/containers/TemplateCreationContainer/ActionButtons/ActionButton';
import { getBreadcrumbs } from '@/ui/containers/TemplateCreationContainer/TemplateCreationContainer.helpers';
import { TemplateDetailInfo } from '@/ui/containers/TemplateCreationContainer/TemplateDetailInfo/TemplateDetailInfo';
import { TEMPLATE_CREATION_FORM_ID } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.constants';
import { getRobotValues } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.helpers';
import { ITemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

import { AppDispatch } from '@/store';
import { templateEditSelector, updateTemplateAction } from '@/store/slices/templates/templates';

const TemplateCreationContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const editTemplate = useSelector(templateEditSelector);

  const { id } = useParams();
  const navigate = useNavigate();

  const [isShouldBlock, setIsShouldBlock] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmited, setIsSumbited] = useState(false);
  const [isSaveWithRedirect, setIsSaveWithRedirect] = useState(false);

  const { proceed, reset, state } = useBlocker(isShouldBlock && !isSubmited);
  const title = `ID: ${id?.slice(0, 5).toUpperCase()}`;

  const breadcrumbs = getBreadcrumbs(title);

  const onConfirmLeave = useCallback(() => {
    if (proceed) {
      proceed();
    }
  }, [proceed]);

  const onCancel = useCallback(() => {
    if (reset) {
      reset();
    }
  }, [reset]);

  const handleSubmit = async (values: ITemplateFormFields) => {
    const { robots: robotsValues, pageType: type, site: siteName, ...restProperties } = values;

    const arrRobots = getRobotValues(robotsValues);

    try {
      const updatedTemplate = {
        ...restProperties,
        robots: arrRobots,
        pageType: type.value,
        site: siteName.value,
      };
      if (id) {
        setIsUpdating(true);
        await dispatch(
          updateTemplateAction({
            id,
            template: updatedTemplate,
          }),
        );
        setIsSumbited(true);
      }
    } catch (e) {
      setIsUpdating(false);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSaveWithRedirect = () => {
    setIsSaveWithRedirect(true);
  };

  useEffect(() => {
    if (isSubmited && isSaveWithRedirect) {
      navigate(`/templates-meta-tags`);
    }
  }, [isSubmited, isSaveWithRedirect]);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        breadcrumbs={breadcrumbs}
        backLink='/templates-meta-tags'
        title={title}
        rightButton={
          <ActionButtons
            formId={TEMPLATE_CREATION_FORM_ID}
            handleSaveWithRedirect={handleSaveWithRedirect}
          />
        }
      />

      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          overflow: 'scroll',
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={isUpdating} />
        <TemplateDetailInfo
          template={editTemplate}
          setIsShouldBlock={setIsShouldBlock}
          handleSubmit={handleSubmit}
        />

        {state === 'blocked' && (
          <ModalConfirm
            title={EAddNewsConfirmationTexts.OUT_TITLE}
            text={EAddNewsConfirmationTexts.NOT_SAVE_MENU_SITE}
            confirmHandler={onConfirmLeave}
            opened={state === 'blocked'}
            onClose={onCancel}
          />
        )}
      </Paper>
    </Box>
  );
};

export default TemplateCreationContainer;
