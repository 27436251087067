import {
  Payload,
  TemplateItem,
  TQueryParamsTemplates,
  TQueryUpdateTemplate,
} from '@/types/templates/templates';

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryGetTemplateList, tryUpdateTemplate } from '@/api/templates/templates';

import notify from '@/utils/notify';

import { LIMIT, PAGE } from '@/constants/common';

import { TOption } from '@/ui/components/MultiSelect';
import {
  ERROR_MESSAGE,
  NOT_FOUND_MESSAGE,
} from '@/ui/containers/TemplatesContainer/TemplatesContainer.constants';

import { State } from '@/store';

interface ITemplatesState {
  status: {
    fetchingTemplateList: boolean;
  };
  items: TemplateItem[] | null;
  total: number | null;
  limit: number;
  offset: number;
  searchSiteName: TOption[] | null;
  searchPageType: TOption[] | null;
  filterMessage: string | null;
  editTemplateItem: TemplateItem | null;
  updatedTemplateItems: TemplateItem[] | null;
}

const initialState: ITemplatesState = {
  status: {
    fetchingTemplateList: false,
  },
  items: null,
  total: null,
  limit: LIMIT,
  offset: PAGE,
  searchSiteName: null,
  searchPageType: null,
  filterMessage: null,
  editTemplateItem: null,
  updatedTemplateItems: null,
};

export const fetchTemplateListAction = createAsyncThunk(
  '/templateList',
  async (params: TQueryParamsTemplates, { rejectWithValue }) => {
    try {
      const result = await tryGetTemplateList(params);
      return result as Payload;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const updateTemplateAction = createAsyncThunk(
  '/updateTemplate',
  async (params: TQueryUpdateTemplate, { rejectWithValue }) => {
    try {
      return (await tryUpdateTemplate(params)) as TemplateItem;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setLoadingTemplates: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingTemplateList = action.payload;
    },
    setTemplatesOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setSearchSiteName: (state, action) => {
      state.searchSiteName = action.payload;
    },
    setSearchPageType: (state, action) => {
      state.searchPageType = action.payload;
    },
    setEditTemplateItem: (state, action) => {
      if (state.items) {
        state.editTemplateItem = state.items.find((item) => item.id === action.payload) ?? null;
      }
    },
    setUpdateTemplateItem: (state, action) => {
      if (state.updatedTemplateItems) {
        state.updatedTemplateItems.push(action.payload);
      } else {
        state.updatedTemplateItems = [action.payload];
      }
      notify({ message: 'Шаблон сохранен', type: 'success' });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplateListAction.pending, (state) => {
        state.status.fetchingTemplateList = true;
      })
      .addCase(fetchTemplateListAction.fulfilled, (state, action) => {
        const { items, pagination } = action.payload;
        state.status.fetchingTemplateList = false;
        state.items = items;
        state.total = pagination.total;
        if (state.searchSiteName || (state.searchPageType && !items.length)) {
          state.filterMessage = NOT_FOUND_MESSAGE;
        }
      })
      .addCase(fetchTemplateListAction.rejected, (state) => {
        if (state.searchSiteName || state.searchPageType) {
          state.filterMessage = ERROR_MESSAGE;
        }
        state.status.fetchingTemplateList = false;
      })
      .addCase(updateTemplateAction.pending, (state) => {
        state.status.fetchingTemplateList = true;
      })
      .addCase(updateTemplateAction.fulfilled, (state, action) => {
        const { payload } = action;
        state.status.fetchingTemplateList = false;
        if (state.items) {
          state.items = state.items.map((item) => (item.id === payload.id ? payload : item));
        }
        notify({ message: 'Шаблон успешно обновлен', type: 'success' });
      })
      .addCase(updateTemplateAction.rejected, (state) => {
        notify({ message: 'Ошибка обновления шаблона', type: 'error' });
        state.status.fetchingTemplateList = false;
      });
  },
});

//selectors
export const templatesListSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.items,
);

export const loadingTemplateListSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.status.fetchingTemplateList,
);
export const templatesTotalSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.total,
);
export const templatesLimitSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.limit,
);
export const templatesOffsetSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.offset,
);
export const templatesSearchMessageSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.filterMessage,
);

export const templatesSiteNameFilterSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.searchSiteName,
);

export const templatesPageTypeFilterSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.searchPageType,
);

export const templateEditSelector = createSelector(
  (state: State) => state.templates,
  (templates) => templates.editTemplateItem,
);

export const {
  setTemplatesOffset,
  setSearchPageType,
  setSearchSiteName,
  setEditTemplateItem,
  setUpdateTemplateItem,
} = templatesSlice.actions;

export default templatesSlice.reducer;
