export const permissionCodes = [
  'ARTICLE_GET',
  'ARTICLE_GET_LIST',
  'ARTICLE_CREATE',
  'BANNER_GET',
  'BANNER_GET_LIST',
  'BANNER_CREATE',
  'DICTIONARY_CREATE',
  'DICTIONARY_UPDATE',
  'DICTIONARY_DELETE',
  'GET_DRAFT',
  'CREATE_DRAFT',
  'FAQ_GET',
  'FAQ_GET_LIST',
  'FAQ_CREATE',
  'FAQ_UPDATE',
  'FAQ_DELETE',
  'FEATURE_TOGGLE_GET_ALL',
  'FEATURE_TOGGLE_UPDATE',
  'UPLOAD_FILE',
  'TMP_UPLOAD_FILE',
  'GET_GROUPS',
  'MENU_GET',
  'MENU_GET_LIST',
  'MENU_UPDATE',
  'NEWS_GET',
  'NEWS_GET_LIST',
  'NEWS_CREATE',
  'PROJECT_VIEW',
  'PROJECT_GET_LIST',
  'PROJECT_CREATE',
  'PROJECT_UPDATE',
  'PROJECT_UPDATE_STATUS',
  'PROJECT_DELETE',
  'PROMOTION_GET',
  'PROMOTION_GET_LIST',
  'PROMOTION_CREATE',
  'PROMOTION_UPDATE',
  'PROMOTION_DELETE',
  'QUESTION_GET',
  'QUESTION_GET_LIST',
  'QUESTION_ANSWER_CREATE',
  'QUESTION_ANSWER_UPDATE',
  'REVIEW_VIEW',
  'LIST_REDIRECTS',
  'CREATE_REDIRECT',
  'VIEW_SEO_TEMPLATE',
  'LIST_SEO_TEMPLATE',
  'CREATE_SEO_TEMPLATE',
  'CREATE_SEO_META',
  'LIST_SEO_META',
  'VIEW_SEO_META',
  'SETTING_GET_ALL',
  'SETTING_UPDATE',
  'SEND_PARTNERS_FORM',
  'SEND_PROSV_REGIONS_FORM',
  'SEND_PRACTICE_FORM',
  'SEND_CONTACT_FORM',
  'SEND_PRESS_CENTER_INQUIRY_FORM',
  'SEND_CAREER_FORM',
  'UMK_PAGE_GET_LIST',
] as const;

export type PermissionCode = (typeof permissionCodes)[number];

export const permissionGroups = {
  CONFIG: [
    'SETTING_GET_ALL',
    'UPLOAD_FILE',
    'FEATURE_TOGGLE_GET_ALL',
    'MENU_GET_LIST',
    'PROJECT_GET_LIST',
    'DICTIONARY_CREATE',
    'DICTIONARY_UPDATE',
    'DICTIONARY_DELETE',
  ],

  CONTENT: ['ARTICLE_GET_LIST', 'NEWS_GET_LIST', 'BANNER_GET_LIST', 'PROMOTION_GET_LIST'],
  SEO: ['LIST_SEO_META', 'LIST_SEO_TEMPLATE', 'LIST_REDIRECTS'],
  SUPPORT: ['REVIEW_VIEW', 'QUESTION_GET_LIST', 'FAQ_GET_LIST'],

  DICTIONARY: ['DICTIONARY_CREATE', 'DICTIONARY_UPDATE', 'DICTIONARY_DELETE'],
} as const; /* satisfies Record<string, PermissionCode[]>; */

export type PermissionGroup = keyof typeof permissionGroups;
export type Permission = PermissionCode | PermissionGroup;
export type GetPermission = (params?: Permission | Permission[]) => boolean | null;

export interface ItemPermissions {
  canGet?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  canUpdateActivity?: boolean;
  canUpdateViewsCount?: boolean;

  /** право на просмотр (добавляется не всегда). */
  canView?: boolean;
  /** право на сохранение (добавляется не всегда). */
  canSave?: boolean;
}

export type ItemPermissionsPrefix = 'ARTICLE_' | 'BANNER_' | 'NEWS_' | 'PROMOTION_' | 'UMK_PAGE_';
