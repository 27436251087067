import { IGetNewsResponse, INewsUpdateData } from '@/types/news/newsDetail';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

interface IParams {
  uuid: string;
  newsData: INewsUpdateData;
}

export const useUpdateNews = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    updateNews: async ({ uuid, newsData }: IParams) => {
      setIsLoading(true);
      try {
        const request = await api.patch<IGetNewsResponse>(URLS.news.putNews(uuid), { ...newsData });
        notify({ message: 'Новость успешно обновлена', type: 'success' });
        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка обновления новости', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
