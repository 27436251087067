import {
  IResponseMetaTag,
  IUpdateResponse,
  TQueryParamsMetaTag,
  TQueryUpdateMetaTag,
} from '@/types/metaTagsAnyPage/metaTagsAnyPage';

import { api } from '@/api';
import { URLS } from '@/api/urls';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

const getMetaTagList = async (params: TQueryParamsMetaTag) => {
  return api.get<IResponseMetaTag>(URLS.metaTagsAnyPage.getMetaTagsList, { params });
};

const updateMetaTag = async (params: TQueryUpdateMetaTag) => {
  const { id, ...data } = params;
  return api.post<IUpdateResponse>(URLS.metaTagsAnyPage.updateMetaTag(id), data);
};

export const tryGetMetaTagList = (params: TQueryParamsMetaTag) => {
  return getMetaTagList(params)
    .then((res) => res.data.payload)
    .catch(axiosErrorHandler);
};

export const tryUpdateMetaTag = (params: TQueryUpdateMetaTag) => {
  return updateMetaTag(params)
    .then((res) => res.data.payload)
    .catch(axiosErrorHandler);
};
