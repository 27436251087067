import { TAuthParams } from '@/types/auth/auth';

import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Center, Grid, Paper, Title, } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';

import { tryGetAuthSSO } from '@/api/auth/auth';

import notify from '@/utils/notify';

import { AUTH_ERR } from '@/constants/auth';
import { TITLE_AUTH } from '@/constants/titles';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { AUTH_ERROR_MESSAGE } from '@/pages/Auth/Auth.constants';
import {
  fetchAuth,
  fetchingGetConfiguration,
  setIsLoggin,
  tokenSelector,
} from '@/store/slices/auth/auth';

const AuthPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isAuthError = searchParams.get(AUTH_ERR);
  const redirectTo = searchParams.get('redirectTo');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const fetching = useSelector(fetchingGetConfiguration);

  useDocumentTitle(TITLE_AUTH);

  useEffect(() => {
    if (token) {
      if (redirectTo) {
        window.location.href = redirectTo;
      } else {
        navigate('/');
      }
    }
  }, [token]);

  useEffect(() => {
    if (isAuthError) {
      notify({ message: AUTH_ERROR_MESSAGE, type: 'error' });
      setSearchParams('');
    }
  }, []);

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
  });

  const data = {
    grant_type: process.env.GRANT_TYPE,
    client_id: process.env.CLIENT_ID,
    client_secret: process.env.CLIENT_SECRET,
  };

  const handleSubmit = (formData: TAuthParams) => {
    dispatch(fetchAuth({ ...data, ...formData }));
  };

  const handleAuthBySSO = () => {
    try {
      dispatch(setIsLoggin(true));
      tryGetAuthSSO()
        .then((res) => {
          if (res?.payload) {
            window.location.href = res.payload.redirectUrl;
          } else {
            console.error('Нет ссылки в ответе', res);
          }
        })
        .finally(() => dispatch(setIsLoggin(false)));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid gutter={0}>
      <Grid.Col span={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src='../images/auth.svg' alt='логотип' />
      </Grid.Col>
      <Grid.Col span={6} sx={{ borderLeft: '1px solid #F0F1F2' }}>
        <Center sx={{ minHeight: '100vh' }}>
          <Paper p={24} radius={8}>
            <form style={{ width: '300px' }} onSubmit={form.onSubmit(handleSubmit)}>
              <Title order={1} mb={32} align='center'>
                Войти в систему
              </Title>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>

                <Button
                  fullWidth
                  variant='outline'
                  type='button'
                  disabled={fetching}
                  color='gray'
                  sx={{ color: 'black', border: '1px solid #BFCEDB' }}
                  onClick={handleAuthBySSO}
                >
                  Войти через SSO
                </Button>
              </Box>
            </form>
          </Paper>
        </Center>
      </Grid.Col>
    </Grid>
  );
};

export default AuthPage;
