import { SupportDetailResponse } from '@/store/slices/support/supportDetailsTypes';

export const defaultInitialValues: SupportDetailResponse = {
  code: '0',
  message: '',
  payload: {
    draftId: '',
    isActive: true,
    question: '',
    category: { id: '' },
    sort: '',
    isDisplayOnMain: true,
    isDisplayOnHeader: true,
    positionOnHeader: '1',
    positionOnMain: '1',
    urlCode: '',
    id: '',
  },
};

export enum SupportDetailFormField {
  IS_ACTIVE = 'isActive',
  QUESTION = 'question',
  URL_CODE = 'urlCode',
  CATEGORY_ID = 'categoryId',
  SORT = 'sort',
  IS_DISPLAY_ON_MAIN = 'isDisplayOnMain',
  IS_DISPLAY_ON_HEADER = 'isDisplayOnHeader',
  POSITION_ON_MAIN = 'positionOnMain',
  POSITION_ON_HEADER = 'positionOnHeader',
  DRAFT_ID = 'draftId',
}
