import { TProductsResponse } from '@/types/products/products';

import { URLS } from '@/api/urls';

import api from '../instance';

export type FiltersParam = {
  code: string;
  values?: string[];
};

export type TSortProductRequest = 'default' | 'novelty' | 'price' | 'discount';

export interface ProductRequest {
  categoryCode?: string;
  selectionCode?: string;
  query?: {
    query: string;
  };
  filters?: FiltersParam[];
  pagination: {
    limit: number;
    offset: number;
  };
  sort?: {
    field: TSortProductRequest;
    direction: 'asc' | 'desc';
  };
}

export interface FiltersRequest {
  categoryCode?: string;
  selectionCode?: string;
  query?: {
    query: string;
  };
  filters?: FiltersParam[];
}

export const apiGetProductsSuggest = (search: string) => {
  return api.post<TProductsResponse>(URLS.catalog.getProductSuggest, { search });
};

export const apiGetProduct = (id: string) => {
  return api.get(URLS.catalog.getProduct(id));
};

export const apiGetProducts = (params: ProductRequest) => {
  return api.post(URLS.catalog.getProducts, { ...params });
};

export const apiGetCatalogCategories = () => {
  return api.get(URLS.catalog.getCatalogCategory);
};

export const apiGetCatalogFilters = (params: FiltersRequest) => {
  return api.post(URLS.catalog.getCatalogFilters, params);
};

export const tryGetCatalogFilterss = (params: FiltersRequest) => {
  return apiGetCatalogFilters(params).then((res) => res.data);
};

export const tryGetProductsSuggest = (params: string): Promise<TProductsResponse> => {
  return apiGetProductsSuggest(params).then((res) => res.data);
};

export const tryGetCatalogFilters = () => {
  return apiGetCatalogCategories().then((res) => res.data);
};

export const tryGetProduct = (id: string) => {
  return apiGetProduct(id).then((res) => res.data);
};

export const tryGetProducts = (params: ProductRequest): Promise<TProductsResponse> => {
  return apiGetProducts(params).then((res) => res.data);
};
