export const pageTypeOptions = [
  {
    value: 'material',
    label: 'Материал',
  },
  {
    value: 'news',
    label: 'Новость',
  },
  {
    value: 'lending',
    label: 'Лендинг',
  },
  {
    value: 'conference',
    label: 'Конференция',
  },
  {
    value: 'event',
    label: 'Мероприятие',
  },
];

export const siteOptions = [
  {
    value: 'prosv',
    label: 'prosv.ru',
  },
  {
    value: 'corp',
    label: 'corp.prosv.ru',
  },
];

export const ERROR_MESSAGE = 'Фильтрация пока не доступна. Повторите попытку позднее';
export const NOT_FOUND_MESSAGE =
  'К сожалению, по вашему запросу ничего не найдено. Пожалуйста, измените параметры, чтобы найти интересующую вас информацию';
