import { useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Radio, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { InputWithBadges } from '@/pages/Projects/ProjectDetails/components/InputWithBadges';
import { validationSchema } from '@/pages/Projects/ProjectDetails/components/projectDetailFormSchema';
import {
  FORM_ID,
  ProjectDetailsValuesEnum,
} from '@/pages/Projects/ProjectDetails/ProjectDetails.consts';
import {
  useLazyCreateProjectQuery,
  useLazyEditProjectQuery,
} from '@/store/slices/projects/projectsSlice';
import { projectTypeOptions } from '@/store/slices/projects/projectsSlice.constants';
import { FormValues } from '@/store/slices/projects/projectsSlice.types';

export const ProjectDetailForm = ({ initialValues }: { initialValues: FormValues }) => {
  const form = useForm<FormValues>({
    initialValues,
    validate: yupResolver(validationSchema),
  });
  const [createRequest, createResponse] = useLazyCreateProjectQuery();
  const [editRequest, editResponse] = useLazyEditProjectQuery();

  const type = form.values?.type;
  const params = useParams();

  const handleSubmit = (data: FormValues) => {
    if (params.id) {
      editRequest(data);
    } else {
      createRequest(data);
    }
  };

  return (
    <>
      {(createResponse.isFetching || editResponse.isFetching) && <LoadingOverlay visible={true} />}

      <form
        id={FORM_ID}
        onSubmit={form.onSubmit((values, event) => {
          event.preventDefault();
          handleSubmit(values);
        })}
        style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 24 }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 24 }}>
          <TextInput
            withAsterisk
            label='Название проекта'
            placeholder='Введите название проекта'
            {...form.getInputProps(ProjectDetailsValuesEnum.NAME)}
          />

          <Radio.Group
            label='Активность'
            withAsterisk
            sx={{ marginRight: 60 }}
            {...form.getInputProps(ProjectDetailsValuesEnum.IS_ACTIVE)}
          >
            <Radio value='true' label='Да' />
            <Radio value='false' label='Нет' />
          </Radio.Group>
        </Box>
        <TextInput
          readOnly={!!params.id}
          withAsterisk
          label='UUID спецпроекта'
          placeholder='Введите UUID спецпроекта'
          {...form.getInputProps(ProjectDetailsValuesEnum.UUID)}
        />
        <Select
          required
          label='Тип'
          placeholder='Выберите тип'
          data={projectTypeOptions}
          {...form.getInputProps(ProjectDetailsValuesEnum.TYPE)}
        />
        {type === 'landing' && (
          <InputWithBadges
            label='ID проекта в id.prosv.ru'
            placeholder='ВВедите ID проекта'
            {...form.getInputProps(ProjectDetailsValuesEnum.PROJECT_ID)}
          />
        )}

        {type === 'eventBased' && (
          <InputWithBadges
            inputType='number'
            placeholder='Введите ID мероприятия'
            label='Связанные мероприятия'
            {...form.getInputProps(ProjectDetailsValuesEnum.EVENT_IDS)}
          />
        )}
      </form>
    </>
  );
};
