import { useState } from 'react';

import { IGetProductsByIdsResponse } from '@/api/catalog/categories';
import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

const emptyResponse: IGetProductsByIdsResponse = {
  code: '200',
  message: 'Default response',
  payload: {
    items: [],
    meta: { count: 0 },
    texts: [],
  },
};

export const useProductsByIds = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getProductsByIds: async ({ productIds }: { productIds: string[] }) => {
      if (!productIds.length) return emptyResponse;
      setIsLoading(true);
      try {
        const request = await api.post<IGetProductsByIdsResponse>(URLS.catalog.getProductsByIds, {
          productIds,
        });

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения продуктов по id', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
