import { FC } from 'react';
import { Select } from '@mantine/core';

import { DictionarySelectProps } from './DictionarySelect.types';

import { useDictionaryOptions } from '@/store/slices/dictionary';

export const DictionarySelect: FC<DictionarySelectProps> = ({
  dictionary,
  idField,
  disabled,
  ...props
}) => {
  const { data } = useDictionaryOptions(dictionary, idField);

  return <Select data={data} disabled={disabled || !data.length} {...props} />;
};
