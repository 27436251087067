import { CSSProperties, FC } from 'react';
import { Text } from '@mantine/core';

interface IProps {
  title: string;
  numSpanInGrid?: string;
  isRequire?: boolean;
  styles?: CSSProperties;
}

export const CustomLabel: FC<IProps> = ({ title, numSpanInGrid, isRequire = false, styles }) => {
  return (
    <Text sx={{ gridColumn: `span ${numSpanInGrid}` }} style={styles}>
      {title} {isRequire && <span style={{ color: '#EB5757' }}>{'*'}</span>}
    </Text>
  );
};
