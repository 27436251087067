import { CategoryResponse } from '@prosv/core/types/bff/catalog/admin/category';
import { createTree } from '@prosv/core/utils/tree';
import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { createCategoriesOptions } from './categories.helpers';

export const categoriesSlice = createApi({
  reducerPath: 'apiCategories',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Categories'],
  endpoints: (build) => ({
    getCategories: build.query<CategoryResponse['payload']['items'], void>({
      query: () => ({ method: 'get', url: URLS.catalog.getCatalogCategory }),
      transformResponse: ({ payload }: CategoryResponse) => payload.items,
      providesTags: [{ type: 'Categories', id: 'LIST' }],
    }),
  }),
});

export const { useGetCategoriesQuery } = categoriesSlice;

export const selectCategories = categoriesSlice.endpoints.getCategories.select();

export const selectCategoriesTree = createSelector(selectCategories, ({ data }) =>
  createTree(data ?? [], {
    sort: ({ sort: a }, { sort: b }) => a - b,
  }),
);

export const selectCategoriesOptions = createSelector(selectCategoriesTree, (tree) =>
  createCategoriesOptions(tree),
);
