export enum ENewsSnippetFormFields {
  TYPE = 'type',
  CATEGORY = 'category',
  THEME = 'theme',
  DIVISION = 'division',
  COMPANY = 'company',
  TAGS = 'tags',
  LOOP_SLIDER = 'loopSlide',
  BACKGROUND = 'background',
  LINK = 'link',
  NEWS = 'news'
}
