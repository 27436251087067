import {
  GetSelectionPlaceResponse,
  SelectionsPlacesRequest,
  SelectionsPlacesResponse,
  UpdateSelectionPlaceRequest,
  UpdateSelectionPlaceResponse,
} from '@prosv/core/types/bff/catalog/admin';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { ISelectionResponse } from '@/api/catalog/categories';
import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import { selectionsPlacesFiltersInitialState } from './selections.constants';

import { State } from '@/store';

export const selectionsSlice = createApi({
  reducerPath: 'api/selections',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Selections', 'SelectionsPlaces'],
  endpoints: (build) => ({
    getSelections: build.query<ISelectionResponse['payload'], void>({
      query: () => ({ method: 'get', url: URLS.catalog.getSelections }),
      transformResponse: ({ payload }: ISelectionResponse) => payload,
      providesTags: [{ type: 'Selections', id: 'LIST' }],
    }),

    getSelectionsPlaces: build.query<
      SelectionsPlacesResponse['payload'],
      SelectionsPlacesRequest | void
    >({
      query: (params = {}) => ({
        method: 'get',
        url: getUrlWithSearchParams(URLS.selectionsPlaces.list, params, { arrayFormat: 'comma' }),
      }),
      transformResponse: ({ payload }: SelectionsPlacesResponse) => payload,
      providesTags: [{ type: 'SelectionsPlaces', id: 'LIST' }],
    }),

    getSelectionPlace: build.query<GetSelectionPlaceResponse['payload'], string>({
      query: (id) => ({ method: 'get', url: URLS.selectionsPlaces.get(id) }),
      transformResponse: ({ payload }: GetSelectionPlaceResponse) => payload,
      providesTags: (res) => (res ? [{ type: 'SelectionsPlaces', id: res.id }] : []),
    }),

    updateSelectionPlace: build.mutation<
      UpdateSelectionPlaceResponse['payload'],
      UpdateSelectionPlaceRequest
    >({
      query: ({ id, ...data }) => ({ method: 'patch', url: URLS.selectionsPlaces.patch(id), data }),
      transformResponse: ({ payload }: UpdateSelectionPlaceResponse) => payload,
      invalidatesTags: (res) =>
        res ? ['SelectionsPlaces', { type: 'SelectionsPlaces', id: res.id }] : [],
    }),
  }),
});

export const selectionsPlacesFiltersSlice = createSlice({
  name: 'selectionsPlacesFilters',
  initialState: selectionsPlacesFiltersInitialState,
  reducers: {
    resetFilters: () => selectionsPlacesFiltersInitialState,
    setFilters: (_, { payload }: PayloadAction<SelectionsPlacesRequest>) => payload,
    changeFilters: (state, { payload }: PayloadAction<SelectionsPlacesRequest>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const selectionsPlacesFiltersActions = selectionsPlacesFiltersSlice.actions;

export const getSelectionsPlacesFilters = (state: State) => state.selectionsPlacesFilters;

export const {
  useGetSelectionsQuery,
  useLazyGetSelectionsQuery,
  useGetSelectionsPlacesQuery,
  useLazyGetSelectionsPlacesQuery,
  useGetSelectionPlaceQuery,
  useLazyGetSelectionPlaceQuery,
  useUpdateSelectionPlaceMutation,
} = selectionsSlice;
