import { FC } from 'react';
import { useDocumentTitle } from '@mantine/hooks';

import { TITLE_REDIRECTS } from '@/constants/titles';

import { RedirectsContainer } from '@/ui/containers/RedirectsContainer';

const Redirects: FC = () => {
  useDocumentTitle(TITLE_REDIRECTS);

  return <RedirectsContainer />;
};

export default Redirects;
