import { TBreadcrumbsExtItem } from '@/types';

export const breadcrumbs: TBreadcrumbsExtItem[] = [
  {
    name: 'Управление УМК',
    url: '/umk/',
  },
  {
    name: 'Детальная страница УМК',
  },
];

export const UMK_FORM_ID = 'umkForm';
