import { FC, useEffect } from 'react';
import { Autocomplete, Text } from '@mantine/core';
import { AutocompleteProps } from '@mantine/core/lib/Autocomplete/Autocomplete';
import { useDebouncedState } from '@mantine/hooks';

interface ILazyAutocompleteInput extends AutocompleteProps {
  searchAction: (query: string) => void;
  autoCompleteItem?: FC<any>;
  limit?: number;
}

export const LazyAutocomplete: FC<ILazyAutocompleteInput> = ({
  searchAction,
  autoCompleteItem,
  limit,
  ...props
}) => {
  const [query, setQuery] = useDebouncedState('', 400);

  useEffect(() => {
    searchAction(query);
  }, [query]);

  const nothingFound = <Text>Ничего не найдено, попробуйте изменить поисковые запрос</Text>;

  return (
    <Autocomplete
      {...props}
      itemComponent={autoCompleteItem}
      limit={limit}
      onInput={(e) => setQuery(e.currentTarget.value)}
      nothingFound={nothingFound}
      styles={{
        dropdown: {
          maxHeight: 'calc(100vh - 90%)',
          overflow: 'auto',
        },
      }}
    />
  );
};
