import { Product } from '@prosv/core/types';
import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { create } from 'zustand';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getFormatDateEndDay, getFormatDateStartDay } from '@/utils/getFormatDateForBackend';
import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';
import notify from '@/utils/notify';

import { reviewAndQuestionInitialFilter } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.consts';
import {
  ReviewAndQuestionsQueryParams,
  StatusTypes,
} from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';
import { ReviewsProductsResponse } from '@/ui/containers/Reviews/listing/tabs/ProductsTab/ReviewProductsTab.types';
import { ReviewResponseType } from '@/ui/containers/Reviews/types';

import { IRootReducer } from '@/store';
import { createTableFilterSlice } from '@/store/slices/banners/bannersSlice';

export const reviewsProductsFilterSlice = createTableFilterSlice<ReviewAndQuestionsQueryParams>({
  name: 'reviewsProductsFilter',
  initialState: reviewAndQuestionInitialFilter,
});
export const reviewsProductsFilterActions = reviewsProductsFilterSlice.actions;

export const reviewsApi = createApi({
  reducerPath: 'api/reviewsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],

  endpoints: (build) => ({
    getReviewsProductsList: build.query<ReviewsProductsResponse, ReviewAndQuestionsQueryParams>({
      query: (params) => {
        const newParams = {
          offset:
            params.currentPage === 1 ? 0 : Number(params.limit) * (Number(params.currentPage) - 1),
          limit: params.limit,
          sortField: params.sortField,
          sortDirection: params.sortDirection,
          status: (params.status || []).map((e) => e.value),
          rating: (params.rating || []).map((e) => e.value),
          periodStart: params.createDate?.[0]
            ? getFormatDateStartDay(params.createDate?.[0])
            : null,
          periodEnd: params.createDate?.[1] ? getFormatDateEndDay(params.createDate?.[1]) : null,
          article: params.article,
          id: params.id,
          hasReply: params.hasReply?.value,
        };
        return {
          method: 'get',
          url: getUrlWithSearchParams(URLS.reviews.products.list, newParams, {
            arrayFormat: 'index',
            skipNull: true,
          }),
        };
      },
      keepUnusedDataFor: 0,
    }),
    getSingleProduct: build.query<{ code: string; payload: Product; message: string }, string>({
      query: (id) => ({
        method: 'get',
        url: `/v2/catalog/product/${id}`,
      }),
    }),
    getSingleReview: build.query<ReviewResponseType, string>({
      query: (id) => {
        return { method: 'get', url: getUrlWithSearchParams(URLS.reviews.products.single(id), {}) };
      },
      keepUnusedDataFor: 0,
    }),
    setStatusReview: build.query<
      ReviewResponseType,
      { id: string; reason?: string; status: StatusTypes; onSuccessPublished: () => void }
    >({
      query: ({ id, status }) => ({
        url: `/v1/admin/reviews/${id}/status`,
        data: { status },
        method: 'patch',
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: async ({ onSuccessPublished, status }, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          onSuccessPublished();
        } catch (e) {
          const getText = () => {
            if (status === 'hidden') {
              return 'Отзыв не удалось скрыть';
            } else {
              return 'Отзыв не удалось опубликовать';
            }
          };
          notify({
            message: getText(),
            type: 'error',
          });
        }
      },
    }),
    setReplyReview: build.query<
      ReviewResponseType,
      {
        id: string;
        replyText: string;
        text?: string;
        onSuccessPublished?: () => void;
        onPublishedReview?: () => void;
      }
    >({
      query: ({ id, replyText }) => ({
        url: `/v1/admin/reviews/${id}`,
        data: { replyText },
        method: 'patch',
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: async ({ onSuccessPublished, onPublishedReview }, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.payload.status === 'published') {
            onSuccessPublished?.();
          } else {
            onPublishedReview?.();
          }
        } catch (e) {
          notify({
            message: `Отзыв не удалось сохранить`,
            type: 'error',
          });
        }
      },
    }),
  }),
});

export const {
  useLazySetReplyReviewQuery,
  useLazyGetReviewsProductsListQuery,
  useGetSingleProductQuery,
  useGetSingleReviewQuery,
  useLazySetStatusReviewQuery,
} = reviewsApi;

const reviewsProductsFilter = (state: IRootReducer) => state.reviewsProductsFilter;

export const reviewsProductsFilterSelector = createSelector(reviewsProductsFilter, (params) => {
  return params;
});

export const isDirtyReviewsProductsFilterSelector = createSelector(
  reviewsProductsFilter,
  ({ status, rating, createDate, hasReply, sortDirection, sortField, id, article }) => {
    const initFilterJSON = JSON.stringify({
      status: reviewAndQuestionInitialFilter.status,
      rating: reviewAndQuestionInitialFilter.rating,
      createDate: reviewAndQuestionInitialFilter.createDate,
      hasReply: reviewAndQuestionInitialFilter.hasReply,
      sortDirection: reviewAndQuestionInitialFilter.sortDirection,
      sortField: reviewAndQuestionInitialFilter.sortField,
      id: reviewAndQuestionInitialFilter.id,
      article: reviewAndQuestionInitialFilter.article,
    });

    const currentFilterJSON = JSON.stringify({
      status,
      rating,
      createDate,
      hasReply,
      sortDirection,
      sortField,
      id,
      article,
    });
    return initFilterJSON !== currentFilterJSON;
  },
);

interface ReviewDetailStore {
  params: { answer: string; attachments?: string[]; sessionId?: string };
  setAnswer: (v: string) => void;
  setAttachments: (attachments: string[], sessionId: string) => void;
}

export const useReviewDetailStore = create<ReviewDetailStore>((set) => ({
  params: { answer: '' },
  setAnswer: (answer: string) =>
    set((state) => ({ ...state, params: { ...state.params, answer } })),
  setAttachments: (attachments, sessionId) =>
    set((state) => ({ ...state, params: { ...state.params, attachments, sessionId } })),
}));
