import { IAuthBySSO, IGetTokenParams, TUserSession } from '@/types/auth/auth';

import { AxiosResponse } from 'axios';

import { api } from '@/api';
import { tryAction } from '@/api/tryAction';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

export const getRequestUrl = () => {
  const u = new URL(window.location.href);
  const backUrl =
    u.searchParams.get('redirectTo') || window.location.pathname + window.location.search;

  const url = `/v1/admin/auth/redirect?backUrl=${backUrl}`;
  return api.get(url);
};

export const getToken = ({ code, state }: IGetTokenParams): Promise<AxiosResponse<IAuthBySSO>> => {
  const postUrl = `/v1/admin/auth/token`;

  return api.post(postUrl, {
    code,
    state,
  });
};

export const tryGetAuthSSO = () =>
  getRequestUrl()
    .then((res) => res.data)
    .catch(axiosErrorHandler);

export const tryGetToken = ({ code, state }: IGetTokenParams) =>
  getToken({ code, state }).then((res) => res.data);

export const apiPostAuth = (_trash: any) => {
  // eslint-disable-next-line no-restricted-syntax
  console.log('Fetch mock token');

  const session = {
    accessToken:
      'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3YjRYbjBTVU1mdUlkWGlxemlCbmd0czBXemRwWlRiZnl5VFdmX1JCakdRIn0.eyJleHAiOjE2NzgxODUwNDYsImlhdCI6MTY3ODE4NDc0NiwianRpIjoiYzE4NjBlY2UtMzFkOC00NmNhLTkzMGUtNzg1ZjJlYjRhNjQ4IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5mYXJtcGVyc3Bla3RpdmEuYWVyb2lkZWEucnUvYXV0aC9yZWFsbXMvVGVzdCIsInN1YiI6IjAyMDU3Y2JlLTZlZWYtNDkxOC1iZDBmLWFiYzk0NzU1MTM3ZiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImZvY3VzIiwic2Vzc2lvbl9zdGF0ZSI6ImMzMzg1YzRlLTU5MzEtNGMzYi04ZmZlLTkzMDNkZjM3ODA0NCIsImFjciI6IjEiLCJzY29wZSI6InNlcnZpY2VzICoiLCJzaWQiOiJjMzM4NWM0ZS01OTMxLTRjM2ItOGZmZS05MzAzZGYzNzgwNDQifQ.V78yROCB1w9EWR0uUSWkFo6lYTMurfjJty86nUbhZd0yHwYz7FXZA1MvCIj4kNGroYb0PHiRVSnSrJCdYeeD5wN7BlqcNNMp0iODQ_-UuRFq2HM8Urnkn_XFdmSvQQfPkdFuGgZisaXCy14p37iHVd1dZKC9DlR7iu6UXXY1Iud-JaM3eXa46BfAoN5GJ75VC1GzvP_oJa0VTZagzXQeZU5plFPIYUVZuIjNJ2Z_LY4OuLkoNed8n31E7R1mkRM6OQXZdJX1a2GM2vr7cpPfS8fXff5E8g3SavIBD2dYM06v0X79_GQGoA6T7JvEUnDKZR9fRl4nDecSUP456yyEpg',
    expires_in: 30000000000,
    refresh_expires_in: 18000000000,
    refresh_token:
      'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI4Y2I0NWM2Yi1iZTM1LTRhMzMtYTg0OS0wZTY4OWZiMWQwYTkifQ.eyJleHAiOjE2NzgxODY1NDYsImlhdCI6MTY3ODE4NDc0NiwianRpIjoiZjhhZDc1YjYtOWJkZS00ZWQ3LWE0NWYtNzllZDUxNzMxYmUyIiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5mYXJtcGVyc3Bla3RpdmEuYWVyb2lkZWEucnUvYXV0aC9yZWFsbXMvVGVzdCIsImF1ZCI6Imh0dHBzOi8va2V5Y2xvYWsuZmFybXBlcnNwZWt0aXZhLmFlcm9pZGVhLnJ1L2F1dGgvcmVhbG1zL1Rlc3QiLCJzdWIiOiIwMjA1N2NiZS02ZWVmLTQ5MTgtYmQwZi1hYmM5NDc1NTEzN2YiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoiZm9jdXMiLCJzZXNzaW9uX3N0YXRlIjoiYzMzODVjNGUtNTkzMS00YzNiLThmZmUtOTMwM2RmMzc4MDQ0Iiwic2NvcGUiOiJzZXJ2aWNlcyAqIiwic2lkIjoiYzMzODVjNGUtNTkzMS00YzNiLThmZmUtOTMwM2RmMzc4MDQ0In0.E9u_OiRlFDL7O8TFXZgj7p4ZdFrqwF4nmNkP5AgSWDY',
    token_type: 'Bearer',
    'not-before-policy': 0,
    session_state: 'c3385c4e-5931-4c3b-8ffe-9303df378044',
    scope: 'services *',
    expiresAt: 16781850460384,
    refreshExpiresAt: 16781850460384,
  };

  const response = {
    data: session,
    status: 200,
  };

  return Promise.resolve(response) as Promise<AxiosResponse<TUserSession>>;
};

// TODO mock api auth
export const apiAuthByRefresh = (_trash: any) => {
  // eslint-disable-next-line no-restricted-syntax
  console.log('Fetch mock token');

  const session = {
    accessToken:
      'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3YjRYbjBTVU1mdUlkWGlxemlCbmd0czBXemRwWlRiZnl5VFdmX1JCakdRIn0.eyJleHAiOjE2NzgxODUwNDYsImlhdCI6MTY3ODE4NDc0NiwianRpIjoiYzE4NjBlY2UtMzFkOC00NmNhLTkzMGUtNzg1ZjJlYjRhNjQ4IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5mYXJtcGVyc3Bla3RpdmEuYWVyb2lkZWEucnUvYXV0aC9yZWFsbXMvVGVzdCIsInN1YiI6IjAyMDU3Y2JlLTZlZWYtNDkxOC1iZDBmLWFiYzk0NzU1MTM3ZiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImZvY3VzIiwic2Vzc2lvbl9zdGF0ZSI6ImMzMzg1YzRlLTU5MzEtNGMzYi04ZmZlLTkzMDNkZjM3ODA0NCIsImFjciI6IjEiLCJzY29wZSI6InNlcnZpY2VzICoiLCJzaWQiOiJjMzM4NWM0ZS01OTMxLTRjM2ItOGZmZS05MzAzZGYzNzgwNDQifQ.V78yROCB1w9EWR0uUSWkFo6lYTMurfjJty86nUbhZd0yHwYz7FXZA1MvCIj4kNGroYb0PHiRVSnSrJCdYeeD5wN7BlqcNNMp0iODQ_-UuRFq2HM8Urnkn_XFdmSvQQfPkdFuGgZisaXCy14p37iHVd1dZKC9DlR7iu6UXXY1Iud-JaM3eXa46BfAoN5GJ75VC1GzvP_oJa0VTZagzXQeZU5plFPIYUVZuIjNJ2Z_LY4OuLkoNed8n31E7R1mkRM6OQXZdJX1a2GM2vr7cpPfS8fXff5E8g3SavIBD2dYM06v0X79_GQGoA6T7JvEUnDKZR9fRl4nDecSUP456yyEpg',
    expires_in: 30000000000,
    refresh_expires_in: 18000000000,
    refresh_token:
      'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI4Y2I0NWM2Yi1iZTM1LTRhMzMtYTg0OS0wZTY4OWZiMWQwYTkifQ.eyJleHAiOjE2NzgxODY1NDYsImlhdCI6MTY3ODE4NDc0NiwianRpIjoiZjhhZDc1YjYtOWJkZS00ZWQ3LWE0NWYtNzllZDUxNzMxYmUyIiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5mYXJtcGVyc3Bla3RpdmEuYWVyb2lkZWEucnUvYXV0aC9yZWFsbXMvVGVzdCIsImF1ZCI6Imh0dHBzOi8va2V5Y2xvYWsuZmFybXBlcnNwZWt0aXZhLmFlcm9pZGVhLnJ1L2F1dGgvcmVhbG1zL1Rlc3QiLCJzdWIiOiIwMjA1N2NiZS02ZWVmLTQ5MTgtYmQwZi1hYmM5NDc1NTEzN2YiLCJ0eXAiOiJSZWZyZXNoIiwiYXpwIjoiZm9jdXMiLCJzZXNzaW9uX3N0YXRlIjoiYzMzODVjNGUtNTkzMS00YzNiLThmZmUtOTMwM2RmMzc4MDQ0Iiwic2NvcGUiOiJzZXJ2aWNlcyAqIiwic2lkIjoiYzMzODVjNGUtNTkzMS00YzNiLThmZmUtOTMwM2RmMzc4MDQ0In0.E9u_OiRlFDL7O8TFXZgj7p4ZdFrqwF4nmNkP5AgSWDY',
    token_type: 'Bearer',
    'not-before-policy': 0,
    session_state: 'c3385c4e-5931-4c3b-8ffe-9303df378044',
    scope: 'services *',
    expiresAt: 16781850460384,
    refreshExpiresAt: 16781850460384,
    token:
      'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3YjRYbjBTVU1mdUlkWGlxemlCbmd0czBXemRwWlRiZnl5VFdmX1JCakdRIn0.eyJleHAiOjE2NzgxODUwNDYsImlhdCI6MTY3ODE4NDc0NiwianRpIjoiYzE4NjBlY2UtMzFkOC00NmNhLTkzMGUtNzg1ZjJlYjRhNjQ4IiwiaXNzIjoiaHR0cHM6Ly9rZXljbG9hay5mYXJtcGVyc3Bla3RpdmEuYWVyb2lkZWEucnUvYXV0aC9yZWFsbXMvVGVzdCIsInN1YiI6IjAyMDU3Y2JlLTZlZWYtNDkxOC1iZDBmLWFiYzk0NzU1MTM3ZiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImZvY3VzIiwic2Vzc2lvbl9zdGF0ZSI6ImMzMzg1YzRlLTU5MzEtNGMzYi04ZmZlLTkzMDNkZjM3ODA0NCIsImFjciI6IjEiLCJzY29wZSI6InNlcnZpY2VzICoiLCJzaWQiOiJjMzM4NWM0ZS01OTMxLTRjM2ItOGZmZS05MzAzZGYzNzgwNDQifQ.V78yROCB1w9EWR0uUSWkFo6lYTMurfjJty86nUbhZd0yHwYz7FXZA1MvCIj4kNGroYb0PHiRVSnSrJCdYeeD5wN7BlqcNNMp0iODQ_-UuRFq2HM8Urnkn_XFdmSvQQfPkdFuGgZisaXCy14p37iHVd1dZKC9DlR7iu6UXXY1Iud-JaM3eXa46BfAoN5GJ75VC1GzvP_oJa0VTZagzXQeZU5plFPIYUVZuIjNJ2Z_LY4OuLkoNed8n31E7R1mkRM6OQXZdJX1a2GM2vr7cpPfS8fXff5E8g3SavIBD2dYM06v0X79_GQGoA6T7JvEUnDKZR9fRl4nDecSUP456yyEpg',
  };

  const response = {
    data: session,
    status: 200,
  };

  return Promise.resolve(response) as Promise<AxiosResponse<TUserSession>>;
};

export const tryPostAuth = (data: any) => tryAction(apiPostAuth({ data }));
