import { IPromotionItem } from '@/types/promotions';

import { useMemo, useState } from 'react';

import { useCreateDraft } from '@/api/draft/useCreateDraft';
import { useUpdatePromotion, useUpdatePromotionActivity } from '@/api/promotion/hooks';
import { useCreatePromotion } from '@/api/promotion/hooks/useCreatePromotion';
import { useDeletePromotion } from '@/api/promotion/hooks/useDeletePromotion';
import { useGetBanners } from '@/api/promotion/hooks/useGetBanners';
import { useGetPromotion } from '@/api/promotion/hooks/useGetPromotion';

import { getItemPermissions } from '@/utils/permissions';

import { usePermission } from '@/hooks/usePermissions';

import {
  setSelectorDefaultFormData,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { IPromotionFormFields } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.types';
import { IPromotionCreationState } from '@/ui/containers/PromotionCreation/PromotionCreation.types';

export const usePromotionCreationState = (id: string = ''): IPromotionCreationState => {
  const canCreate = !!usePermission('PROMOTION_CREATE');
  const draftId = useStoreContentEditor((state) => state.draftId);
  const defaultFormData = useStoreContentEditor((state) =>
    state.getDefaultFormData<IPromotionFormFields>(),
  );

  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  const [promotion, setPromotion] = useState<IPromotionItem | null>(null);
  const [isShouldBlock, setIsShouldBlock] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isForceChangeTemplate, setIsForceChangeTemplate] = useState(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);

  const isNewPromotion = !Boolean(id);
  const isShowPromotionDetail = isNewPromotion || (!isNewPromotion && promotion);
  const title = isNewPromotion ? 'Новая акция' : `ID: ${promotion?.uuid || ''}`;
  const [trySavePromotion, setTrySavePromotion] = useState<boolean>(false);

  const { isLoading: isCreatePromotionLoading, createPromotion } = useCreatePromotion();
  const { isLoading: isCreateDraftLoading, createDraft } = useCreateDraft();
  const { isLoading: isGetPromotionLoading, getPromotion } = useGetPromotion();
  const { isLoading: isUpdatePromotionLoading, updatePromotion } = useUpdatePromotion();
  const { isLoading: isUpdatePromotionActivityLoading, updatePromotionActivity } =
    useUpdatePromotionActivity();
  const { isLoading: isDeletePromotionLoading, deletePromotion } = useDeletePromotion();

  const { getBanners } = useGetBanners();

  const isLoading =
    isCreatePromotionLoading ||
    isCreateDraftLoading ||
    isGetPromotionLoading ||
    isUpdatePromotionLoading ||
    isUpdatePromotionActivityLoading ||
    isDeletePromotionLoading;

  const permissions = useMemo(() => {
    const res = getItemPermissions('PROMOTION_', promotion?.permissions);
    return {
      ...res,
      canView: !promotion || !!(isNewPromotion ? canCreate : res.canGet),
      canSave: !!(isNewPromotion ? canCreate : res.canUpdate || res.canUpdateActivity),
      canUpdate: !!(isNewPromotion ? canCreate : res.canUpdate),
    };
  }, [isNewPromotion, canCreate, promotion]);

  return <IPromotionCreationState>{
    permissions,
    title,
    isNewPromotion,
    isShowPromotionDetail,
    draftId,
    defaultFormData,
    promotion,
    isShouldBlock,
    isLoading,
    isForceChangeTemplate,
    setIsForceChangeTemplate,
    isOpenConfirmModal,
    setOpenConfirmModal,
    setDefaultFormData,
    trySavePromotion,
    setTrySavePromotion,
    createPromotion,
    createDraft,
    getPromotion,
    deletePromotion,
    updatePromotion,
    updatePromotionActivity,
    getBanners,
    setPromotion,
    setIsShouldBlock,
    isShowDeleteModal,
    setIsShowDeleteModal,
  };
};
