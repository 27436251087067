import { TMenu } from '@/types';

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';
import TableHeadSortable from '@/ui/components/TableExt/components/TableHeadSortable/TableHeadSortable';

import { setTypeMenu } from '@/store/slices/menu/menuTypes';

interface IProps {
  items: TMenu[];
}

const MenuContainerTable: FC<IProps> = ({ items }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickHandler = (code: string) => {
    dispatch(setTypeMenu({ type: code }));
    navigate(`/menu/${code}`);
  };

  return (
    <Table highlightOnHover verticalSpacing='xs' fontSize='sm' horizontalSpacing='xs'>
      <thead style={{ background: '#E8ECF0' }}>
        <tr>
          <TableHeadSortable name='Название' onSort={() => {}} reversed={false} sorted />
          <th>
            <TableColumnTitle>Код</TableColumnTitle>
          </th>
          <th style={{ width: '500px' }}></th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item, index) => {
          return (
            <tr key={index} style={{ cursor: 'pointer' }} onClick={() => onClickHandler(item.code)}>
              <td>{item.name}</td>
              <td>{item.code}</td>
              <td style={{ width: '500px' }}></td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default MenuContainerTable;
