import { IMaterials } from '@/types/materials/materials';
import { TMaterialDictionaryItem } from '@/types/materials/materialsDetail';

import { TOption } from '@/ui/components/MultiSelect';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import {
  EActivityFieldValues,
  EMaterialsFormFields,
} from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';

const getDate = (date?: Date | string | null) => (date ? new Date(date) : '');

const getMaterialsStatus = (isActive?: boolean) =>
  isActive ? EActivityFieldValues.ACTIVE : EActivityFieldValues.NOT_ACTIVE;

export const getOptionsFormFields = (field?: TMaterialDictionaryItem[]) => {
  return field?.map((item) => item.id || '');
};

export const getOptionsSpecialMultiSelect = (field?: TMaterialDictionaryItem[]): TOption[] => {
  return (
    field?.map((f) => {
      return {
        value: f.id,
        label: f.name,
      };
    }) || []
  );
};

export const getDefaultValues = (materials: IMaterials | null): IMaterialsFormFields => {
  const isContainsVideo = !!materials?.isContainsVideo && 'isContainsVideo';
  const isContainsDownloadMaterial =
    !!materials?.isContainsDownloadMaterial && 'isContainsDownloadMaterial';
  const contains: string[] = [];

  if (isContainsVideo) contains.push(isContainsVideo);
  if (isContainsDownloadMaterial) contains.push(isContainsDownloadMaterial);

  return {
    [EMaterialsFormFields.DIGITAL_SERVICE]: getOptionsFormFields(materials?.digitalService),
    [EMaterialsFormFields.IS_GENERATE_TABLE_OF_CONTENTS]:
      materials?.isGenerateTableOfContents || false,
    [EMaterialsFormFields.IS_MAIN_ARTICLE]: materials?.isMainArticle || false,
    [EMaterialsFormFields.TYPE]: materials?.type?.id || '',
    [EMaterialsFormFields.URL_CODE]: materials?.urlCode || '',
    [EMaterialsFormFields.IS_ACTIVE]: materials?.isActive
      ? getMaterialsStatus(materials.isActive)
      : EActivityFieldValues.NOT_ACTIVE,
    [EMaterialsFormFields.TITLE]: materials?.title || '',
    [EMaterialsFormFields.DATA_ACTIVE_FROM_DAYS]: getDate(materials?.dateActiveFrom.value) || '',
    [EMaterialsFormFields.DATA_ACTIVE_TO]: '',
    [EMaterialsFormFields.DISPLAY_ON_SITES]: materials?.displayOnSites || [],
    image: {
      [EMaterialsFormFields.IMAGE_ID]: materials?.image?.imageData.id || '',
      [EMaterialsFormFields.IMAGE_ALT]: materials?.image?.alt || '',
      [EMaterialsFormFields.IMAGE_TITLE]: materials?.image?.title || '',
    },
    [EMaterialsFormFields.DRAFT_ID]: materials?.draftId || '',
    [EMaterialsFormFields.SHORT_DESCRIPTION]: materials?.shortDescription || '',
    [EMaterialsFormFields.READER_ROLE]: getOptionsFormFields(materials?.readerRole),
    [EMaterialsFormFields.TYPE_OF_CONTROL]: getOptionsFormFields(materials?.typeOfControl),
    [EMaterialsFormFields.DIRECTION]: getOptionsFormFields(materials?.direction),
    [EMaterialsFormFields.EDUCATION_LEVEL]: getOptionsFormFields(materials?.educationLevel),
    [EMaterialsFormFields.AGE]: getOptionsFormFields(materials?.age),
    [EMaterialsFormFields.THEME]: getOptionsFormFields(materials?.theme),
    [EMaterialsFormFields.SUBJECT]: getOptionsFormFields(materials?.subject),
    [EMaterialsFormFields.AUTHOR]: getOptionsSpecialMultiSelect(materials?.author),
    [EMaterialsFormFields.LINE_UMK]: getOptionsSpecialMultiSelect(materials?.lineUmk),
    [EMaterialsFormFields.MODIFIED_VIEWS_COUNT]: materials?.modifiedViewsCount || 0,
    [EMaterialsFormFields.IS_DISPLAY_VIEWS]: materials?.isDisplayViews || false,
    [EMaterialsFormFields.SORT]: materials?.sort || 100,
    [EMaterialsFormFields.READING_TIME]: materials?.readingTime || 0,
    [EMaterialsFormFields.TAGS]: getOptionsSpecialMultiSelect(materials?.tags),
    [EMaterialsFormFields.CONTAINS]: contains,
    [EMaterialsFormFields.TARGET_PAGES]: materials?.targetPages || ['all'],
  };
};
