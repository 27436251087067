import { FC } from 'react';
import { Box, Button } from '@mantine/core';

interface IProps {
  handleSaveWithRedirect?: () => void;
  formId: string;
}
export const ActionButtons: FC<IProps> = ({ formId, handleSaveWithRedirect }) => {
  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <>
        <Button type='submit' form={formId} sx={{ width: '200px' }}>
          Сохранить
        </Button>
      </>

      <Button type='submit' form={formId} sx={{ width: '200px' }} onClick={handleSaveWithRedirect}>
        Сохранить и выйти
      </Button>
    </Box>
  );
};
