import React, { useRef } from 'react';
import { Input, InputBase, Textarea } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import { FileLoader } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { TooltipTextWrapper } from '@/ui/components/TooltipFormWrapper';
import {
  getImageData,
  handleAddSnippetData,
} from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

import { EProductSnippetModalFormFields } from './ProductSnippetModal.constants';
import { getProductSnippetDefaultValues } from './ProductSnippetModal.helpers';
import { productSnippetSchema } from './ProductSnippetModal.schema';

const ProductModalUI = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('product');
  const sessionID = useRef(uuid_v4());

  const form = useForm({
    initialValues: getProductSnippetDefaultValues(defaultValues),
    validate: yupResolver(productSnippetSchema),
  });

  const handleFileLoaderChange = ({ id, path, name }: IFileLoaderOnChangeParams) => {
    form.values.image = {
      id,
      path,
      name,
    };
  };

  const handleFileLoaderRemove = () => {
    form.values.image = {
      id: '',
      path: '',
      name: '',
    };
  };

  const imgData = getImageData({
    id: form.values.image.id,
    path: form.values.image.path?.replace(process.env.MEDIA_STORAGE_URL as string, ''),
    name: form.values.image.name,
  });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Продукт'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        if (values[EProductSnippetModalFormFields.IMAGE].id) {
          await replaceTempFile({
            query: {
              sessionId: sessionID.current,
              ids: [values[EProductSnippetModalFormFields.IMAGE].id],
            },
          });
        }

        return handleAddSnippetData('product', values, handleClose, snippetData);
      })}
    >
      <InputBase
        sx={{ gridColumn: 'span 6' }}
        label={
          <TooltipTextWrapper
            label='Заголовок'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите заголовок продукта'
        {...form.getInputProps(EProductSnippetModalFormFields.TITLE)}
      />

      <InputBase
        withAsterisk
        sx={{ gridColumn: 'span 6' }}
        label='Ссылка'
        placeholder='Ссылка на продукт'
        {...form.getInputProps(EProductSnippetModalFormFields.LINK)}
      />

      <Textarea
        sx={{ gridRow: 'span 2', gridColumn: '1 / 7', textArea: { minHeight: '120px' } }}
        label={
          <TooltipTextWrapper
            label='Описание'
            text='Используется также для заполнения SEO-тега name у изображения'
          />
        }
        placeholder='Введите описание продукта'
        {...form.getInputProps(EProductSnippetModalFormFields.DESCRIPTION)}
      />

      <InputBase
        withAsterisk
        sx={{ gridColumn: 'span 6' }}
        label='Автор'
        placeholder='Введите автора'
        {...form.getInputProps(EProductSnippetModalFormFields.AUTHOR)}
      />

      <Input.Wrapper
        withAsterisk
        label='Изображение'
        sx={{ gridRow: 'span 2', gridColumn: '1 / 7' }}
        {...form.getInputProps('image.id')}
      >
        <FileLoader
          sessionID={sessionID.current}
          savedFormFiles={imgData}
          onRemove={handleFileLoaderRemove}
          onChange={handleFileLoaderChange}
          placeholder='Перетащите или добавьте изображение. Изображение должно быть не более 1000 пикс. в высоту'
        />
      </Input.Wrapper>

      <InputBase
        withAsterisk
        sx={{ gridColumn: 'span 6' }}
        label='alt'
        placeholder='Введите alt'
        {...form.getInputProps(EProductSnippetModalFormFields.ALT)}
      />

      <InputBase
        withAsterisk
        sx={{ gridColumn: 'span 6' }}
        label='Введите title'
        placeholder='Введите title'
        {...form.getInputProps(EProductSnippetModalFormFields.IMAGE_TITLE)}
      />
    </SnippetModal>
  );
};

export const ProductSnippetModal = React.memo(ProductModalUI);
