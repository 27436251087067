/* eslint-disable no-restricted-syntax */

import { TContentData } from '@/ui/containers/ContentEditor/ContentEditor.types';
import { TViewActions, TViewState } from '@/ui/containers/ContentEditor/store/store.types';

export const parseContentData = (data?: string | void): null | TContentData => {
  if (!data) return { versionModel: 1, grid: [] };

  try {
    const parsed = JSON.parse(data);
    const isCorrectData = parsed instanceof Object && 'versionModel' in parsed && 'grid' in parsed;

    if (isCorrectData) {
      return parsed as TContentData;
    } else {
      console.log(
        'Полученные данные редактора не являются требуемым объектом. Создан новый объект',
      );
      return { versionModel: 1, grid: [] };
    }
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const getUpdateContentDataParams = (state: TViewState & TViewActions, draftId?: string) => {
  if (draftId) {
    return {
      id: draftId,
      contentData: state.savedContentDataList[draftId] as TContentData,
    };
  }

  return {
    id: state.draftId as string,
    contentData: state.savedContentData as TContentData,
  };
};

export const getSavedContent = (state: TViewState & TViewActions, draftId?: string) => {
  if (draftId) {
    return state.savedContentDataList[draftId] as TContentData;
  }

  return state.savedContentData as TContentData;
};
