import { IconX } from '@tabler/icons';

interface Props {
  onClick?: (str: string) => void;
  text: string;
}

export const Chip = ({ text, onClick }: Props) => {
  return (
    <div
      style={{
        backgroundColor: '#E8ECF0',
        padding: '8px 12px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        gap: 8,
      }}
    >
      ID: {text}{' '}
      <IconX onClick={() => onClick?.(text)} color='#BFCEDB' style={{ cursor: 'pointer' }} />
    </div>
  );
};
