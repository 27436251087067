import { TService } from '@/types/services/services';

import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, CloseButton, Group, Radio, Text, UnstyledButton } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { Settings } from 'tabler-icons-react';

import { setLastRoute } from '@/utils/setLastRoute';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { usePermissions } from '@/hooks/usePermissions';

import styles from '@/ui/Layout/components/LayoutNavbar/components/LayoutNavbarButton/LayoutNavbarButton.module.scss';

import {
  fetchServiceAction,
  selectServices,
  setCurrentService,
  setServiceChanged,
} from '@/store/slices/service/service';

interface IProps {
  opened: boolean | undefined;
}

const LayoutNavbarRadioSelect: FC<IProps> = ({ opened }) => {
  const dispatch = useAppDispatch();
  const { getPermission } = usePermissions();
  const [radioValue, setRadioValue] = useState<string>('');
  const [showRadio, setShowRadio] = useState<boolean>(false);
  const [currServiceObj, setCurrServiceObj] = useState<TService>({
    name: '',
    plugins: [],
  });
  const navigate = useNavigate();
  const services = useSelector(selectServices);
  const ref = useRef<any>(null);
  const [service, setService] = useLocalStorage({ key: 'service', defaultValue: '' });

  useOutsideClick(ref, () => setShowRadio(false));

  useEffect(() => {
    dispatch(fetchServiceAction(getPermission));
  }, [getPermission]);

  useEffect(() => {
    if (radioValue) {
      dispatch(setCurrentService(radioValue));
      setService(radioValue);
    }
    if (services && Array.isArray(services)) {
      const found = services[radioValue];
      if (found) setCurrServiceObj(found);
    }
  }, [radioValue]);

  useLayoutEffect(() => {
    if (services) {
      if (service) {
        const found = services[service];
        if (found) setCurrServiceObj(found);
        setRadioValue(service);
      } else {
        const storedValue = localStorage.getItem('service');
        if (storedValue) {
          setService(JSON.parse(storedValue));
        }
      }
    }
  }, [services, service]);

  const onClickNavigate = (value: string) => {
    if (services) {
      const route = services[value].plugins[0];
      navigate(`/${route}`);
      setLastRoute(route);
    }
  };

  return (
    <Box>
      <UnstyledButton
        title={`Сервисы: ${currServiceObj?.name}`}
        className={cn(styles.root)}
        py={12}
        onClick={() => setShowRadio(true)}
        sx={({ colors }) => ({
          ':hover': {
            '*': { color: colors.primary[7] },
          },
        })}
      >
        <Group spacing={10} noWrap>
          <Settings size={24} />
          <Text lineClamp={1} sx={{ lineHeight: 1.125, paddingBottom: '2px' }}>
            {opened ? `Сервисы: ${currServiceObj?.name}` : ''}
          </Text>
        </Group>
      </UnstyledButton>

      {showRadio && (
        <Box
          ref={ref}
          sx={{
            position: 'absolute',
            border: '1px solid #E8ECF0',
            borderRadius: 4,
            background: '#FFFFFF',
            zIndex: 100,
            padding: '10px 22px 22px 22px',
            bottom: 70,

            button: {
              position: 'absolute',
              top: 0,
              right: 0,
            },
            '.mantine-Radio-radioWrapper': {
              borderBottom: '1px solid #E8ECF0',
              paddingBottom: 10,
              width: 240,
            },
            '.mantine-Radio-radioWrapper:last-of-type': {
              borderBottom: 'none',
              paddingBottom: 0,
            },
          }}
        >
          <CloseButton aria-label='Close modal' onClick={() => setShowRadio(false)} />
          <Radio.Group
            value={radioValue}
            onChange={(value) => {
              dispatch(setServiceChanged(true));
              setRadioValue(value);
              onClickNavigate(value);
            }}
            orientation='vertical'
          >
            {services &&
              Object.keys(services)?.map((code, index) => (
                <Radio value={code} label={services[code]?.name} key={index} />
              ))}
          </Radio.Group>
        </Box>
      )}
    </Box>
  );
};

export default LayoutNavbarRadioSelect;
