import { IDraftResponse, TCreateDraft } from '@/types/draft/draft';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../instance';

export const useCreateDraft = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    createDraft: async (draftData: TCreateDraft) => {
      setIsLoading(true);
      try {
        const request = await api.post<IDraftResponse>(URLS.draft.postDraft, { ...draftData });

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка создания черновика', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
