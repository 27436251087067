import { useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box, Button, Center, LoadingOverlay, Pagination, Paper, Text } from '@mantine/core';

import { usePagination } from '@/hooks/usePagination';
import { usePermission } from '@/hooks/usePermissions';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';

import { DictionaryItemModal } from './DictionaryItemModal';
import { DICTIONARY_ITEMS_LIMIT as limit } from './DictionaryItems.constants';
import * as sx from './DictionaryItems.styles';
import { DictionaryItemsTable } from './DictionaryItemsTable';

import { useDictionary, useDictionaryItems } from '@/store/slices/dictionary';

export const DictionaryItems = () => {
  const { code = '' } = useParams();
  const canCreate = usePermission('DICTIONARY_CREATE');
  const [offset, setOffset] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const { data: dictionary, isLoading: isLoadingDictionary } = useDictionary(code);
  const { data, isLoading: isLoadingItems } = useDictionaryItems({ code, limit, offset });

  const { items, total } = useMemo(
    () => ({ items: data?.items ?? [], total: data?.total ?? 0 }),
    [data],
  );

  const { pages, setPage } = usePagination({ limit: limit, total, setOffset });
  const isLoading = isLoadingDictionary || isLoadingItems;

  const breadcrumbs = useMemo(
    () => [
      { name: 'Справочники', url: '/dictionary' },
      { name: dictionary?.name || '', url: `/dictionary/${code}` },
    ],
    [dictionary, code],
  );

  if (!isLoading && !dictionary) {
    return <Navigate to='/dictionary' replace />;
  }

  return (
    <>
      <Box sx={sx.root}>
        <PageHeader
          title={dictionary?.name || ''}
          subTitle={dictionary?.code || ''}
          backLink='/dictionary'
          breadcrumbs={breadcrumbs}
          rightButton={
            canCreate ? (
              <>
                <Button onClick={() => setOpen(true)} sx={{ width: '200px' }}>
                  Создать
                </Button>
              </>
            ) : null
          }
        />

        <Paper radius={8} p={24} pb={12} shadow='xs' sx={sx.paper}>
          {dictionary && items.length > 0 && (
            <>
              <Box sx={sx.tableWrapper}>
                <DictionaryItemsTable
                  dictionary={dictionary}
                  elements={items}
                  columns={['Название', 'Активность', 'Код', 'Дата создания', '']}
                />
                <LoadingOverlay visible={isLoading} />
              </Box>

              <Box sx={sx.footer}>
                <Box sx={sx.total}>
                  <Text size='md' color='greyDefault'>
                    Всего:
                  </Text>
                  &nbsp;
                  <Text size='md'>{total}</Text>
                </Box>
                {pages > 1 && (
                  <Pagination
                    sx={sx.pagination}
                    position={'right'}
                    total={pages}
                    onChange={setPage}
                  />
                )}
              </Box>
            </>
          )}

          {!items.length && (
            <Box sx={sx.emptyBlock}>
              <LoadingOverlay visible={isLoading} />

              {!isLoading && (
                <Center style={{ height: '100%' }}>
                  <Text align={'center'}>У вас нет справочников</Text>
                </Center>
              )}
            </Box>
          )}
        </Paper>

        {dictionary && (
          <DictionaryItemModal
            dictionary={dictionary}
            opened={isOpen}
            onClose={() => setOpen(false)}
          />
        )}
      </Box>
    </>
  );
};
