import { TNewsSortValues } from '@/types/news/news';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MultiValue } from 'react-select';
import { DateRangePickerValue } from '@mantine/dates';

import { getFormatDateEndDay, getFormatDateStartDay } from '@/utils/getFormatDateForBackend';

import { TOption } from '@/ui/components/MultiSelect';

import { useDictionaryOptions } from '@/store/slices/dictionary';
import {
  isLoadingNewsListSelector,
  newsLimitSelector,
  newsListPageSelector,
  newsListSelector,
  newsOffsetSelector,
  newsTotalSelector,
} from '@/store/slices/news/news';

export const useNewsContainerState = () => {
  const newsList = useSelector(newsListSelector);
  const newsTotal = useSelector(newsTotalSelector);
  const newsLimit = useSelector(newsLimitSelector);
  const newsOffset = useSelector(newsOffsetSelector);
  const page = useSelector(newsListPageSelector);
  const isLoadingNewsList = useSelector(isLoadingNewsListSelector);

  const { data: categoryOptions } = useDictionaryOptions('category', 'code');
  const { data: themeOptions } = useDictionaryOptions('theme', 'code');
  const { data: divisionOptions } = useDictionaryOptions('division', 'code');
  const { data: companyOptions } = useDictionaryOptions('company', 'code');

  const [filterDate, setFilterDate] = useState<DateRangePickerValue>([null, null]);
  const [filter, setFilterState] = useState<Record<string, string>>({});
  const [categorySelected, setCategorySelected] = useState<MultiValue<TOption>>([]);
  const [themeSelected, setThemeSelected] = useState<MultiValue<TOption>>([]);
  const [companySelected, setCompanySelected] = useState<MultiValue<TOption>>([]);
  const [divisionSelected, setDivisionSelected] = useState<MultiValue<TOption>>([]);
  const [sortFiled, setSortField] = useState<{
    field: TNewsSortValues;
    direction: 'asc' | 'desc';
  } | null>(null);

  const setFilter = (fieldName: string, values: MultiValue<TOption>) => {
    const newFilter = values.map((i) => i.value).join(',');

    setFilterState((prev) => {
      if (newFilter) {
        return {
          ...prev,
          [fieldName]: newFilter,
        };
      }
      delete prev[fieldName];
      return {
        ...prev,
      };
    });
  };

  const setDateFilter = (dateRange: DateRangePickerValue) => {
    const [createDateFrom, createDateTo] = dateRange;

    setFilterState((prev) => {
      if (createDateFrom) {
        prev.createDateFrom = getFormatDateStartDay(createDateFrom) || '';
      } else {
        delete prev.createDateFrom;
      }

      if (createDateTo) {
        prev.createDateTo = getFormatDateEndDay(createDateTo) || '';
      } else {
        delete prev.createDateTo;
      }

      if (!createDateFrom || !createDateTo) {
        delete prev.createDateFrom;
        delete prev.createDateTo;
      }

      return { ...prev };
    });
  };

  const resetFilters = () => {
    setFilterState({});
    setCategorySelected([]);
    setThemeSelected([]);
    setCompanySelected([]);
    setDivisionSelected([]);
    setFilterDate([null, null]);
    setSortField(null);
  };

  return {
    newsList,

    newsTotal,
    newsLimit,
    newsOffset,
    page,
    isLoadingNewsList,

    categoryOptions,
    themeOptions,
    divisionOptions,
    companyOptions,
    valueDate: filterDate,
    setValueDate: setFilterDate,
    filter,
    setFilterState,
    categorySelected,
    setCategorySelected,
    themeSelected,
    setThemeSelected,
    companySelected,
    setCompanySelected,
    divisionSelected,
    setDivisionSelected,

    setFilter,
    setDateFilter,
    resetFilters,

    sortFiled,
    setSortField,
  };
};
