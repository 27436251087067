export const MAX_NUMBER_BLOCKS = 4;

export const OPTIONS = [
  { value: '0', label: '0 пикс.' },
  { value: '4', label: '4 пикс.' },
  { value: '8', label: '8 пикс.' },
  { value: '16', label: '16 пикс.' },
  { value: '32', label: '32 пикс.' },
];

export const DEFAULT_OPTIONS = OPTIONS[0];
