import { ITemplate } from '@/types/templates/templates';

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { setEditTemplateItem } from '@/store/slices/templates/templates';

interface IProps {
  items: ITemplate[];
}

export const TemplatesTable: FC<IProps> = ({ items }) => {
  const dispatch = useAppDispatch();

  const theme = useMantineTheme();

  const navigate = useNavigate();

  const handleEditTemplate = (templateId: string) => {
    navigate(`/templates-meta-tags/${templateId}`);
    dispatch(setEditTemplateItem(templateId));
  };

  return (
    <>
      <Table highlightOnHover verticalSpacing='xs' fontSize='sm'>
        <thead style={{ background: '#E8ECF0' }}>
          <tr>
            <th>Сайт</th>
            <th>Название</th>
            <th>Тип страницы</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ id, site, title, pageType }) => {
            return (
              <tr key={id}>
                <td>{site}</td>
                <td>{title}</td>
                <td>{pageType}</td>
                <td />
                <td style={{ maxWidth: '20px' }}>
                  <Button
                    name='Редактировать'
                    title='Редактировать'
                    variant='subtle'
                    style={{ position: 'static' }}
                    compact
                    onClick={() => handleEditTemplate(id)}
                  >
                    <Pencil size={22} color={theme.colors.greyLight[9]} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
