import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell } from '@mantine/core';

import { getCookie } from '@/utils/cookie';

import LayoutNavbar from './components/LayoutNavbar/LayoutNavbar';

const Layout: FC = () => {
  const parsedToken = getCookie('token') as string;

  if (!parsedToken) return <Outlet />;

  return (
    <AppShell
      fixed
      navbar={<LayoutNavbar />}
      sx={{
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        height: '100vh',
        overflowY: 'hidden',
      }}
    >
      <Outlet />
    </AppShell>
  );
};

export default Layout;
