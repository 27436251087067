import { useDocumentTitle } from '@mantine/hooks';
import { UmkListContainer } from 'src/ui/containers/UmkListContainer';

import { TITLE_UMK } from '@/constants/titles';

const Umk = () => {
  useDocumentTitle(TITLE_UMK);
  return <UmkListContainer />;
};

export default Umk;
