import React from 'react';
import { Tabs, Title } from '@mantine/core';

import { ReviewsProductsTab } from '@/ui/containers/Reviews/listing/tabs/ProductsTab/ReviewsProductsTab';

export const Reviews = () => {
  return (
    <>
      <Title order={1}>Отзывы</Title>

      <Tabs
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 24,
          paddingBottom: 12,
          background: 'white',
          borderRadius: 8,
          marginTop: 16,
        }}
        color='teal'
        defaultValue='products'
      >
        <Tabs.List sx={{ borderBottom: 'none' }}>
          <Tabs.Tab value='products' pb='md' mb='xl' color='primary'>
            Продукты
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 196px)',
          }}
          value='products'
          pt='xs'
        >
          <ReviewsProductsTab />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
