import { usePermission } from '@/hooks/usePermissions';

export const usePermissions = () => ({
  allSettings: usePermission('SETTING_GET_ALL'),
  projects: usePermission('PROJECT_GET_LIST'),
  uploads: usePermission('UPLOAD_FILE'),
  // Исправить скрытие страницы тоглов
  features: usePermission('UPLOAD_FILE'),
  menu: usePermission('MENU_GET_LIST'),
  dictionary: usePermission('DICTIONARY'),

  articles: usePermission('ARTICLE_GET_LIST'),
  news: usePermission('NEWS_GET_LIST'),
  banners: usePermission('BANNER_GET_LIST'),
  promotions: usePermission('PROMOTION_GET_LIST'),

  reviews: usePermission('REVIEW_VIEW'),
  questions: usePermission('QUESTION_GET_LIST'),
  faq: usePermission('FAQ_GET_LIST'),

  globalSettings: true, // TODO: usePermission('???'),
  redirects: usePermission('LIST_REDIRECTS'),
  templates: usePermission('LIST_SEO_TEMPLATE'),
  metas: usePermission('LIST_SEO_META'),
});
