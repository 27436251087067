import { PersonalField } from './PersonalFormBlock.types';

export const type = 'cube-personal';

export const PERSONAL_FIELDS: PersonalField[] = [
  'personal1',
  'personal2',
  'personal3',
  'personal4',
] as const;
