export const caseChangeData = [
  {
    id: '1',
    title: 'Изменение падежа',
    items: [
      { id: '1', fullText: 'именительный', shortText: 'им', templateText: '[page_name:им]' },
      { id: '2', fullText: 'родительный', shortText: 'род', templateText: '[page_name:род]' },
      { id: '3', fullText: 'дательный', shortText: 'дат', templateText: '[page_name:дат]' },
      { id: '4', fullText: 'винительный', shortText: 'вин', templateText: '[page_name:вин]' },
      { id: '5', fullText: 'творительный', shortText: 'твор', templateText: '[page_name:твор]' },
      { id: '6', fullText: 'предложный', shortText: 'пред', templateText: '[page_name:пред]' },
    ],
  },
];

export const letterCaseData = [
  {
    id: '1',
    title: 'Регистр букв',
    items: [
      { id: '1', fullText: 'всё заглавные', shortText: 'капс', templateText: '[page_name:капс]' },
      { id: '2', fullText: 'заглавная', shortText: 'загл', templateText: '[page_name:загл]' },
      { id: '3', fullText: 'строчные', shortText: 'строч', templateText: '[page_name:строч]' },
    ],
  },
];

export const numberData = [
  {
    id: '1',
    title: 'Число',
    items: [
      { id: '1', fullText: 'множ. число', shortText: 'мн', templateText: '[page_name:мн]' },
      { id: '2', fullText: 'ед. число', shortText: 'загедл', templateText: '[page_name:ед]' },
    ],
  },
];
