export const TITLE_DEFAULT = 'CMS';
export const TITLE_AUTH = 'CMS - Авторизация';
export const TITLE_MEDIA = 'CMS - Медиа';
export const TITLE_CONFIGURATION = 'CMS - Конфигурации';
export const TITLE_MODELS = 'CMS - Модели';
export const TITLE_MENU = 'CMS - Меню';
export const TITLE_TEMPLATES = 'CMS - Шаблоны';
export const TITLE_META_TAGS = 'CMS - Мета-теги';
export const TITLE_REDIRECTS = 'CMS - Редиректы';
export const TITLE_PROMOTIONS = 'CMS - Акции';
export const TITLE_UMK = 'CMS - УМК';
