import { UseFormReturnType } from '@mantine/form';
import { SnippetValues, VideoSnippet } from '@prosv/core/types/snippets';

import { EVideoSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoSnippetModal.constants';

interface TUseVideoSnippetModal {
  form: UseFormReturnType<SnippetValues<VideoSnippet>>;
}

export const useVideoSnippetModal = ({ form }: TUseVideoSnippetModal) => {
  const handleAddVideo = () => {
    const newVideo = {
      [EVideoSnippetFormFields.TITLE]: '',
      [EVideoSnippetFormFields.DESCRIPTION]: '',
      [EVideoSnippetFormFields.VIDEO_URl]: '',
      [EVideoSnippetFormFields.SLIDE]: {
        id: '',
        name: '',
        path: '',
      },
    };

    form.values.videos?.push(newVideo);

    form.setValues(form.values);
  };

  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = form.values.videos![dragIndex];
    form.values.videos?.splice(dragIndex, 1);
    form.values.videos?.splice(hoverIndex, 0, draggedItem);
    form.setValues(form.values);
  };

  return { handleAddVideo, handleMoveItem };
};
