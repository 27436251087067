import { useParams } from 'react-router-dom';
import { Box, Paper } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';

import { ActionButtons } from '@/pages/Projects/ProjectDetails/components/ActionButtons';
import { ProjectDetailForm } from '@/pages/Projects/ProjectDetails/components/ProjectDetailForm';
import { getBreadcrumbs } from '@/pages/Projects/ProjectDetails/ProjectDetails.helpers';
import { FormValues } from '@/store/slices/projects/projectsSlice.types';

interface Props {
  title: string;
  initialValues: FormValues;
}

export const ProjectDetails = ({ title, initialValues }: Props) => {
  const params = useParams();
  const isNew = !params?.id;

  const canUpdate = usePermission('PROJECT_UPDATE');
  const canCreate = usePermission('PROJECT_CREATE');
  const canSave = isNew ? canCreate : canUpdate;

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        breadcrumbs={getBreadcrumbs(title)}
        title={isNew ? 'Новый проекта' : title}
        rightButton={canSave ? <ActionButtons /> : null}
      />
      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          gap: 24,
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            gap: '24px',
            overflow: 'auto',
            width: '100%',
          }}
        >
          <ProjectDetailForm initialValues={initialValues} />
        </Box>
      </Paper>
    </Box>
  );
};
