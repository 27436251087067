import { IBannerCreationData, IGetBannerResponse } from '@/types/banner/bannerDetail';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useCreateBanner = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    createBanner: async (bannerData: IBannerCreationData) => {
      setIsLoading(true);
      try {
        const request = await api.post<IGetBannerResponse>(URLS.banners.postBanner, {
          ...bannerData,
        });
        notify({ message: 'Баннер добавлен', type: 'success' });
        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка добавления баннера', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
