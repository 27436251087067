import {
  TConfigurationOption,
  TConfigurationOptionParams,
  TPutConfigurationOptionReq,
} from '@/types';

import api from '../instance';
import { tryAction, tryActionStatus } from '../tryAction';
import { URLS } from '../urls';

export const apiGetConfigurationOption = (params: TConfigurationOptionParams) => {
  const { confId, optId } = params;

  return api.get<TConfigurationOption>(
    URLS.configuration.putConfigurationOptionById(confId, optId)
  );
};

export const apiPutConfigurationOption = ({ params, data }: TPutConfigurationOptionReq) => {
  const { confId, optId } = params;

  return api.put(URLS.configuration.putConfigurationOptionById(confId, optId), { ...data });
};

export const apiDelConfigurationOption = (params: TConfigurationOptionParams) => {
  const { confId, optId } = params;

  return api.delete(URLS.configuration.delConfigurationOptionById(confId, optId));
};

/*TRY ...CATCH */
export const tryGetConfigurationOption = (params: TConfigurationOptionParams) =>
  tryAction<TConfigurationOption>(apiGetConfigurationOption(params));

export const tryPutConfigurationOption = ({ params, data }: TPutConfigurationOptionReq) =>
  tryActionStatus(apiPutConfigurationOption({ params, data }));

export const tryDelConfigurationOption = (params: TConfigurationOptionParams) =>
  tryActionStatus(apiDelConfigurationOption(params));
