import { TQueryParams } from '@/types';

import { URLS } from '@/api/urls';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

import {
  IGetMaterialsResponse,
  IMaterialsFilterRequest,
} from '@/ui/containers/MaterialsContainer/Materials.types';

import api from '../instance';

export const getMaterialsList = (params: TQueryParams) =>
  api.get<IGetMaterialsResponse>(URLS.materials.getMaterialsList(), { params });

export const tryGetMaterialList = (params: IMaterialsFilterRequest) =>
  getMaterialsList(params)
    .then((res) => res.data)
    .catch(axiosErrorHandler);
