import { TemplateItem } from '@/types/templates/templates';

import { ETemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.constants';
import {
  ISelectOptions,
  ITemplateFormFields,
} from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

const createSelectorValues = (value: string | undefined): ISelectOptions => {
  if (!value) return { label: '', value: '' };
  return { label: value, value: value };
};

export const getRobotValues = (robots: ISelectOptions[]): string[] => {
  return robots.map((i) => i.value);
};

export const getDefaultValues = (template: TemplateItem | null): ITemplateFormFields => {
  return {
    //[ETemplateFormFields.NAME]: null,
    [ETemplateFormFields.SITE]: createSelectorValues(template?.site),
    [ETemplateFormFields.PAGE_TYPE]: createSelectorValues(template?.pageType),
    [ETemplateFormFields.PAGE_H1]: template?.pageH1 || '',
    [ETemplateFormFields.TITLE]: template?.title || '',
    [ETemplateFormFields.DESCRIPTION]: template?.description || '',
    [ETemplateFormFields.KEYWORDS]: template?.keywords || '',
    [ETemplateFormFields.ROBOTS]: template?.robots.map(
      (item) => createSelectorValues(item) as ISelectOptions,
    ) || [{ label: 'all', value: 'all' }],
    [ETemplateFormFields.AUTHOR]: template?.author || '',
    [ETemplateFormFields.COPYRIGHT]: template?.copyright || '',
    [ETemplateFormFields.CONTENT]: template?.content || '',
    [ETemplateFormFields.CANONICAL]: template?.canonical || '',
  };
};
