import * as Yup from 'yup';

import { validationTexts } from '@/constants/validationTexts';

import { QuestionSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/QuestionSnippet/QuestionSnippet.constants';

const errText = 'Поле обязательно для заполнения';

const commonStringValidation = Yup.string().required(errText);

const maxLengthValidation = (maxLength: number) =>
  commonStringValidation.max(maxLength, validationTexts.MAX_LENGTH(maxLength));

export const questionSnippetSchema = Yup.object({
  [QuestionSnippetFormFields.TITLE]: maxLengthValidation(150),
  [QuestionSnippetFormFields.QUESTIONS]: Yup.array(
    Yup.object({
      [QuestionSnippetFormFields.ANSWER]: Yup.string(),
      [QuestionSnippetFormFields.QUESTION]: Yup.string(),
    }),
  ),
});
