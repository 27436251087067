import { Sx } from '@mantine/core';

export const root: Sx = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '96vh',
  overflowY: 'hidden',
};

export const paper: Sx = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: 'calc(100% - 40px)',
  maxHeight: '100%',
  overflow: 'hidden',
};

export const tableWrapper: Sx = { position: 'relative', overflow: 'scroll' };

export const footer: Sx = {
  marginTop: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  minHeight: '38px',
};

export const total: Sx = {
  display: 'flex',
  alignItems: 'center',
};

export const pagination: Sx = {
  right: '0',
  position: 'absolute',
};

export const emptyBlock: Sx = {
  position: 'relative',
  height: '100%',
};
