import { FC } from 'react';
import { Box, Button, Modal, NumberInput, Text, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { useModifiedViews } from '@/api/materials/hooks/useModifiedViews';

import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import { EMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { ENewsFormFields } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  form: UseFormReturnType<IMaterialsFormFields>;
  isNewMaterial: boolean;
  materialUuid?: string;
}

export const EditViewPopup: FC<IProps> = ({
  isOpen,
  onClose,
  form,
  isNewMaterial,
  materialUuid,
}) => {
  const { isLoading, modifiedViews } = useModifiedViews();

  const handleClick = () => {
    return isNewMaterial
      ? onClose()
      : modifiedViews({
          uuid: materialUuid || '',
          modifiedViewsCount: form.values[EMaterialsFormFields.MODIFIED_VIEWS_COUNT],
        }).then(() => onClose());
  };

  return (
    <Modal
      opened={isOpen}
      centered
      onClose={onClose}
      styles={{
        header: {
          marginBottom: 0,
        },
      }}
      size={640}
      title={<Title order={4}>Редактировать просмотры</Title>}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Text color='darkest' pt={16} sx={{ fontSize: '14px' }}>
        Введите необходимое количество просмотров. Отредактированные просмотры будут отображаться в
        строке “Отредактированные” в информации о материале. Если вы хотите вернуться к прежнему
        количеству просмотров, очиститите поле или нажмите 0
      </Text>

      <NumberInput
        pt={24}
        label='Введите количество'
        sx={{ width: '335px' }}
        placeholder='Количество просмотров'
        {...form.getInputProps(ENewsFormFields.MODIFIED_VIEWS_COUNT)}
      />

      <Box pt={64}>
        <Button loading={isLoading} onClick={handleClick} sx={{ width: '143px' }}>
          Сохранить
        </Button>
      </Box>
    </Modal>
  );
};
