import { HtmlSnippet } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { EHtmlSnippetFields } from '@/ui/containers/ContentEditor/snippets/HtmlSnippet/HtmlSnippet.constants';

export const getHtmlBlockDefaultValues = (defaultValues?: HtmlSnippet) => {
  const isFullWidth = defaultValues?.isFullWidth?.toString();

  return {
    [EHtmlSnippetFields.IS_FULL_WIDTH]: isFullWidth || 'false',
    [EHtmlSnippetFields.CONTENT]: defaultValues?.content || '',
    [EHtmlSnippetFields.ID]: defaultValues?.id || uuid_v4(),
  };
};
