import React, { useMemo } from 'react';
import { Text } from '@mantine/core';

import { ImagePreview } from '@/ui/containers/Reviews/details/components/ImagePreview';
import { VideoPreview } from '@/ui/containers/Reviews/details/components/VideoPreview';
import { AttachmentsTypes } from '@/ui/containers/Reviews/types';

export const ReviewUploadPhoto = ({
  readOnly,
  ...params
}: Partial<AttachmentsTypes> & { readOnly?: boolean }) => {
  const { name, extension, config } = params;
  const fileType = useMemo(() => {
    if (['MP4', 'M4V', 'WEBM'].includes((extension || '').toUpperCase())) {
      return 'video';
    } else {
      return 'photo';
    }
  }, []);

  const preview = config?.previewConfig?.path || '';
  return (
    <div
      style={{
        border: '1px solid #E8ECF0',
        background: readOnly ? '#F6F8F8' : 'fff',
        display: 'flex',
        borderRadius: 4,
        padding: 8,
        width: 280,
      }}
    >
      <div
        style={{
          maxWidth: 96,
          maxHeight: 64,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {fileType === 'photo' ? <ImagePreview src={preview} /> : <VideoPreview src={preview} />}
      </div>
      <Text
        sx={{
          marginTop: 8,
          marginLeft: 8,
          display: 'inline',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        size='sm'
      >
        {name}
      </Text>

      <div
        style={{
          display: 'flex',
          marginLeft: 'auto',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 8,
        }}
      />
    </div>
  );
};
