import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, Group, Select, Text, TextInput, TextInputProps } from '@mantine/core';
import { SelectionsPlacesRequest } from '@prosv/core/types/bff/catalog/admin';
import { RotateClockwise } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { PAGE_ELEMENTS } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { MultiSelect, TOption } from '@/ui/components/MultiSelect';
import { TMultiSelectProps } from '@/ui/components/MultiSelect/MultiSelect.types';

import {
  activityOptions,
  inputStyles,
  itemsPerPageBox,
  sx,
} from './SelectionsPlacesFilters.constants';

import { useDictionaryOptions } from '@/store/slices/dictionary';
import {
  getSelectionsPlacesFilters,
  selectionsPlacesFiltersActions,
} from '@/store/slices/selections';

export const SelectionsPlacesFilters: FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(getSelectionsPlacesFilters);
  const { changeFilters, resetFilters } = selectionsPlacesFiltersActions;

  const { data: pagesOptions = [] } = useDictionaryOptions('selectionpage', 'code');

  const [state, setState] = useState(filters);

  useEffect(() => {
    setState(filters);
  }, [filters]);

  const selectedPages = useMemo<TOption[]>(
    () =>
      (state.pages ?? '')
        .split(',')
        .map((v) => v.trim())
        .filter((v) => !!v)
        .map((value) => pagesOptions.find((page) => page.value === value) ?? { value, label: '' }),
    [state, pagesOptions],
  );

  const activityValue = useMemo(
    () => (typeof filters.isActive === 'boolean' ? activityOptions[filters.isActive ? 0 : 1] : []),
    [filters.isActive],
  );

  const updateFiltersDebounced = useDebouncedCallback(
    (newFilters: SelectionsPlacesRequest) => dispatch(changeFilters(newFilters)),
    300,
  );

  const updateState = (newState: SelectionsPlacesRequest) => {
    setState((oldState) => {
      newState = { ...oldState, ...newState };
      updateFiltersDebounced(newState);
      return newState;
    });
  };

  const handleSelectionChange: TextInputProps['onChange'] = ({ target }) => {
    updateState({ selection: target.value });
  };

  const handleActivityChange: TMultiSelectProps['onChange'] = (v) => {
    updateState({ pages: (Array.isArray(v) ? v : [v]).map(({ value }) => value).join(',') });
  };

  const handlePageSizeChange = (v: string) => {
    updateState({ offset: 0, limit: parseInt(v ?? PAGE_ELEMENTS[0].value) });
  };

  return (
    <Group sx={{ zIndex: 2 }} mb={24} position='left' align='flex-end' spacing='xl' grow>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Выберите подборку
        </Text>
        <TextInput
          value={state.selection ?? ''}
          styles={inputStyles}
          onChange={handleSelectionChange}
          placeholder='Название'
        />
      </Box>
      <Box sx={{ ...sx, maxWidth: 240 }}>
        <Text size='sm' color='grayDefault'>
          Расположение
        </Text>

        <MultiSelect
          value={selectedPages}
          height={40}
          options={pagesOptions}
          onChange={handleActivityChange}
          label='Расположение'
        />
      </Box>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Активность
        </Text>

        <MultiSelect
          height={40}
          options={activityOptions}
          isMulti={false}
          value={activityValue}
          onChange={(v) => {
            const item = Array.isArray(v) ? v[0] : v;
            const value = item?.value === 'true' ? true : false;
            updateState({ isActive: value === state.isActive ? null : value });
          }}
          label='Активность'
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}>
        <Button
          variant='subtle'
          radius='xs'
          size='sm'
          compact
          sx={({ colors }) => ({
            color: colors.primary[9],
            ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
          })}
          rightIcon={<RotateClockwise size={20} />}
          onClick={() => dispatch(resetFilters())}
        >
          Сбросить
        </Button>
      </Box>

      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        <Box sx={itemsPerPageBox}>
          <Select
            sx={{ width: '60px', input: { paddingRight: '20px' } }}
            size='sm'
            value={`${filters.limit || PAGE_ELEMENTS[0].value}`}
            onChange={handlePageSizeChange}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
