import React, { FC, useRef } from 'react';
import { Button, Checkbox } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { Plus } from 'tabler-icons-react';
import { v4 as uuid_v4 } from 'uuid';

import { DnDBlock } from '@/ui/components/DnDBlock/DnDBlock';
import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { VideoItemUI } from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoItemUI';
import { EVideoSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoSnippetModal.constants';
import { useVideoSnippetModal } from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoSnippetModal.handlers';
import {
  convertVideosUrl,
  getVideoSnippetInitialValues,
  replaceFiles,
} from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoSnippetModal.helpers';
import { videoSnippetSchema } from '@/ui/containers/ContentEditor/snippets/VideoSnippetModal/VideoSnippetModal.schema';

const VideoModalUI: FC = () => {
  const sessionID = useRef(uuid_v4());
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('video');

  const form = useForm({
    initialValues: getVideoSnippetInitialValues(defaultValues),
    validate: yupResolver(videoSnippetSchema),
  });

  const visibleAddBtn =
    Object.values(form.values.videos?.at(-1) || {}).some((value) => !!value) ||
    !form.values.videos?.length;

  const handlers = useVideoSnippetModal({ form });

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Видео'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        values.videos = convertVideosUrl(values);
        await replaceFiles(values, sessionID.current);
        return handleAddSnippetData('video', values, handleClose, snippetData);
      })}
    >
      <div
        style={{
          gridColumn: 'span 12',
          display: 'flex',
          gap: 30,
          padding: '0 16',
        }}
      >
        <Checkbox
          label='Зациклить слайдер'
          checked={form.getInputProps(EVideoSnippetFormFields.LOOP_SLIDE).value}
          {...form.getInputProps(EVideoSnippetFormFields.LOOP_SLIDE)}
        />
        <Checkbox
          label='Только для авторизованных'
          checked={form.getInputProps(EVideoSnippetFormFields.ONLY_FOR_AUTH).value}
          {...form.getInputProps(EVideoSnippetFormFields.ONLY_FOR_AUTH)}
        />
      </div>

      {form.values.videos?.map((video, index) => {
        return (
          <DnDBlock
            key={index}
            id={video.slide.id}
            moveItem={handlers.handleMoveItem}
            index={index}
          >
            <VideoItemUI {...{ index, form, video, sessionID: sessionID.current }} key={index} />
          </DnDBlock>
        );
      })}

      <div style={{ gridColumn: 'span 12', height: 60, display: 'flex' }}>
        {visibleAddBtn && (
          <Button
            variant='white'
            sx={{ padding: '10px 0 0 0', height: 'min-content' }}
            onClick={handlers.handleAddVideo}
          >
            <Plus size={18} style={{ marginRight: 10 }} />
            Добавить ещё видео
          </Button>
        )}
      </div>
    </SnippetModal>
  );
};

export const VideoSnippetModal = React.memo(VideoModalUI);
