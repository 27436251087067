import { EStatusFeatureToggle, TFeature } from '@/types/features/features';

import React from 'react';
import { useDispatch } from 'react-redux';

import { subString } from '@/utils/subString';

import { usePermission } from '@/hooks/usePermissions';

import { ActiveStatus } from './ActiveStatus';

import { setEditFeature } from '@/store/slices/features/features';

type TTableBodyProps = {
  items?: TFeature[] | null;
};

const TableBodyUI: React.FC<TTableBodyProps> = ({ items }) => {
  // Исправить скрытия страницы тоглов
  const canUpdate = usePermission('DICTIONARY_CREATE');
  const dispatch = useDispatch();

  const handleEditFeature = (feature: TFeature) => {
    dispatch(setEditFeature(feature));
  };

  return (
    <tbody>
      {items?.map((item, index) => {
        return (
          <tr key={index}>
            <td style={{}}>{item.name}</td>
            <td style={{}}>
              <ActiveStatus
                canUpdate={!!canUpdate}
                status={EStatusFeatureToggle[item.status]}
                onChangeStatus={() => handleEditFeature(item)}
              />
            </td>
            <td style={{}}>{subString(item.description, 200)}</td>
            <td style={{}}>{subString(item.strategy, 200)}</td>
            <td style={{ maxWidth: '50px' }}></td>
          </tr>
        );
      })}
    </tbody>
  );
};

export const TableBody = React.memo(TableBodyUI);
