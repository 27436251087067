export const validationTexts = {
  REQUIRED: 'Заполните поле',
  CODE: 'Использовать латиницу в нижнем регистре, цифры и дефис',
  STRING: 'Поле должно содержать только буквы, цифры и строковые символы (, . ! ? ; : " \' ` )',
  STRING_NOT_ONLY_WHITESPACE_REGEXP: 'Нельзя использовать только пробелы',
  URL: 'Использовать латиницу в нижнем регистре, цифры, дефис и слэш',
  NOT_SLASH_FIRST: 'Слэш в URL может быть только в середине',
  ONLY_NUMBER: 'Поле должно содержать только цифры',
  EMAIL: 'Поле должно содержать валидный формат почты',
  PHONE: 'Поле должно содержать валидный формат номера телефона',
  ONLY_ALLOWED_URL: (...host: string[]) =>
    `Добавление материалов доступно только только с видеохостингов ${host.join(', ')}.`,
  MIN_LENGTH: (value: number) => `Поле должно содержать не менее ${value} символов`,
  MAX_LENGTH: (value: number) => `Поле должно содержать не более ${value} символов`,
  MAX_SIZE: (value: number) => `Нельзя загружать файлы больше ${value}Мб`,
};
