import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Center, Container, Group, LoadingOverlay, Paper, Text, Title } from '@mantine/core';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { FeatureEditorModal } from './components/FeatureEditorModal';
import FeatureTogglesTable from './components/FeatureTogglesTable/FeatureTogglesTable';

import {
  editFeatureSelector,
  featureListSelector,
  fetchFeaturesListAction,
  loadingFeatureListSelector,
} from '@/store/slices/features/features';
import { setServiceChanged } from '@/store/slices/service/service';

const FeatureToggles: FC = () => {
  const dispatch = useAppDispatch();
  const featureList = useSelector(featureListSelector);
  const editFeature = useSelector(editFeatureSelector);
  const isLoadingFeature = useSelector(loadingFeatureListSelector);

  const fetchFeaturesList = () => {
    return dispatch(fetchFeaturesListAction());
  };

  useEffect(() => void dispatch(setServiceChanged(false)), []);

  useEffect(() => void fetchFeaturesList(), []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '96vh',
          overflowY: 'hidden',
        }}
      >
        <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
          <Group position='apart'>
            <Title order={1}>Сервис включения функциональности</Title>
          </Group>
        </Container>

        <Paper
          radius={8}
          p={24}
          pb={12}
          shadow='xs'
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >
          {featureList && featureList?.length > 0 && (
            <Box sx={{ position: 'relative', overflow: 'scroll' }}>
              <FeatureTogglesTable elements={featureList} />
              <LoadingOverlay visible={isLoadingFeature} />
            </Box>
          )}

          {!featureList?.length && (
            <Box sx={{ position: 'relative', height: '100%' }}>
              <LoadingOverlay visible={isLoadingFeature} />

              {!isLoadingFeature && (
                <Center style={{ height: '100%' }}>
                  <Text align={'center'}>У вас нет feature toggles</Text>
                </Center>
              )}
            </Box>
          )}
        </Paper>
      </Box>

      {!!editFeature && <FeatureEditorModal editFeature={editFeature} />}
    </>
  );
};

export default FeatureToggles;
