import { ItemPermissions } from '@/types/auth';

import { FC } from 'react';
import { Box, Button } from '@mantine/core';

import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

interface IProps {
  isNewPromotion: boolean;
  handleDelete: () => void;
  onSave?: () => void;
  formID: string;
  permissions: ItemPermissions;
}

export const ActionButtons: FC<IProps> = ({
  isNewPromotion,
  onSave,
  handleDelete,
  formID,
  permissions,
}) => {
  const draftId = useStoreContentEditor((state) => state.draftId);
  const savedContentData = useStoreContentEditor((state) => state.savedContentData);
  const url = `${process.env.PREVIEW_URL}/promotions/preview/${draftId}`;

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      {!!savedContentData?.grid.length && (
        <Button
          component='a'
          href={url}
          rel='noopener noreferrer'
          target='_blank'
          sx={{ width: '200px' }}
          variant='default'
        >
          Предпросмотр
        </Button>
      )}
      {!isNewPromotion && permissions.canDelete && (
        <>
          <Button sx={{ width: '200px', color: 'red' }} variant='default' onClick={handleDelete}>
            Удалить
          </Button>
        </>
      )}
      {permissions.canSave && (
        <Button type='submit' form={formID} onClick={onSave} sx={{ width: '200px' }}>
          Сохранить
        </Button>
      )}
    </Box>
  );
};
