import { ItemPermissions } from '@/types/auth';

import { FC } from 'react';
import { Box, Button } from '@mantine/core';

interface Props {
  onSubmit?: () => void;
  onDelete: () => void;
  formId: string;
  permissions?: ItemPermissions;
  previewUrl: string;
  isShowPreviewBtn: boolean;
  isNewUmk?: boolean;
}

export const ActionButtons: FC<Props> = ({
  onSubmit,
  onDelete,
  formId,
  previewUrl,
  isShowPreviewBtn,
  permissions,
  isNewUmk,
}) => {
  const isShowSaveBtn = isNewUmk || permissions?.canUpdate;

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      {isShowPreviewBtn && previewUrl && (
        <Button
          component='a'
          href={previewUrl}
          rel='noopener noreferrer'
          target='_blank'
          sx={{ width: '200px' }}
          variant='default'
        >
          Предпросмотр
        </Button>
      )}
      {permissions?.canDelete && (
        <Button sx={{ width: '200px', color: 'red' }} variant='default' onClick={onDelete}>
          Удалить
        </Button>
      )}
      {isShowSaveBtn && (
        <Button sx={{ width: '200px' }} type='submit' form={formId} onSubmit={onSubmit}>
          Сохранить
        </Button>
      )}
    </Box>
  );
};
