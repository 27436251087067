import { Sx } from '@mantine/core';

export const thumb: Sx = {
  position: 'relative',
  figcaption: {
    overflowWrap: 'break-word',
  },
};

export const deleteButton: Sx = { position: 'absolute', right: 5, top: 5 };
