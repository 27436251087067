import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { setSelectorDraftId, useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

import {
  useGetCategoryTreatmentQuery,
  useGetSupportDetailQuery,
} from '@/store/slices/support/supportDetailSlice';

export const useSupportDetailPage = () => {
  const params = useParams();

  const setDraftId = useStoreContentEditor(setSelectorDraftId);
  const { data, isLoading } = useGetSupportDetailQuery(params?.id || '');
  useEffect(() => {
    if (data?.payload.draftId) {
      setDraftId(data?.payload.draftId);
    }
  }, [data?.payload.draftId]);

  // useSupportDraftFlow(data?.payload.draftId, isSuccess);
  const categoryTreatmentResponse = useGetCategoryTreatmentQuery();

  const categoryOptions = useMemo(
    () =>
      (categoryTreatmentResponse.data?.payload.items || []).map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [categoryTreatmentResponse.data?.payload.items],
  );
  const breadcrumbs = useMemo(
    () => [
      {
        name: 'Поддержка-F.A.Q.',
        url: '/support',
      },
      {
        name: `ID: ${data?.payload.question}`,
      },
    ],
    [data?.payload.question],
  );
  return {
    breadcrumbs,
    isLoading: isLoading || categoryTreatmentResponse.isLoading,
    categoryOptions,
    data,
  };
};
