import { IFileType, IFolder, IFolderType, TElementsRes, TQueryParamsNew } from '@/types';

import api from '../instance';
import { tryAction } from '../tryAction';
import { URLS } from '../urls';

export const apiGetMedia = (params: TQueryParamsNew) =>
  api.get<TElementsRes<IFolderType & IFileType>>(URLS.media.getMedia, { params: params.params });

export const apiGetFolders = () => api.get<IFolder[]>(URLS.media.getFolders);

/*TRY ...CATCH */
export const tryGetMedia = (params: TQueryParamsNew) =>
  tryAction(apiGetMedia(params));

export const tryGetFolders = () => tryAction<IFolder[]>(apiGetFolders());
