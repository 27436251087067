import React, { FC, useRef } from 'react';
import { Button, InputBase } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { Plus } from 'tabler-icons-react';
import { v4 as uuid_v4 } from 'uuid';

import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper/SelectSnippetModalWrapper.helpers';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { PeopleFromItemUI } from '@/ui/containers/ContentEditor/snippets/PeopleSnippetModal/PeopleFromItem';
import { EPeopleSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/PeopleSnippetModal/PeopleSnippetModal.constants';
import {
  getPeopleSnippetInitialValues,
  replaceFiles,
} from '@/ui/containers/ContentEditor/snippets/PeopleSnippetModal/PeopleSnippetModal.helpers';
import { peopleSnippetSchema } from '@/ui/containers/ContentEditor/snippets/PeopleSnippetModal/PeopleSnippetModal.schema';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal/SnippetModal';

import { usePeopleSnippetModal } from './PeopleSnippetModal.handlers';

export const PeopleModalUI: FC = () => {
  const sessionID = useRef(uuid_v4());
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('people');

  const form = useForm({
    initialValues: getPeopleSnippetInitialValues(defaultValues),
    validate: yupResolver(peopleSnippetSchema),
  });

  const handlers = usePeopleSnippetModal({ form });

  const visibleAddBtn =
    Object.values(form.values.people?.at(-1) || {}).some((value) => !!value) ||
    !form.values.people?.length;

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Люди'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        await replaceFiles(values, sessionID.current);
        return handleAddSnippetData('people', values, handleClose, snippetData);
      })}
    >
      <InputBase
        withAsterisk
        label='Название блока'
        placeholder='Введите название блока, например, Команда'
        sx={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EPeopleSnippetFormFields.TITLE)}
      />

      {form.values.people?.map((person, index) => {
        return (
          <PeopleFromItemUI
            {...{ index, form, person, sessionID: sessionID.current }}
            key={index}
          />
        );
      })}

      <div style={{ gridColumn: 'span 12', height: 60, display: 'flex' }}>
        {visibleAddBtn && (
          <Button
            variant='white'
            sx={{ padding: '10px 0 0 0', height: 'min-content' }}
            onClick={handlers.handleAddPerson}
          >
            <Plus size={18} style={{ marginRight: 10 }} />
            Добавить персону в блок
          </Button>
        )}
      </div>
    </SnippetModal>
  );
};

export const PeopleSnippetModal = React.memo(PeopleModalUI);
