import { IFileResponse, IUploadFileResponse } from '@/types/file';

import notify from '@/utils/notify';

import api from '../instance';
import { URLS } from '../urls';

import { IReplaceTempFileRequest } from './types';

export const uploadTempFile = async (formData: FormData) => {
  try {
    const { data } = await api.post<IUploadFileResponse>(URLS.file.uploadTempFile(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data.payload.file ?? null;
  } catch (error) {
    notify({ message: 'Произошла ошибка при загрузке файла', type: 'error' });
    console.error(error);
    return null;
  }
};

export const replaceTempFile = async (params: IReplaceTempFileRequest) => {
  try {
    const { data } = await api.patch<IFileResponse>(URLS.file.replaceTempFile(), params);

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
