import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, useMantineTheme } from '@mantine/core';
import dayjs from 'dayjs';
import { Pencil } from 'tabler-icons-react';
import { useDebouncedCallback } from 'use-debounce';

import { formatDate } from '@/constants/common';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { usePermission } from '@/hooks/usePermissions';

import { SupportStatusCell } from '@/ui/components/CustomTable/columns/SupportStatusCell';
import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';
import {
  ReviewAndQuestionsFilterProps,
  ReviewAndQuestionsQueryParams,
} from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

import { QuestionType } from '@/store/slices/questions/questions.types';
import {
  isDirtyQuestionsFilterSelector,
  questionProductsFilterSelector,
  questionsProductsFilterActions,
  questionsProductsInitialState,
} from '@/store/slices/questions/questionsSlice';

export const useProductsTab = (action: ReviewAndQuestionsFilterProps['onChange']) => {
  const canGet = usePermission('QUESTION_GET');
  const canUpdate = usePermission('QUESTION_ANSWER_UPDATE');
  const theme = useMantineTheme();
  const navigation = useNavigate();
  const filter = useAppSelector(questionProductsFilterSelector);
  const dispatch = useAppDispatch();
  const { setFilter } = questionsProductsFilterActions;
  const handleSetSort = (type: string) => {
    const params = {
      sortField: type,
      sortDirection: setSortDirection(filter.sortDirection),
    };
    dispatch(setFilter(params));
    action(params);
  };

  const columns: ITableColumns<QuestionType>[] = [
    {
      title: 'ID',
      field: 'id',
      style: {
        width: 200,
        paddingRight: 40,
      },
    },
    {
      title: 'Статус',
      field: 'active',
      style: { maxWidth: 176, minWidth: 130 },
      render: ({ status }) => <SupportStatusCell status={status} />,
    },
    {
      title: 'Артикул',
      field: 'header',
      style: { width: 150 },
      render: ({ entity }) => entity.article || ' - ',
    },
    {
      title: 'Название',
      field: 'type',
      style: { minWidth: 176, maxWidth: 400, width: 'fit-content' },
      render: ({ entity }) => <span>{entity.name || ' - '}</span>,
    },
    {
      title: 'Текст вопроса',
      field: 'title',
      position: 'center',
      style: {
        maxWidth: 300,
      },
      render: ({ question }) => <div className='line-clamp-2'>{question || ' - '}</div>,
    },
    {
      title: 'Ответ',
      position: 'left',
      field: 'create_date',
      sort: filter.sortField === 'hasAnswer' ? filter.sortDirection : undefined,
      style: { width: 110, marginRight: 'auto' },
      sortAction: () => handleSetSort('hasAnswer'),

      render: ({ hasAnswer }) => (
        <div style={{ marginRight: 'auto', width: 'fit-content' }}>{hasAnswer ? 'Да' : 'Нет'}</div>
      ),
    },
    {
      title: 'Создан',
      position: 'right',
      field: 'createdAt',
      sort: filter.sortField === 'createdAt' ? filter.sortDirection : undefined,
      sortAction: () => handleSetSort('createdAt'),
      render: ({ createdAt }) =>
        createdAt.value ? dayjs(createdAt.value).format(formatDate) : ' - ',
    },
    {
      title: '',
      style: { width: 50 },
      field: 'actions',
      render: ({ id }) =>
        canGet || canUpdate ? (
          <Button
            name='Редактировать'
            title='Редактировать'
            variant='subtle'
            compact
            style={{ position: 'static' }}
            onClick={() => navigation(`/questions/${id}`)}
          >
            <Pencil size={22} color={theme.colors.greyLight[9]} />
          </Button>
        ) : null,
    },
  ];
  return { columns };
};

export const useQuestionsConfig = (response: (params: ReviewAndQuestionsQueryParams) => void) => {
  const filter = useAppSelector(questionProductsFilterSelector);

  const { resetFilters, setFilterWithResetCurrentPage, setFilter } = questionsProductsFilterActions;
  const dispatch = useAppDispatch();

  const handleSearchDebounced = useDebouncedCallback(
    (params: { article?: string; id?: string }) => {
      response({ ...filter, ...params });
    },
    500,
  );

  const actionWithDebounce = (params: Partial<ReviewAndQuestionsQueryParams>) => {
    dispatch(setFilterWithResetCurrentPage(params));
    handleSearchDebounced(params);
  };
  const onChange = (params: Partial<ReviewAndQuestionsQueryParams>) => {
    dispatch(setFilter(params));
    response({ ...filter, ...params });
  };

  const isDirty = useAppSelector(isDirtyQuestionsFilterSelector);

  const onReset = () => {
    dispatch(resetFilters());
    response(questionsProductsInitialState);
  };

  useEffect(() => {
    response(questionsProductsInitialState);
  }, []);

  return {
    isDirty,
    ...filter,
    onReset,
    actionWithDebounce,
    onChange,
  };
};
