import { useEffect, useState } from 'react';
import { Box, Button, useMantineTheme } from '@mantine/core';
import { SelectionPlaceItem, SelectionsPlacesRequest } from '@prosv/core/types/bff/catalog/admin';
import { IconHelp } from '@tabler/icons';
import { Pencil } from 'tabler-icons-react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { setSortDirection } from '@/ui/components/CustomTable/CustomTable.helppers';
import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';
import { ActiveStatusCell } from '@/ui/containers/MaterialsContainer/Components/ActiveStatusCell';

import {
  getSelectionsPlacesFilters,
  selectionsPlacesFiltersActions,
  useGetSelectionsPlacesQuery,
} from '@/store/slices/selections';

export const useTableData = () => {
  const theme = useMantineTheme();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(getSelectionsPlacesFilters);
  const [limit, setLimit] = useState(filters.limit ?? 10);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isFetching } = useGetSelectionsPlacesQuery(filters);
  const [editItem, setEditItem] = useState<SelectionPlaceItem | null>(null);
  const items = data?.items ?? [];
  const total = data?.total ?? 0;
  const totalPages = Math.ceil(total / limit);
  const { changeFilters } = selectionsPlacesFiltersActions;

  useEffect(() => {
    if (currentPage < 1 || currentPage > totalPages) {
      setCurrentPage(Math.max(1, Math.min(currentPage, totalPages)));
    } else {
      dispatch(changeFilters({ limit, offset: limit * (currentPage - 1) }));
    }
  }, [totalPages, limit, currentPage]);

  const changeSort = (sortField: SelectionsPlacesRequest['sortField']) => {
    dispatch(changeFilters({ sortField, sortDirection: setSortDirection(filters.sortDirection) }));
  };

  const getSort = (field: string) =>
    filters.sortField === field ? filters.sortDirection : undefined;

  const columns: ITableColumns<SelectionPlaceItem>[] = [
    {
      title: 'Страница',
      field: 'page',
      sort: getSort('page'),
      sortAction: () => changeSort('page'),
      render: ({ page }) => page.name,
    },
    {
      title: 'Позиция',
      field: 'position',
      sort: getSort('position'),
      sortAction: () => changeSort('position'),
      render: ({ position }) => position.name,
    },
    {
      title: 'Подборка',
      field: 'selection',
      sort: getSort('selection'),
      sortAction: () => changeSort('selection'),
      render: ({ selection }) => selection?.title || '--',
    },
    {
      title: 'Активность',
      field: 'isActive',
      sort: getSort('isActive'),
      sortAction: () => changeSort('isActive'),
      render: (item) => (
        <Box sx={{ display: 'flex', gap: 5 }}>
          <ActiveStatusCell isActive={item.isActive} toggleActivity={() => setEditItem(item)} />
          {item.selection?.isRemovedFromPublication && <IconHelp color='red' />}
        </Box>
      ),
    },
    {
      title: '',
      style: { width: 50 },
      field: 'actions',
      render: (item) => (
        <Button
          onClick={() => setEditItem(item)}
          name='Редактировать'
          title='Редактировать'
          variant='subtle'
          style={{ position: 'static' }}
          compact
        >
          <Pencil size={22} color={theme.colors.greyLight[9]} />
        </Button>
      ),
    },
  ];

  return {
    columns,
    filters,
    currentPage,
    setCurrentPage,
    limit,
    setLimit,
    total,
    totalPages,
    items,
    isFetching,
    editItem,
    setEditItem,
  };
};
