import React, { FC, useMemo, useState } from 'react';
import { AutocompleteItem, Box, Button, Image } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useInputState } from '@mantine/hooks';
import { OldProductsSnippet, ProductItem, SnippetValues } from '@prosv/core/types';

import { tryGetProducts } from '@/api/catalog/catalog';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { LazyAutocomplete } from '@/ui/components/LazyAutocomplete/LazyAutocomplete';
import { getSelectedProduct } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/components/CustomProducts/CustomProducts.helpers';
import { AutoCompleteItem } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/components/CustomProducts/ProductAutoCompleteItem';
import { useProductsTableData } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/hooks/Products.hooks';
import { getProductsDefaultPagination } from '@/ui/containers/ContentEditor/snippets/ProductsSnippetModal/ProductsSnippetModal.helpers';

import NoImage from '@/assets/images/noimage.png';

interface IProps {
  form: UseFormReturnType<SnippetValues<OldProductsSnippet>>;
}

const CustomProductsUI: FC<IProps> = ({ form }) => {
  const { columns } = useProductsTableData();
  const [value, setValue] = useInputState('');
  const [autocompleteSuggest, setAutocompleteSuggest] = useState<AutocompleteItem[]>([]);
  const [suggestData, setSuggestData] = useState<ProductItem[]>([]);
  const [error, setError] = useState('');
  const searchAction = async (search: string) => {
    const query = {
      ...getProductsDefaultPagination(),
      query: {
        query: search,
      },
    };

    const data = await tryGetProducts(query);
    setSuggestData(data.payload.items);
    const mappedData = data.payload.items.map((product) => {
      const code = product.id.split('_')[0];
      return {
        value: code,
        articleNumber: code,
        bookName: product.name,
      };
    });
    setAutocompleteSuggest(mappedData);
  };

  const productsTableData = useMemo(() => {
    return form.values.products?.map((product) => {
      return {
        image: (
          <Image
            width='60px'
            src={product.image ? process.env.MEDIA_STORAGE_URL + product.image.src : NoImage}
          />
        ),
        name: product.name,
        code: product.id.split('_')[0],
        authors: product.authors,
      };
    });
  }, [form.values.products]);

  const resetError = () => {
    setError('');
  };

  const selectedProduct = async () => {
    return getSelectedProduct(suggestData, value);
  };

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const tempFormProducts = [...form.values.products];
    const draggedItem = tempFormProducts[dragIndex];
    tempFormProducts.splice(dragIndex, 1);
    tempFormProducts.splice(hoverIndex, 0, draggedItem);
    form.setValues((prev) => {
      return { ...prev, products: tempFormProducts };
    });
  };

  const addProduct = async () => {
    const products = [...form.values.products];
    const product = await selectedProduct();
    setValue('');
    if (products.find((pr) => pr.id === product?.id)) {
      setError('Данный продукт уже находится в подборке');
      return;
    }
    const resultProducts = [...products];
    if (product) resultProducts.unshift(product);
    form.values.products = resultProducts;
  };

  const deleteProduct = (data: ProductItem) => {
    if (!form.values.products) return;
    form.setValues({
      ...form.values,
      products: form.values.products.filter((item) => item.id.split('_')[0] !== data.code),
    });
  };

  return (
    <div style={{ gridColumn: 'span 12' }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
        <LazyAutocomplete
          data={autocompleteSuggest}
          autoCompleteItem={AutoCompleteItem}
          limit={10}
          defaultValue=''
          filter={() => {
            return true;
          }}
          searchAction={searchAction}
          withAsterisk
          onChange={setValue}
          sx={{ width: '100%' }}
          value={value}
          placeholder='Введите артикулы товаров'
          label='Артикулы товаров'
          onClick={resetError}
          error={error}
        />
        <Button px={60} sx={{ fontSize: 16 }} onClick={addProduct} disabled={!selectedProduct()}>
          Добавить
        </Button>
      </Box>
      {productsTableData && productsTableData.length > 0 && (
        <CustomTable
          status='loaded'
          data={productsTableData}
          columns={columns}
          noFooter
          draggable
          onRenderClick={deleteProduct}
          moveItem={moveItem}
        />
      )}
    </div>
  );
};

export const CustomProducts = React.memo(CustomProductsUI);
