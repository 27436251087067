import { TQueryParamsRedirects, TQueryUpdateRedirect, TResponseUpdateRedirect } from '@/types';
import { IRedirect, IResponseRedirect } from '@/types/redirects/redirects';

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  tryDeleteRedirect,
  tryGetRedirectList,
  tryUpdateRedirect,
} from '@/api/redirects/redirects';

import notify from '@/utils/notify';

import { LIMIT } from '@/constants/common';

import { TOption } from '@/ui/components/MultiSelect';
import {
  ERROR_MESSAGE,
  NOT_FOUND_MESSAGE,
} from '@/ui/containers/RedirectsContainer/RedirectsContainer.constants';

import { State } from '@/store';

interface IRedirectsState {
  status: {
    fetchingRedirectList: boolean;
  };
  items: IRedirect[] | null;
  total: number | null;
  limit: number;
  offset: number;
  searchUrl: string | null;
  searchCode: TOption[] | null;
  filterMessage: string | null;
  editRedirectItem: IRedirect | null;
  deleteRedirectId: string | null;
}

const initialState: IRedirectsState = {
  status: {
    fetchingRedirectList: false,
  },
  items: null,
  total: null,
  limit: LIMIT,
  offset: 0,
  searchUrl: null,
  searchCode: null,
  filterMessage: null,
  editRedirectItem: null,
  deleteRedirectId: null,
};

export const fetchRedirectListAction = createAsyncThunk(
  '/redirectList',
  async (params: TQueryParamsRedirects, { rejectWithValue }) => {
    try {
      const result = await tryGetRedirectList(params);
      return result as IResponseRedirect;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const updateRedirectAction = createAsyncThunk(
  '/updateRedirect',
  async (params: TQueryUpdateRedirect, { rejectWithValue }) => {
    try {
      const result = await tryUpdateRedirect(params);
      return result as TResponseUpdateRedirect;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const deleteRedirectAction = createAsyncThunk(
  '/deleteRedirect',
  async (id: string, { rejectWithValue }) => {
    try {
      await tryDeleteRedirect(id);
      return { id } as { id: string };
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const redirectsSlice = createSlice({
  name: 'redirects',
  initialState,
  reducers: {
    setLoadingRedirect: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingRedirectList = action.payload;
    },
    setRedirectOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setCode: (state, action) => {
      state.searchCode = action.payload;
    },
    setUrl: (state, action) => {
      state.searchUrl = action.payload;
    },
    setDeleteRedirectId: (state, action) => {
      state.deleteRedirectId = action.payload;
    },
    setClearFilters: (state) => {
      state.searchUrl = null;
      state.searchCode = null;
    },
    setEditRedirectItem: (state, action) => {
      const editItem = state.items && state.items.find((item) => item.id === action.payload);
      if (editItem) {
        state.editRedirectItem = editItem;
      } else {
        state.editRedirectItem = null;
      }
    },
    setUpdateRedirectItem: (state, action) => {
      state.items =
        state.items &&
        state.items.map((item) => (item.id === action.payload.id ? action.payload : item));
    },
    setRemoveRedirectItem: (state, action) => {
      state.items = state.items && state.items.filter((item) => item.id !== action.payload);
      state.deleteRedirectId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRedirectListAction.pending, (state) => {
        state.status.fetchingRedirectList = true;
      })
      .addCase(fetchRedirectListAction.fulfilled, (state, action) => {
        const {
          payload: { pagination, items },
        } = action.payload;
        state.status.fetchingRedirectList = false;

        state.items = items;
        state.total = pagination.total;
        if (state.searchCode || (state.searchUrl && !items.length)) {
          state.filterMessage = NOT_FOUND_MESSAGE;
        }
      })
      .addCase(fetchRedirectListAction.rejected, (state) => {
        if (state.searchCode || state.searchUrl) {
          state.filterMessage = ERROR_MESSAGE;
        }
        notify({ message: 'Ошибка при получении редиректов', type: 'error' });
        state.status.fetchingRedirectList = false;
      })
      .addCase(updateRedirectAction.pending, (state) => {
        state.status.fetchingRedirectList = true;
      })
      .addCase(updateRedirectAction.fulfilled, (state, action) => {
        state.status.fetchingRedirectList = false;
        notify({ message: 'Редирект успешно обновлен', type: 'success' });
        const { payload } = action.payload;
        if (state.items) {
          state.items = state.items.map((item) => (item.id === payload.id ? payload : item));
        }
      })
      .addCase(updateRedirectAction.rejected, (state) => {
        if (state.searchCode || state.searchUrl) {
          state.filterMessage = ERROR_MESSAGE;
        }
        state.status.fetchingRedirectList = false;
        notify({ message: 'Ошибка при обновлении редиректа', type: 'error' });
      })
      .addCase(deleteRedirectAction.pending, (state) => {
        state.status.fetchingRedirectList = true;
      })
      .addCase(deleteRedirectAction.fulfilled, (state, action) => {
        state.status.fetchingRedirectList = false;
        notify({ message: 'Редирект успешно удален', type: 'success' });
        if (state.items) {
          state.items = state.items.filter((item) => item.id !== action.payload.id);
        }
        if (state.total) {
          state.total = state.total -= 1;
        }
      })
      .addCase(deleteRedirectAction.rejected, (state) => {
        if (state.searchCode || state.searchUrl) {
          state.filterMessage = ERROR_MESSAGE;
        }
        state.status.fetchingRedirectList = false;
        notify({ message: 'Ошибка при удалении редиректа', type: 'error' });
      });
  },
});

//selectors
export const redirectListSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.items,
);
export const loadingRedirectListSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.status.fetchingRedirectList,
);
export const redirectsTotalSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.total,
);
export const redirectsLimitSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.limit,
);
export const redirectsOffsetSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.offset,
);

export const redirectsUrlFilterSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.searchUrl,
);

export const redirectsCodeFilterSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.searchCode,
);

export const redirectsSearchMessageSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.filterMessage,
);

export const editRedirectItemSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.editRedirectItem,
);

export const deleteRedirectIdSelector = createSelector(
  (state: State) => state.redirects,
  (dictionary) => dictionary.deleteRedirectId,
);

export const {
  setLoadingRedirect,
  setRedirectOffset,
  setCode,
  setUrl,
  setDeleteRedirectId,
  setRemoveRedirectItem,
  setEditRedirectItem,
  setUpdateRedirectItem,
  setClearFilters,
} = redirectsSlice.actions;

export default redirectsSlice.reducer;
