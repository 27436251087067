import { IMetaTag } from '@/types/metaTagsAnyPage/metaTagsAnyPage';

import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, useMantineTheme } from '@mantine/core';
import { Pencil } from 'tabler-icons-react';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { setEditMetaTagItem } from '@/store/slices/metaTagsAnyPage/metaTagsAnyPage';

interface IProps {
  items: IMetaTag[];
}

export const MetaTable: FC<IProps> = ({ items }) => {
  const dispatch = useAppDispatch();

  const theme = useMantineTheme();

  const navigate = useNavigate();
  const handleEditMetaTag = (metaTagId: string) => {
    dispatch(setEditMetaTagItem(metaTagId));
    navigate(`/meta-tags-any-page/${metaTagId}`);
  };

  return (
    <>
      <Table highlightOnHover verticalSpacing='xs' fontSize='sm'>
        <thead style={{ background: '#E8ECF0' }}>
          <tr>
            <th>Название</th>
            <th>URL</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map(({ id, title, canonical }) => {
            return (
              <tr key={id}>
                <td style={{ width: '400px' }}>{title}</td>
                <td>{canonical}</td>
                <td />
                <td style={{ maxWidth: '20px' }}>
                  <Button
                    name='Редактировать'
                    title='Редактировать'
                    variant='subtle'
                    compact
                    onClick={() => handleEditMetaTag(id)}
                    style={{ position: 'static' }}
                  >
                    <Pencil size={22} color={theme.colors.greyLight[9]} />
                  </Button>
                </td>{' '}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
