import React, { FC } from 'react';
import { Box, Select } from '@mantine/core';

import { Loader } from '../Loader';
import {
  getTemplateSelectValue,
  savedContentDataSelector,
  savedFromAllDataSelector,
  useStoreContentEditor,
} from '../store';

import { RowConfiguration } from './RowConfiguration';
import { EMPTY_ROW_ID } from './Rows.constnats';

interface Props {
  draftId?: string;
}

export const Rows: FC<Props> = ({ draftId }) => {
  const savedContentData = useStoreContentEditor(savedContentDataSelector);
  const savedContentCurrentEditorData = useStoreContentEditor((state) =>
    savedFromAllDataSelector(state, draftId ?? ''),
  );

  const loadingContentData = useStoreContentEditor((state) => state.loadingContentData);
  const selectValue = useStoreContentEditor(getTemplateSelectValue);

  const savedContent = draftId ? savedContentCurrentEditorData : savedContentData;

  return (
    <>
      <Box
        sx={{
          border: '1px solid #EDF1F3',
          padding: '16px 14px',
          height: '100%',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {selectValue && (
          <Select
            sx={{ maxWidth: '584px' }}
            data={[selectValue]}
            value={selectValue.value}
            disabled
          />
        )}
        {savedContent?.grid?.map((row, index) => {
          return <RowConfiguration rowData={row} rowId={index} key={index + Math.random()} />;
        })}
        <RowConfiguration rowId={EMPTY_ROW_ID} />
      </Box>
      <Loader loading={loadingContentData} />
    </>
  );
};
