import * as yup from 'yup';

import { ProjectDetailsValuesEnum } from '@/pages/Projects/ProjectDetails/ProjectDetails.consts';

export const validationSchema = yup.object().shape({
  [ProjectDetailsValuesEnum.NAME]: yup.string().required('Введите название проекта'),
  [ProjectDetailsValuesEnum.UUID]: yup.string().required('Введите название проекта'),
  [ProjectDetailsValuesEnum.IS_ACTIVE]: yup.boolean().required('Выберите активность'),
  [ProjectDetailsValuesEnum.TYPE]: yup.string().required('Выберите тип'),
  [ProjectDetailsValuesEnum.EVENT_IDS]: yup.array().when(ProjectDetailsValuesEnum.TYPE, {
    is: (type: string) => type === 'eventBased',
    then: yup.array().test({
      message: 'Добавьте хотя бы один ID события',
      test: (value) => Array.isArray(value) && value.length > 0,
    }),
    otherwise: yup.array(),
  }),
  [ProjectDetailsValuesEnum.PROJECT_ID]: yup.array().when(ProjectDetailsValuesEnum.TYPE, {
    is: (type: string) => type !== 'eventBased',
    then: yup.array().min(1, 'Введите хотя бы один ID проекта'),
    otherwise: yup.array(),
  }),
});
