import type { TGEtNewsQuery } from '@/types/news/news';
import { TNewsResponse } from '@/types/news/news';
import { IGetNewsResponse } from '@/types/news/newsDetail';

import type { TMailsTemplate, TQueryParams } from 'src/types';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

import api from '../instance';
import { URLS } from '../urls';

export const getNewsList = (params: TQueryParams) =>
  api.get<TNewsResponse>(URLS.news.getNewsList(), { params });

export const apiDelMailsTemplate = (id: string) =>
  api.delete<{ id: string }>(URLS.mailsTemplate.delMailsTemplateById(id));

export const apiDelMailsTemplates = (mailTemplatesIds: string[]) =>
  api.delete<{ id: string }>(URLS.mailsTemplate.getMailsTemplates, {
    params: { mailTemplatesIds },
  });

export const apiGetMailTemplate = (id: string) =>
  api.get<TMailsTemplate>(URLS.mailsTemplate.getMailTemplate(id));

export const apiPutMailTemplate = (id: string, data: Partial<TMailsTemplate>) =>
  api.put<TMailsTemplate>(URLS.mailsTemplate.getMailTemplate(id), { ...data });

export const apiAddMailTemplate = (data: Partial<TMailsTemplate>) =>
  api.post<TMailsTemplate>(URLS.mailsTemplate.getMailsTemplates, { ...data });
export const getNews = (uuid: string) => api.get<IGetNewsResponse>(URLS.news.getNews(uuid));
export const getNewsByLink = (site: string, urlCode: string) =>
  api.get<IGetNewsResponse>(URLS.news.getNewsByLink(site, urlCode));

/*TRY ...CATCH */
export const tryGetNews = (uuid: string) =>
  getNews(uuid)
    .then((res) => res.data)
    .catch(axiosErrorHandler);
export const tryGetNewsByLink = (site: string, urlCode: string) =>
  getNewsByLink(site, urlCode).then((res) => res.data.payload);
export const tryGetNewsList = (params: TGEtNewsQuery) =>
  getNewsList(params)
    .then((res) => res.data)
    .catch(axiosErrorHandler);

export const tryDelMailsTemplate = (id: string) =>
  apiDelMailsTemplate(id)
    .then((res) => res.status)
    .catch(axiosErrorHandler);

export const tryMultiDelMailsTemplate = (id: string[]) =>
  apiDelMailsTemplates(id)
    .then((res) => res.status)
    .catch(axiosErrorHandler);

export const tryGetMailTemplate = (id: string) =>
  apiGetMailTemplate(id)
    .then((res) => res)
    .catch(axiosErrorHandler);

export const tryPutMailTemplate = (id: string, data: Partial<TMailsTemplate>) =>
  apiPutMailTemplate(id, data)
    .then((res) => res)
    .catch(axiosErrorHandler);

export const tryAddMailTemplate = (data: Partial<TMailsTemplate>) =>
  apiAddMailTemplate(data)
    .then((res) => res)
    .catch(axiosErrorHandler);
