import * as Yup from 'yup';

import { REDIRECT_URL_REGEXP } from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';

import { EMenuContainerEditRedirectConstants } from './EditRedirectModal.constants';

export const NEW_ITEM_FORM = Yup.object().shape({
  [EMenuContainerEditRedirectConstants.OLD_URL]: Yup.string()
    .required('Поле "Старый URL" обязательно для заполнения')
    .matches(REDIRECT_URL_REGEXP, validationTexts.URL),
  [EMenuContainerEditRedirectConstants.NEW_URL]: Yup.string()
    .required('Поле "Новый URL" обязательно для заполнения')
    .matches(REDIRECT_URL_REGEXP, validationTexts.URL),
  [EMenuContainerEditRedirectConstants.CODE]: Yup.string()
    .test('code', 'Значение должно состоять из 3 символов', (val) => String(val).length === 3)
    .test('code', 'Значение должно начинаться с цифры 3', (val) => String(val).startsWith('3')),
});
