import {
  CreateDictionaryItemResponse,
  DictionariesResponse,
  DictionaryItemRequest,
  DictionaryItemResponse,
  DictionaryItemsRequest,
  DictionaryItemsResponse,
  UpdateDictionaryItemResponse,
} from '@prosv/core/types/bff/dictionary';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import { CreateDictionaryItemParams, UpdateDictionaryItemParams } from './dictionary.types';

export const dictionaryApiSlice = createApi({
  reducerPath: 'api/dictionary',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Dictionaries', 'DictionaryItems'],
  endpoints: (build) => ({
    // получение списка словарей
    getDictionaries: build.query<DictionariesResponse['payload']['items'], void>({
      query: () => ({ method: 'get', url: URLS.dictionary.getDictionaries }),
      transformResponse: ({ payload }: DictionariesResponse) => payload.items,
      providesTags: ['Dictionaries'],
    }),

    // получения элеметов словаря по его коду `code`
    getDictionaryItems: build.query<DictionaryItemsResponse['payload'], DictionaryItemsRequest>({
      query: ({ code, ...params }) => ({
        method: 'get',
        url: getUrlWithSearchParams(
          URLS.dictionary.getDictionaryItems(code),
          { limit: 1e6, ...params },
          { arrayFormat: 'comma' },
        ),
      }),
      transformResponse: ({ payload }: DictionaryItemsResponse) => payload,
      providesTags: [{ type: 'DictionaryItems', id: 'LIST' }],
    }),

    // получение конкретного элемента словаря
    getDictionaryItem: build.query<DictionaryItemResponse['payload'], DictionaryItemRequest>({
      query: ({ code, id }) => ({
        method: 'get',
        url: URLS.dictionary.getDictionaryItem(code, id),
      }),
      transformResponse: ({ payload }: DictionaryItemResponse) => payload,
      providesTags: (res) => (res ? [{ type: 'DictionaryItems', id: res.id }] : []),
    }),

    // создание нового элемента справочника
    createDictionaryItem: build.mutation<
      CreateDictionaryItemResponse['payload'],
      CreateDictionaryItemParams
    >({
      query: ({ code, params: data }) => ({
        method: 'post',
        url: URLS.dictionary.createDictionaryItem(code),
        data,
      }),
      transformResponse: ({ payload }: CreateDictionaryItemResponse) => payload,
      invalidatesTags: (res) =>
        res ? ['DictionaryItems', { type: 'DictionaryItems', id: res.id }] : ['DictionaryItems'],
    }),

    // изменение существующего элемента справочника
    updateDictionaryItem: build.mutation<
      UpdateDictionaryItemResponse['payload'],
      UpdateDictionaryItemParams
    >({
      query: ({ code, id, params: data }) => ({
        method: 'put',
        url: URLS.dictionary.updateDictionaryItem(code, id),
        data,
      }),
      transformResponse: ({ payload }: UpdateDictionaryItemResponse) => payload,
      invalidatesTags: ['DictionaryItems'],
      // TODO: раскомментировать PROSVNEW-8805
      // invalidatesTags: (res) =>
      //   res ? ['DictionaryItems', { type: 'DictionaryItems', id: res.id }] : ['DictionaryItems'],
    }),
  }),
});
