import { useState } from 'react';

import { ISelectionResponse } from '@/api/catalog/categories';
import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useSelections = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getSelections: async () => {
      setIsLoading(true);
      try {
        const request = await api.get<ISelectionResponse>(URLS.catalog.getSelections);

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения подборок', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
