import React from 'react';
import { Box, Input, Text } from '@mantine/core';

import { TInputFormProps } from './InputForm.type';

export const InputForm: React.FC<TInputFormProps> = ({
  title,
  elementProps,
  placeholder,
  description,
}) => {
  return (
    <>
      <Text span>{title}</Text>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <Input placeholder={placeholder} {...(elementProps || {})} />

        {!!description && (
          <Text span size={14} weight={400} color='greyDark'>
            {description}
          </Text>
        )}
      </Box>
    </>
  );
};
