import { ChangeEvent, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, yupResolver } from '@mantine/form';

import { transliteration } from '@/utils/transliteration';

import { FAQ_DETAIL_FORM } from '@/constants/validationSchemas';

import { GeneralInformationCardTypes } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.types';
import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';
import { SupportDetailFormField } from '@/ui/containers/SupportContainer/detail/components/tabs/general/GeneralTab.constants';

import {
  useLazyCreateNewQuestionQuery,
  useLazyUpdateQuestionQuery,
} from '@/store/slices/support/supportDetailSlice';
import {
  SupportDetailForm,
  SupportDetailResponse,
} from '@/store/slices/support/supportDetailsTypes';

export const useGeneralTabForm = (data?: SupportDetailResponse, type?: 'create' | 'edit') => {
  const isCreate = type === 'create';
  const queryParams = useParams();
  const navigate = useNavigate();
  const initialValues = useMemo(
    () =>
      ({
        [SupportDetailFormField.IS_ACTIVE]: data?.payload.isActive ? 'true' : '',
        [SupportDetailFormField.QUESTION]: data?.payload.question,
        [SupportDetailFormField.CATEGORY_ID]: data?.payload.category?.id,
        [SupportDetailFormField.SORT]: (data?.payload.sort || '').toString(),
        [SupportDetailFormField.IS_DISPLAY_ON_MAIN]: data?.payload.isDisplayOnMain ? 'true' : '',
        [SupportDetailFormField.IS_DISPLAY_ON_HEADER]: data?.payload.isDisplayOnHeader
          ? 'true'
          : '',
        [SupportDetailFormField.POSITION_ON_MAIN]: (data?.payload.positionOnMain || '').toString(),
        [SupportDetailFormField.POSITION_ON_HEADER]: (
          data?.payload.positionOnHeader || ''
        ).toString(),
        [SupportDetailFormField.DRAFT_ID]: data?.payload.draftId,
        [SupportDetailFormField.URL_CODE]: data?.payload.urlCode,
      } as SupportDetailForm),
    [data?.payload],
  );

  const productInfo: GeneralInformationCardTypes | undefined =
    type === 'edit'
      ? {
          cardType: 'support',
          generalId: data?.payload.id || '',
          author: {
            name: data?.payload.meta?.author.fullName,
            createDate: data?.payload.meta?.createDate.value,
          },
          editor: {
            name: data?.payload.meta?.editor?.fullName,
            editDate: data?.payload.meta?.updateDate?.value,
          },
        }
      : undefined;

  const form = useForm<SupportDetailForm>({
    initialValues: useMemo(() => initialValues, [initialValues, type]),
    validate: yupResolver(FAQ_DETAIL_FORM),
  });

  const handleChangeQuestion = (event: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(
      SupportDetailFormField.URL_CODE,
      `${transliteration(event.currentTarget.value, { onlyLower: true })}`,
    );
    return form.getInputProps(SupportDetailFormField.QUESTION).onChange(event);
  };

  const isViewMainPageConfig = form.values.isDisplayOnMain;
  const isDisplayOnHeader = form.values.isDisplayOnHeader;
  const draftId = useStoreContentEditor((state) => state.draftId || '');
  const [createRequest, createResponse] = useLazyCreateNewQuestionQuery();
  const [updateRequest, updateResponse] = useLazyUpdateQuestionQuery();
  const backToList = () => navigate('/support');

  const onSubmit = useCallback(
    (params: SupportDetailForm) => {
      const requestData = { ...params, draftId };
      if (type === 'create') {
        createRequest({ data: requestData, backToList });
      } else {
        updateRequest({ data: requestData, id: queryParams?.id || '', backToList });
      }
    },
    [type, draftId],
  );

  return {
    productInfo,
    isViewMainPageConfig,
    isDisplayOnHeader,
    handleChangeQuestion,
    form,
    onSubmit,
    isLoading: isCreate ? createResponse.isLoading : updateResponse.isLoading,
  };
};
