import { TFeature } from '@/types/features/features';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Group, Modal, Radio, Select, Slider, Text } from '@mantine/core';
import { debounce } from 'lodash';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { getPayloadUpdateFeature } from '@/ui/containers/FeatureToggles/components/FeatureEditorModal/getPayloadUpdateFeature';

import { MEMBER_GROUP_OPTIONS, STATUS_TOGGLE } from './FeatureEditorModal.constants';

import {
  editFeatureSelector,
  loadingFeatureSelector,
  setEditFeature,
  setLoadingFeature,
  updateFeatureAction,
} from '@/store/slices/features/features';

interface IProps {
  editFeature: TFeature;
}

const FeatureEditorModalUI: FC<IProps> = ({
  editFeature: { code, status, coveragePercentage },
}) => {
  const dispatch = useAppDispatch();

  const editFeature = useSelector(editFeatureSelector);
  const disabled = useSelector(loadingFeatureSelector);
  const [activity, setActivity] = useState<string>(STATUS_TOGGLE[status]);
  const [percentage, setPercentage] = useState<number>(coveragePercentage || 0);
  const [group, setGroup] = useState<string>(MEMBER_GROUP_OPTIONS[1].value);

  const handleClose = () => {
    if (disabled) return;
    dispatch(setEditFeature(null));
  };

  const handlePreventClickModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      e.isPropagationStopped();
    }
  };

  const handleChangeStatus = (value: string) => setActivity(value);

  const handleChangeCountPeople = debounce((value: number) => {
    setPercentage(value);
  }, 1000);

  const handleChangeMemberGroup = (value: string) => {
    setGroup(value);
  };

  const handleSubmit = async () => {
    const payload = getPayloadUpdateFeature(activity, code, percentage, group);

    dispatch(setLoadingFeature(true));
    dispatch(updateFeatureAction(payload));
    dispatch(setLoadingFeature(false));
    handleClose();
  };

  return (
    <Modal
      opened={!!editFeature}
      onClose={handleClose}
      title={
        <Text size={24} align='left' weight={600}>
          Настройка
        </Text>
      }
      size={650}
      withCloseButton
      overflow='inside'
      sx={{
        '*': { cursor: disabled ? 'not-allowed !important' : 'inherit' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 400,
          position: 'relative',
          gap: '20px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Text size={16} weight={600}>
            Активность
          </Text>
          <Radio.Group
            withAsterisk
            onChange={handleChangeStatus}
            sx={{ 'div > *': { cursor: 'pointer' } }}
            defaultValue={STATUS_TOGGLE[status]}
          >
            <Radio value='1' label='Активно' />
            <Radio value='2' label='Частично активно' />
            <Radio value='0' label='Неактивно' />
          </Radio.Group>

          <Text pt={8} size={14} weight={400} color='greyDark'></Text>
        </Box>
        {activity === '2' && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <Text size={14} weight={600}>
                Доля пользователей
              </Text>

              <Slider
                labelTransition='skew-down'
                labelTransitionDuration={150}
                labelTransitionTimingFunction='ease'
                onChange={handleChangeCountPeople}
                defaultValue={coveragePercentage || 0}
              />

              <Text size={14} weight={400} color='greyDark'>
                Доля пользователей, для которых включаем фичу. Задаётся в процентах от 0 до 100.
              </Text>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <Text size={14} weight={600}>
                Группа пользователей
              </Text>

              <Select
                placeholder='Выберите группу пользователей'
                onChange={handleChangeMemberGroup}
                nothingFound='No options'
                data={MEMBER_GROUP_OPTIONS}
                defaultValue={MEMBER_GROUP_OPTIONS[1].value}
              />

              <Text size={14} weight={400} color='greyDark'>
                Выпадающий список с группами пользователей, для которых включаем фичу.
              </Text>
            </Box>
          </>
        )}

        {disabled && (
          <Box
            sx={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1000 }}
            onClick={handlePreventClickModal}
          />
        )}
      </Box>

      <Group position='right' pt={32}>
        <Button
          sx={{ fontSize: 16, fontWeight: 600, height: 40 }}
          loading={disabled}
          onClick={handleSubmit}
        >
          {disabled ? 'Сохранение' : 'Сохранить'}
        </Button>
      </Group>
    </Modal>
  );
};

export const FeatureEditorModal = React.memo(FeatureEditorModalUI);
