import React, { FC, useEffect } from 'react';

import { ContentEditor } from '@/ui/containers/ContentEditor/ContentEditor';
import {
  setSelectorDefaultFormData,
  setSelectorDraftId,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

interface Props {
  draftId: string;
}

export const ContentEditorWrapper: FC<Props> = ({ draftId }) => {
  const setDraftIde = useStoreContentEditor(setSelectorDraftId);
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  useEffect(() => {
    setDraftIde(draftId);
    return () => {
      setDefaultFormData(null);
      setDraftIde(null);
    };
  }, [draftId, setDefaultFormData, setDraftIde]);

  return <ContentEditor />;
};
