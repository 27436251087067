import { FC, useRef } from 'react';
import { Button, ColorInput, InputBase, Radio, Select } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { SnippetValues } from '@prosv/core/types/snippets';
import { UmkSystem as UmkSystemSnippetType } from '@prosv/core/types/snippets/umkSystem';
import { Plus } from 'tabler-icons-react';
import { v4 as uuid_v4 } from 'uuid';

import { DnDBlock } from '@/ui/components/DnDBlock';
import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';
import { UmkSystemBlock } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemBlock';
import {
  UMK_COLOR_PICKER_COLORS,
  umkBlockFieldOptions,
  UmkSystemSnippetFormFields,
} from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemSnippet.constants';
import { getUmkSystemSnippetDefaultValues } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemSnippet.helpers';
import { useUmkSystemSnippetModal } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemSnippet.hooks';
import { umkSystemSnippetSchema } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemSnippet.schema';

export const UmkSystemSnippet: FC = () => {
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('umkSystem');
  const sessionID = useRef(uuid_v4());

  const form = useForm({
    initialValues: getUmkSystemSnippetDefaultValues(defaultValues),
    validate: yupResolver(umkSystemSnippetSchema),
  });

  const handlers = useUmkSystemSnippetModal({ form });

  const handleRemoveBlock = (index: number) => {
    form.values.blocks?.splice(index, 1);
    form.setValues(form.values);
  };

  const handleSubmit = async (values: SnippetValues<UmkSystemSnippetType>) => {
    const backgroundColor = values.blockType === 'row' ? '' : values.backgroundColor;
    return handleAddSnippetData(
      'umkSystem',
      { ...values, backgroundColor },
      handleClose,
      snippetData,
    );
  };

  const isShowRadioAndColorFields = form.values.blockType === 'column';

  return (
    <SnippetModal
      isOpen={true}
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Как устроена система'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit((values) => handleSubmit(values))}
    >
      <InputBase
        label='Заголовок блока'
        placeholder='Введите заголовок блока'
        withAsterisk
        sx={{ gridColumn: 'span 6' }}
        {...form.getInputProps(UmkSystemSnippetFormFields.TITLE)}
      />
      <Select
        data={umkBlockFieldOptions}
        sx={{ gridColumn: 'span 6' }}
        label='Вид блока'
        placeholder='Выберите из списка'
        withAsterisk
        clearable
        {...form.getInputProps(UmkSystemSnippetFormFields.BLOCK_TYPE)}
      />
      {isShowRadioAndColorFields && (
        <>
          <ColorInput
            placeholder='Цвет фона'
            label='Цвет заливки фона'
            sx={{ gridColumn: 'span 6' }}
            withPicker={false}
            swatches={UMK_COLOR_PICKER_COLORS}
            {...form.getInputProps(UmkSystemSnippetFormFields.BACKGROUND_COLOR)}
          />
          <Radio.Group
            label='Начинать заливку с первого блока'
            sx={{ gridColumn: 'span 6' }}
            {...form.getInputProps(UmkSystemSnippetFormFields.START_POURING_FROM_FIRST_BLOCK)}
          >
            <Radio value='true' label='Да' />
            <Radio value='false' label='Нет' />
          </Radio.Group>
        </>
      )}
      {form.values.blocks?.map((umkBlock, index) => {
        return (
          <DnDBlock
            key={index + sessionID.current}
            id={umkBlock.toString() + index}
            moveItem={handlers.handleMoveItem}
            index={index}
          >
            <UmkSystemBlock
              sessionID={sessionID.current}
              index={index}
              handleRemoveBlock={handleRemoveBlock}
              form={form}
            />
          </DnDBlock>
        );
      })}
      <div style={{ gridColumn: 'span 12', height: 60, display: 'flex' }}>
        <Button variant='white' sx={{ gridColumn: 'span 12' }} onClick={handlers.handleAddUmkBlock}>
          <Plus size={18} style={{ marginRight: 10 }} />
          Добавить ещё блок
        </Button>
      </div>
    </SnippetModal>
  );
};
