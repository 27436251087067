import { IPromotionItem, TPromotionsResponse } from '@/types/promotions';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useDeletePromotion = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    deletePromotion: async (uuid: string) => {
      setIsLoading(true);
      try {
        return await api
          .delete<TPromotionsResponse<IPromotionItem>>(URLS.promotions.deletePromotion(uuid))
          .then((res) => {
            notify({ message: 'Акция успешно удалена', type: 'success' });
            return res.data;
          });
      } catch (err) {
        return notify({ message: 'Ошибка удаления акции', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
