import { FC } from 'react';
import { Navigate } from 'react-router-dom';

interface NavigateToPluginProps {
  plugins: string[];
}

const NavigateToPlugin: FC<NavigateToPluginProps> = ({ plugins }) => {
  return <>{plugins && plugins.length > 0 && <Navigate to={`/${plugins[0]}`} />}</>;
};

export default NavigateToPlugin;
