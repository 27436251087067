import type { OptionProps } from 'react-select';
import { Box, Checkbox, Text } from '@mantine/core';

import type { TOption } from '../MultiSelect.types';

export const OptionComponent = (props: OptionProps<TOption>) => {
  const { innerProps, innerRef } = props;
  return (
    <Box
      ref={innerRef as any}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        padding: '12px 16px',
      }}
      {...innerProps}
    >
      <Checkbox
        styles={{ input: { cursor: 'pointer' } }}
        size='sm'
        checked={props.isSelected}
        color='primary'
      />
      <Text
        size='sm'
        color={props.isSelected ? 'black' : 'greyDark'}
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {props.label}
      </Text>
    </Box>
  );
};
