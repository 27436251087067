import React, { useState } from 'react';

import { ReactComponent as EventNote } from '@/assets/icons/event-note.svg';
import { ModalsVariants, useModalsStore } from '@/store/slices/modalsZustandStore/modalsStore';

export const VideoPreview = ({ src }: { src: string }) => {
  const [error, setError] = useState(false);
  const openModal = useModalsStore((state) => state.openModal);

  return (
    <>
      {error ? (
        <div
          style={{
            background: '#E8ECF0',
            width: 77,
            height: 96,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EventNote />
        </div>
      ) : (
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() =>
            openModal({
              currentModal: ModalsVariants.REVIEW_DETAILS_VIDEO,
              stringSource: (process.env.MEDIA_STORAGE_URL as string) + src,
            })
          }
        >
          <video
            onError={() => setError(true)}
            src={(process.env.MEDIA_STORAGE_URL as string) + src}
          ></video>
        </div>
      )}
    </>
  );
};
