import { INewsCreationData } from '@/types/news/newsDetail';

import dayjs from 'dayjs';

import {
  DATE_TEMPLATE_DAYS,
  DATE_TEMPLATE_HOURS,
  FILTERED_FIELDS,
} from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { INewsFormFields } from '@/ui/containers/NewsDetailInfo';
import {
  ACTIVITY_FIELD_VALUES,
  EActivityFieldValues,
  ENewsFormFields,
} from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

export const getBreadcrumbs = (title: string) => [
  {
    name: 'Новости',
    url: '/news',
  },
  {
    name: title,
  },
];

export const dateFormToNewsDate = (date: string | Date, template: string) => {
  return dayjs(date).format(template);
};
export const formFieldsToCreate = (
  formFields: INewsFormFields,
  draftId: string,
): INewsCreationData => {
  const active = formFields[ENewsFormFields.IS_ACTIVE] as EActivityFieldValues;
  const dateActiveValue = formFields[ENewsFormFields.DATA_ACTIVE_TO];
  const dateActiveFrom =
    dateFormToNewsDate(formFields[ENewsFormFields.DATA_ACTIVE_FROM_DAYS], DATE_TEMPLATE_DAYS) +
    dateFormToNewsDate(formFields[ENewsFormFields.DATA_ACTIVE_FROM_HOURS], DATE_TEMPLATE_HOURS);

  const dateActiveTo = dateActiveValue
    ? dateFormToNewsDate(dateActiveValue, DATE_TEMPLATE_DAYS + DATE_TEMPLATE_HOURS)
    : '';

  return {
    ...formFields,
    draftId,
    umk: formFields[ENewsFormFields.SERIES_LINE_UMK],
    isActive: ACTIVITY_FIELD_VALUES[active],
    dateActiveFrom,
    dateActiveTo,
  };
};

export const formFieldsToUpdate = (
  formFields: INewsFormFields,
  draftId: string,
): INewsCreationData => {
  const fields = formFieldsToCreate(formFields, draftId);

  return Object.fromEntries(
    Object.entries(fields).filter(([key]) => !FILTERED_FIELDS.includes(key)),
  ) as INewsCreationData;
};
