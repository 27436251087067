import { TNewsItem, TNewsSortValues } from '@/types/news/news';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Table, useMantineTheme } from '@mantine/core';
import ModalConfirm from 'src/ui/components/ModalConfirm/ModalConfirm';
import { Pencil } from 'tabler-icons-react';

import { formatDate } from '@/utils/formatDate';
import { getItemPermissions } from '@/utils/permissions';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { ActiveStatus } from '@/ui/components/ActiveStatus';
import { TableColumnTitle } from '@/ui/components/TableExt/components/TableColumnTitle';
import TableHeadSortable from '@/ui/components/TableExt/components/TableHeadSortable/TableHeadSortable';

import {
  fetchDelMailsTemplateAction,
  fetchMailsTemplatesAction,
  selectDelMailsTemplateModal,
  selectDelMailsTemplatesModal,
  selectMailsTemplatesLimit,
  selectMailsTemplatesOffset,
  setOpenDelMailsTemplateModal,
  setOpenDelMailsTemplatesModal,
} from '@/store/slices/mailsTemplates/mailsTemplates';

type TConfigurationsTable = {
  elements: TNewsItem[];
  sortFiled?: { field: TNewsSortValues; direction: 'asc' | 'desc' } | null;
  setSortField?: React.Dispatch<
    React.SetStateAction<{ field: TNewsSortValues; direction: 'asc' | 'desc' } | null>
  >;
};

const NewsTable: FC<TConfigurationsTable> = ({ elements, sortFiled, setSortField }) => {
  const dispatch = useAppDispatch();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const delDelMailsTemplateModal = useSelector(selectDelMailsTemplateModal);
  const delDelMailsTemplatesModal = useSelector(selectDelMailsTemplatesModal);
  const mailsTemplatesLimit = useSelector(selectMailsTemplatesLimit);
  const mailsTemplatesOffset = useSelector(selectMailsTemplatesOffset);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentItemId, setCurrentItemId] = useState<null | string>(null);

  const delConfirmHandler = async (id: null | string) => {
    if (id !== null) await dispatch(fetchDelMailsTemplateAction(id));
    await dispatch(
      fetchMailsTemplatesAction({
        offset: mailsTemplatesOffset,
        limit: mailsTemplatesLimit,
        order: 'asc',
      }),
    );
  };

  const subString35 = (str: string) => {
    if (str && str.length > 35) {
      return str.substring(35) + '...';
    }
    return str;
  };

  const handleEditNews = (newsId: string | number) => {
    navigate(`/news/${newsId}`);
  };

  const getSortProps = (fieldName: TNewsSortValues) => {
    return {
      onSort: () => {
        setSortField?.((prev) => {
          const defaultDirection = 'desc';
          const getReverseDirection = (direction: 'asc' | 'desc') =>
            direction === 'desc' ? 'asc' : 'desc';

          const direction =
            prev?.field === fieldName ? getReverseDirection(prev?.direction) : defaultDirection;

          return { field: fieldName, direction };
        });
      },
      reversed: sortFiled?.direction === 'asc',
      sorted: sortFiled?.field === fieldName,
    };
  };

  const ths = (
    <tr>
      <th>
        <TableColumnTitle>ID</TableColumnTitle>
      </th>
      <TableHeadSortable name='Активность' {...getSortProps('isActive')} />
      <th>
        <TableColumnTitle>Заголовок</TableColumnTitle>
      </th>
      <th>
        <TableColumnTitle>Категория</TableColumnTitle>
      </th>
      <th>
        <TableColumnTitle>Тема</TableColumnTitle>
      </th>
      <th>
        <TableColumnTitle>Компания</TableColumnTitle>
      </th>
      <TableHeadSortable name='Дата создания' {...getSortProps('createDate')} />
      <TableHeadSortable name='Дата публикации' {...getSortProps('dateActiveFrom')} />
      <th style={{ maxWidth: '50px' }}></th>
    </tr>
  );

  const rows = elements.map((element) => {
    const { canGet, canUpdate, canUpdateActivity } = getItemPermissions(
      'NEWS_',
      element.permissions,
    );

    return (
      <tr key={element.id}>
        <td style={{ maxWidth: '254px' }}>{element.id}</td>
        <td style={{ maxWidth: '254px' }}>
          <ActiveStatus
            status={element.isActive}
            newsId={element.uuid}
            canUpdate={canUpdateActivity || canUpdate}
          />
        </td>
        <td style={{ maxWidth: '254px' }}>{element.title}</td>
        <td style={{ maxWidth: '254px' }}>{subString35(element.category?.name || '-')}</td>
        <td style={{ maxWidth: '254px' }}>{subString35(element.theme?.name || '-')}</td>
        <td style={{ maxWidth: '254px' }}>{subString35(element.company?.name || '-')}</td>
        <td style={{ maxWidth: '254px', textAlign: 'center' }}>
          {subString35(formatDate(element.meta.createDate.value))}
        </td>
        <td style={{ maxWidth: '254px', textAlign: 'center' }}>
          {subString35(formatDate(element.dateActiveFrom.value))}
        </td>
        <td style={{ maxWidth: '50px' }}>
          {!!(canGet || canUpdate) && (
            <Button
              name='Редактировать'
              title='Редактировать'
              variant='subtle'
              compact
              style={{ position: 'static' }}
              onClick={() => handleEditNews(element.uuid)}
            >
              <Pencil size={22} color={theme.colors.greyLight[9]} />
            </Button>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <Table highlightOnHover verticalSpacing='xs' fontSize='sm'>
        <thead style={{ background: '#E8ECF0' }}>{ths}</thead>
        <tbody>{rows}</tbody>
      </Table>

      <ModalConfirm
        title='Вы уверены?'
        text='Восстановить данные после удаления не получится'
        opened={delDelMailsTemplateModal || delDelMailsTemplatesModal}
        onClose={() =>
          delDelMailsTemplatesModal
            ? dispatch(setOpenDelMailsTemplatesModal(false))
            : dispatch(setOpenDelMailsTemplateModal(false))
        }
        confirmHandler={() =>
          delDelMailsTemplatesModal ? void 0 : delConfirmHandler(currentItemId)
        }
        isReverseButton
      />
    </>
  );
};

export default NewsTable;
