export const MEMBER_GROUP_OPTIONS = [
  {
    value: 'admin',
    label: 'Администраторы',
  },
  {
    value: 'tester',
    label: 'Тестировщики',
  },
  {
    value: 'developer',
    label: 'Разработчики',
  },
  {
    value: 'oggetto',
    label: 'oggetto',
  },
];

export const STATUS_TOGGLE = {
  inactive: '0',
  active: '1',
  'partial-active': '2',
};
