import * as Yup from 'yup';

import { CODE_URL_REGEXP } from '@/constants/validationSchemas';

import {
  EPromotionFormFields,
  EVisibilityFieldValues,
} from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.constants';

const ERR_REQUIRED_TEXT = 'Поле обязательно для заполнения';
export const promotionFormSchema = Yup.object({
  [EPromotionFormFields.CODE]: Yup.string()
    .matches(CODE_URL_REGEXP, 'Символьный код не должен содержать спец символы')
    .required(ERR_REQUIRED_TEXT),
  [EPromotionFormFields.TITLE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EPromotionFormFields.IS_ACTIVE]: Yup.string(),
  [EPromotionFormFields.IS_MAIN]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EPromotionFormFields.ERID]: Yup.string().nullable(),
  [EPromotionFormFields.DATE_ACTIVE_START]: Yup.mixed().required(ERR_REQUIRED_TEXT),
  [EPromotionFormFields.DATE_ACTIVE_END]: Yup.mixed().test(
    EPromotionFormFields.DATE_ACTIVE_START,
    ERR_REQUIRED_TEXT,
    (value) => {
      return !!value;
    },
  ),
  [EPromotionFormFields.DRAFT_ID]: Yup.string(),
  [EPromotionFormFields.DESCRIPTION]: Yup.string()
    .max(149, 'Максимальное количество символов 149')
    .nullable(),
  [EPromotionFormFields.TERMS]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EPromotionFormFields.IS_SHOW_DELIVERY]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EPromotionFormFields.DELIVERY_DESCRIPTION]: Yup.string().when(
    [EPromotionFormFields.IS_SHOW_DELIVERY],
    {
      is: EVisibilityFieldValues.SHOW,
      then: Yup.string().required(ERR_REQUIRED_TEXT),
    },
  ),
  [EPromotionFormFields.TYPE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EPromotionFormFields.BANNER_ID]: Yup.string()
    .nullable()
    .required('Поле "Баннер" обязательно для заполнения'),
});
