import {
  TAddConfigurationOptionsReq,
  TConfigurationOption,
  TGetConfigurationOptionsReq,
  TPutConfigurationOptionsReq,
} from '@/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  tryAddConfigurationOptions,
  tryGetConfigurationOptions,
  tryPutConfigurationOptions,
} from '@/api';

import notify from '@/utils/notify';

import { CONFIGURATION_OPTIONS_LIMIT, OFFSET } from '@/constants/common';

interface IConfigurationOptionsState {
  status: {
    fetchingGetConfigurationOptions: boolean;
    fetchingAddConfigurationOptions: boolean;
    fetchingPutConfigurationOptions: boolean;
  };
  modals: {
    addConfigurationOptionsModal: boolean;
    fillConfigurationOptionsModal: boolean;
  };
  options: TConfigurationOption[] | null;
  total: number | null;
  limit: number;
  offset: number;
}

const initialState: IConfigurationOptionsState = {
  status: {
    fetchingGetConfigurationOptions: false,
    fetchingAddConfigurationOptions: false,
    fetchingPutConfigurationOptions: false,
  },
  modals: {
    addConfigurationOptionsModal: false,
    fillConfigurationOptionsModal: false,
  },
  options: null,
  total: null,
  limit: CONFIGURATION_OPTIONS_LIMIT,
  offset: OFFSET,
};

export const fetchGetConfigurationOptionsAction = createAsyncThunk(
  '/GetConfigurationOptions',
  async (data: TGetConfigurationOptionsReq, { rejectWithValue }) => {
    const result = await tryGetConfigurationOptions(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const fetchAddConfigurationOptionsAction = createAsyncThunk(
  '/AddConfigurationOptions',
  async (data: TAddConfigurationOptionsReq, { rejectWithValue }) => {
    const result = await tryAddConfigurationOptions(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const fetchPutConfigurationOptionsAction = createAsyncThunk(
  '/PutConfigurationOptions',
  async (data: TPutConfigurationOptionsReq, { rejectWithValue }) => {
    const result = await tryPutConfigurationOptions(data);

    if (result === 204) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const configurationOptionsSlice = createSlice({
  name: 'configurationOptions',
  initialState,
  reducers: {
    setConfigurationOptionsLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setConfigurationOptionsOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setAddConfigurationOptionsModalOpened: (state, action: PayloadAction<boolean>) => {
      state.modals.addConfigurationOptionsModal = action.payload;
    },
    setFillConfigurationOptionsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.fillConfigurationOptionsModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetConfigurationOptionsAction.pending, (state) => {
        state.status.fetchingGetConfigurationOptions = true;
      })
      .addCase(fetchGetConfigurationOptionsAction.fulfilled, (state, action) => {
        state.status.fetchingGetConfigurationOptions = false;
        state.options = action.payload.items;
        state.total = action.payload.total;
      })
      .addCase(fetchGetConfigurationOptionsAction.rejected, (state) => {
        state.status.fetchingGetConfigurationOptions = false;
      });
    builder
      .addCase(fetchAddConfigurationOptionsAction.pending, (state) => {
        state.status.fetchingAddConfigurationOptions = true;
      })
      .addCase(fetchAddConfigurationOptionsAction.fulfilled, (state) => {
        state.status.fetchingAddConfigurationOptions = false;
        state.modals.addConfigurationOptionsModal = false;

        notify({ message: 'Опция добавлена', type: 'success' });
      })
      .addCase(fetchAddConfigurationOptionsAction.rejected, (state) => {
        state.status.fetchingAddConfigurationOptions = false;
      });
    builder
      .addCase(fetchPutConfigurationOptionsAction.pending, (state) => {
        state.status.fetchingPutConfigurationOptions = true;
      })
      .addCase(fetchPutConfigurationOptionsAction.fulfilled, (state) => {
        state.status.fetchingPutConfigurationOptions = false;
        state.modals.fillConfigurationOptionsModal = false;

        notify({ message: 'Настройки сохранены', type: 'success' });
      })
      .addCase(fetchPutConfigurationOptionsAction.rejected, (state) => {
        state.status.fetchingPutConfigurationOptions = false;
      });
  },
});

// Selectors
type TSelectorState = { configurationOptions: IConfigurationOptionsState };

// statuses
export const selectFetchingGetConfigurationOptions = (state: TSelectorState) =>
  state.configurationOptions.status.fetchingGetConfigurationOptions;
export const selectFetchingAddConfigurationOptions = (state: TSelectorState) =>
  state.configurationOptions.status.fetchingAddConfigurationOptions;
export const selectFetchingPutConfigurationOptions = (state: TSelectorState) =>
  state.configurationOptions.status.fetchingPutConfigurationOptions;

// modals
export const selectAddConfigurationOptionsModal = (state: TSelectorState) =>
  state.configurationOptions.modals.addConfigurationOptionsModal;
export const selectFillConfigurationOptionsModal = (state: TSelectorState) =>
  state.configurationOptions.modals.fillConfigurationOptionsModal;

export const selectConfigurationOptions = (state: TSelectorState) =>
  state.configurationOptions.options;
export const selectConfigurationOptionsLimit = (state: TSelectorState) =>
  state.configurationOptions.limit;
export const selectConfigurationOptionsOffset = (state: TSelectorState) =>
  state.configurationOptions.offset;

// reducers and actions
export const {
  setAddConfigurationOptionsModalOpened,
  setConfigurationOptionsLimit,
  setConfigurationOptionsOffset,
  setFillConfigurationOptionsModalOpen,
} = configurationOptionsSlice.actions;

export default configurationOptionsSlice.reducer;
