import { useNavigate } from 'react-router-dom';

import { imageHandler } from '@/utils/imageReplaceHandler';

import {
  formFieldsToCreate,
  formFieldsToUpdate,
} from '@/ui/containers/MaterialsCreationContainer/MaterialCreationContainer.helpers';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import { IMaterialState } from '@/ui/containers/MaterialsCreationContainer/MaterialState.types';
import { ENewsFormFields } from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

export const useMaterialsState = (materialsState: IMaterialState, id: string) => {
  const navigate = useNavigate();
  const handleSubmit = async (formValues: IMaterialsFormFields) => {
    materialsState.setDefaultFormData(formValues);

    const backendImageId = await imageHandler(
      formValues.image[ENewsFormFields.IMAGE_ID],
      materialsState.sessionID,
    );

    const requestData = { ...formValues, image: { ...formValues.image, id: backendImageId } };
    const data = id
      ? formFieldsToUpdate(requestData, materialsState.draftId || '')
      : formFieldsToCreate(requestData, materialsState.draftId || '');

    const update = () =>
      materialsState.permissions.canUpdate
        ? materialsState.updateMaterial({ uuid: id, materialData: data })
        : materialsState.updateMaterialActivity(id, data.isActive).then(() => {
            materialsState.setMaterial((prev) => prev && { ...prev, isActive: data.isActive });
            return id;
          });

    let responseData = await (id ? update() : materialsState.createMaterial(data));

    if (responseData) {
      let uuid: string;
      if (typeof responseData === 'string') {
        uuid = responseData;
      } else {
        materialsState.setMaterial(responseData.payload);
        uuid = responseData.payload.uuid;
      }

      const materialsResponse = await materialsState.getMaterial(uuid);
      if (materialsResponse) materialsState.setMaterial(materialsResponse.payload);

      setTimeout(() => navigate(`/materials/${uuid}`, { replace: true }), 0);
    }
  };

  const handleDelete = async () => {
    const responseData = await materialsState.deleteMaterial(id || '');

    if (responseData) {
      materialsState.setMaterial(null);
      navigate('/materials/');
    }
  };
  return {
    handleSubmit,
    handleDelete,
  };
};
