import { useRef } from 'react';
import { Button, InputBase } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { Plus } from 'tabler-icons-react';
import { v4 as uuid_v4 } from 'uuid';

import { handleAddSnippetData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { DownloadsSnippetFormItem } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal/DownloadsSnippetFormItem';
import { downloadSnippetSchema } from '@/ui/containers/ContentEditor/snippets/DownloadsSnippetModal/DownLoadsSnippetModal.schema';
import { useGetSnippetData } from '@/ui/containers/ContentEditor/snippets/hooks/useGetSnippetData';
import { SnippetModal } from '@/ui/containers/ContentEditor/snippets/SnippetModal';

import { EDownloadsSnippetFormFields } from './DownloadsSnippetModal.constants';
import { useDownloadsSnippetModalHandlers } from './DownloadsSnippetModal.handlers';
import { getDownloadsSnippetInitialValues, replaceFiles } from './DownloadsSnippetModal.helpers';

export const DownloadsSnippetModal = () => {
  const sessionID = useRef(uuid_v4());
  const { defaultValues, snippetData, handleClose } = useGetSnippetData('download');

  const form = useForm({
    initialValues: getDownloadsSnippetInitialValues(defaultValues),
    validate: yupResolver(downloadSnippetSchema),
  });

  const handlers = useDownloadsSnippetModalHandlers({ form });

  const visibleAddBtn =
    Object.values(form.values.files?.at(-1) || {})[0] || !form.values.files?.length;

  return (
    <SnippetModal
      isOpen
      isNew={snippetData?.isNew}
      onClose={handleClose}
      title='Скачиваемые материалы'
      handleClickBackBtn={handleClose}
      onSubmit={form.onSubmit(async (values) => {
        await replaceFiles(values, sessionID.current);
        return handleAddSnippetData('download', values, handleClose, snippetData);
      })}
    >
      <InputBase
        label='Название блока'
        placeholder='Введите название блока, например, Материалы для скачивания'
        sx={{ gridColumn: 'span 6' }}
        {...form.getInputProps(EDownloadsSnippetFormFields.TITLE)}
      />

      {form.values.files?.map((file, index) => {
        return (
          <DownloadsSnippetFormItem
            {...{ index, form, file, sessionID: sessionID.current }}
            key={index}
          />
        );
      })}

      <div style={{ gridColumn: 'span 12', height: 60, display: 'flex' }}>
        {visibleAddBtn && (
          <Button
            variant='white'
            sx={{ padding: '10px 0 0 0', height: 'min-content' }}
            onClick={handlers.handleAddFile}
          >
            <Plus size={18} style={{ marginRight: 10 }} />
            Добавить файл в блок
          </Button>
        )}
      </div>
    </SnippetModal>
  );
};
