import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Group, Text } from '@mantine/core';

import { GRADIENTS } from '@/ui/containers/BannerCreationContainer/components/GradientSelectItem/GradientSelectItem.constants';

interface ItemProps extends ComponentPropsWithoutRef<'div'> {
  color: string;
  label: string;
}

export const GradientSelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ color, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div
          style={{
            width: '28px',
            height: '28px',
            background: GRADIENTS[color],
            borderRadius: '5px',
          }}
        />
        <div>
          <Text>{label}</Text>
        </div>
      </Group>
    </div>
  ),
);
