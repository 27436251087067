import { GetPermission, Permission, PermissionCode, permissionGroups } from '@/types/auth';

import { useCallback, useMemo } from 'react';

import { isPermissionGroup } from '@/utils/permissions';

import { authApi } from '@/store/slices/auth';

export const usePermissions = () => {
  const { data, isLoading, isError, ...rest } = authApi.useGetPermissionsQuery();
  const permissions = useMemo(() => data ?? [], [data]);
  const isReady = !(isLoading || isError);

  const getPermission = useCallback<GetPermission>(
    (params) => {
      if (!params) return true;
      if (!isReady) return null;

      const codes = (Array.isArray(params) ? params : [params]).reduce<PermissionCode[]>(
        (res, item) => [...res, ...(isPermissionGroup(item) ? permissionGroups[item] : [item])],
        [],
      );

      return codes.some((code) => permissions.includes(code));
    },
    [permissions, isReady],
  );

  return { permissions, getPermission, isLoading, isError, isReady, ...rest };
};

/**
 * Хук для запроса разрешений доступа.
 *
 * @param code Кодовое обозначение запрашиваемого доступа.
 * @returns true - доступ разрешён или не задан `code`, false - доступ запрещён, null - еще не загрузились.
 */
export const usePermission = (code?: Permission | Permission[]): boolean | null => {
  const { getPermission } = usePermissions();
  return useMemo(() => getPermission(code), [getPermission, code]);
};
