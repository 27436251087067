import { EffectCallback, useEffect } from 'react';

import { useCreateDraft } from '@/api/draft/useCreateDraft';
import { useGetMaterial } from '@/api/materials/hooks/useGetMaterial';

import {
  setSelectorDefaultFormData,
  setSelectorDraftId,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { createNewDraftData } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';

export const useModeMaterials = (state: any, id: string = '') => {
  const createDraft = useCreateDraft();
  const { getMaterial } = useGetMaterial();
  const setDraftId = useStoreContentEditor(setSelectorDraftId);
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  let effect: EffectCallback = () => {};
  if (state.isNewMaterial) {
    effect = () => {
      createDraft.createDraft(createNewDraftData).then((res) => {
        if (res?.payload.draftId) {
          setDraftId(res?.payload.draftId);
        }
      });
      return () => {
        setDefaultFormData(null);
      };
    };
  }

  if (!state.material && id) {
    effect = () => {
      getMaterial(id).then((materialResponse) => {
        if (materialResponse) {
          state.setMaterial(materialResponse.payload);
          setDraftId(materialResponse.payload.draftId);
        }
      });
    };
  }

  useEffect(effect, []);
};
