import { AxiosError } from 'axios';

import { errorHandler } from './errorHandler';

export const axiosErrorHandler = (
  error: AxiosError<{ message: string; error_description: string }>,
  fallbackMsg?: string,
) => {
  const message =
    fallbackMsg ||
    error.response?.data?.message ||
    (error.response?.data?.error_description && 'Неверный логин или пароль') ||
    'Неизвестная ошибка';

  if (message) errorHandler(error, `${message}`);
};
