import { useCallback } from 'react';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';

import { IPromotionFormFields } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.types';
import { formFieldsToCreate } from '@/ui/containers/PromotionCreation/PromotionCreation.helpers';
import { IPromotionCreationState } from '@/ui/containers/PromotionCreation/PromotionCreation.types';

export const useCreationPromotionHandlers = (
  id: string,
  promotionState: IPromotionCreationState,
) => {
  const navigate = useNavigate();
  const { proceed, reset, state } = useBlocker(
    promotionState.isShouldBlock && !!promotionState.promotion,
  );

  const handleSubmit = async (formValues: IPromotionFormFields) => {
    promotionState.setDefaultFormData(formValues);

    const data = id
      ? formFieldsToCreate(formValues, promotionState.draftId || '')
      : formFieldsToCreate(formValues, promotionState.draftId || '');

    const update = () =>
      promotionState.permissions.canUpdate
        ? promotionState.updatePromotion({ uuid: id, promotionData: data })
        : promotionState.updatePromotionActivity(id, data.isActive).then(() => {
            promotionState.setPromotion((prev) => prev && { ...prev, isActive: data.isActive });
            return id;
          });

    let responseData = await (id ? update() : promotionState.createPromotion(data));

    if (responseData) {
      let uuid: string;
      if (typeof responseData === 'string') {
        uuid = responseData;
      } else {
        promotionState.setPromotion(responseData.payload);
        uuid = responseData.payload.uuid;
      }

      const newsResponse = await promotionState.getPromotion(uuid);
      if (newsResponse) promotionState.setPromotion(newsResponse.payload);

      promotionState.setIsShouldBlock(false);

      setTimeout(() => navigate(`/promotions/${uuid}`, { replace: true }), 0);
      promotionState.setIsForceChangeTemplate(false);
    }
  };

  const handleDelete = async () => {
    promotionState.setIsShowDeleteModal(false);
    const responseData = await promotionState.deletePromotion(id || '');

    if (responseData) {
      promotionState.setPromotion(null);
      setTimeout(() => {
        navigate('/promotions');
      }, 0);
    }
  };

  const onConfirmLeave = useCallback(() => {
    if (proceed) {
      proceed();
    }
  }, [proceed]);

  const onCancel = useCallback(() => {
    if (reset) {
      reset();
    }
  }, [reset]);

  const onConfirmChangeTemplate = useCallback(() => {
    promotionState.setIsForceChangeTemplate(true);
    promotionState.setOpenConfirmModal(false);
  }, [promotionState]);

  const onCancelChangeTemplate = useCallback(() => {
    promotionState.setOpenConfirmModal(false);
  }, [promotionState]);

  const onChangeForm = useCallback(() => {
    promotionState.setIsShouldBlock(true);
  }, []);

  return {
    handleSubmit,
    handleDelete,
    onConfirmLeave,
    onCancel,
    onConfirmChangeTemplate,
    onCancelChangeTemplate,
    onChangeForm,
    state,
  };
};
