import { Box, Button, Group, Select, Text } from '@mantine/core';
import { RotateClockwise } from 'tabler-icons-react';

import { PAGE_ELEMENTS } from '@/constants/common';
import { useAppSelector } from '@/hooks/useAppSelector';

import { sx } from '@/ui/containers/PromotionsContainer';
import { activeStatus } from '@/ui/containers/PromotionsContainer/components/PromotionsFilters';

import { projectFilterSelector } from '@/store/slices/projects/projectsSlice';
import { projectTypeOptions } from '@/store/slices/projects/projectsSlice.constants';
import { ReturnedProjectConfig } from '@/store/slices/projects/projectsSlice.types';

export const ProjectsFilter = ({
  isDirty,
  onReset,
  actionWithDebounce,
  onChange,
}: ReturnedProjectConfig) => {
  const { isActive, projectType, limit } = useAppSelector(projectFilterSelector);
  const limitValue = limit || PAGE_ELEMENTS[0].value;
  return (
    <Group sx={{ zIndex: 2 }} mb={24} position='left' align='flex-end' spacing='xl' grow>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Тип проекта
        </Text>
        <Select
          data={projectTypeOptions}
          clearable
          styles={{ input: { height: 39 } }}
          placeholder='Тип проекта'
          value={projectType}
          onChange={(value) => {
            actionWithDebounce({ projectType: value as string | undefined });
          }}
        />
      </Box>
      <Box sx={sx}>
        <Text size='sm' color='grayDefault'>
          Активность
        </Text>
        <Select
          data={activeStatus}
          clearable
          styles={{ input: { height: 39 } }}
          placeholder='Активность'
          value={isActive}
          onChange={(value) => {
            actionWithDebounce({ isActive: value as string | undefined });
          }}
        />
      </Box>
      <Box style={{ marginLeft: 'auto', maxWidth: 'fit-content', display: 'flex', gap: 6 }}>
        {isDirty && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 38,
              maxWidth: 'fit-content',
              marginLeft: 'auto',
            }}
          >
            <Button
              variant='subtle'
              radius='xs'
              size='sm'
              compact
              sx={({ colors }) => ({
                color: colors.primary[9],
                ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
              })}
              rightIcon={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}
        <Select
          sx={{ width: '72px', input: { paddingRight: '20px' } }}
          size='sm'
          value={limitValue}
          onChange={(v) => {
            if (limitValue !== v && !!v) {
              onChange({ limit: v as string });
            }
          }}
          allowDeselect
          label=''
          data={PAGE_ELEMENTS}
        />
      </Box>
    </Group>
  );
};
