import { AttributesItem } from '@/types/banner/banner';
import { IBannerCreationData } from '@/types/banner/bannerDetail';

import { useCallback } from 'react';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { imageHandler } from '@/utils/imageReplaceHandler';
import notify from '@/utils/notify';

import {
  DATE_FILTERED_FIELDS,
  imageFields,
  SELECTED_PAGE_AUDIO,
} from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.constants';
import {
  IBannerCreationState,
  Results,
} from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.types';
import { EBannerFormFields } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.constants';
import { IBannerFormFields } from '@/ui/containers/BannerCreationContainer/components/BannerForm/BannerForm.types';
import {
  DATE_TEMPLATE,
  DATE_TEMPLATE_DAYS,
  DATE_TEMPLATE_HOURS,
} from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';

export const getBreadcrumbs = (title: string) => [
  {
    name: 'Баннеры',
    url: '/banners',
  },
  {
    name: title,
  },
];

export const dateFormToNewsDate = (date: string | Date, template: string) => {
  return !!date ? dayjs(date).format(template) : '';
};

export const requestPositionsBanner = (formValues: IBannerFormFields) => {
  return formValues[EBannerFormFields.PAGE].map((page) => ({
    page,
    places: formValues[EBannerFormFields.PLACE]
      .filter((place) => place.startsWith(page))
      .map((item) => item.replaceAll(`${page}-`, '')),
  }));
};

export const formFieldsToCreate = (formFields: IBannerFormFields): IBannerCreationData => {
  const active = /^true$/i.test(formFields[EBannerFormFields.IS_ACTIVE]);
  const dateActiveFrom =
    dateFormToNewsDate(formFields[EBannerFormFields.DATE_ACTIVE_FROM_DAYS], DATE_TEMPLATE_DAYS) +
    dateFormToNewsDate(formFields[EBannerFormFields.DATE_ACTIVE_FROM_HOURS], DATE_TEMPLATE_HOURS);
  const dateActiveTo =
    dateFormToNewsDate(formFields[EBannerFormFields.DATE_ACTIVE_TO_DAYS], DATE_TEMPLATE_DAYS) +
    dateFormToNewsDate(formFields[EBannerFormFields.DATE_ACTIVE_TO_HOURS], DATE_TEMPLATE_HOURS);

  const dateFrom = dateFormToNewsDate(formFields[EBannerFormFields.DATE_FROM], DATE_TEMPLATE);
  const dateTo = dateFormToNewsDate(formFields[EBannerFormFields.DATE_TO], DATE_TEMPLATE);

  const positions = requestPositionsBanner(formFields);

  return {
    ...formFields,
    dateActiveFrom,
    dateActiveTo,
    dateFrom,
    dateTo,
    isActive: active,
    positions,
  };
};

export const formFieldsToUpdate = (formFields: IBannerFormFields): IBannerCreationData => {
  const fields = formFieldsToCreate(formFields);

  return Object.fromEntries(
    Object.entries(fields).filter(([key]) => !DATE_FILTERED_FIELDS.includes(key)),
  ) as IBannerCreationData;
};

export const useCreationBannerHandlers = (id: string, bannerState: IBannerCreationState) => {
  const { canUpdate } = bannerState.permissions;
  const navigate = useNavigate();
  const { proceed, reset, state } = useBlocker(bannerState.isShouldBlock);

  const handleSubmit = async (formValuesData: IBannerFormFields) => {
    const isAudioPage = formValuesData.page[0] === SELECTED_PAGE_AUDIO;
    const audioAttributeValues: AttributesItem[] = [];

    if (isAudioPage && !!formValuesData[EBannerFormFields.GRADE]) {
      audioAttributeValues?.push({ type: 'class', value: formValuesData[EBannerFormFields.GRADE] });
    }

    if (isAudioPage && !!formValuesData[EBannerFormFields.SUBJECTS]) {
      audioAttributeValues?.push({
        type: 'subject',
        value: formValuesData[EBannerFormFields.SUBJECTS],
      });
    }

    const formValues = {
      ...formValuesData,
      attributes: audioAttributeValues,
    };
    bannerState.setDefaultFormData(formValues);

    let requestData = { ...formValues };

    if (!id || canUpdate) {
      const imagePromises = imageFields.map(async (fieldName) => {
        try {
          const imageId = await imageHandler(formValues[fieldName], bannerState.sessionID);
          return [fieldName, imageId];
        } catch {
          return [fieldName, null];
        }
      });
      Promise.allSettled(imagePromises)
        .then((results) => {
          const imageIds = Object.fromEntries(results as Results);
          requestData = { ...requestData, ...imageIds };
        })
        .catch(() => {
          notify({ message: 'Ошибка обновления баннера', type: 'error' });
        });
    }

    const data = id ? formFieldsToUpdate(requestData) : formFieldsToUpdate(requestData);

    const update = () =>
      canUpdate
        ? bannerState.updateBanner({ uuid: id, bannerData: data })
        : bannerState.updateBannerActivity(id, data.isActive).then(() => {
            bannerState.setBanner((prev) => prev && { ...prev, isActive: data.isActive });
            return id;
          });

    let responseData = await (id ? update() : bannerState.createBanner(data));

    if (responseData) {
      let uuid: string;
      if (typeof responseData === 'string') {
        uuid = responseData;
      } else {
        bannerState.setBanner(responseData.payload);
        uuid = responseData.payload.uuid;
      }

      bannerState.setIsShouldBlock(false);
      setTimeout(() => navigate(`/banners/${uuid}`, { replace: true }), 0);
    }
  };

  const handleDelete = async () => {
    const responseData = await bannerState.deleteBanner(id || '');

    if (responseData) {
      bannerState.setBanner(null);
      bannerState.setIsShouldBlock(false);
      navigate('/banners/');
    }
  };

  const onConfirmLeave = useCallback(() => {
    if (proceed) {
      proceed();
    }
  }, [proceed]);

  const onCancel = useCallback(() => {
    if (reset) {
      reset();
    }
  }, [reset]);

  const onChangeForm = useCallback(() => {
    bannerState.setIsShouldBlock(true);
  }, []);

  return {
    handleSubmit,
    handleDelete,
    onConfirmLeave,
    onCancel,
    onChangeForm,
    state,
  };
};
