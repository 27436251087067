import { NewsSnippet, SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

export const getNewsSnippetInitialValues = (
  defaultValues?: NewsSnippet,
): SnippetValues<NewsSnippet> => {
  return {
    id: defaultValues?.id || uuid_v4(),
    category: defaultValues?.category || '',
    theme: defaultValues?.theme || '',
    division: defaultValues?.division || '',
    company: defaultValues?.company || '',
    tags: defaultValues?.tags || [],
    news: defaultValues?.news || [],
    loopSlide: defaultValues?.loopSlide ?? true,
    type: defaultValues?.type || 'auto',
  };
};
