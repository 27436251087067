import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useUpdateBannerActivity = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    updateBannerActivity: async (uuid: string, isActive: boolean) => {
      setIsLoading(true);
      try {
        await api.put(URLS.banners.putBannerStatus(uuid), { isActive });
        notify({ message: 'Баннер успешно обновлен', type: 'success' });
        return true;
      } catch (err) {
        notify({ message: 'Ошибка обновления баннера', type: 'error' });
        return false;
      } finally {
        setIsLoading(false);
      }
    },
  };
};
