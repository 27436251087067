import { INewsErrResponse } from '@/types/news/news';
import { IGetNewsResponse, INewsCreationData } from '@/types/news/newsDetail';

import { useState } from 'react';
import { AxiosError } from 'axios';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

enum EErrCode {
  URL_CODE_EXISTS = '0a48a1ad-2f3a-4452-9d35-184e0b46a09a',
}

export const useCreateNews = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    createNews: async (newsData: INewsCreationData) => {
      setIsLoading(true);
      try {
        const request = await api.post<IGetNewsResponse>(URLS.news.createNews, { ...newsData });
        notify({ message: 'Новость добавлена', type: 'success' });
        return request.data;
      } catch (err) {
        const { response } = err as AxiosError<INewsErrResponse, {}>;
        if (response?.data.code === EErrCode.URL_CODE_EXISTS) {
          return notify({
            message: 'Новость c таким символьным кодом уже существует',
            type: 'error',
          });
        }
        return notify({ message: 'Ошибка добавления новости', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
