import { TConfiguration } from '@/types';
import { TMenu } from '@/types/menu/menu';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { apiGetMenu, tryAddMenus } from '@/api/menu/menus';

import notify from '@/utils/notify';

import { LIMIT, OFFSET } from '@/constants/common';

interface IMenuState {
  modals: {
    newMenusOpened: boolean;
  };
  status: {
    fetchingGetMenus: boolean;
    fetchingAddMenus: boolean;
  };
  menus: TMenu[] | null;
  total: number | null;
  limit: number;
  offset: number;
  currentMenu: TMenu | null;
  currentMenuName: string | null;
}

const initialState: IMenuState = {
  modals: {
    newMenusOpened: false,
  },
  status: {
    fetchingGetMenus: false,
    fetchingAddMenus: false,
  },
  menus: null,
  total: null,
  limit: LIMIT,
  offset: OFFSET,
  currentMenu: null,
  currentMenuName: null,
};

export const fetchMenusAction = createAsyncThunk(
  '/menus',
  async (data: any, { rejectWithValue }) => {
    const result = await apiGetMenu(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  },
);

export const fetchAddMenusAction = createAsyncThunk(
  '/menus/add',
  async (params: any, { rejectWithValue }) => {
    const result = await tryAddMenus(params);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  },
);

export const menusSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenusLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setMenusOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setModalNewMenusOpened: (state, action: PayloadAction<boolean>) => {
      state.modals.newMenusOpened = action.payload;
    },
    setCurrentMenu: (state, action: PayloadAction<TConfiguration>) => {
      state.currentMenu = action.payload;
    },
    setCurrentMenuName: (state, action: PayloadAction<string>) => {
      state.currentMenuName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenusAction.pending, (state) => {
        state.status.fetchingGetMenus = true;
      })
      .addCase(fetchMenusAction.fulfilled, (state, action) => {
        state.status.fetchingGetMenus = false;
        state.menus = action.payload.data.items;
        state.total = action.payload.data.total;
        // state.modals.editConfigurationModal = false;
        //
        // notify({ message: 'Конфигурация изменена', type: 'success' });
      })
      .addCase(fetchMenusAction.rejected, (state) => {
        state.status.fetchingGetMenus = false;
      });
    builder
      .addCase(fetchAddMenusAction.pending, (state) => {
        state.status.fetchingAddMenus = true;
      })
      .addCase(fetchAddMenusAction.fulfilled, (state) => {
        state.status.fetchingAddMenus = false;
        state.modals.newMenusOpened = false;

        notify({ message: 'Меню добавленно', type: 'success' });
      })
      .addCase(fetchAddMenusAction.rejected, (state) => {
        state.status.fetchingAddMenus = false;
      });
  },
});

// Selectors
type TSelectorState = { menus: IMenuState };

// statuses
export const selectFetchingGetMenus = (state: TSelectorState) =>
  state.menus.status.fetchingGetMenus;

// modals
export const selectModalNewMenus = (state: TSelectorState) => state.menus.modals.newMenusOpened;

export const selectCurrentMenu = (state: TSelectorState) => state.menus.currentMenu;

export const selectMenus = (state: TSelectorState) => state.menus.menus;
export const selectCurrentMenuName = (state: TSelectorState) => state.menus.menus;
export const selectMenusTotal = (state: TSelectorState) => state.menus.total;
export const selectMenusLimit = (state: TSelectorState) => state.menus.limit;
export const selectMenusOffset = (state: TSelectorState) => state.menus.offset;

// reducers and actions

export const {
  setCurrentMenu,
  setMenusLimit,
  setMenusOffset,
  setModalNewMenusOpened,
  setCurrentMenuName,
} = menusSlice.actions;

export default menusSlice.reducer;
