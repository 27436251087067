import {TModel, TQueryParamsNew} from '@/types';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { tryGetModels } from '@/api';

interface IModelsState {
  status: {
    fetchingGetModels: boolean;
  };
  models: TModel[] | null;
  total: number;
}

const initialState: IModelsState = {
  status: {
    fetchingGetModels: false,
  },
  models: null,
  total: 0,
};

export const fetchGetModelsAction = createAsyncThunk(
  'getModels',
  async (params: TQueryParamsNew, { rejectWithValue }) => {
    const result = await tryGetModels(params);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetModelsAction.pending, (state) => {
        state.status.fetchingGetModels = true;
      })
      .addCase(fetchGetModelsAction.fulfilled, (state, action) => {
        state.status.fetchingGetModels = false;
        state.models = action.payload.items;
        state.total = action.payload.total;
      })
      .addCase(fetchGetModelsAction.rejected, (state) => {
        state.status.fetchingGetModels = false;
      });
  },
});

// Selectors
type TSelectorState = { models: IModelsState };

// statuses
export const selectFetchingGetModels = (state: TSelectorState) =>
  state.models.status.fetchingGetModels;

export const selectModels = (state: TSelectorState) => state.models.models;
export const selectModelsTotal = (state: TSelectorState) => state.models.total;

// reducers and actions

export default modelsSlice.reducer;
