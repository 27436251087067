import { FC } from 'react';
import { Text } from '@mantine/core';

interface IProps {
  title: string;
  text: string;
}

export const TextLegend: FC<IProps> = ({ title, text }) => {
  return (
    <Text color='greyDark'>
      <b>{title}</b> - {text}
    </Text>
  );
};
