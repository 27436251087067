import { TFeature, TFeatureUpdateResponse } from '@/types/features/features';

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryGetFeaturesList, tryUpdateFeature } from '@/api/featuresToggles/featuresToggles';

import { State } from '@/store';

interface IFeaturesState {
  status: {
    fetchingFeatureList: boolean;
    fetchingFeature: boolean;
  };
  editFeature: TFeature | null;
  items: TFeature[] | null;
  total: number | null;
}

const initialState: IFeaturesState = {
  status: {
    fetchingFeatureList: false,
    fetchingFeature: false,
  },
  editFeature: null,
  items: null,
  total: null,
};

export const fetchFeaturesListAction = createAsyncThunk(
  '/featureList',
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await tryGetFeaturesList();
      return payload;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const updateFeatureAction = createAsyncThunk(
  '/updateFeature',
  async (params: TFeatureUpdateResponse, { rejectWithValue }) => {
    try {
      const { payload } = await tryUpdateFeature(params);
      return payload;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const newsSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setEditFeature: (state, action: PayloadAction<TFeature | null>) => {
      state.editFeature = action.payload;
    },
    setLoadingFeature: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingFeature = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeaturesListAction.pending, (state) => {
        state.status.fetchingFeatureList = true;
      })
      .addCase(fetchFeaturesListAction.fulfilled, (state, action) => {
        state.status.fetchingFeatureList = false;
        state.items = action.payload.items;
        state.total = action.payload.items.length;
      })
      .addCase(fetchFeaturesListAction.rejected, (state) => {
        state.status.fetchingFeatureList = false;
      })
      .addCase(updateFeatureAction.pending, (state) => {
        state.status.fetchingFeatureList = true;
      })
      .addCase(updateFeatureAction.fulfilled, (state, action) => {
        state.status.fetchingFeatureList = false;
        state.items = action.payload.items;
        state.total = action.payload.items.length;
      })
      .addCase(updateFeatureAction.rejected, (state) => {
        state.status.fetchingFeatureList = false;
      });
  },
});

export const featureListSelector = createSelector(
  (state: State) => state.features,
  (features) => features.items,
);
export const editFeatureSelector = createSelector(
  (state: State) => state.features,
  (features) => features.editFeature,
);
export const loadingFeatureSelector = createSelector(
  (state: State) => state.features,
  (features) => features.status.fetchingFeature,
);
export const loadingFeatureListSelector = createSelector(
  (state: State) => state.features,
  (features) => features.status.fetchingFeatureList,
);
// Reducers and actions

export const { setEditFeature, setLoadingFeature } = newsSlice.actions;

export default newsSlice.reducer;
