import { useEffect, useState } from 'react';

export interface UsePaginationParams {
  total: number;
  limit: number;
  initialPage?: number;
  setOffset?: (offset: number) => void;
}

export const usePagination = ({
  total,
  limit,
  initialPage = 1,
  setOffset,
}: UsePaginationParams) => {
  const [page, setPage] = useState(initialPage);

  const pages = Math.ceil(total / limit);
  const offset = Math.max(0, page - 1) * limit;

  useEffect(() => setOffset?.(offset), [offset, setOffset]);

  return { pages, offset, limit, page, setPage };
};
