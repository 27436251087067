import { IMaterialsCreationData } from '@/types/materials/materialsDetail';

import { TOption } from '@/ui/components/MultiSelect';
import { IMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialForm.types';
import { EMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { IMaterialBreadcrumbs } from '@/ui/containers/MaterialsCreationContainer/MaterialState.types';
import {
  DATE_TEMPLATE_DAYS,
  DATE_TEMPLATE_HOURS,
  FILTERED_FIELDS,
} from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { dateFormToNewsDate } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.helpers';
import {
  ACTIVITY_FIELD_VALUES,
  EActivityFieldValues,
} from '@/ui/containers/NewsDetailInfo/NewsForm/NewsForm.constants';

export const getBreadcrumbs = (title: string): IMaterialBreadcrumbs[] => [
  {
    name: 'Материалы',
    url: '/materials',
  },
  {
    name: title,
  },
];

const specialFormFields = (option: string | TOption) =>
  typeof option === 'string' ? option : option.value;

export const formFieldsToCreate = (
  formFields: IMaterialsFormFields,
  draftId: string,
): IMaterialsCreationData => {
  const active = formFields[EMaterialsFormFields.IS_ACTIVE] as EActivityFieldValues;

  const dateActiveFrom = dateFormToNewsDate(
    formFields[EMaterialsFormFields.DATA_ACTIVE_FROM_DAYS],
    DATE_TEMPLATE_DAYS + DATE_TEMPLATE_HOURS,
  );

  const tags = formFields[EMaterialsFormFields.TAGS]?.map(specialFormFields);
  const author = formFields[EMaterialsFormFields.AUTHOR]?.map(specialFormFields);
  const lineUmk = formFields[EMaterialsFormFields.LINE_UMK]?.map(specialFormFields);
  const isContainsVideo = !!formFields[EMaterialsFormFields.CONTAINS]?.includes('isContainsVideo');
  const isContainsDownloadMaterial = !!formFields[EMaterialsFormFields.CONTAINS]?.includes(
    'isContainsDownloadMaterial',
  );

  delete formFields[EMaterialsFormFields.CONTAINS];

  return {
    ...formFields,
    draftId,
    isActive: ACTIVITY_FIELD_VALUES[active],
    dateActiveFrom,
    tags,
    author,
    lineUmk,
    isContainsVideo,
    isContainsDownloadMaterial,
  };
};

export const formFieldsToUpdate = (
  formFields: IMaterialsFormFields,
  draftId: string,
): IMaterialsCreationData => {
  const fields = formFieldsToCreate(formFields, draftId);

  return Object.fromEntries(
    Object.entries(fields).filter(([key]) => !FILTERED_FIELDS.includes(key)),
  ) as IMaterialsCreationData;
};
