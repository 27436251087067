import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dictionary } from '@prosv/core/types/bff/dictionary';

interface IProps {
  items: Dictionary[];
}

const TableBody: FC<IProps> = ({ items }) => {
  const navigate = useNavigate();

  return (
    <tbody>
      {items.map((item, index) => {
        return (
          <tr
            key={index}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/dictionary/${item.code}`)}
          >
            <td>{item.code}</td>
            <td>{item.name}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
