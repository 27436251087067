import { IMetaTagDetail } from '@/types/metaTags/metaTags';
import {
  IMetaTag,
  IPayload,
  TQueryParamsMetaTag,
  TQueryUpdateMetaTag,
} from '@/types/metaTagsAnyPage/metaTagsAnyPage';

import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryGetMetaTagList, tryUpdateMetaTag } from '@/api/metaTagsAnyPage/metaTagsAnyPage';

import notify from '@/utils/notify';

import { LIMIT, OFFSET } from '@/constants/common';

import {
  ERROR_MESSAGE,
  NOT_FOUND_MESSAGE,
} from '@/ui/containers/MetaTagsContainer/MetaTagsContainer.constants';

import { State } from '@/store';

interface IMetaTagsState {
  status: {
    fetchingMetaTagList: boolean;
  };
  items: IMetaTag[] | null;
  total: number | null;
  limit: number;
  offset: number;
  url: string | null;
  filterMessage: string | null;
  editMetaTag: IMetaTag | null;
  updatedMetaTags: IMetaTagDetail[] | null;
}

const initialState: IMetaTagsState = {
  status: {
    fetchingMetaTagList: false,
  },
  items: null,
  total: null,
  limit: LIMIT,
  offset: OFFSET,
  url: null,
  filterMessage: null,
  editMetaTag: null,
  updatedMetaTags: null,
};

export const fetchMetaTagListAction = createAsyncThunk(
  '/metaTagsList',
  async (params: TQueryParamsMetaTag, { rejectWithValue }) => {
    try {
      return (await tryGetMetaTagList(params)) as IPayload;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const updateMetaTagAction = createAsyncThunk(
  '/updateMetaTag',
  async (params: TQueryUpdateMetaTag, { rejectWithValue }) => {
    try {
      return (await tryUpdateMetaTag(params)) as IMetaTag;
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

export const metaTagsAnyPageSlice = createSlice({
  name: 'metaTags',
  initialState,
  reducers: {
    setLoadingMetaTags: (state, action: PayloadAction<boolean>) => {
      state.status.fetchingMetaTagList = action.payload;
    },
    setMetaTagsOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    },

    setEditMetaTagItem: (state, action) => {
      if (state.items) {
        state.editMetaTag = state.items.find((item) => item.id === action.payload) ?? null;
      }
    },
    setUpdateMetaTagItem: (state, action) => {
      if (state.updatedMetaTags) {
        state.updatedMetaTags.push(action.payload);
      } else {
        state.updatedMetaTags = [action.payload];
      }
      notify({ message: 'Шаблон сохранен', type: 'success' });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetaTagListAction.pending, (state) => {
        state.status.fetchingMetaTagList = true;
      })
      .addCase(fetchMetaTagListAction.fulfilled, (state, action) => {
        const { items, pagination } = action.payload;
        state.status.fetchingMetaTagList = false;
        state.items = items;
        state.total = pagination.total;
        if (state.url && !items.length) {
          state.filterMessage = NOT_FOUND_MESSAGE;
        }
      })
      .addCase(fetchMetaTagListAction.rejected, (state) => {
        if (state.url) {
          state.filterMessage = ERROR_MESSAGE;
        }
        state.status.fetchingMetaTagList = false;
      })
      .addCase(updateMetaTagAction.pending, (state) => {
        state.status.fetchingMetaTagList = true;
      })
      .addCase(updateMetaTagAction.fulfilled, (state, action) => {
        const { payload } = action;
        state.status.fetchingMetaTagList = false;
        if (state.items) {
          state.items = state.items.map((item) => (item.id === payload.id ? payload : item));
          notify({ message: 'Мета-тэг успешно обновлен', type: 'success' });
        }
      })
      .addCase(updateMetaTagAction.rejected, (state) => {
        state.status.fetchingMetaTagList = false;
        notify({ message: 'Ошибка обновления мета-тэга', type: 'error' });
      });
  },
});

//selectors
export const metaTagListSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.items,
);

export const loadingMetaTagListSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.status.fetchingMetaTagList,
);
export const metaTagsTotalSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.total,
);
export const metaTagsLimitSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.limit,
);
export const metaTagsOffsetSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.offset,
);
export const metaTagsSearchMessageSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.filterMessage,
);

export const metaTagsSiteNameFilterSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.url,
);
export const metaTagEditSelector = createSelector(
  (state: State) => state.metaTags,
  (metaTags) => metaTags.editMetaTag,
);

export const {
  setLoadingMetaTags,
  setMetaTagsOffset,
  setUrl,
  setEditMetaTagItem,
  setUpdateMetaTagItem,
} = metaTagsAnyPageSlice.actions;

export default metaTagsAnyPageSlice.reducer;
