import { TMeta, TRequestMenuItems, TRequestUpdateMenuItems } from '@/types';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid_v4 } from 'uuid';

import { tryGetMenuItemsContext, tryPutMenuItem } from '@/api/menu/menuItem';

import {
  buildDataForNewItem,
  buildDataForUpdateItem,
  getMenusByParentId,
  transformMenuItems,
  updateChildrenMenu,
} from '@/utils/getTransformMenuItems';
import notify from '@/utils/notify';

import {
  MenuContextEnum,
  MenuTypeEnum,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import {
  TSettingsData,
  TTransformMenuItem,
} from '@/ui/containers/MenuContainer/MenuContainer.types';

interface IMenuItemState {
  status: {
    fetchingGetMenuItems: boolean;
    fetchingUpdateMenuItem: boolean;
  };
  modals: {
    addMenuItemsModal: boolean;
    delMenuItemModal: boolean;
    editMenuItemOpenModal: boolean;
  };
  defaultMenuItems: TTransformMenuItem[] | null;
  menuItems: TTransformMenuItem[] | null;
  menuContext: MenuContextEnum;
  editMenuItem: TTransformMenuItem | null;
  parentId: string | null;
  deleteMenuItemId: string | null;
  meta: TMeta | null;
  anotherContextItems: TSettingsData[] | null;
  menuType: MenuTypeEnum | null;
}

const initialState: IMenuItemState = {
  status: {
    fetchingGetMenuItems: false,
    fetchingUpdateMenuItem: false,
  },
  modals: {
    addMenuItemsModal: false,
    delMenuItemModal: false,
    editMenuItemOpenModal: false,
  },
  defaultMenuItems: null,
  menuItems: null,
  menuContext: MenuContextEnum.CORP,
  editMenuItem: null,
  deleteMenuItemId: null,
  parentId: null,
  meta: null,
  anotherContextItems: null,
  menuType: null,
};

export const fetchGetMenuItemsContextAction = createAsyncThunk(
  'GetMenuItemsContext',
  async (params: TRequestMenuItems, { rejectWithValue }) => {
    try {
      const { payload } = await tryGetMenuItemsContext(params.menuType);
      return { payload, context: params.menuContext };
    } catch (e) {
      return rejectWithValue('Ошибка');
    }
  },
);

export const fetchUpdateMenuAction = createAsyncThunk(
  'UpdateMenu',
  async (params: TRequestUpdateMenuItems, { rejectWithValue }) => {
    try {
      return await tryPutMenuItem(params);
    } catch (e) {
      return rejectWithValue('Ошибка');
    }
  },
);

export const menuItemsSlice = createSlice({
  name: 'menuItems',
  initialState,
  reducers: {
    setAddMenuItemsModalOpened: (state, action) => {
      state.modals.addMenuItemsModal = action.payload;
      if (!action.payload) {
        state.editMenuItem = null;
      }
    },
    setEditMenuItemModalOpened: (state, action: PayloadAction<boolean>) => {
      state.modals.editMenuItemOpenModal = action.payload;
    },
    setDelMenuItemModalOpened: (state, action: PayloadAction<boolean>) => {
      state.modals.delMenuItemModal = action.payload;
    },
    setMenuItem: (state, action) => {
      const newItemId = uuid_v4();
      if (state.menuItems && state.anotherContextItems) {
        const newItem = buildDataForNewItem(
          action.payload.values,
          state.menuItems.length + 1,
          action.payload.parentId,
          newItemId,
        );
        state.menuItems = [...state.menuItems, newItem];

        if (state.anotherContextItems) {
          state.anotherContextItems = state.anotherContextItems.map((contextItem) => ({
            ...contextItem,
            items: [
              ...contextItem.items,
              {
                id: newItem.id,
                sortWeight: newItem.sortWeight,
                isVisible: newItem.isVisible,
              },
            ],
          }));
        }
      }
    },
    setMenuContext: (state, action) => {
      state.menuContext = action.payload;
    },
    setEditMenuItem: (state, action) => {
      state.editMenuItem = action.payload.item;
      state.parentId = action.payload.parentId;
    },
    setUpdatedMenuItem: (state, action) => {
      const {
        payload: { updatedMenu, menuItems },
      } = action;

      if (state.menuItems) {
        const itemsById = getMenusByParentId(menuItems);
        let res: TTransformMenuItem[] = [buildDataForUpdateItem(updatedMenu)];
        res = updateChildrenMenu(state.menuItems, res, itemsById, updatedMenu);
        const updatedIds = res.map(({ id }) => id);
        const filteredMenus = state.menuItems.filter((menu) => !updatedIds.includes(menu.id));
        state.menuItems = [...filteredMenus, ...res].sort((a, b) => a.sortWeight - b.sortWeight);
      }
    },
    setDeleteMenuItem: (state, action) => {
      if (state.menuItems && state.anotherContextItems) {
        state.menuItems = state.menuItems
          .filter((item) => item.id !== action.payload)
          .filter((item) => item.parentId !== action.payload);
        const ids = state.menuItems.map((item) => item.id);
        state.anotherContextItems = state.anotherContextItems.map((item) => ({
          ...item,
          items: item.items.filter((subItem) => ids.includes(subItem.id)),
        }));
      }
    },
    setDeleteMenuItemId: (state, action) => {
      state.deleteMenuItemId = action.payload;
    },
    setMenuItems: (state, action) => {
      state.menuItems = action.payload;
    },
    setParentId: (state, action) => {
      state.parentId = action.payload;
    },
    setMenuItemsAction: (state, action) => {
      state.menuItems = action.payload;
    },
    setResetMenuItems: (state) => {
      state.menuItems = null;
      state.meta = null;
      state.menuContext = MenuContextEnum.CORP;
    },
    setMenuType: (state, action) => {
      state.menuType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetMenuItemsContextAction.pending, (state) => {
        state.status.fetchingGetMenuItems = true;
      })
      .addCase(fetchGetMenuItemsContextAction.fulfilled, (state, action) => {
        const {
          payload: { payload },
        } = action;
        state.status.fetchingGetMenuItems = false;
        state.meta = payload.meta;
        state.menuItems = transformMenuItems(payload, state.menuContext);
        state.defaultMenuItems = transformMenuItems(payload, state.menuContext);
        state.anotherContextItems = payload.settings.filter(
          (item: TSettingsData) => item.context !== action.payload.context,
        );
      })
      .addCase(fetchGetMenuItemsContextAction.rejected, (state) => {
        state.status.fetchingGetMenuItems = false;
        notify({ message: 'Неизвестная ошибка', type: 'error' });
      })
      .addCase(fetchUpdateMenuAction.pending, (state) => {
        state.status.fetchingUpdateMenuItem = true;
      })
      .addCase(fetchUpdateMenuAction.fulfilled, (state) => {
        state.status.fetchingUpdateMenuItem = false;
        state.parentId = null;
        state.defaultMenuItems = state.menuItems;
        notify({ message: 'Список меню обновлен', type: 'success' });
      })
      .addCase(fetchUpdateMenuAction.rejected, (state) => {
        state.status.fetchingUpdateMenuItem = false;
        notify({ message: 'Неизвестная ошибка', type: 'error' });
      });
  },
});

// Selectors
type TSelectorState = { menuItems: IMenuItemState };

// modals
export const selectEditMenuItemModal = (state: TSelectorState) =>
  state.menuItems.modals.editMenuItemOpenModal;
export const selectAddMenuItemsModal = (state: TSelectorState) =>
  state.menuItems.modals.addMenuItemsModal;
export const selectDelMenuItemsModal = (state: TSelectorState) =>
  state.menuItems.modals.delMenuItemModal;

// statuses
export const selectFetchingGetMenuItems = (state: TSelectorState) =>
  state.menuItems.status.fetchingGetMenuItems;
export const selectFetchingUpdateMenuItem = (state: TSelectorState) =>
  state.menuItems.status.fetchingUpdateMenuItem;

export const selectDefaultMenuItems = (state: TSelectorState) => state.menuItems.defaultMenuItems;
export const selectMenuItems = (state: TSelectorState) => state.menuItems.menuItems;
//
export const selectParentId = (state: TSelectorState) => state.menuItems.parentId;
export const selectMenuContext = (state: TSelectorState) => state.menuItems.menuContext;
export const selectAnotherContextItems = (state: TSelectorState) =>
  state.menuItems.anotherContextItems;
export const selectMenuMeta = (state: TSelectorState) => state.menuItems.meta;
export const selectEditMenuItem = (state: TSelectorState) => state.menuItems.editMenuItem;
export const selectDeleteMenuItemId = (state: TSelectorState) => state.menuItems.deleteMenuItemId;
export const selectMenuType = (state: TSelectorState) => state.menuItems.menuType;

// reducers and actions

export const {
  setAddMenuItemsModalOpened,
  setMenuItem,
  setMenuContext,
  setParentId,
  setEditMenuItem,
  setUpdatedMenuItem,
  setDeleteMenuItem,
  setDeleteMenuItemId,
  setResetMenuItems,
  setMenuItemsAction,
  setMenuType,
} = menuItemsSlice.actions;

export default menuItemsSlice.reducer;
