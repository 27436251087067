import { IPromotionItem, TPromotionsResponse } from '@/types/promotions';

import { useState } from 'react';

import { api } from '@/api';
import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

export const useGetPromotion = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getPromotion: async (uuid: string) => {
      setIsLoading(true);
      try {
        const request = await api.get<TPromotionsResponse<IPromotionItem>>(
          URLS.promotions.getPromotion(uuid),
        );
        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения акции', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
