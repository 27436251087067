import { UseFormReturnType } from '@mantine/form';
import { GallerySnippet, SnippetValues } from '@prosv/core/types/snippets';

import { EGallerySnippetFormFields } from '@/ui/containers/ContentEditor/snippets/GallerySnippetModal/GallerySnippetModal.constants';

interface TUseGallerySnippetModal {
  form: UseFormReturnType<SnippetValues<GallerySnippet>>;
}

export const useGallerySnippetModal = ({ form }: TUseGallerySnippetModal) => {
  const handleAddSlide = () => {
    const newGallerySlide = {
      [EGallerySnippetFormFields.TITLE]: '',
      [EGallerySnippetFormFields.DESCRIPTION]: '',
      [EGallerySnippetFormFields.IMAGE]: {
        id: '',
        name: '',
        path: '',
      },
    };

    form.values.slides?.push(newGallerySlide);

    form.setValues(form.values);
  };

  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = form.values.slides![dragIndex];
    form.values.slides?.splice(dragIndex, 1);
    form.values.slides?.splice(hoverIndex, 0, draggedItem);
    form.setValues(form.values);
  };

  return { handleAddSlide, handleMoveItem };
};
