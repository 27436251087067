import * as Yup from 'yup';

import { validationTexts } from '@/constants/validationTexts';

import { UmkSystemSnippetFormFields } from './UmkSystemSnippet.constants';

const errText = 'Поле обязательно для заполнения';

const commonStringValidation = Yup.string().required(errText);

const maxLengthValidation = (maxLength: number) =>
  commonStringValidation.max(maxLength, validationTexts.MAX_LENGTH(maxLength));

export const umkSystemSnippetSchema = Yup.object({
  [UmkSystemSnippetFormFields.TITLE]: maxLengthValidation(150),
  [UmkSystemSnippetFormFields.BACKGROUND_COLOR]: Yup.string(),
  [UmkSystemSnippetFormFields.BLOCK_TYPE]: Yup.string().required(errText),
  [UmkSystemSnippetFormFields.START_POURING_FROM_FIRST_BLOCK]: Yup.boolean(),
  [UmkSystemSnippetFormFields.BLOCKS]: Yup.array(
    Yup.object({
      [UmkSystemSnippetFormFields.TEXT]: Yup.string().required(errText),
      [UmkSystemSnippetFormFields.IMAGE]: Yup.object({
        id: Yup.string().required(errText),
        name: Yup.string().required(errText),
        path: Yup.string().required(errText),
      }),
    }),
  ).min(1),
});
