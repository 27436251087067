import { useMemo } from 'react';

import { useAppSelector } from '@/hooks/useAppSelector';

import { selectDictionaryOptions } from './dictionary.selectors';
import { dictionaryApiSlice } from './dictionary.slices';
import { DictionaryItemIdField } from './dictionary.types';

export const {
  useGetDictionariesQuery: useDictionaries,
  useGetDictionaryItemsQuery: useDictionaryItems,
  useGetDictionaryItemQuery: useDictionaryItem,
  useCreateDictionaryItemMutation: useCreateDictionaryItem,
  useUpdateDictionaryItemMutation: useUpdateDictionaryItem,
} = dictionaryApiSlice;

export const useDictionary = (code?: string) => {
  const { data: items, ...rest } = useDictionaries();

  const data = useMemo(
    () => (code ? items?.find((item) => item.code === code) : undefined),
    [code, items],
  );

  return { data, ...rest };
};

export const useDictionaryOptions = (code: string, idField: DictionaryItemIdField = 'id') => ({
  ...useDictionaryItems({ code }),
  data: useAppSelector(selectDictionaryOptions(code, idField)),
});
