import { UseFormReturnType } from '@mantine/form';
import { SnippetValues, UmkSystem } from '@prosv/core/types/snippets';

import { UmkSystemSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemSnippet.constants';

interface UseUmkSystemSnippetModal {
  form: UseFormReturnType<SnippetValues<UmkSystem>>;
}

export const useUmkSystemSnippetModal = ({ form }: UseUmkSystemSnippetModal) => {
  const handleAddUmkBlock = () => {
    const newUmkBlock = {
      [UmkSystemSnippetFormFields.TEXT]: '',
      [UmkSystemSnippetFormFields.IMAGE]: {
        id: '',
        name: '',
        path: '',
      },
    };

    form.values.blocks?.push(newUmkBlock);

    form.setValues(form.values);
  };

  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = form.values.blocks![dragIndex];
    form.values.blocks?.splice(dragIndex, 1);
    form.values.blocks?.splice(hoverIndex, 0, draggedItem);
    form.setValues(form.values);
  };

  return { handleAddUmkBlock, handleMoveItem };
};
