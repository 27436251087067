import {TConfiguration} from "@/types";

import {tryAction} from "@/api/tryAction";
import {URLS} from "@/api/urls";

import api from "../instance";


export const apiGetMenu = (data: any) =>
  api.get<TConfiguration>(URLS.menus.getMenusById(data.id));


/*TRY ...CATCH */
export const tryGetMenu = (data: any) =>
  tryAction<TConfiguration>(apiGetMenu(data));
