import { SelectItem } from '@mantine/core';

export enum EUmkFormFields {
  PAGE_TYPE = 'pageType',
  ACTIVE = 'isActive',
  UMK_NAME = 'umkId',
  BANNER = 'bannerValues',
}

export const umkPageTypeOptions: SelectItem[] = [
  {
    value: 'system',
    label: 'Система УМК',
  },
  { value: 'line', label: 'Линия/Серия УМК' },
  { value: 'direction', label: 'Направление' },
];

export const searchSelectLabel = {
  line: 'Линия/Серия УМК',
  direction: 'Направление',
  system: 'Система УМК',
};

export const DEFAULT_BANNERS_LIMIT = 200;
export const SEARCH_DEBOUNCE_LIMIT = 500;
export const SEARCH_QUERY_LIMIT = 3;
