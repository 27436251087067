import * as Yup from 'yup';

import { STRING_LINK_REGEXP } from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';

import { EMetaTagFormFields } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.constants';

export const metaTagSchema = Yup.object({
  //[EMetaTagFormFields.NAME]: Yup.string(),
  [EMetaTagFormFields.SITE]: Yup.string(),
  [EMetaTagFormFields.SITE_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.TYPE_PAGE]: Yup.string(),
  [EMetaTagFormFields.PAGE_H1]: Yup.string().max(150, 'Максимальное количество символов 150'),
  [EMetaTagFormFields.PAGE_H1_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.TITLE]: Yup.string().max(100, 'Максимальное количество символов 100'),
  [EMetaTagFormFields.TITLE_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.DESCRIPTION]: Yup.string().max(150, 'Максимальное количество символов 150'),
  [EMetaTagFormFields.DESCRIPTION_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.KEYWORDS]: Yup.string().max(150, 'Максимальное количество символов 150'),
  [EMetaTagFormFields.KEYWORDS_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.ROBOTS]: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
      label: Yup.string(),
    }),
  ),
  [EMetaTagFormFields.ROBOTS_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.AUTHOR]: Yup.string(),
  [EMetaTagFormFields.AUTHOR_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.COPYRIGHT]: Yup.string(),
  [EMetaTagFormFields.CANONICAL_URL_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.CONTENT]: Yup.string(),
  [EMetaTagFormFields.CONTENT_CHECKBOX]: Yup.boolean(),
  [EMetaTagFormFields.CANONICAL_URL]: Yup.string().matches(STRING_LINK_REGEXP, validationTexts.URL),
});
