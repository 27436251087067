import { TRequestUpdateMenuItems } from '@/types';

import { URLS } from '@/api/urls';

import api from '../instance';

export const apiGetMenuItem = (menuType: string) => api.get(URLS.menus.getMenusByType(menuType));

export const apiPutMenuItem = (params: TRequestUpdateMenuItems) =>
  api.put(URLS.menus.putMenusByType(params.menuType), params.data);

export const tryGetMenuItemsContext = async (menuType: string) => {
  const { data } = await apiGetMenuItem(menuType);
  return data;
};

export const tryPutMenuItem = async (params: TRequestUpdateMenuItems) => {
  const { data } = await apiPutMenuItem(params);
  return data;
};
