import React from 'react';

import { ContentTypeCard } from '@/ui/containers/ContentEditor/ContentTypeCard';
import {
  setSelectGridDataSelector,
  setSnippetDataSelector,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';

import { cardData } from './Card.constants';
import { TCardProps } from './Card.types';
import { TemplateCard } from './TemplateCard';

export const Card: React.FC<TCardProps> = ({ variant, cardId, rowId, disabled }) => {
  const setSelectGridData = useStoreContentEditor(setSelectGridDataSelector);
  const setSnippetData = useStoreContentEditor(setSnippetDataSelector);

  const onClick = () => {
    setSelectGridData(null);
    const idIsNotNull = cardId !== null && rowId !== null;
    if (idIsNotNull) {
      setSnippetData({ variant, cardId, rowId, isNew: true });
    }
  };

  const { title, description } = cardData[variant] || { title: 'Unknown', description: '' };

  return (
    <TemplateCard
      icon={<ContentTypeCard type='card' {...{ variant, rowId, cardId, disabled }} />}
      title={title}
      description={description}
      disabled={disabled}
      {...{ onClick }}
    />
  );
};
