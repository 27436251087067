import React, { useState } from 'react';

import { ReactComponent as EventNote } from '@/assets/icons/event-note.svg';
import { ModalsVariants, useModalsStore } from '@/store/slices/modalsZustandStore/modalsStore';

export const ImagePreview = ({ src }: { src: string }) => {
  const [error, setError] = useState(false);
  const openModal = useModalsStore((state) => state.openModal);
  const [isLoad, setIsLoad] = useState(false);
  const mediaUrl = `${process.env.MEDIA_STORAGE_URL}${src}`;

  return (
    <>
      <img onLoad={() => setIsLoad(true)} alt='loader' style={{ display: 'none' }} src={mediaUrl} />
      {error || !isLoad ? (
        <div
          style={{
            background: '#E8ECF0',
            width: 77,
            height: 70,
            flexShrink: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EventNote />
        </div>
      ) : (
        <img
          onError={() => setError(true)}
          onClick={() =>
            openModal({
              currentModal: ModalsVariants.REVIEW_DETAILS_VIDEO,
              stringSource: mediaUrl,
            })
          }
          style={{
            objectFit: 'cover',
            width: '100%',
            flexShrink: 0,
            height: '100%',
          }}
          alt='example'
          src={mediaUrl}
        />
      )}
    </>
  );
};
