import { NavigateFunction } from 'react-router-dom';
import { create } from 'zustand';

interface Store {
  navigate: null | NavigateFunction;
  setNavigate: (nav: NavigateFunction) => void;
}

export const useNavigationStore = create<Store>((set) => ({
  navigate: null,
  setNavigate: (navigate) => set(() => ({ navigate })),
}));
