import { TResponse } from '@/types';
import { IGetClicksBannerResponse } from '@/types/banner/bannerDetail';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useGetNumberOfClicks = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getNumberOfClicks: async (uuid: string) => {
      setIsLoading(true);
      try {
        const request = await api.get<TResponse<IGetClicksBannerResponse>>(
          URLS.banners.getNumberOfClicks(uuid),
        );

        return request.data.payload.numberClicks;
      } catch (err) {
        return notify({ message: 'Ошибка получения кликов баннера', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
