import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './store';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend as any}>
      <App />
    </DndProvider>
  </Provider>,
);
