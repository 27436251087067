import { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box } from '@mantine/core';
import { IconGripVertical } from '@tabler/icons';
import type { Identifier, XYCoord } from 'dnd-core';

import * as sx from './DndListItem.styles';
import { DndListItemProps, DragItem } from './DndListItem.types';

export const DndListItem: FC<DndListItemProps> = ({ type, index, children, move }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY + sx.margin < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY - sx.margin > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move?.(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag, preview] = useDrag({
    type,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box ref={preview} sx={sx.root} data-handler-id={handlerId}>
      <Box ref={ref} sx={sx.handle}>
        <IconGripVertical size={20} color='#8DA2B5' />
      </Box>

      <Box sx={sx.content}>{children}</Box>
    </Box>
  );
};
