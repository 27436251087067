import React from 'react';
import { Box, Switch, Text } from '@mantine/core';

import { TTogglerProps } from './Toggler.type';

export const Toggler: React.FC<TTogglerProps> = (props) => {
  return (
    <>
      <Text span>{props.title}</Text>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <Switch {...(props.elementProps || {})} />

        {props.description && (
          <Text size={14} weight={400} color='greyDark' span>
            {props.description}
          </Text>
        )}
      </Box>
    </>
  );
};
