import React from 'react';
import { Button } from '@mantine/core';
import { Image } from '@mantine/core';

import { ITableColumns } from '@/ui/components/CustomTable/CustomTable.types';
import { SortHeader } from '@/ui/containers/MaterialsContainer/Components/SortHeader';

import TrashSVG from '@/assets/icons/trash.svg';

export const useNewsTableData = () => {
  //TODO ADD TYPE for columns after add api
  const columns: ITableColumns<any>[] = [
    {
      title: 'Заголовок',
      field: 'header',
    },
    {
      title: 'Категория',
      field: 'category',
    },
    {
      title: 'Тема',
      field: 'theme',
    },
    {
      title: 'Компания',
      field: 'company',
    },
    {
      title: (
        // eslint-disable-next-line no-restricted-syntax
        <SortHeader sorted={false} reversed={false} onSort={() => console.log('Дата создания')}>
          Дата создания
        </SortHeader>
      ),
      position: 'right',
      field: 'create_date',
      style: {
        width: 160,
      },
    },
    {
      title: (
        // eslint-disable-next-line no-restricted-syntax
        <SortHeader sorted={false} reversed={false} onSort={() => console.log('Дата публикации')}>
          Дата публикации
        </SortHeader>
      ),
      position: 'right',
      style: {
        width: 175,
      },
      field: 'public_date',
    },
    {
      title: '',
      style: { width: 50 },
      field: 'actions',
      render: (onRenderClick) => (
        <Button
          name='Редактировать'
          title='Редактировать'
          compact
          onClick={onRenderClick}
          sx={{
            backgroundColor: 'initial',
            '&:hover': {
              backgroundColor: 'initial!important',
            },
          }}
        >
          <Image
            src={TrashSVG}
            sx={{
              minWidth: 13,
            }}
          />
        </Button>
      ),
    },
  ];

  return { columns };
};
