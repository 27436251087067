import {
  TConfiguration,
  TDeleteConfigurationReq,
  TGetConfigurationReq,
  TPutConfigurationReq
} from '@/types';

import api from '../instance';
import { tryAction, tryActionStatus } from '../tryAction';
import { URLS } from '../urls';

export const apiGetConfiguration = (data: TGetConfigurationReq) =>
  api.get<TConfiguration>(URLS.configuration.getConfigurationsById(data.confId));

export const apiPutConfiguration = ({ id, data }: TPutConfigurationReq) =>
  api.put(URLS.configuration.putConfigurationsById(id), { ...data });

export const apiDelConfiguration = (data: TDeleteConfigurationReq) =>
  api.delete(URLS.configuration.delConfigurationsById(data.id));

/*TRY ...CATCH */
export const tryGetConfiguration = (data: TGetConfigurationReq) =>
  tryAction<TConfiguration>(apiGetConfiguration(data));

export const tryPutConfiguration = ({ id, data }: TPutConfigurationReq) =>
  tryActionStatus(apiPutConfiguration({ id, data }));

export const tryDelConfiguration = (data: TDeleteConfigurationReq) => tryActionStatus(apiDelConfiguration(data));
