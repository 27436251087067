import { useState } from 'react';

import { useStoreContentEditor } from '@/ui/containers/ContentEditor/store';

type TUseStateMoveRowButtonProps = {
  rowId: number;
};

export const useStateMoveRowButton = ({ rowId }: TUseStateMoveRowButtonProps) => {
  const isLastRow = useStoreContentEditor((state) => {
    const grid = state?.savedContentData?.grid;
    if (grid) {
      const lastIndex = grid.length - 1;
      return lastIndex === rowId;
    }
    return true;
  });

  const [opened, setOpened] = useState(false);

  const isFirstRow = rowId === 0;

  return {
    isLastRow,
    opened,
    setOpened,
    isFirstRow,
  };
};
