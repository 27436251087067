export enum EPeopleSnippetFormFields {
  TITLE = 'title',
  FULL_NAME = 'fullName',
  PHONE = 'phone',
  POSITION = 'position',
  EMAIL = 'email',
  BRIEF_INFORMATION = 'briefInformation',
  FILE = 'file',
  ALT = 'alt',
  IMAGE_TITLE = 'imageTitle',
  PEOPLE = 'people',
}
