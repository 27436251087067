import { NewsDetailsPayload } from '@/types/news/newsDetail';

import { useMemo, useRef, useState } from 'react';
import { v4 as uuid_v4 } from 'uuid';

import { useCreateDraft } from '@/api/draft/useCreateDraft';
import {
  useCreateNews,
  useDeleteNews,
  useGetNews,
  useUpdateNews,
  useUpdateNewsActivity,
} from '@/api/news/hooks';

import { getItemPermissions } from '@/utils/permissions';

import { usePermission } from '@/hooks/usePermissions';

import {
  setSelectorDefaultFormData,
  useStoreContentEditor,
} from '@/ui/containers/ContentEditor/store';
import { INewsCreationState } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.types';
import { INewsFormFields } from '@/ui/containers/NewsDetailInfo';

export const useNewsCreationState = (id: string = ''): INewsCreationState => {
  const canCreate = usePermission('NEWS_CREATE');
  const draftId = useStoreContentEditor((state) => state.draftId);
  const defaultFormData = useStoreContentEditor((state) =>
    state.getDefaultFormData<INewsFormFields>(),
  );

  const sessionID = useRef(uuid_v4());
  const setDefaultFormData = useStoreContentEditor(setSelectorDefaultFormData);

  const [news, setNews] = useState<NewsDetailsPayload | null>(null);
  const [isShouldBlock, setIsShouldBlock] = useState(false);

  const isNewNews = !Boolean(id);
  const isShowNewsDetail = isNewNews || (!isNewNews && news);
  const title = isNewNews ? 'Новая новость' : `ID: ${news?.id || ''}`;
  const [trySaveNews, setTrySaveNews] = useState<boolean>(false);

  const { isLoading: isCreateNewsLoading, createNews } = useCreateNews();
  const { isLoading: isCreateDraftLoading, createDraft } = useCreateDraft();
  const { isLoading: isGetNewsLoading, getNews } = useGetNews();
  const { isLoading: isUpdateNewsLoading, updateNews } = useUpdateNews();
  const { isLoading: isUpdateNewsActivityLoading, updateNewsActivity } = useUpdateNewsActivity();
  const { isLoading: isDeleteNewsLoading, deleteNews } = useDeleteNews();

  const isLoading =
    isCreateNewsLoading ||
    isCreateDraftLoading ||
    isGetNewsLoading ||
    isUpdateNewsLoading ||
    isUpdateNewsActivityLoading ||
    isDeleteNewsLoading;

  const permissions = useMemo(() => {
    const res = getItemPermissions('NEWS_', news?.permissions);
    return {
      ...res,
      canView: !news || !!(isNewNews ? canCreate : res.canGet),
      canSave: !!(isNewNews ? canCreate : res.canUpdate || res.canUpdateActivity),
      canUpdate: !!(isNewNews ? canCreate : res.canUpdate),
    };
  }, [isNewNews, canCreate, news]);

  return {
    permissions,
    title,
    isNewNews,
    isShowNewsDetail,
    draftId,
    defaultFormData,
    news,
    isShouldBlock,
    isLoading,
    sessionID,
    setDefaultFormData,
    trySaveNews,
    setTrySaveNews,
    createNews,
    createDraft,
    getNews,
    deleteNews,
    updateNews,
    updateNewsActivity,
    setNews,
    setIsShouldBlock,
  };
};
