import { IGetBannerListResponse } from '@/types/banner/bannerDetail';

import { useState } from 'react';
import queryString from 'query-string';

import { api } from '@/api';
import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

export const useGetBanners = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getBanners: async (search: string) => {
      setIsLoading(true);
      try {
        const request = await api.get<IGetBannerListResponse>(
          queryString.stringifyUrl(
            { url: URLS.banners.listing, query: { limit: 100, offset: 0, query: search } },
            { skipEmptyString: true, skipNull: true },
          ),
        );

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения баннеров', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
