import { itemsPerPageOptions } from '@/ui/containers/MaterialsContainer/MaterialsContainer.constants';

import { PromotionFilterSlice } from '@/store/slices/promotions/promotions.types';

export const promotionDefaultFilter: PromotionFilterSlice = {
  currentPage: 1,
  limit: itemsPerPageOptions[0].value,
  sortField: '',
  title: '',
  dateActiveStart: [null, null],
  dateActiveEnd: [null, null],
  isActive: undefined,
};
