import { IGetBannerResponse } from '@/types/banner/bannerDetail';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useDeleteBanner = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    deleteBanner: async (uuid: string) => {
      setIsLoading(true);
      try {
        return await api.delete<IGetBannerResponse>(URLS.banners.delBanner(uuid)).then((res) => {
          notify({ message: 'Баннер успешно удалён', type: 'success' });
          return res.data;
        });
      } catch (err) {
        return notify({ message: 'Ошибка удаления баннера', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
