import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, Group, Modal, Radio, Select, Text } from '@mantine/core';
import { SelectionPlaceItem } from '@prosv/core/types/bff/catalog/admin';
import { IconAlertCircle } from '@tabler/icons';

import notify from '@/utils/notify';

import {
  useGetSelectionPlaceQuery,
  useGetSelectionsQuery,
  useUpdateSelectionPlaceMutation,
} from '@/store/slices/selections';

export interface SelectionPlaceEditorModalProps {
  item?: SelectionPlaceItem | null;
  onClose: (updated: boolean) => void;
}

interface FormState {
  isActive: boolean;
  selectionId: string;
}

export const SelectionPlaceEditorModal: FC<SelectionPlaceEditorModalProps> = ({
  item,
  onClose,
}) => {
  const id = item?.id ?? '';
  const { data, isFetching, isError } = useGetSelectionPlaceQuery(id, { skip: !id });
  const { data: selections } = useGetSelectionsQuery();
  const [update, updateState] = useUpdateSelectionPlaceMutation();

  const [state, setState] = useState<FormState>({
    isActive: !!(data?.isActive ?? item?.isActive),
    selectionId: data?.selection?.id ?? '',
  });

  const isRemovedFromPublication =
    data?.selection &&
    data.selection.isRemovedFromPublication &&
    data.selection.id === state.selectionId;

  useEffect(() => {
    setState({
      isActive: !!(data?.isActive ?? item?.isActive),
      selectionId: data?.selection?.id ?? '',
    });
  }, [item, data]);

  const isSaving = updateState.isLoading;
  const disabled = isSaving || isFetching || isError;

  const selectionsItems = useMemo(() => {
    const res = (selections?.items ?? []).map(({ id: value, name: label }) => ({ value, label }));
    const currentSelection = data?.selection;
    if (currentSelection && !res.find(({ value }) => value === currentSelection.id)) {
      res.push({ value: currentSelection.id, label: currentSelection.title });
    }
    return res;
  }, [selections]);

  const handleChangeActivity = (value: string) => {
    setState((prev) => ({ ...prev, isActive: value === 'true' }));
  };

  const handleChangeSelection = (value: string) => {
    setState((prev) => ({ ...prev, selectionId: value }));
  };

  const handleSubmit = () => {
    update({ ...state, id })
      .unwrap()
      .then(() => {
        notify({ message: 'Изменения сохранены', type: 'success' });
        onClose(true);
      })
      .catch(() => {
        notify({ message: 'Произошла ошибка, изменения не были сохранены', type: 'error' });
      });
  };

  return (
    <Modal
      size='auto'
      opened={!!item}
      onClose={() => onClose(false)}
      title={
        <Text size={24} align='left' weight={600}>
          {item?.page.name} - {item?.position.name}
        </Text>
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 'calc(min(100vw, 480px) - 50px)',
          maxWidth: 800,
          position: 'relative',
          gap: '20px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Text size={16} weight={600}>
            Отображать на сайте?
          </Text>
          <Radio.Group
            withAsterisk
            onChange={handleChangeActivity}
            sx={{ 'div > *': { cursor: 'pointer' } }}
            value={state.isActive ? 'true' : 'false'}
          >
            <Radio value='true' label='Да' disabled={disabled} />
            <Radio value='false' label='Нет' disabled={disabled} />
          </Radio.Group>
        </Box>

        <Box>
          <Text size='sm' color='grayDefault'>
            Выберите подборку
          </Text>
          <Select
            data={selectionsItems}
            value={state.selectionId}
            placeholder='Выберите подборку'
            onChange={handleChangeSelection}
          />

          {isRemovedFromPublication && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} mt={10} color='red'>
              <IconAlertCircle color='red' size={12} />
              <Text size='xs' color='red'>
                Данная подборка удалена или больше неактивна, пожалуйста, выберите другую подборку
              </Text>
            </Box>
          )}
        </Box>
      </Box>

      <Group position='right' pt={64}>
        <Button
          sx={{ fontSize: 16, fontWeight: 600, height: 40 }}
          loading={isSaving}
          disabled={disabled}
          onClick={handleSubmit}
        >
          {isSaving ? 'Сохранение' : 'Подтвердить'}
        </Button>
      </Group>
    </Modal>
  );
};
