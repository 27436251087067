import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Group, Paper, Title } from '@mantine/core';

import { usePermission } from '@/hooks/usePermissions';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { PromotionsFilters } from '@/ui/containers/PromotionsContainer/components/PromotionsFilters';
import {
  usePromotionsColumns,
  usePromotionsConfig,
} from '@/ui/containers/PromotionsContainer/PromotionsContainer.hooks';

import { useLazyGetPromotionsListingQuery } from '@/store/slices/promotions/promotionsSlice';

export const PromotionsContainer = () => {
  const canCreate = usePermission('PROMOTION_CREATE');
  const navigate = useNavigate();
  const [getPromotionsListingRequest, data] = useLazyGetPromotionsListingQuery();

  const filterProps = usePromotionsConfig(getPromotionsListingRequest);
  const columns = usePromotionsColumns(filterProps);

  return (
    <Box style={{ height: '96vh', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
      <Container fluid mb={16} sx={{ marginLeft: 'initial', marginRight: 'initial' }}>
        <Group position='apart'>
          <Title order={1}>Акции</Title>

          {canCreate && (
            <Button sx={{ width: '200px' }} onClick={() => navigate('/promotions/new')}>
              Создать
            </Button>
          )}
        </Group>
      </Container>
      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <PromotionsFilters {...filterProps} />
        <CustomTable
          onChangePagination={(v) => filterProps.onChange({ currentPage: v })}
          limit={Number(filterProps.filters.limit)}
          noData='К сожалению, список акций пуст.'
          total={data.data?.payload?.meta.total || 0}
          currentPage={filterProps.filters.currentPage}
          status={data.isFetching ? 'loading' : 'loaded'}
          data={data.data?.payload?.items || []}
          columns={columns}
        />
      </Paper>
    </Box>
  );
};
