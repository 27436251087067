import { FC } from 'react';
import { Button, Group, Textarea, TextInput, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { EGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.constants';
import { getDefaultValues } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.helpers';
import { globalSettingFormSchema } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.schema';
import { IGlobalSettingFormFields } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingsForm/GlobalSettingsForm.types';
import { ISetting } from '@/ui/containers/GlobalSettingsContainer/components/GlobalSettingTable/GlobalSettingsTable.types';

interface IProps {
  globalSetting: ISetting[] | null;
  handleSubmit: (values: IGlobalSettingFormFields) => void;
}

export const GlobalSettingsForm: FC<IProps> = ({ globalSetting, handleSubmit }) => {
  const form = useForm({
    initialValues: getDefaultValues(globalSetting),
    validate: yupResolver(globalSettingFormSchema),
  });

  return (
    <form style={{ width: 'inherit' }} onSubmit={form.onSubmit(handleSubmit)}>
      <Title order={1} mb={32} sx={{ fontWeight: 500, fontSize: 24 }}>
        Настройки: {globalSetting?.[0].value}
      </Title>
      <TextInput
        label='Название сайта'
        placeholder='site_name для корпоративного сайта'
        withAsterisk
        mb={12}
        {...form.getInputProps(EGlobalSettingFormFields.TITLE)}
      />
      <Textarea
        withAsterisk
        label='Robots.txt'
        placeholder='Введите содержимое robots.txt'
        mb={12}
        minRows={10}
        maxRows={15}
        {...form.getInputProps(EGlobalSettingFormFields.ROBOTS)}
      />
      <Textarea
        withAsterisk
        label='Синонимы'
        placeholder='Введите содержимое синонимов'
        minRows={10}
        maxRows={15}
        {...form.getInputProps(EGlobalSettingFormFields.SYNONYMS)}
      />
      <Group position='right' sx={{ paddingTop: 32 }}>
        <Button type='submit'>Подтвердить</Button>
      </Group>
    </form>
  );
};
