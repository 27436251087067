import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker, useNavigate, useParams } from 'react-router-dom';
import { Box, LoadingOverlay, Paper } from '@mantine/core';

import { getSelectorValues } from '@/utils/getSelectorValues';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { getBreadcrumbs } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsCreationContainer.helpers';
import { MetaTagsDetailInfo } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsDetailInfo/MetaTagsDetailInfo';
import { META_TAG_CREATION_FORM_ID } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.constants';
import { IMetaTagFormFields } from '@/ui/containers/MetaTagsCreationContainer/MetaTagsForm/MetaTagsForm.types';
import { EAddNewsConfirmationTexts } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { ActionButtons } from '@/ui/containers/TemplateCreationContainer/ActionButtons/ActionButton';

import { AppDispatch } from '@/store';
import {
  metaTagEditSelector,
  updateMetaTagAction,
} from '@/store/slices/metaTagsAnyPage/metaTagsAnyPage';

export const MetaTagsCreationContainer = () => {
  const dispatch: AppDispatch = useDispatch();

  const { id } = useParams();

  const editMetaTag = useSelector(metaTagEditSelector);

  const navigate = useNavigate();

  const [isShouldBlock, setIsShouldBlock] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmited, setIsSumbited] = useState(false);
  const [isSaveWithRedirect, setIsSaveWithRedirect] = useState(false);

  const { proceed, reset, state } = useBlocker(isShouldBlock && !isSubmited);

  const title = `ID: ${id?.slice(0, 5).toUpperCase()}`;

  const breadcrumbs = getBreadcrumbs(title);

  const onConfirmLeave = useCallback(() => {
    if (proceed) {
      proceed();
    }
  }, [proceed]);

  const onCancel = useCallback(() => {
    if (reset) {
      reset();
    }
  }, [reset]);

  const handleSubmit = async (values: IMetaTagFormFields) => {
    const { robots: robotsValues, ...restProperties } = values;

    const arrRobots = getSelectorValues(robotsValues);

    try {
      if (id) {
        setIsUpdating(true);
        await dispatch(
          updateMetaTagAction({
            ...restProperties,
            robots: arrRobots,
            id,
          }),
        );
        setIsSumbited(true);
      }
    } catch (e) {
      setIsUpdating(false);
    } finally {
      setIsUpdating(false);
    }
  };
  const handleSaveWithRedirect = () => {
    setIsSaveWithRedirect(true);
  };

  useEffect(() => {
    if (isSubmited && isSaveWithRedirect) {
      navigate(`/meta-tags-any-page`);
    }
  }, [isSubmited, isSaveWithRedirect]);

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        breadcrumbs={breadcrumbs}
        backLink='/meta-tags-any-page'
        title={title}
        rightButton={
          <ActionButtons
            formId={META_TAG_CREATION_FORM_ID}
            handleSaveWithRedirect={handleSaveWithRedirect}
          />
        }
      />

      <Paper
        radius={8}
        p={24}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          overflow: 'scroll',
          position: 'relative',
        }}
      >
        <LoadingOverlay visible={isUpdating} />
        <MetaTagsDetailInfo
          metaTagTemplate={editMetaTag}
          setIsShouldBlock={setIsShouldBlock}
          handleSubmit={handleSubmit}
        />

        {state === 'blocked' && (
          <ModalConfirm
            title={EAddNewsConfirmationTexts.OUT_TITLE}
            text={EAddNewsConfirmationTexts.NOT_SAVE_MENU_SITE}
            confirmHandler={onConfirmLeave}
            opened={state === 'blocked'}
            onClose={onCancel}
          />
        )}
      </Paper>
    </Box>
  );
};
