import React, { useState } from 'react';
import { Box, Button, TextInput } from '@mantine/core';

import { Chip } from '@/pages/Projects/ProjectDetails/components/Chip';

interface Props {
  value: string[];
  onChange: (v: string[]) => void;
  error: string;
  label?: string;
  inputType?: React.HTMLInputTypeAttribute;
  placeholder?: string;
}
export const InputWithBadges = ({
  value = [],
  onChange,
  error,
  inputType,
  placeholder,
  label,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const handleAdd = () => {
    if (!value.includes(inputValue)) {
      onChange([...value, inputValue]);
    }
    setInputValue('');
  };

  const handleDelete = (str: string) => {
    onChange([...value.filter((v) => v !== str)]);
  };
  return (
    <div>
      <Box
        sx={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'flex-start', gap: 16 }}
      >
        <TextInput
          value={inputValue}
          type={inputType}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === 'Enter' && inputValue) {
              handleAdd();
              e.preventDefault();
            }
          }}
          label={
            <span>
              {label || ''} <span style={{ color: '#fa5252' }}>*</span>
            </span>
          }
          error={error}
          placeholder={placeholder}
        />
        <Button
          onClick={handleAdd}
          disabled={!inputValue || value.includes(inputValue)}
          type='button'
          sx={{ width: '154px', marginTop: 24 }}
        >
          Добавить
        </Button>
      </Box>
      <Box mt={16} sx={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
        {value.map((chip) => (
          <Chip text={chip} onClick={handleDelete} key={chip} />
        ))}
      </Box>
    </div>
  );
};
