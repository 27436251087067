import { FC } from 'react';
import { Box, Group, Text, Textarea, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { MultiSelect } from '@/ui/components/MultiSelect';
import {
  ETemplateFormFields,
  selectPageTypeOptions,
  selectRobotOptions,
  selectSiteOptions,
} from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.constants';
import { ITemplateFormFields } from '@/ui/containers/TemplateCreationContainer/TemplateForm/TemplateForm.types';

interface IProps {
  form: UseFormReturnType<ITemplateFormFields>;
}

export const TemplateForm: FC<IProps> = ({ form }) => {
  const { onChange: onChangeRobots, value: valueRobots } = form.getInputProps(
    ETemplateFormFields.ROBOTS,
  );
  const { onChange: onChangeSiteName, value: valueSiteName } = form.getInputProps(
    ETemplateFormFields.SITE,
  );

  const { onChange: onChangePageType, value: valuePageType } = form.getInputProps(
    ETemplateFormFields.PAGE_TYPE,
  );

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'center',
        width: '100%',
        paddingBottom: '22px',
        fontWeight: 500,
        fontSize: 14,
      }}
    >
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text size='sm'>Сайт</Text>
        <Box
          sx={{
            width: '526px',
          }}
        >
          <MultiSelect
            isDisabled
            label='siteName'
            options={selectSiteOptions}
            value={valueSiteName}
            onChange={onChangeSiteName}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text size='sm' sx={{ fontWeight: 500 }}>
          Тип страницы
        </Text>
        <Box
          sx={{
            width: '526px',
          }}
        >
          <MultiSelect
            isDisabled
            label='typePage'
            options={selectPageTypeOptions}
            value={valuePageType}
            onChange={onChangePageType}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>page_h1</Text>
        <TextInput
          sx={{ gridColumn: 'span 8', width: 526 }}
          placeholder='Введите page_h1'
          {...form.getInputProps(ETemplateFormFields.PAGE_H1)}
        />{' '}
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>title</Text>
        <TextInput
          sx={{ gridColumn: 'span 8', width: 526 }}
          placeholder='Введите title'
          {...form.getInputProps(ETemplateFormFields.TITLE)}
        />{' '}
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>description</Text>
        <TextInput
          sx={{ gridColumn: 'span 8', width: 526 }}
          placeholder='Введите description'
          {...form.getInputProps(ETemplateFormFields.DESCRIPTION)}
        />{' '}
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>keywords</Text>
        <TextInput
          sx={{ gridColumn: 'span 8', width: 526 }}
          placeholder='Введите keywords'
          {...form.getInputProps(ETemplateFormFields.KEYWORDS)}
        />
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text size='sm' sx={{ fontWeight: 500 }}>
          robots
        </Text>
        <Box
          sx={{
            width: '526px',
          }}
        >
          <MultiSelect
            label='robots'
            options={selectRobotOptions}
            value={valueRobots}
            onChange={onChangeRobots}
          />
        </Box>
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>author</Text>
        <TextInput
          sx={{ gridColumn: 'span 8', width: 526 }}
          placeholder='Введите author'
          {...form.getInputProps(ETemplateFormFields.AUTHOR)}
        />
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>copyright</Text>
        <TextInput
          sx={{ gridColumn: 'span 8', width: 526 }}
          placeholder='Введите copyright'
          {...form.getInputProps(ETemplateFormFields.COPYRIGHT)}
        />
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>content</Text>
        <Textarea
          placeholder='Введите content'
          sx={{ gridColumn: 'span 12', textarea: { minHeight: '114px' }, width: 526 }}
          {...form.getInputProps(ETemplateFormFields.CONTENT)}
        />
      </Group>
      <Group sx={{ width: 'inherit', justifyContent: 'space-between', alignItems: 'start' }}>
        <Text>Канонический URL</Text>
        <TextInput
          sx={{ gridColumn: 'span 6', width: 526 }}
          placeholder='Введите канонический URL'
          {...form.getInputProps(ETemplateFormFields.CANONICAL)}
        />
      </Group>
    </Box>
  );
};
