import { IGetBannerListResponse, IGetBannerResponse } from '@/types/banner/bannerDetail';

import { EffectCallback, useEffect, useState } from 'react';
import queryString from 'query-string';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import { IBannerCreationState } from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.types';

import api from '../../../api/instance';

export const useGetBanner = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getBanner: async (id: string) => {
      setIsLoading(true);
      try {
        const request = await api.get<IGetBannerResponse>(URLS.banners.getBanner(id));

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения баннера', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};

export const useGetBanners = (limit?: number) => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    getBanners: async ({ search, page }: { search?: string; page?: string[] }) => {
      setIsLoading(true);
      try {
        const request = await api.get<IGetBannerListResponse>(
          queryString.stringifyUrl(
            {
              url: URLS.banners.listing,
              query: { limit: limit || 100, offset: 0, query: search, page: page },
            },
            { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' },
          ),
        );

        return request.data;
      } catch (err) {
        return notify({ message: 'Ошибка получения баннеров', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};

export const useModeBanner = (state: IBannerCreationState, id: string = '') => {
  const { getBanner } = useGetBanner();

  let effect: EffectCallback = () => {};

  if (!state.banner && id) {
    effect = () => {
      getBanner(id).then((bannerResponse) => {
        if (bannerResponse) {
          state.setBanner(bannerResponse.payload);
        }
      });
    };
  }

  useEffect(effect, [id, state.banner]);
};
