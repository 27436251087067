import * as Yup from 'yup';

import { validationTexts } from '@/constants/validationTexts';

import { ELinkButtonModal } from '@/ui/containers/ContentEditor/snippets/LinkButtonSnippetModal/LinkButtonModal.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const linkBtnSchema = Yup.object({
  [ELinkButtonModal.LINK]: Yup.string().required(ERR_REQUIRED_TEXT),
  [ELinkButtonModal.NAME]: Yup.string()
    .required(ERR_REQUIRED_TEXT)
    .max(80, validationTexts.MAX_LENGTH(80)),
  [ELinkButtonModal.ALIGNMENT]: Yup.string().required(ERR_REQUIRED_TEXT),
});
