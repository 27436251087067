import React, { useEffect, useMemo } from 'react';

import { useCreateDraft } from '@/api/draft/useCreateDraft';

import { setSelectorDraftId, useStoreContentEditor } from '@/ui/containers/ContentEditor/store';
import { createNewDraftData } from '@/ui/containers/NewsCreationContainer/NewsCreationContainer.constants';
import { defaultInitialValues } from '@/ui/containers/SupportContainer/detail/components/tabs/general/GeneralTab.constants';
import { DetailPageContainer } from '@/ui/containers/SupportContainer/detail/DetailPageContainer';

import { useGetCategoryTreatmentQuery } from '@/store/slices/support/supportDetailSlice';

export const DetailCreatePageContainer = () => {
  const createDraft = useCreateDraft();
  const setDraftId = useStoreContentEditor(setSelectorDraftId);

  const categoryTreatmentResponse = useGetCategoryTreatmentQuery();

  useEffect(() => {
    createDraft.createDraft(createNewDraftData).then((res) => {
      if (res?.payload.draftId) {
        setDraftId(res?.payload.draftId);
      }
    });
  }, []);

  const categoryOptions = useMemo(
    () =>
      (categoryTreatmentResponse.data?.payload.items || []).map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [categoryTreatmentResponse.data?.payload.items],
  );
  const breadcrumbs = [
    {
      name: 'Поддержка-F.A.Q.',
      url: '/support',
    },
    {
      name: `Создание`,
    },
  ];
  return (
    <DetailPageContainer
      title='Создание'
      isLoading={false}
      categoryOptions={categoryOptions}
      breadcrumbs={breadcrumbs}
      data={defaultInitialValues}
    />
  );
};
