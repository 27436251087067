export const LIMIT = 10;
export const OFFSET = 0;
export const PAGE = 1;

export const STR_LENGTH_LIFT = 10;

export const CONFIGURATION_OPTIONS_LIMIT = 50;

export const S3_STORAGE_LINK = process.env.MEDIA_STORAGE_URL;

export const MEDIA_LIMIT = 50;
export const MEDIA_OFFSET = 0;
export const MEDIA_PAGE = 1;

export const PAGE_ELEMENTS = [
  { value: '10', label: '10' },
  { value: '16', label: '16' },
  { value: '32', label: '32' },
  { value: '64', label: '64' },
];

export const formatDateWithHours = 'DD.MM.YYYY, HH:mm';
export const formatDate = 'DD.MM.YYYY';
