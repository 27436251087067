import { createSelector } from '@reduxjs/toolkit';

import { dictionaryApiSlice } from './dictionary.slices';
import { DictionaryItemIdField, DictionarySelectOption } from './dictionary.types';

export const selectDictionaryItems = (code: string) =>
  dictionaryApiSlice.endpoints.getDictionaryItems.select({ code });

export const selectDictionaryOptions = (code: string, idField: DictionaryItemIdField = 'id') =>
  createSelector(selectDictionaryItems(code), ({ data }) =>
    (data?.items ?? [])
      .filter(({ isActive }) => isActive)
      .sort(({ sort: a }, { sort: b }) => a - b)
      .map<DictionarySelectOption>((item) => ({ value: item[idField], label: item.name })),
  );
