import { forwardRef } from 'react';
import { Box, SelectItemProps, Text } from '@mantine/core';

interface ItemProps extends SelectItemProps {
  articleNumber: string;
  bookName: string;
}

export const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ articleNumber, bookName, ...props }: ItemProps, ref) => (
    <Box
      ref={ref}
      {...props}
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(10, 1fr)',
        padding: '16px',
        borderBottom: '1px solid #E8ECF0',
        overflow: 'auto',
        '&:hover': {
          background: '#f1f3f5',
          cursor: 'pointer',
        },
      }}
    >
      <Text
        sx={{
          gridColumn: 'span 1',
        }}
      >
        {articleNumber.split('_')[0]}
      </Text>
      <Text
        sx={{
          gridColumn: 'span 9',
        }}
      >
        {bookName}
      </Text>
    </Box>
  ),
);
