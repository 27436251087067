import { ChangeEvent, FC, useState } from 'react';
import { Box, Button, Checkbox, Group, Modal, Radio, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import {
  MenuContextEnum,
  MenuItemTypeEnum,
} from '@/ui/containers/MenuContainer/MenuContainer.constants';
import { TEditMenuItem } from '@/ui/containers/MenuContainer/MenuContainer.types';
import {
  EAddNewItemConfirmationTexts,
  EMenuContainerAddNewItemConstants,
} from '@/ui/containers/MenusContainer/components/AddOrEditItem/AddOrEditItem.constants';
import { getNewItemInitialValues } from '@/ui/containers/MenusContainer/components/AddOrEditItem/AddOrEditItem.helpers';
import { NEW_MENU_ITEM_FORM } from '@/ui/containers/MenusContainer/MenuContainer.schema';
import { TAddOrEditItem } from '@/ui/containers/MenusContainer/MenuContainer.types';

interface IProps {
  context: MenuContextEnum;
  isOpened: boolean;
  editItem?: TEditMenuItem;
  parentId?: string | null;
  visibilityParent: boolean;
  onClose: () => void;
  onChange: (values: Omit<TAddOrEditItem, 'id'> | TEditMenuItem, parentId: string | null) => void;
}
export const AddOrEditItem: FC<IProps> = ({
  context,
  parentId,
  visibilityParent,
  editItem,
  isOpened,
  onClose,
  onChange,
}) => {
  const addNewItemForm = useForm({
    initialValues: getNewItemInitialValues(editItem),
    validate: yupResolver(NEW_MENU_ITEM_FORM),
  });

  const [isOpenedConfirmation, setIsOpenedConfirmation] = useState<boolean>(false);

  const isCorpSite = context === MenuContextEnum.CORP;

  const handlePopup = () => {
    if (addNewItemForm.isValid()) {
      addNewItemForm.setFieldValue(EMenuContainerAddNewItemConstants.IS_VISIBLE, false);
      setIsOpenedConfirmation(!isOpenedConfirmation);
    } else {
      addNewItemForm.validate();
    }
  };

  const handleSubmit = (values: Omit<TAddOrEditItem, 'id'>) => {
    const urlValue = !values.url?.trim() ? '#' : values.url?.trim();

    if (addNewItemForm.isValid()) {
      if (editItem) {
        onChange({ ...editItem, ...values, url: urlValue }, parentId || null);
      } else {
        onChange(values, parentId || null);
      }

      addNewItemForm.reset();
    } else {
      addNewItemForm.validate();
    }
    onClose();
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      addNewItemForm.setFieldValue(EMenuContainerAddNewItemConstants.IS_VISIBLE, true);
    } else {
      setIsOpenedConfirmation(true);
    }
  };

  return (
    <Modal
      centered
      opened={isOpened}
      onClose={onClose}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Text size={24} align='left' weight={400}>
            {editItem ? 'Редактирование пункта меню' : 'Добавление пункта меню'}
          </Text>
        </Box>
      }
      size={640}
      withCloseButton
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          minHeight: 450,
        }}
      >
        <form
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '24px' }}
          onSubmit={addNewItemForm.onSubmit((values) => handleSubmit(values))}
        >
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <TextInput
              withAsterisk
              label='Название'
              placeholder='Введите название'
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.MENU_NAME)}
            />
            <Checkbox
              label='Выделить жирным'
              checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.TEXT_BOLD]}
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.TEXT_BOLD)}
            />
          </Box>
          <Radio.Group
            label='Тип пункта'
            {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.ITEM_TYPE)}
          >
            <Radio value={MenuItemTypeEnum.INTERNAL} label='Страница сайта' />
            <Radio value={MenuItemTypeEnum.EXTERNAL} label='Внешняя ссылка' />
          </Radio.Group>
          {addNewItemForm.values[EMenuContainerAddNewItemConstants.ITEM_TYPE] ===
          MenuItemTypeEnum.INTERNAL ? (
            <TextInput
              label='Url'
              placeholder='Введите ссылку'
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.URL)}
            />
          ) : (
            <TextInput
              label='Url'
              placeholder='Введите внешнюю ссылку'
              {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.URL)}
            />
          )}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              gap: 24,
            }}
          >
            {isCorpSite ? (
              <Checkbox
                label='Отображать пункт на КС'
                checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_VISIBLE]}
                onChange={handleCheckboxChange}
                disabled={!visibilityParent}
              />
            ) : (
              <Checkbox
                label='Отображать пункт на ПС'
                checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_VISIBLE]}
                onChange={handleCheckboxChange}
                disabled={!visibilityParent}
              />
            )}
          </Box>
          <Checkbox
            label='Добавить атрибут nofollow'
            checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_NO_FOLLOW]}
            {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.IS_NO_FOLLOW)}
          />
          <Checkbox
            label='Поместить в нижнюю часть бургера на десктопе'
            checked={addNewItemForm.values[EMenuContainerAddNewItemConstants.IS_BURGER_BOTTOM]}
            {...addNewItemForm.getInputProps(EMenuContainerAddNewItemConstants.IS_BURGER_BOTTOM)}
          />
          <Group position='right' pt={32}>
            <Button type='submit' px={60} sx={{ fontSize: 16 }}>
              Подтвердить
            </Button>
          </Group>
        </form>
        <ModalConfirm
          title={EAddNewItemConfirmationTexts.TITLE}
          text={
            isCorpSite
              ? EAddNewItemConfirmationTexts.REMOVE_FROM_CORP_SITE
              : EAddNewItemConfirmationTexts.REMOVE_FROM_USER_SITE
          }
          confirmHandler={handlePopup}
          opened={isOpenedConfirmation}
          onClose={() => setIsOpenedConfirmation(!isOpenedConfirmation)}
        />
      </Box>
    </Modal>
  );
};
