import { TContentData } from '@/ui/containers/ContentEditor/ContentEditor.types';

export const SELECT_VALUE = {
  compact: { value: 'Компактная акция', label: 'Компактная акция' },
  full: { value: 'Полная акция', label: 'Полная акция' },
};

export const TEMPLATE: { compact: TContentData; full: TContentData } = {
  compact: {
    versionModel: 1,
    grid: [
      {
        gapColumnSize: '0',
        gapRowSize: '32',
        backgroundColor: '',
        blocks: [
          {
            id: '',
            variant: 'compactPromo',
            versionModel: 1,
            image: {
              id: '',
              name: '',
              path: '',
            },
            imageAlt: '',
            imageTitle: '',
            description: '',
          },
        ],
      },
      {
        gapColumnSize: '0',
        gapRowSize: '32',
        backgroundColor: '',
        blocks: [
          {
            id: '',
            selectionCode: '',
            variant: 'catalog',
            versionModel: 1,
          },
        ],
      },
    ],
  },
  full: {
    versionModel: 1,
    grid: [
      {
        gapColumnSize: '0',
        gapRowSize: '32',
        backgroundColor: '',
        blocks: [{ bannerId: '', id: '', variant: 'banner', versionModel: 1 }],
      },
      {
        gapColumnSize: '0',
        gapRowSize: '32',
        backgroundColor: '',
        blocks: [
          {
            category: '',
            filters: [],
            id: '',
            isShowAllProducts: false,
            limit: 10,
            products: [],
            title: 'Рекомендуем',
            type: 'custom',
            variant: 'products',
            versionModel: 1,
          },
        ],
      },
      {
        gapColumnSize: '0',
        gapRowSize: '32',
        backgroundColor: '',
        blocks: [
          {
            description: '',
            id: '',
            promoCode: '',
            promoTitle: '',
            title: '',
            variant: 'promo',
            versionModel: 1,
          },
        ],
      },
      {
        gapColumnSize: '0',
        gapRowSize: '32',
        backgroundColor: '',
        blocks: [
          {
            content: '',
            id: '',
            isFullWidth: false,
            variant: 'html',
            versionModel: 1,
          },
        ],
      },
      {
        gapColumnSize: '0',
        gapRowSize: '32',
        backgroundColor: '',
        blocks: [
          {
            id: '',
            selectionCode: '',
            variant: 'catalog',
            versionModel: 1,
          },
        ],
      },
    ],
  },
};
