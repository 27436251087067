import * as Yup from 'yup';

import { CODE_URL_REGEXP } from '@/constants/validationSchemas';

import { EMaterialsFormFields } from '@/ui/containers/MaterialsCreationContainer/MaterialsForm/MaterialsForm.constants';
import { ERR_REQUIRED_TEXT } from '@/ui/containers/NewsDetailInfo/NewsDetailInfo.constants';

export const materialFormSchema = Yup.object({
  [EMaterialsFormFields.URL_CODE]: Yup.string()
    .matches(CODE_URL_REGEXP, 'Символьный код не должен содержать спец символы')
    .required(ERR_REQUIRED_TEXT),
  [EMaterialsFormFields.IS_ACTIVE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EMaterialsFormFields.TITLE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EMaterialsFormFields.DATA_ACTIVE_FROM_DAYS]: Yup.string().nullable().required(ERR_REQUIRED_TEXT),
  [EMaterialsFormFields.DATA_ACTIVE_TO]: Yup.string().nullable(),
  [EMaterialsFormFields.TARGET_PAGES]: Yup.array().min(1, ERR_REQUIRED_TEXT),
  [EMaterialsFormFields.DISPLAY_ON_SITES]: Yup.array()
    .min(1, ERR_REQUIRED_TEXT)
    .required(ERR_REQUIRED_TEXT),
  image: Yup.object({
    [EMaterialsFormFields.IMAGE_ID]: Yup.string().nullable(),
    [EMaterialsFormFields.IMAGE_ALT]: Yup.string().nullable(),
    [EMaterialsFormFields.IMAGE_TITLE]: Yup.string().nullable(),
  }),
  [EMaterialsFormFields.SORT]: Yup.number().moreThan(0, 'Значение должно быть больше 0'),
  [EMaterialsFormFields.READING_TIME]: Yup.number().moreThan(0, 'Значение должно быть больше 0'),
  [EMaterialsFormFields.DRAFT_ID]: Yup.string(),
  [EMaterialsFormFields.TYPE]: Yup.string().required(ERR_REQUIRED_TEXT),
  [EMaterialsFormFields.SHORT_DESCRIPTION]: Yup.string()
    .max(150, 'Максимальное количество символов 150')
    .nullable(),
  [EMaterialsFormFields.IS_DISPLAY_VIEWS]: Yup.string(),
  [EMaterialsFormFields.MODIFIED_VIEWS_COUNT]: Yup.number(),
});
