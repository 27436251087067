import { IBanner, IPositionItem } from '@/types/banner/banner';

const FILTERED_BANNERS = [
  'stories',
  'platform',
  'roleParents',
  'roleEducators',
  'roleStudents',
  'roleDirectors',
  'mini121',
  'mini122',
  'mini221',
  'mini222',
  'offer',
];

const filterBannerByPositions = (positions: IPositionItem[]) => {
  return positions.filter(({ page, places }) => {
    return (
      !FILTERED_BANNERS.includes(page) && !places.some((place) => FILTERED_BANNERS.includes(place))
    );
  });
};

export const getConvertAndFilteredBanners = (banners: IBanner[]) => {
  return banners
    .filter(({ positions }) => !!filterBannerByPositions(positions).length)
    .map(({ uuid, title }) => ({
      value: uuid || '',
      label: title ?? '',
    }))
    .flat();
};
