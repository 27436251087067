import { Sx } from '@mantine/core';

export const root: Sx = {
  width: '75%',
  form: {
    gridTemplateColumns: 'repeat(12, 1fr)',
    display: 'grid',
    gap: '24px',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #E8ECF0',
    padding: '12px',
  },
};
