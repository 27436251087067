import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tryGetCatalogFilters } from '@/api/catalog/catalog';
import { Category } from '@/api/catalog/categories';

import { LIMIT, OFFSET } from '@/constants/common';

interface ICategoryListState {
  status: {
    fetchingNewsList: boolean;
    fetchingAddNews: boolean;
  };
  items: Category[] | null;
  total: number | null;
  limit: number;
  offset: number;
}

const initialState: ICategoryListState = {
  status: {
    fetchingNewsList: true,
    fetchingAddNews: false,
  },
  items: null,
  total: null,
  limit: LIMIT,
  offset: OFFSET,
};

type TCategoryListSelector = { categories: ICategoryListState };
export const fetchCatalogFiltersAction = createAsyncThunk('/filter', async () => {
  const result = await tryGetCatalogFilters();

  if (result) {
    return result.payload;
  } else {
    return null;
  }
});

export const catalogSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setNewsPage: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogFiltersAction.pending, (state) => {
        state.status.fetchingNewsList = true;
      })
      .addCase(fetchCatalogFiltersAction.fulfilled, (state, action) => {
        state.status.fetchingNewsList = false;
        state.items = action.payload.items;
        state.total = action.payload.meta.total;
      })
      .addCase(fetchCatalogFiltersAction.rejected, (state) => {
        state.status.fetchingNewsList = false;
      });
  },
});

export const CategoryListSelector = (state: TCategoryListSelector) => {
  return state.categories?.items;
};

export default catalogSlice.reducer;
