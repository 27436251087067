export const COLOR_PICKER_COLORS = [
  '#DFF1FF',
  '#D9480F',
  '#E67700',
  '#5C940D',
  '#2B8A3E',
  '#1864AB',
  '#364FC7',
  '#5F3DC4',
  '#862E9C',
  '#A61E4D',
  '#C92A2A',
  '#101113',
  '#212529',
  '#495057',
  '#868E96',
  '#ADB5BD',
  '#DEE2E6',
  '#E9ECEF',
];
