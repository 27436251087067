import { TFeature, TFeatureResponse, TFeatureUpdateResponse } from '@/types/features/features';

import { api } from '@/api';
import { URLS } from '@/api/urls';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

export const getFeaturesList = () => api.get(URLS.featureToggles.getFeatureToggles);
export const patchFeature = (params: TFeatureUpdateResponse) =>
  api.patch(URLS.featureToggles.patchFeature(), { 'feature-toggle': [params] });

// TODO uncomment
// export const getFeaturesList = (params: TQueryParams) =>
//   api.get<TGetTMailsTemplate>(URLS.news.getNewsList(), { params });

export const tryGetFeaturesList = async (): Promise<TFeatureResponse<TFeature[]>> => {
  try {
    const { data } = await getFeaturesList();
    return data;
  } catch (e) {
    // @ts-ignore
    return axiosErrorHandler;
  }
};

export const tryUpdateFeature = async (
  params: TFeatureUpdateResponse,
): Promise<TFeatureResponse<TFeature[]>> => {
  try {
    const { data } = await patchFeature(params);
    return data;
  } catch (e) {
    // @ts-ignore
    return axiosErrorHandler;
  }
};
