import { FC } from 'react';
import { Box, Image, Text } from '@mantine/core';

import * as sx from './LabelBlock.styles';
import { LabelBlockProps } from './LabelBlock.types';

export const LabelBlock: FC<LabelBlockProps> = ({ label, description, image }) => {
  return (
    <Box sx={sx.root}>
      <Text sx={sx.label} size='sm' weight='bold'>
        {label} <span className='required'>*</span>
      </Text>

      <Text size='sm' color='gray'>
        {description}
      </Text>

      {!!image && <Image sx={sx.image} src={`/images/cube/${image}`} />}
    </Box>
  );
};
