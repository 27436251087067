import { CompactPromoSnippet } from '@prosv/core/types';
import { FilePath } from '@prosv/core/types/base';
import { SnippetValues } from '@prosv/core/types/snippets';
import { v4 as uuid_v4 } from 'uuid';

import { replaceTempFile } from '@/api';

import notify from '@/utils/notify';

import { ECompactPromoSnippetFields } from '@/ui/containers/ContentEditor/snippets/CompactPromoSnippet/СompactPromoSnippet.constants';

export const getCompactPromoBlockDefaultValues = (
  defaultValues?: CompactPromoSnippet,
): SnippetValues<CompactPromoSnippet> => {
  return {
    [ECompactPromoSnippetFields.ID]: defaultValues?.id || uuid_v4(),
    [ECompactPromoSnippetFields.IMAGE]: defaultValues?.image || {
      id: '',
      name: '',
      path: '',
    },
    [ECompactPromoSnippetFields.IMAGE_ALT]: defaultValues?.imageAlt || '',
    [ECompactPromoSnippetFields.IMAGE_TITLE]: defaultValues?.imageTitle || '',
    [ECompactPromoSnippetFields.DESCRIPTION]: defaultValues?.description || '',
  };
};

export const getImageReplacedImage = async (
  image: FilePath,
  sessionId: string,
): Promise<FilePath | null> => {
  try {
    const requestParams = {
      query: {
        sessionId,
        ids: [image.id],
      },
    };
    const replacedImage = await replaceTempFile(requestParams);
    const imageTest = replacedImage?.payload.items[0];
    if (imageTest) {
      return {
        name: imageTest.name,
        id: imageTest.id,
        path: process.env.MEDIA_STORAGE_URL + imageTest?.config.previewConfig.path,
      };
    }
    return null;
  } catch (e) {
    notify({ message: 'Ошибка сохранинея картинки' });
    return null;
  }
};
